import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { BubbleArtistFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { pluralizeText } from '../../utils/textUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ArtistProfileImage } from './ArtistProfileImage';

gql(/* GraphQL */ `
  fragment BubbleArtist on Artist {
    id
    name
    profileImage {
      id
      url
    }
    mainVault {
      id
      contentCount
      type
    }
    linkValue
  }
`);

const NewArtistBubble: FC<{
  artist: FragmentType<BubbleArtistFragmentDoc>;
  className?: string;
}> = ({ artist, className }) => {
  const {
    name,
    profileImage,
    linkValue,
    mainVault: { contentCount },
  } = getFragment(BubbleArtistFragmentDoc, artist);
  return (
    <Link
      to={`/${linkValue}`}
      className={twMerge(
        'h-f relative flex w-full flex-col items-center justify-center gap-3 overflow-clip text-[unset] no-underline transition-all duration-500 ease-in-out hover:opacity-80',
        className,
      )}
    >
      <ArtistProfileImage
        className="aspect-square w-full rounded-full object-cover object-center"
        profileImageUrl={profileImage?.url}
      />
      <View className="flex flex-col items-center">
        <Text className="font-base text-base-l font-medium text-white">{name}</Text>
        {!!contentCount && contentCount > 0 && (
          <Text className="font-base text-base-m font-normal text-base400">
            {contentCount} {pluralizeText({ text: 'track', count: contentCount })}
          </Text>
        )}
      </View>
    </Link>
  );
};

const SkeletonArtistBubble: FC<{ className?: string }> = ({ className }) => {
  return (
    <LoadingSkeleton
      className={twMerge(
        'relative aspect-square w-full overflow-clip rounded-full text-[unset] no-underline',
        className,
      )}
    >
      <LoadingSkeleton className="h-full w-full rounded-none bg-neutral700" />
    </LoadingSkeleton>
  );
};

export { NewArtistBubble, SkeletonArtistBubble };
