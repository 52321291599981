import React from 'react';

import * as Sentry from '@sentry/react';

import { proxy, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { isUUID4 } from '@soundxyz/utils/validation';
import { useAuthContext } from '../contexts/AuthContext';
import { fetchGQL } from '../graphql/client';
import {
  type MutationReportPlaySessionInput,
  ReportPlayStoppedAnalyticsDocument,
} from '../graphql/generated';

export interface PlaySession {
  startTime: number;
  trackID: string;
  sessionId: string;
  isPreview: boolean;
}

interface UserSessionStats {
  numTimesPaused: number;
  lastStartTime?: number;
  listenDurationSeconds: number;
}

interface SealedSession extends PlaySession, UserSessionStats {}

interface TrackPlaySessionState {
  sealedSession?: SealedSession;
  currentSession?: PlaySession;
  stats: UserSessionStats;
}

const trackPlaySessionsState = proxy<TrackPlaySessionState>({
  stats: {
    numTimesPaused: 0,
    listenDurationSeconds: 0,
  },
});

const reset = () => {
  trackPlaySessionsState.currentSession = undefined;
  trackPlaySessionsState.stats = {
    numTimesPaused: 0,
    listenDurationSeconds: 0,
    lastStartTime: undefined,
  };
};

const currentListenDuration = (): number => {
  if (!trackPlaySessionsState.stats.lastStartTime) return 0;

  return (Date.now() - trackPlaySessionsState.stats.lastStartTime) / 1000;
};

const updateListenDuration = (paused: boolean = true) => {
  if (trackPlaySessionsState.stats.lastStartTime) {
    trackPlaySessionsState.stats.listenDurationSeconds += currentListenDuration();
    trackPlaySessionsState.stats.lastStartTime = paused ? undefined : Date.now();
  }
};

gql(/* GraphQL */ `
  mutation reportPlayStoppedAnalytics($input: MutationReportPlaySessionInput!) {
    reportPlaySession(input: $input)
  }
`);

export const TrackPlaySessionReporter = React.memo(function SealedSessionReporter() {
  const { sealedSession } = useSnapshot(trackPlaySessionsState);
  const { loggedInUser } = useAuthContext();

  React.useEffect(() => {
    const latestReport = sealedSession;
    if (!latestReport || !isUUID4(latestReport.trackID)) return;

    const reportInput: MutationReportPlaySessionInput = {
      sessionId: latestReport.sessionId,
      vaultContentId: latestReport.trackID,
      duration: latestReport.listenDurationSeconds,
      numTimesPaused: latestReport.numTimesPaused,
      isPreview: latestReport.isPreview,
    };
    fetchGQL(ReportPlayStoppedAnalyticsDocument, {
      variables: {
        input: reportInput,
      },
      keepalive: true,
    }).catch(error => {
      Sentry.captureException(error, {
        extra: {
          reportInput,
        },
        tags: {
          type: 'reportPlayStoppedAnalyticsMutation',
        },
      });
    });
  }, [loggedInUser?.id, sealedSession]);

  return null;
});

export const getCurrentSession = () => {
  return trackPlaySessionsState.currentSession;
};

export const getLastFinishedSession = () => {
  return trackPlaySessionsState.sealedSession;
};

export const startSession = (session: PlaySession) => {
  trackPlaySessionsState.currentSession = session;
  trackPlaySessionsState.stats.lastStartTime = session.startTime;
};

export const userPaused = () => {
  if (!trackPlaySessionsState.currentSession) return;

  trackPlaySessionsState.stats.numTimesPaused++;
  updateListenDuration();
};

export const userResumedListening = () => {
  if (!trackPlaySessionsState.currentSession) return;

  trackPlaySessionsState.stats.lastStartTime = Date.now();
};

export const endActiveSession = () => {
  if (!trackPlaySessionsState.currentSession) return;
  updateListenDuration();

  const currentSession = trackPlaySessionsState.currentSession;

  const sealedSession: SealedSession = {
    ...currentSession,
    ...trackPlaySessionsState.stats,
  };

  trackPlaySessionsState.sealedSession = sealedSession;
  reset();
};

export const userReportCurrentSession = () => {
  updateListenDuration(false);

  const { currentSession, stats } = trackPlaySessionsState;

  if (
    stats.listenDurationSeconds > 30 &&
    currentSession != null &&
    isUUID4(currentSession.trackID)
  ) {
    fetchGQL(ReportPlayStoppedAnalyticsDocument, {
      variables: {
        input: {
          sessionId: currentSession.sessionId,
          vaultContentId: currentSession.trackID,
          duration: stats.listenDurationSeconds,
          numTimesPaused: stats.numTimesPaused,
        },
      },
      keepalive: true,
    });

    return true;
  }

  return false;
};
