import { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  type DraggableAttributes,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faRocket, faSave } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronUp } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGripDots } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faRadar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlus } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { getZonedDate } from '../announcement/helpers';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { EventHeader } from '../event/EventHeader';
import { Toggle } from '../forms/Toggle';
import { getDSPIcon } from './helpers';
import { CampaignLinksType } from './schema';
import { CampaignType, type DSPInfo } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const LinksView = ({ type }: { type: CampaignLinksType }) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { fields, setField } = useCampaignForm();

  const [items, setItems] = useState<DSPInfo[]>(fields.dsps);
  const [presaveLinks, setPresaveLinks] = useState<DSPInfo[]>([]);

  useEffect(() => {
    setItems(fields.dsps);
  }, [fields.dsps]);

  useEffect(() => {
    const filteredLinks = items.filter(
      dsp => dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic,
    );

    const sortedLinks = [...filteredLinks].sort((a, _b) => {
      if (fields.showSpotifyPresaveFirst) {
        return a.key === ThirdPartyPlatform.Spotify ? -1 : 1;
      } else {
        return a.key === ThirdPartyPlatform.AppleMusic ? -1 : 1;
      }
    });

    setPresaveLinks(sortedLinks);

    // Check if Spotify link exists
    const hasSpotifyLink = sortedLinks.some(link => link.key === ThirdPartyPlatform.Spotify);
    if (!hasSpotifyLink && fields.showSpotifyPresave) {
      setField('showSpotifyPresave', false);
    }

    // Check if Apple Music link exists
    const hasAppleMusicLink = sortedLinks.some(link => link.key === ThirdPartyPlatform.AppleMusic);
    if (!hasAppleMusicLink && fields.showAppleMusicPresave) {
      setField('showAppleMusicPresave', false);
    }
  }, [
    fields.showSpotifyPresaveFirst,
    items,
    fields.showSpotifyPresave,
    fields.showAppleMusicPresave,
    setField,
  ]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.findIndex(item => item.key === active.id);
        const newIndex = items.findIndex(item => item.key === over?.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        // Update the showSpotifyPresaveFirst field based on the new order
        const isSpotifyFirst =
          newOrder.findIndex(item => item.key === ThirdPartyPlatform.Spotify) <
          newOrder.findIndex(item => item.key === ThirdPartyPlatform.AppleMusic);
        setField('showSpotifyPresaveFirst', isSpotifyFirst);

        setField('dsps', newOrder);
        return newOrder;
      });
    }
  }

  const formatDate = format(
    getZonedDate(fields.releaseDate ?? new Date()),
    'eeee, MMM d, yyyy h:mm a',
  );

  const headerData = () => {
    switch (type) {
      case CampaignLinksType.PresavePrereleaseLinks:
        return {
          title: 'Pre-save page',
          icon: faSave,
          description: `${fields.isEditMode ? 'Edit' : 'Setup'} the pre-save services for your page. Fans will earn receipts from these services.`,
        };
      case CampaignLinksType.PresaveReleaseLinks:
        return {
          title: 'Release page',
          icon: faRocket,
          description: (
            <>
              Your drop will transform into a release page at{' '}
              <strong className="text-vault_text">{formatDate}</strong>. Add additional links you
              want fans to access.
            </>
          ),
        };
      case CampaignLinksType.StreamReleaseLinks:
        return {
          title: 'Release page',
          icon: faRocket,
          description: `${fields.isEditMode ? 'Edit' : 'Add'} links to play your song and other important destinations.`,
        };
    }
  };

  const vaultContentItem = (index: number, vaultItem: DSPInfo) => {
    return (
      <div className="pb-2">
        <SortableLinkInput
          key={vaultItem.key}
          type={type}
          dsp={vaultItem}
          isPresavePreReleaseLink={type === CampaignLinksType.PresavePrereleaseLinks}
          isPresaveReleaseLink={type === CampaignLinksType.PresaveReleaseLinks}
          index={index}
        />
      </div>
    );
  };

  const showAddLinkButton =
    type !== CampaignLinksType.PresavePrereleaseLinks ||
    !fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.Spotify) ||
    !fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.AppleMusic);

  return (
    <View>
      <EventHeader
        icon={headerData().icon}
        title={headerData().title}
        description={headerData().description}
      />
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <View className="flex h-full w-full flex-col gap-2">
          {fields.campaignType === CampaignType.Presave && type === 'presavePreReleaseLinks' ? (
            <SortableContext
              items={presaveLinks.map(item => item.key)}
              strategy={verticalListSortingStrategy}
            >
              <Virtuoso
                useWindowScroll
                data={presaveLinks}
                itemContent={vaultContentItem}
                increaseViewportBy={500}
              />
            </SortableContext>
          ) : (
            <SortableContext
              items={items.map(item => item.key)}
              strategy={verticalListSortingStrategy}
            >
              <Virtuoso
                useWindowScroll
                data={items}
                itemContent={vaultContentItem}
                increaseViewportBy={500}
              />
            </SortableContext>
          )}

          {showAddLinkButton && (
            <Button
              className="mb-4 flex w-full items-center justify-center rounded-full bg-vault_text/15 py-4 text-base-m font-normal text-vault_text"
              leadingIcon={faPlus}
              type="secondary"
              label="Add link"
              onClick={() => {
                openBottomsheet({
                  type: 'DSP',
                  shared: {
                    withVaultTheme: true,
                    showFullScreen: true,
                    preventSwipeToDismiss: true,
                    hidePulleyBar: true,
                    hideCloseBottomsheetButton: true,
                  },
                  dspBottomsheetProps: {
                    type,
                  },
                });
              }}
            />
          )}
        </View>
      </DndContext>
    </View>
  );
};

const SortableLinkInput = ({
  type,
  dsp,
  isPresavePreReleaseLink,
  isPresaveReleaseLink,
  index,
}: {
  type: CampaignLinksType;
  dsp: DSPInfo;
  isPresavePreReleaseLink: boolean;
  isPresaveReleaseLink: boolean;
  index: number;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: dsp.key });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <LinkInput
        type={type}
        dsp={dsp}
        isPresavePreReleaseLink={isPresavePreReleaseLink}
        isPresaveReleaseLink={isPresaveReleaseLink}
        attributes={attributes}
        listeners={listeners ?? {}}
        index={index}
      />
    </div>
  );
};

const LinkInput = ({
  type,
  dsp,
  isPresavePreReleaseLink,
  isPresaveReleaseLink,
  attributes,
  listeners,
  index,
}: {
  type: CampaignLinksType;
  dsp: DSPInfo;
  isPresavePreReleaseLink: boolean;
  isPresaveReleaseLink: boolean;
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap;
  index: number;
}) => {
  const { setField, fields, errors, validateField } = useCampaignForm();
  const [isExpanded, setIsExpanded] = useState(!isPresavePreReleaseLink);
  const [localDsp, setLocalDsp] = useState(dsp);

  const icon = getDSPIcon(dsp.key);

  const updateDSP = (key: keyof DSPInfo, value: string | boolean) => {
    setLocalDsp(prev => ({ ...prev, [key]: value }));
  };

  const showScanningInfo =
    isPresaveReleaseLink &&
    (dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic);

  useEffect(() => {
    const updatedDSPs = fields.dsps.map((d, i) => {
      if (d.key === ThirdPartyPlatform.Other) {
        return i === index ? localDsp : d;
      }
      return d.key === localDsp.key ? localDsp : d;
    });
    // Since there can be multiple Other links, use index instead
    setField('dsps', updatedDSPs);
    validateField('dsps');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDsp, setField, validateField]);

  useEffect(() => {
    if (isPresavePreReleaseLink) setIsExpanded(false);
  }, [isPresavePreReleaseLink]);

  const handleToggleExpand = () => {
    if (!isPresavePreReleaseLink) {
      setIsExpanded(!isExpanded);
    }
  };

  const hasError =
    errors.dsps?.some(e => e?.key === dsp.key && e?.index === index && (e?.uri || e?.buttonText)) ||
    (errors.contentType &&
      (dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic));

  const getCheckedValue = (): boolean => {
    if (dsp.key === ThirdPartyPlatform.AppleMusic) {
      return fields.showAppleMusicPresave === true;
    } else if (dsp.key === ThirdPartyPlatform.Spotify) {
      return fields.showSpotifyPresave === true;
    }
    return true;
  };

  const handleToggleChange = (checked: boolean) => {
    if (dsp.key === ThirdPartyPlatform.AppleMusic) {
      setField('showAppleMusicPresave', checked);
    } else if (dsp.key === ThirdPartyPlatform.Spotify) {
      setField('showSpotifyPresave', checked);
    }
  };

  return (
    <View
      className={twMerge(
        'flex flex-col gap-2 rounded-lg bg-vault_text/5 p-5',
        !isExpanded && hasError && 'border border-solid border-destructive300',
      )}
    >
      <View
        className="flex cursor-pointer touch-none flex-row justify-between outline-none"
        onClick={handleToggleExpand}
        {...attributes}
        {...listeners}
      >
        <View className="flex flex-row items-center gap-2">
          <FontAwesomeIcon
            icon={faGripDots}
            fontSize="16"
            className="mr-1 cursor-move text-vault_text/60 outline-none"
            {...attributes}
            {...listeners}
          />
          <FontAwesomeIcon
            className={twMerge('h-5 w-5 text-vault_text', dsp.showLink ? '' : 'opacity-50')}
            icon={icon}
          />
          <Text
            className={twMerge(
              'font-title !text-title-s font-medium text-vault_text',
              dsp.showLink ? '' : 'opacity-50',
            )}
          >
            {dsp.name}
          </Text>
        </View>
        <View className="flex flex-row items-center gap-4 text-vault_text/50">
          {dsp.uri && dsp.uri.trim() !== '' && (
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              fontSize="14"
              className="cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                window.open(dsp.uri, '_blank');
              }}
            />
          )}
          {isPresavePreReleaseLink ? (
            <Toggle
              className=" text-vault_text"
              componentClassName="bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent"
              label=""
              checked={getCheckedValue()}
              onChange={e => handleToggleChange(e.target.checked)}
            />
          ) : (
            <FontAwesomeIcon
              icon={isExpanded ? faChevronUp : faChevronDown}
              fontSize="16"
              className="cursor-pointer"
            />
          )}
        </View>
      </View>
      {isExpanded && (
        <View className="flex flex-col gap-5">
          <View className="flex flex-col">
            {dsp.key === ThirdPartyPlatform.Other && (
              <>
                <input
                  className={clsx(
                    'mt-2 rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
                    errors.dsps?.some(e => e?.key === dsp.key && e?.index === index && e?.name)
                      ? 'border-destructive300'
                      : 'border-vault_text/10  focus:border-vault_text',
                  )}
                  placeholder="link title"
                  value={localDsp.name}
                  onChange={e => updateDSP('name', e.target.value)}
                />
                {errors.dsps?.some(e => e?.key === dsp.key && e?.index === index && e?.name) && (
                  <Text className="mt-2 text-base-s text-destructive300">
                    {
                      errors.dsps.find(e => e?.key === dsp.key && e?.index === index && e?.name)
                        ?.name
                    }
                  </Text>
                )}
              </>
            )}
            {showScanningInfo && (
              <View className="mt-2 flex flex-row items-center gap-1">
                <FontAwesomeIcon className="text-[13px] text-vault_text/50" icon={faRadar} />
                <Text className="text-base-s font-medium text-vault_text/50">
                  If left blank, we'll auto-scan for your music.
                </Text>
              </View>
            )}
            <div className="relative mt-2">
              <input
                className={clsx(
                  'box-border w-full rounded-md border border-solid bg-transparent py-4 pl-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
                  showScanningInfo ? 'pr-10' : 'pr-4',
                  errors.dsps?.some(e => e?.key === dsp.key && e?.index === index && e?.uri) ||
                    (errors.contentType &&
                      (dsp.key === ThirdPartyPlatform.Spotify ||
                        dsp.key === ThirdPartyPlatform.AppleMusic))
                    ? 'border-destructive300'
                    : 'border-vault_text/10 focus:border-vault_text',
                )}
                placeholder={`${dsp.key === ThirdPartyPlatform.Other ? 'https://' : `Your ${localDsp.name} ${type === CampaignLinksType.PresavePrereleaseLinks ? 'URI' : 'URL'}...`}`}
                value={localDsp.uri}
                onChange={e => updateDSP('uri', e.target.value)}
              />
              {showScanningInfo && (
                <FontAwesomeIcon
                  icon={faRadar}
                  className={twMerge(
                    'absolute right-4 top-1/2 -translate-y-1/2 text-[16px]',
                    localDsp.uri ? 'text-vault_text/50' : 'text-vault_accent',
                  )}
                />
              )}
            </div>
            {(errors.dsps?.some(e => e?.key === dsp.key && e?.index === index && e?.uri) ||
              (errors.contentType &&
                (dsp.key === ThirdPartyPlatform.Spotify ||
                  dsp.key === ThirdPartyPlatform.AppleMusic))) && (
              <Text className="mt-2 text-base-s text-destructive300">
                {errors.dsps?.find(e => e?.key === dsp.key && e?.index === index && e?.uri)?.uri ||
                  errors.contentType}
              </Text>
            )}
            <input
              className="mt-2 rounded-md border border-solid border-vault_text/10 bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:border-vault_text focus:font-normal focus:outline-none"
              placeholder="Button title"
              value={localDsp.buttonText}
              onChange={e => updateDSP('buttonText', e.target.value)}
            />
          </View>

          <View className="mx-1 flex flex-row justify-between text-vault_text/60">
            <Toggle
              className="flex-row-reverse justify-end gap-2 text-vault_text"
              componentClassName="bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent"
              label="show link"
              checked={dsp.showLink}
              onChange={e => {
                updateDSP('showLink', e.target.checked);
              }}
            />

            {fields.campaignType === CampaignType.Stream ||
            type !== CampaignLinksType.PresavePrereleaseLinks ||
            (type === CampaignLinksType.PresavePrereleaseLinks &&
              fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.Spotify) &&
              fields.dsps.some(dsp => dsp.key === ThirdPartyPlatform.AppleMusic)) ? (
              <FontAwesomeIcon
                icon={faTrash}
                fontSize="16"
                className="cursor-pointer"
                onClick={() => {
                  // Since there can be multiple Other links, use index instead
                  if (dsp.key === ThirdPartyPlatform.Other) {
                    const index = fields.dsps.findIndex(item => item.key === dsp.key);
                    if (index !== -1) {
                      const updatedDsps = [...fields.dsps];
                      updatedDsps.splice(index, 1);
                      setField('dsps', updatedDsps);
                    }
                  } else {
                    const updatedDsps = fields.dsps.filter(item => item.key !== dsp.key);
                    setField('dsps', updatedDsps);
                  }
                }}
              />
            ) : null}
          </View>
        </View>
      )}
    </View>
  );
};
