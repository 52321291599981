import React from 'react';
import { twMerge } from 'tailwind-merge';
import { View, type ViewProps } from '../common/View';

export function LoadingSkeleton({
  className,
  children,
  withVaultTheme = false,
}: ViewProps & { withVaultTheme?: boolean }) {
  return (
    <View
      className={twMerge(
        'rounded-md bg-neutral800',
        withVaultTheme && 'bg-vault_text/10',
        className,
        'animate-pulse',
      )}
    >
      {children}
    </View>
  );
}
