import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { APP_ICON } from '../../constants/imageConstants';
import { VAULT_FM_APP_STORE_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import { LoginStatus } from '../../types/authTypes';
import type { GetAppBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function GetAppBottomsheet({ vaultId, interstitial, onContinue }: GetAppBottomsheetProps) {
  const { pathname } = useLocation();
  const { loginStatus } = useAuthContext();
  const { closeBottomsheet, isOpen } = useBottomsheetContainer();
  const { updateUpsellInterstitials, setShowedInSession } = useUpsellInterstitials();

  const onGetAppClick = () => {
    trackEvent({
      type: EVENTS.GET_APP,
      properties: {
        interstitial,
        vaultId,
        trackId: null,
      },
      pathname,
    });

    closeBottomsheet();
  };

  const onContinueInBrowserClick = () => {
    trackEvent({
      type: EVENTS.CONTINUE_ON_BROWSER,
      properties: {
        interstitial,
        vaultId,
      },
      pathname,
    });

    closeBottomsheet();
    onContinue?.();
  };

  // Trigger the mutation on close
  useEffect(() => {
    if (!isOpen) {
      loginStatus === LoginStatus.LOGGED_IN &&
        updateUpsellInterstitials({
          input: {
            firstChat: interstitial === 'first_chat' || undefined,
            firstTrack:
              interstitial === 'first_track' || interstitial === 'first_media' || undefined,
          },
        });

      // Avoid showing more than one interstitial in the same session
      setShowedInSession(true);
    }
  }, [interstitial, isOpen, loginStatus, setShowedInSession, updateUpsellInterstitials]);

  return (
    <View className="w-full px-4 md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-col items-center justify-center gap-6">
        <View className="flex flex-col items-center justify-center gap-3 px-6">
          <Image src={APP_ICON} alt="app_icon" height={64} width={64} />
          <Text className="text-center font-title text-title-l font-medium text-white">
            Vault is better on the app
          </Text>
          <Text className="text-center font-base text-base-m text-base400">
            {interstitial === 'first_track' ? (
              <>
                Listen to music in the background, share
                <br />
                snippets to social media and more.
              </>
            ) : interstitial === 'first_media' ? (
              <>
                See artist content, share
                <br />
                to social media and more.
              </>
            ) : (
              <>
                Get notified when the Artist is live in chat,
                <br />
                replies to your messages and more.
              </>
            )}
            <br />
          </Text>
        </View>

        <View className="flex w-4/5 flex-col items-center justify-center gap-6 px-6">
          <Button
            label="Get app"
            isExternal
            href={VAULT_FM_APP_STORE_URL}
            type="primary"
            className="w-full"
            linkClassName="w-full"
            onClick={onGetAppClick}
          />

          <Button
            label="Continue in browser"
            onClick={onContinueInBrowserClick}
            type="default"
            className="w-full !font-title !text-title-s !font-medium text-white"
          />
        </View>
      </View>
    </View>
  );
}
