import React, { type FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faInstagram, faTiktok, faXTwitter } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { useAudioController } from '../audio/AudioController';
import { BackButton } from '../components/buttons/BackButton';
import { Image } from '../components/common/Image';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { ABOUT_IMAGE } from '../constants/imageConstants';
import { HIDDEN_ABOUT_NAVIGATION, ROUTES } from '../constants/routeConstants';
import {
  VAULT_FM_INSTAGRAM_URL,
  VAULT_FM_TIKTOK_URL,
  VAULT_FM_TWITTER_URL,
} from '../constants/urlConstants';
import { useLatestRef } from '../hooks/useLatestRef';
import { useWindow } from '../hooks/useWindow';

const HEIGHT_LIMIT = 1000;

export const AboutPage: FC = () => {
  const [containerHeightClass, setContainerHeightClass] = useState<'about-tall' | 'about-short'>(
    () => (window.innerHeight > HEIGHT_LIMIT ? 'about-tall' : 'about-short'),
  );

  const isFromSettings = window.location.pathname.includes('/settings');

  const currentContainerHeightClass = useLatestRef(containerHeightClass);
  const { isDesktop } = useWindow();

  const { activeTrackId } = useAudioController();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > HEIGHT_LIMIT) {
        if (currentContainerHeightClass.current === 'about-short') {
          setContainerHeightClass('about-tall');
        }
      } else {
        if (currentContainerHeightClass.current === 'about-tall')
          setContainerHeightClass('about-short');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [containerHeightClass, currentContainerHeightClass]);

  if (HIDDEN_ABOUT_NAVIGATION) {
    return <Navigate to={ROUTES.VAULTS} replace />;
  }

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      contentClassName="md2:bg-white/3"
      headerLeft={!isDesktop || isFromSettings ? <BackButton /> : null}
      headerCenter={
        !isDesktop || isFromSettings ? (
          <Text className="text-center font-title text-title-m font-medium">About</Text>
        ) : null
      }
    >
      <View className={twMerge('flex flex-col items-center justify-between', containerHeightClass)}>
        <View className="flex w-full flex-col items-center justify-between">
          <Image src={ABOUT_IMAGE} alt="About" className="w-full" />
          <Text className="mb-4 font-title text-title-l">Empowering artists & fans</Text>
          <Text className="mb-8 w-[90%] justify-center text-center font-body text-base-m">
            With Vault.fm, artists can share their unreleased tracks directly from their vault to
            their fans and tap into a new recurring revenue stream.
          </Text>

          <Text className="mb-8 w-[90%] justify-center text-center font-body text-base-m">
            For questions, email{' '}
            <a href="mailto:help@vault.fm" className="font-semibold text-white">
              help@vault.fm
            </a>
          </Text>
        </View>
        <View
          className={twMerge(
            'flex flex-row items-center justify-center pb-5 text-center',
            activeTrackId != null && 'pb-12',
          )}
        >
          <View className="mx-2 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black hover:cursor-pointer">
            <a href={VAULT_FM_INSTAGRAM_URL} target="_blank">
              <FontAwesomeIcon icon={faInstagram} className="size-6 text-base500" />
            </a>
          </View>
          <View className="mx-2 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black hover:cursor-pointer">
            <a href={VAULT_FM_TIKTOK_URL} target="_blank">
              <FontAwesomeIcon icon={faTiktok} className="size-6 text-base500" />
            </a>
          </View>
          <View className="mx-2 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black hover:cursor-pointer">
            <a href={VAULT_FM_TWITTER_URL} target="_blank">
              <FontAwesomeIcon icon={faXTwitter} className="size-6 text-base500" />
            </a>
          </View>
        </View>
      </View>
    </DefaultLayout>
  );
};
