import { useEffect, useMemo, useState } from 'react';
import parsePhoneNumberFromString, {
  type CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js/min';
import { COUNTRY_CODES } from '../constants/phoneConstants';
import { SignInStore } from '../screens/auth/store';

export function useSignIn() {
  const signInState = SignInStore.useStore().value;

  const [phone, setPhone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>(() => {
    if (signInState?.lastCountryCode != null) return signInState.lastCountryCode;

    const intlCountry = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1];

    if (!intlCountry) return 'US';

    return COUNTRY_CODES.find(({ code }) => code === intlCountry)?.code ?? 'US';
  });
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (countryCode === SignInStore.state.value?.lastCountryCode) return;

    SignInStore.produceExistingState(
      draft => {
        draft.lastCountryCode = countryCode;
      },
      {
        codeRenabled: {},
        lastActivePhoneNumber: null,
        lastCountryCode: countryCode,
      },
    );
  }, [countryCode]);

  const validPhoneNumber = useMemo(() => {
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode as CountryCode)?.number;

    if (
      phoneNumber == null ||
      (!isValidPhoneNumber(phone, countryCode as CountryCode) && phoneNumber !== '+15555555555')
    ) {
      return null;
    }

    return phoneNumber;
  }, [phone, countryCode]);

  const codeRenabled = signInState?.codeRenabled[validPhoneNumber ?? '_'] ?? 1;
  const lastActivePhoneNumber = signInState?.lastActivePhoneNumber;

  return {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
  };
}
