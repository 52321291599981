import { useEffect, useMemo } from 'react';
import { throttle } from 'lodash-es';
import ms from 'ms';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import type { Maybe } from '@soundxyz/utils';
import { useAuthContext } from '../../contexts/AuthContext';
import { type ExecutionResultWithData, invalidateOperations, useQuery } from '../../graphql/client';
import {
  GetUnreadAnnouncementsCountDocument,
  type GetUnreadAnnouncementsCountQuery,
  type GetUnreadAnnouncementsCountQueryVariables,
} from '../../graphql/generated';

import { createBatchingInputStore } from '../../utils/batchInput';
import { useStableCallback } from '../useStableCallback';
import { UpdatingVaultAnnouncementEngagement } from './useVaultAnnouncementEngagement';
import { useVaultScheduledEventUpdatesSubscription } from './useVaultScheduledEventUpdatesSubscription';

gql(/* GraphQL */ `
  query GetUnreadAnnouncementsCount($vaultIds: [UUID!]!) {
    unreadAnnouncementsCounts(vaultIds: $vaultIds) {
      __typename
      ... on QueryUnreadAnnouncementsCountsSuccess {
        data {
          vaultId
          unreadCount
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const { useBatchedKey } = createBatchingInputStore({
  chunkLimit: 25,
});

const throttledRefetchSubscriptionCount = throttle(
  () => {
    invalidateOperations({
      operations: [GetUnreadAnnouncementsCountDocument],
    });
  },
  ms('5 seconds'),
  {
    leading: true,
    trailing: true,
  },
);

export function useBatchedVaultUnreadAnnouncementCount({
  enabled,
  vaultId,
}: {
  enabled: boolean;
  vaultId: Maybe<string>;
}) {
  const { inProgress } = useSnapshot(UpdatingVaultAnnouncementEngagement);

  const ids = useBatchedKey({
    key: vaultId,
  });

  const variables = useMemo<GetUnreadAnnouncementsCountQueryVariables | false>(() => {
    if (!ids) return false;

    return {
      vaultIds: ids,
    };
  }, [ids]);

  const { loggedInUser } = useAuthContext();

  const isQueryEnabled = enabled && !!variables && !!loggedInUser;

  const { data: unreadCount, isLoading } = useQuery(GetUnreadAnnouncementsCountDocument, {
    enabled: isQueryEnabled,
    variables,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
    select: useStableCallback((data: ExecutionResultWithData<GetUnreadAnnouncementsCountQuery>) => {
      return data.data.unreadAnnouncementsCounts.__typename ===
        'QueryUnreadAnnouncementsCountsSuccess'
        ? data.data.unreadAnnouncementsCounts.data.find(value => value.vaultId === vaultId)
            ?.unreadCount ?? null
        : null;
    }),
  });

  const shouldRefetch = inProgress === 0 && isQueryEnabled;

  useEffect(() => {
    if (shouldRefetch) {
      invalidateOperations({
        operations: [GetUnreadAnnouncementsCountDocument],
      });
    }
  }, [shouldRefetch]);

  useVaultScheduledEventUpdatesSubscription({
    vaultId,
    onSubscriptionData({ data: { scheduledEventUpdates } }) {
      if (scheduledEventUpdates.__typename !== 'SubscriptionScheduledEventUpdatesSuccess') return;

      switch (scheduledEventUpdates.data.__typename) {
        case 'AnnouncementUpdateSubscription':
          throttledRefetchSubscriptionCount();
          break;
      }
    },
  });

  return {
    unreadCount,
    isLoading,
  };
}
