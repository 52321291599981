export const ROUTES = {
  ABOUT: '/about',
  EXPLORE: '/explore',
  LANDING_PAGE: '/',
  NOT_FOUND: '/404',
  ONBOARDING: '/onboarding',
  ONBOARDING_USERNAME: '/onboarding/username',
  SETTINGS: '/settings',
  SETTINGS_ARTIST_PAYOUTS: '/settings/payouts',
  SETTINGS_COLLABORATOR_PAYOUTS: '/settings/collaborator-payouts',
  SIGN_IN: '/sign_in',
  JOIN: '/join',
  VAULTS: '/vaults',
  VERIFY: '/verify',
  SPOTIFY_CONNECT: '/spotify-connect',
} as const;

export const HIDDEN_EXPLORE_VAULTS_NAVIGATION = true;
export const HIDDEN_ABOUT_NAVIGATION = true;
