import { proxy } from 'valtio';
import { MediaType } from '../graphql/generated';
import { fileIdentifier } from '../utils/s3Utils';

export const ImagesAttachment = proxy<{
  images: {
    identifier: string;
    progress: number;
    objectUrl: string;
    uploaded: boolean;
    mediaId: string | null;
    cdnUrl: string | null;
    type: MediaType;
  }[];
  isUploading: boolean;
  setImages: (files: File[]) => void;
  updateImageWithUploadResult: (
    identifier: string,
    mediaId: string | null,
    cdnUrl: string | null,
  ) => void;
  updateProgress: (identifier: string, progress: number) => void;
  removeImage: (identifier: string) => void;
  clearImages: () => void;
}>({
  images: [],
  isUploading: false,
  setImages(files: File[]) {
    const newImages = files.map(file => ({
      identifier: fileIdentifier(file),
      progress: 0,
      objectUrl: URL.createObjectURL(file),
      uploaded: false,
      mediaId: null,
      cdnUrl: null,
      type: MediaType.Image,
    }));

    // Add new images to the existing array, avoiding duplicates based on identifier
    const updatedImages = [...ImagesAttachment.images];
    newImages.forEach(newImage => {
      const existingIndex = updatedImages.findIndex(
        image => image.identifier === newImage.identifier,
      );
      if (existingIndex === -1) {
        updatedImages.push(newImage);
      } else {
        // Optionally, update the existing image if needed
        updatedImages[existingIndex] = { ...updatedImages[existingIndex], ...newImage };
      }
    });

    ImagesAttachment.images = updatedImages;
  },
  updateProgress(identifier: string, progress: number) {
    ImagesAttachment.images = ImagesAttachment.images.map(image =>
      image.identifier === identifier ? { ...image, progress } : image,
    );
  },
  updateImageWithUploadResult(identifier: string, mediaId: string | null, cdnUrl: string | null) {
    ImagesAttachment.images = ImagesAttachment.images.map(image =>
      image.identifier === identifier ? { ...image, mediaId, cdnUrl, uploaded: true } : image,
    );
  },
  removeImage(identifier: string) {
    ImagesAttachment.images = ImagesAttachment.images.filter(
      image => image.identifier !== identifier,
    );
  },
  clearImages() {
    ImagesAttachment.images.forEach(image => URL.revokeObjectURL(image.objectUrl));
    ImagesAttachment.isUploading = false;
    ImagesAttachment.images = [];
  },
});
