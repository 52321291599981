import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';

import { type FieldValues, useFormContext, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

/**
 * Needs to be mounted inside a FormProvider.
 * onSubmit needs to return the new state of the form.
 *
 */
export const HookAutoSave = <T extends FieldValues>({
  onSubmit,
  debounceTime = 100,
}: {
  onSubmit: (data: T) => Promise<T>;
  debounceTime?: number;
}) => {
  const methods = useFormContext<T>();
  const [submittedData, setSubmittedData] = useState<T>();

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(() => {
      methods.handleSubmit(async vals => {
        const newVals = await onSubmit(vals);
        setSubmittedData(newVals);
        return newVals;
      })();
    }, debounceTime),
    [],
  );

  const watchedData = useWatch<T>({
    control: methods.control,
  });

  useDeepCompareEffect(() => {
    if (methods.formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful && submittedData) {
      methods.reset(submittedData);
    }
  }, [methods.formState.isSubmitSuccessful, submittedData, methods]);

  return null;
};
