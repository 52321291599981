import { type UIEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException, captureMessage } from '@sentry/react';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faApple, faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faHardDrive, faMessages } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faChevronRight, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { uuidv4 } from '@soundxyz/utils';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAppleMusicContext } from '../../contexts/AppleMusicContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useSpotifyContext } from '../../contexts/SpotifyContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import {
  type ClaimReceiptViewFragment,
  ClaimReceiptViewFragmentDoc,
  type FragmentType,
  getFragment,
  GetReceiptNumberDocument,
  type MembershipReceiptFragment,
  MembershipReceiptFragmentDoc,
  type MutationReportPlayStreamReleaseCampaignInput,
  ReleaseCampaignReportPlayStreamDocument,
  ThirdPartyPlatform,
} from '../../graphql/generated';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { usePlayAppleMusic } from '../../hooks/appleMusic/usePlayAppleMusic';
import { useLastMembershipReceipt } from '../../hooks/campaign/useLastMembershipReceipt';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { ONE_MINUTE_IN_MS } from '../../utils/dateUtils';
import {
  createSpotifyUrl,
  extractAppleMusicResourceId,
  extractSpotifyResourceIdFromUrl,
} from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ReceiptUI } from '../membership/ReceiptUI';
import { SpotifyBottomAudioPlayer } from '../spotify/SpotifyAudioPlayer';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { FallbackBadge } from '../vault/MembershipBadge';

gql(/* GraphQL */ `
  fragment ClaimReceiptView on ReleaseCampaignPublicInfo {
    id
    title
    linkValue
    currentState
    spotifyResourceId
    appleMusicResourceId
    contentType
    coverImage {
      id
      url
    }
    initialReleaseImageUrl
    artist {
      id
      name
      linkValue
      profileImage {
        id
        url
      }
    }
    externalLinks {
      id
      url
      platform
    }
  }

  fragment MembershipReceipt on ArtistMembershipReceipt {
    __typename
    id
    createdAt
    membership {
      createdAt
      artist {
        id
        name
        membershipCardImage {
          id
          url
        }
        linkValue
      }
      receipts
      serialNumber
    }
    user {
      id
      displayName
      username
    }
    serialNumber
  }

  mutation ReleaseCampaignReportPlayStream($input: MutationReportPlayStreamReleaseCampaignInput!) {
    reportPlayStreamReleaseCampaign(input: $input) {
      __typename
      ... on MutationReportPlayStreamReleaseCampaignSuccess {
        data {
          id
          ...MembershipReceipt
        }
      }

      ... on Error {
        message
      }
    }
  }

  query GetReceiptNumber($input: QueryReceiptTypeCountInput!) {
    receiptTypeCount(input: $input) {
      __typename
      ... on QueryReceiptTypeCountSuccess {
        data
      }
    }
  }
`);

export function ClaimReceiptView({
  campaignFrag,
  artistData,
  step,
  sourceParam,
}: {
  campaignFrag: FragmentType<ClaimReceiptViewFragmentDoc>;
  artistData?: {
    id: string | null | undefined;
    membershipImage: string | null | undefined;
    linkValue: string | null | undefined;
    name: string | null | undefined;
  };
  step: string | null;
  sourceParam: 'apple' | 'spotify' | null;
}) {
  const { artistHandle } = useArtistHandle();
  const campaign = getFragment(ClaimReceiptViewFragmentDoc, campaignFrag);

  const coverImageUrl = campaign?.coverImage?.url || campaign?.initialReleaseImageUrl;

  const randomUUID = useMemo(() => uuidv4(), []);

  const [source, setSource] = useState<'apple' | 'spotify' | null>(sourceParam);
  const [receiptData, setReceiptData] = useState<MembershipReceiptFragment | null>(null);

  const { data: lastReceiptData, isLoading } = useLastMembershipReceipt({
    artistHandle,
    releaseCampaignId: campaign.id,
    source,
    receiptType: undefined,
    rsvpEventId: undefined,
    enabled: true,
    staleTime: ONE_MINUTE_IN_MS * 2, // If we call this too quickly after, it will get the same new receipt we just got
  });

  const lastMembershipReceipt = getFragment(
    MembershipReceiptFragmentDoc,
    lastReceiptData?.data.lastMembershipReceipt,
  );

  const background = useMemo(() => {
    return (
      <>
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Blurred Track/Album Cover"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        )}

        <View
          className={twMerge(
            'absolute bottom-0 z-base hidden h-full w-full bg-gradient-to-b from-transparent to-black md2:block',
          )}
        />
        <View
          className={twMerge(
            'absolute bottom-0 z-base h-full w-full bg-gradient-to-b from-transparent via-black/80 to-black md2:hidden',
          )}
        />
      </>
    );
  }, [coverImageUrl, randomUUID]);

  const content = useMemo(() => {
    if (step === 'view')
      return <PlayView campaign={campaign} setReceiptData={setReceiptData} setSource={setSource} />;
    if (step === 'claim')
      return (
        <ClaimView
          campaign={campaign}
          receiptData={receiptData || lastMembershipReceipt}
          source={source}
          artistData={artistData}
          alreadyHasReceipt={!!lastMembershipReceipt}
          isLoading={isLoading}
        />
      );

    return null;
  }, [artistData, campaign, lastMembershipReceipt, isLoading, receiptData, source, step]);

  return (
    <View className="no-scrollbar box-border flex h-full w-full flex-col items-center justify-center overflow-x-clip">
      {background}
      {content}
      <View className="sticky bottom-0 z-stickyHeader w-full">
        <ThirdPartyAudioPlayer />
      </View>
    </View>
  );
}

function PlayView({
  campaign,
  setReceiptData,
  setSource,
}: {
  campaign: ClaimReceiptViewFragment;
  setReceiptData: React.Dispatch<React.SetStateAction<MembershipReceiptFragment | null>>;
  setSource: React.Dispatch<React.SetStateAction<'apple' | 'spotify' | null>>;
}) {
  const navigate = useNavigate();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const location = useLocation();

  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);
  const spotifyAuth = useSpotifyAuth({ enabled: spotifyConnectEnabled });

  const { mutateAsync: reportPlayStream, isLoading } = useMutation(
    ReleaseCampaignReportPlayStreamDocument,
    {
      retry: 3,
    },
  );

  const isSpotifyAccountConnected =
    !!loggedInUser?.spotifyAuthConnection?.spotifyUserId ||
    spotifyAuth.type === 'already-connected';

  const appleMusicAuth = useAppleMusicAuth({
    enabled: appleMusicConnectEnabled,
  });

  const isAppleMusicAccountConnected =
    !!loggedInUser?.appleMusicAuthConnections.length ||
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const randomUUID = useMemo(() => uuidv4(), []);

  const artistLinkValue = campaign.artist.linkValue;
  const artistName = campaign.artist.name || campaign.artist.linkValue;
  const artistImageUrl = campaign.artist.profileImage?.url;
  const coverImageUrl = campaign.coverImage?.url || campaign.initialReleaseImageUrl;
  const title = campaign.title;

  const isPresave = campaign.currentState === 'PRESAVE';

  const spotifyLink = campaign.externalLinks.find(
    link => link.platform === ThirdPartyPlatform.Spotify,
  )?.url;

  const appleMusicLink = campaign.externalLinks.find(
    link => link.platform === ThirdPartyPlatform.AppleMusic,
  )?.url;

  const spotifyResourceId =
    campaign.spotifyResourceId ??
    (spotifyLink != null ? extractSpotifyResourceIdFromUrl(spotifyLink) : null);

  const appleMusicResourceId =
    campaign.appleMusicResourceId ??
    (appleMusicLink != null ? extractAppleMusicResourceId(appleMusicLink) : null);

  const contentType = campaign.contentType;

  const spotifyUrl =
    spotifyResourceId != null
      ? createSpotifyUrl({ id: spotifyResourceId, type: contentType })
      : null;

  const {
    onClick: onClickAppleMusicPlay,
    isDisabled: isAppleMusicDisabled,
    isLoading: isAppleMusicLoading,
  } = usePlayAppleMusic({
    resourceId: appleMusicResourceId,
    resourceType: campaign.contentType,
    campaignId: campaign.id,
  });

  const onCampaignPlay = useStableCallback(async (source: 'spotify' | 'apple') => {
    const input: MutationReportPlayStreamReleaseCampaignInput = {
      releaseCampaignId: campaign.id,
    };
    setSource(source);

    if (source === 'spotify') {
      if (
        (!loggedInUser?.spotifyAuthConnection?.spotifyUserId && !spotifyAuth.authCode) ||
        spotifyUrl == null
      ) {
        openToast({
          text: 'Spotify authentication or link value is missing',
          variant: 'error',
        });
        return;
      }

      input.spotifyAuthCode = spotifyAuth.authCode;
      window.open(spotifyUrl, '_blank');
    } else if (source === 'apple') {
      if (loggedInUser?.appleMusicAuthConnections.length == 0 && !appleMusicAuth?.userToken) {
        openToast({
          text: 'Apple music user token is missing',
          variant: 'error',
        });
        return;
      }
      input.appleMusicAuthUserToken = appleMusicAuth.userToken;
      onClickAppleMusicPlay();
    }

    try {
      const { data } = await reportPlayStream({ input });
      if (
        data.reportPlayStreamReleaseCampaign?.__typename ===
          'MutationReportPlayStreamReleaseCampaignSuccess' ||
        data.reportPlayStreamReleaseCampaign === null
      ) {
        setReceiptData(
          data.reportPlayStreamReleaseCampaign?.data as MembershipReceiptFragment | null,
        );
        setSearchParams({ step: 'claim' });
      }

      if (data.reportPlayStreamReleaseCampaign?.__typename === 'NotAuthorizedError') {
        openToast({
          text: data.reportPlayStreamReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign Report Play Stream - Unauthorized', {
          level: 'error',
        });
      }

      if (data.reportPlayStreamReleaseCampaign?.__typename === 'NotFoundError') {
        openToast({
          text: data.reportPlayStreamReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign Report Play Stream - Not Found', {
          level: 'error',
        });
      }
    } catch (error) {
      captureException(error, {
        tags: { feature: 'Report Play Release Campaign', campaignId: campaign.id },
      });
      openToast({
        text: 'There was an error reporting play stream',
        variant: 'error',
      });
    }
  });

  useEffect(() => {
    if (isSpotifyAccountConnected && !isAppleMusicAccountConnected && !isPresave) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.CUSTOM,
        shared: {
          hideCloseBottomsheetButton: true,
          preventOutsideAutoClose: true,
          preventSwipeToDismiss: true,
        },
        customBottomsheetProps: {
          body: (
            <View className="mx-4 flex flex-col gap-4 text-center">
              <Text className="font-title text-title-l">
                Sorry, you must have a Spotify Premium account
              </Text>

              <Text className="text-base-l text-base400">
                We only support Spotify Premium accounts. You can still stream on your favorite
                platform without connecting.
              </Text>
              <Button
                className="mt-8"
                type="primary"
                label="Ok"
                onClick={() => {
                  closeBottomsheet();
                  const pathParts = location.pathname.split('/');
                  const newPath = pathParts.slice(0, pathParts.indexOf('s') + 2).join('/');
                  navigate(`${newPath}?showConnectionModal=true`);
                }}
              />
            </View>
          ),
        },
      });
    }
  }, [
    isSpotifyAccountConnected,
    isAppleMusicAccountConnected,
    openBottomsheet,
    closeBottomsheet,
    openToast,
    spotifyAuth,
    navigate,
    location,
    isPresave,
  ]);

  return (
    <View className="z-above1 flex h-full flex-col items-center justify-center gap-6 md2:w-2/3 md2:min-w-[420px] md2:max-w-[420px]">
      <View className="relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden">
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Track/Album Cover"
            className="aspect-square w-[140px] rounded-xl object-cover md2:w-[240px]"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="aspect-square w-[140px] rounded-xl md2:w-[240px]"
            svgClassName="rounded-xl"
          />
        )}
      </View>

      <View className="z-above1 flex w-full flex-col items-center">
        <Text className="box-border line-clamp-2 w-full px-6 text-center text-[28px]/[34px] font-semibold text-white md2:px-0 md2:text-[40px]/[46px]">
          {title}
        </Text>

        <Link
          className="mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline"
          to={artistNavigationPath(artistLinkValue, '/')}
        >
          <ArtistProfileImage
            className="h-6 w-6 md2:h-8 md2:w-8"
            profileImageUrl={artistImageUrl}
            withVaultTheme
          />

          <Text
            className={twMerge(
              'line-clamp-1 text-[14px]/[18px] text-white',
              'md2:text-[16px]/[20px] md2:font-medium',
            )}
          >
            {artistName}
          </Text>
        </Link>
      </View>

      <View className="z-above1 flex w-full flex-col items-center gap-4 px-6 md2:px-0">
        {isSpotifyAccountConnected && spotifyResourceId != null && (
          <Button
            label="Play"
            type="primary"
            leadingIcon={faSpotify}
            loading={isLoading}
            onClick={() => onCampaignPlay('spotify')}
            disabledClassName="opacity-40"
            event={{ type: EVENTS.SPOTIFY_PLAY, properties: { campaignId: campaign.id } }}
          />
        )}

        {isAppleMusicAccountConnected && appleMusicResourceId != null && (
          <Button
            label="Play"
            type="primary"
            leadingIcon={faApple}
            loading={isLoading || isAppleMusicLoading}
            onClick={() => onCampaignPlay('apple')}
            disabled={isAppleMusicDisabled}
            disabledClassName="opacity-40"
            event={
              isAppleMusicDisabled
                ? undefined
                : { type: EVENTS.APPLE_PLAY, properties: { campaignId: campaign.id } }
            }
          />
        )}
      </View>
    </View>
  );
}

function ClaimView({
  campaign,
  receiptData,
  source,
  artistData,
  alreadyHasReceipt,
  isLoading,
}: {
  campaign: ClaimReceiptViewFragment;
  receiptData: MembershipReceiptFragment | null | undefined;
  source: 'apple' | 'spotify' | null;
  artistData?: {
    id: string | null | undefined;
    membershipImage: string | null | undefined;
    linkValue: string | null | undefined;
    name: string | null | undefined;
  };
  alreadyHasReceipt: boolean;
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { isDesktop } = useWindow();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();
  const { player, setActiveCampaignId } = useSpotifyContext();
  const { musicKit, setCampaignId } = useAppleMusicContext();

  const isPresave = campaign.currentState === 'PRESAVE';

  const { data: receiptNumberData } = useQuery(GetReceiptNumberDocument, {
    staleTime: 0,
    variables: !!artistData?.id && {
      input: {
        artistId: artistData.id,
        receiptType:
          campaign.currentState === 'PRESAVE'
            ? 'ArtistMembershipPresaveReceipt'
            : campaign.currentState === 'STREAMING'
              ? 'ArtistMembershipPlayStreamReceipt'
              : 'ArtistMembershipUnknownReceipt',
        releaseCampaignId: campaign.id,
      },
    },
    enabled: receiptData === null,
  });

  const receiptNumber =
    receiptData?.serialNumber != null
      ? receiptData?.serialNumber
      : receiptNumberData?.data.receiptTypeCount.__typename === 'QueryReceiptTypeCountSuccess'
        ? receiptNumberData.data.receiptTypeCount.data + 1
        : '...';

  const CLAIM_CAROUSEL_DATA = [
    {
      title: alreadyHasReceipt ? 'Already claimed your receipt' : 'You earned a receipt',
      description: alreadyHasReceipt
        ? `Looks like you already claimed your receipt for ${isPresave ? 'pre-saving' : 'streaming'}`
        : isPresave
          ? 'Thanks for pre-saving! Claim your membership to save the receipt'
          : 'Receipts go towards earning status',
      component: 'receipt',
    },
    {
      title: 'Your membership card',
      description: "As you collect more receipts, you'll unlock more rewards and access.",
      component: 'membershipCard',
    },
    {
      title: 'Access my Vault',
      description:
        "I'll share music, images, videos, and more as my way of thanking you for your support.",
      component: 'vault',
    },
    {
      title: 'Chat with me',
      description:
        'Hang out in the group chat with me and let me know what you think about my music.',
      component: 'chat',
    },
  ];

  const randomMemberId = useMemo(() => Math.floor(Math.random() * 10000000), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % CLAIM_CAROUSEL_DATA.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + CLAIM_CAROUSEL_DATA.length) % CLAIM_CAROUSEL_DATA.length);
  };

  const scrollToSlide = (index: number) => {
    const container = containerRef.current;
    if (container) {
      const child = container.firstChild as HTMLElement | null;
      const childWidth = child?.getBoundingClientRect().width || 0;
      container.scrollLeft = childWidth * index;
    }
  };

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          const newIndex = Math.round(scrollLeft / childWidth);

          if (newIndex !== currentSlide) {
            setCurrentSlide(newIndex);
          }
        },
        100,
        { trailing: true },
      ),
    [currentSlide],
  );

  const onClaimReceipt = useStableCallback(() => {
    if (!isPresave) {
      if (player != null) {
        player.activateElement().then(() => {
          player.pause().then(() => {
            setActiveCampaignId(null);
          });
        });
      }

      if (musicKit != null) {
        musicKit.pause();
        setCampaignId(null);
      }
    }

    const linkValue =
      receiptData?.membership.artist.linkValue || artistData?.linkValue || artistHandle;

    if (loginStatus !== LoginStatus.LOGGED_IN) {
      const queryParams = constructQueryParams({
        linkSpotify: source === 'spotify' ? true : undefined,
        linkAppleMusic: source === 'apple' ? true : undefined,
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        source,
        sourceReleaseCampaignId: campaign.id,
        eventType: isPresave ? 'presave' : 'streaming',
        redirect: null,
      });
      navigate(`${ROUTES.SIGN_IN}?${queryParams}`);
    } else {
      const queryParams = constructQueryParams({
        linkSpotify: source === 'spotify' ? true : undefined,
        linkAppleMusic: source === 'apple' ? true : undefined,
        openBottomSheet: alreadyHasReceipt ? undefined : 'freeTierModal',
        source,
        sourceReleaseCampaignId: campaign.id,
        eventType: isPresave ? 'presave' : 'streaming',
      });
      navigate(artistNavigationPath(linkValue, '/', queryParams));
    }
  });

  useEffect(() => {
    scrollToSlide(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    if (!source) setSearchParams({ step: 'view' });
  }, [setSearchParams, source]);

  const renderCarouselContent = useMemo(() => {
    return (component: string | undefined) => {
      if (!component || !artistHandle) return null;

      switch (component) {
        case 'receipt':
          return (
            <ReceiptUI
              className="aspect-[280/378] h-full max-h-[378px] min-h-[322px] w-[unset] max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]"
              title={campaign.title}
              receiptNumber={receiptNumber}
              username={receiptData?.user.username || receiptData?.user.displayName || '@yourname'}
              artistName={receiptData?.membership.artist.name}
              artistHandle={
                receiptData?.membership.artist.linkValue || artistData?.linkValue || artistHandle
              }
              createdAt={receiptData?.createdAt || new Date().toISOString()}
              type={isPresave ? 'pre-save' : 'streaming'}
            />
          );
        case 'membershipCard':
          return (
            <View className="aspect-[280/378] h-full max-h-[378px] w-[unset] max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]">
              <FallbackBadge
                isLoading={isLoading}
                artistName={receiptData?.membership.artist.name || artistData?.name || artistHandle}
                serialNumber={receiptData?.membership.serialNumber || randomMemberId}
                imageUrl={
                  receiptData?.membership.artist.membershipCardImage?.url ||
                  artistData?.membershipImage
                }
                displayName={
                  receiptData?.user.username || receiptData?.user.displayName || 'yourname'
                }
                createdAt={receiptData?.membership.createdAt || new Date().toDateString()}
                receiptCount={receiptData?.membership.receipts || 0}
                withLanyard={false}
              />
            </View>
          );
        case 'vault':
          return <CarouselItem icon={faHardDrive} />;
        case 'chat':
          return <CarouselItem icon={faMessages} />;
        default:
          return null;
      }
    };
  }, [
    artistHandle,
    campaign.title,
    receiptData?.user.username,
    receiptData?.user.displayName,
    receiptData?.membership.serialNumber,
    receiptData?.membership.artist.linkValue,
    receiptData?.membership.artist.name,
    receiptData?.membership.artist.membershipCardImage?.url,
    receiptData?.membership.createdAt,
    receiptData?.membership.receipts,
    receiptData?.createdAt,
    receiptNumber,
    randomMemberId,
    artistData?.linkValue,
    artistData?.name,
    artistData?.membershipImage,
    isPresave,
    isLoading,
  ]);

  return (
    <View className="flex flex-1 flex-col items-center justify-center pb-4 pt-8">
      <View className="z-above4 flex flex-col">
        <View className="flex">
          {isDesktop ? (
            <View className="flex flex-row items-center gap-8">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
                onClick={prevSlide}
              />
              {renderCarouselContent(CLAIM_CAROUSEL_DATA[currentSlide]?.component)}
              <FontAwesomeIcon
                icon={faChevronRight}
                className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
                onClick={nextSlide}
              />
            </View>
          ) : (
            <View
              containerRef={containerRef}
              className="no-scrollbar flex min-h-[322px] w-full flex-shrink-[1] snap-x snap-mandatory flex-row items-center overflow-x-auto"
              onScroll={onScroll}
            >
              {CLAIM_CAROUSEL_DATA.map((item, index) => (
                <motion.div key={index} className="h-full w-full flex-none snap-center">
                  <View className="flex h-full flex-col items-center justify-center overflow-y-hidden">
                    {renderCarouselContent(item.component)}
                  </View>
                </motion.div>
              ))}
            </View>
          )}
        </View>

        {/* Dots */}
        <View className="mt-6 flex justify-center">
          {CLAIM_CAROUSEL_DATA.map((_, index) => (
            <View
              key={index}
              className={`mx-1 h-2 w-2 rounded-full ${
                index === currentSlide ? 'bg-yellow100' : 'bg-base500'
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </View>

        <View className="mt-4 flex flex-[1.4] flex-shrink-[10] flex-col items-center justify-start">
          {/* Text content */}
          <View className="mt-2 box-border w-full max-w-md px-10 md2:px-0">
            <View className="relative">
              <View className="box-border flex flex-col items-center justify-center overflow-hidden rounded-lg px-4 text-center">
                {CLAIM_CAROUSEL_DATA[currentSlide]?.title && (
                  <Text className="mb-2 text-base-xl font-semibold text-white">
                    {CLAIM_CAROUSEL_DATA[currentSlide]?.title}
                  </Text>
                )}

                {CLAIM_CAROUSEL_DATA[currentSlide]?.description && (
                  <Text className="box-border max-h-20 w-full text-base-l text-base400 md2:w-5/6">
                    {CLAIM_CAROUSEL_DATA[currentSlide]?.description}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <Button
            type="primary"
            label={alreadyHasReceipt ? 'View on Vault' : 'Claim receipt'}
            className="mx-8 mt-5 w-[250px]"
            onClick={onClaimReceipt}
            event={{
              type: EVENTS.CLAIM_RECEIPT,
              properties: { campaignId: campaign.id, dropId: undefined },
            }}
          />
        </View>
      </View>
    </View>
  );
}

// Placeholder components for other carousel items
export const CarouselItem = ({ icon }: { icon: IconDefinition }) => (
  <div className="relative box-border flex h-full max-h-[378px] max-w-[280px] flex-col items-center justify-center px-4 md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]">
    <View className="flex aspect-[260/378] h-full items-center justify-center rounded-lg bg-white/5">
      <FontAwesomeIcon icon={icon} className="text-[88px]" />
    </View>
  </div>
);

export const ClaimReceiptViewSkeleton = () => {
  return (
    <View className="box-border h-full w-full items-center justify-center overflow-x-hidden md2:flex">
      <View className="z-above1 flex h-full flex-col items-center justify-center gap-6 md2:w-2/3 md2:min-w-[420px] md2:max-w-[420px]">
        <View className="relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden">
          <LoadingSkeleton className="aspect-square w-[140px] rounded-xl object-cover md2:w-[240px]" />
        </View>

        <View className="z-above1 flex w-full flex-col items-center">
          <LoadingSkeleton className="h-[40px] w-[280px] md2:h-[46px] md2:w-[300px]" />

          <View className="mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline">
            <LoadingSkeleton className="aspect-square h-6 w-6 rounded-full md2:h-8 md2:w-8" />
            <LoadingSkeleton className="h-[20px] w-[100px] md2:h-[22px]" />
          </View>
        </View>

        <View className="z-above1 flex w-full flex-col items-center px-6 md2:px-0">
          <LoadingSkeleton className="h-[50px] w-[120px]" />
        </View>
      </View>
    </View>
  );
};

function ThirdPartyAudioPlayer() {
  return <SpotifyBottomAudioPlayer withVaultTheme={false} withBottomNavigator={false} />;
}
