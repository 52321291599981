import { type FC, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { proxy, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { TrackSplitsView } from '../../components/views/TrackSplitsView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { invalidateOperations, useMutation, useQuery } from '../../graphql/client';
import {
  ArtistByHandleDocument,
  CreateSplitsDocument,
  GetSplitsDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query GetSplits($input: QuerySplitDetailsInput!, $artistId: UUID!) {
    splitDetails(input: $input) {
      id
      name
      email
      role
      percentBps
      isOwnSplit(artistId: $artistId)
    }
  }

  mutation CreateSplits($input: MutationAssignSplitsInput!) {
    assignSplits(input: $input) {
      __typename

      ... on Error {
        message
      }
    }
  }
`);

export interface SplitDetails {
  id: string;
  name: string;
  emailAddress: string;
  role: string;
  percentBps: number;
  isOwnSplit: boolean;
  isNew: boolean;
}

export const SplitData = proxy<{
  [vaultContentId: string]: {
    [id: string]: SplitDetails;
  };
}>({});

export function setSplitData({
  id,
  emailAddress,
  name,
  role,
  percentBps,
  isOwnSplit,
  vaultContentId,
  isNew,
}: SplitDetails & { vaultContentId: string }): void {
  SplitData[vaultContentId] ||= {};

  SplitData[vaultContentId]![id] = { id, emailAddress, name, role, percentBps, isOwnSplit, isNew };
}

export const TrackSplitsPage: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isArtist, loggedInUser, loginStatus } = useAuthContext();
  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();
  const { vaultContentId } = useParams();
  const { artistHandle } = useArtistHandle();
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

  useVaultTheme();

  useSetMetaHeaders({
    title: 'Editing track splits',
  });

  const { isLoading: isLoadingSplits, isError: isErrorSplits } = useQuery(GetSplitsDocument, {
    staleTime: 0,
    cacheTime: '15 minutes',
    variables: !!vaultContentId &&
      !!loggedInUser?.artist?.id && { input: { vaultContentId }, artistId: loggedInUser.artist.id },
    onSuccess(data) {
      if (vaultContentId && Object.keys(SplitData[vaultContentId] || {}).length === 0) {
        if (data.data.splitDetails != null) {
          for (const split of data.data.splitDetails) {
            setSplitData({
              id: split.id,
              emailAddress: split.email,
              name: split.name,
              role: split.role,
              percentBps: split.percentBps,
              isOwnSplit: split.isOwnSplit,
              vaultContentId,
              isNew: false,
            });
          }

          setIsSaveDisabled(false);
        } else {
          setErrorText('No splits found, please contact Vault support');
          setIsSaveDisabled(true);
        }
      }
    },
  });

  const {
    isError: isErrorArtistData,
    isLoading: isLoadingArtistData,
    data,
  } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null && loginStatus === LoginStatus.LOGGED_IN && isArtist,
  });

  const saveSplitsMutation = useMutation(CreateSplitsDocument, {
    onSuccess: ({ data }) => {
      switch (data.assignSplits.__typename) {
        case 'MutationAssignSplitsSuccess': {
          openToast({ text: 'Splits updated!', variant: 'success' });

          if (!vaultContentId) return;

          SplitData[vaultContentId] = {};

          invalidateOperations({
            operations: [GetSplitsDocument],
          });

          navigate(artistNavigationPath(artistHandle, '/'));
          return;
        }
        case 'NotFoundError': {
          openToast({
            text: 'Some important information could not be found, reload and try again',
            variant: 'error',
          });
          return;
        }
        case 'ValidationError': {
          openToast({ text: 'Please fill out all the information correctly', variant: 'error' });
          return;
        }
        case 'TrackSplitPercentSumError': {
          openToast({ text: 'Splits must add up to 100%', variant: 'error' });
          return;
        }
        default: {
          passiveExhaustiveGuard(data.assignSplits);
        }
      }
    },
    onError: error => {
      openToast({ text: error.message, variant: 'error' });
    },
  });

  if (!vaultContentId || !artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: { bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW, event: EVENTS.EDIT_TRACK_SPLITS },
      pathname,
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: () => {
          // Clear the storage for splits
          delete SplitData[vaultContentId];

          navigate(artistNavigationPath(artistHandle, '/'));
        },
        event: EVENTS.EDIT_TRACK,
      },
    });
  });

  const onSaveClick = useStableCallback(async () => {
    if (!vaultContentId || isSaveDisabled) return;

    const data = Object.values(SplitData[vaultContentId] || {}).filter(({ isNew }) => !isNew);

    if (data.some(({ emailAddress }) => !emailAddress || emailAddress.length === 0)) {
      const error = 'Please fill out all email fields correctly';
      trackEvent({
        type: 'Edit Track Splits Validation Error',
        properties: { artistHandle, vaultContentId, error, data },
      });
      setErrorText(error);
    }

    const percentSum = data.reduce((acc, { percentBps }) => acc + percentBps, 0);
    if (percentSum !== 10000) {
      const error = 'Splits must add up to 100%';
      trackEvent({
        type: 'Edit Track Splits Validation Error',
        properties: { artistHandle, vaultContentId, error, data },
      });
      setErrorText(error);
      return;
    }

    trackEvent({
      type: EVENTS.EDIT_TRACK_SPLITS,
      properties: { artistHandle, vaultContentId, details: data },
      pathname,
    });

    saveSplitsMutation.mutate({
      input: {
        vaultContentId,
        splitDetails: data.map(({ name, emailAddress, role, percentBps }) => ({
          id: uuidv4(),
          name,
          emailAddress,
          role,
          percentBps,
        })),
      },
    });
  });

  const splitsInfo = useSnapshot(SplitData)[vaultContentId] || {};

  if (
    isErrorSplits ||
    isErrorArtistData ||
    (data?.data.artistLink?.artist && !data.data.artistLink.artist.mainVault.isUserArtistAdmin)
  ) {
    // TODO: Error View?
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isLoadingSplits || isLoadingArtistData || Object.keys(splitsInfo).length === 0) {
    return <FullPageLoading withVaultTheme />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Splits</Text>
      }
      headerRight={
        <Text
          className={twMerge(
            'font-title !text-base-m font-medium hover:cursor-pointer active:opacity-70',
            isSaveDisabled ? 'text-vault_accent/50' : 'text-vault_accent',
          )}
          onClick={onSaveClick}
        >
          Save
        </Text>
      }
    >
      <TrackSplitsView
        artistVaultType={data?.data.artistLink?.artist.mainVault.type}
        artistLinkValue={artistHandle}
      />
      {errorText != null && (
        <Text className="mt-3 text-center font-base !text-base-m font-normal text-destructive300">
          {errorText}
        </Text>
      )}
    </DefaultLayout>
  );
};
