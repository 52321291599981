import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';
import { faMobile } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { RsvpEventReleaseAnnouncementTarget } from '../../graphql/generated';
import { DateInput } from '../../screens/vault/announcement/Create';
import { formatSelectedDate, getTimezone } from '../announcement/helpers';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { EventHeader } from '../event/EventHeader';
import { Toggle } from '../forms/Toggle';
import { RSVP_MESSAGE_MAX_CHARS } from './schema';
import { useRsvpEventForm } from './useRsvpEventForm';

export const ReleaseView = ({ artistName }: { artistName: string | undefined | null }) => {
  const { setField, fields, errors, validateField } = useRsvpEventForm();
  const [messageCharCounter, setMessageCharCounter] = useState(fields.message?.length || 0);

  const currDate = useMemo(() => new Date(), []);

  const filterPassedTime = useMemo(() => {
    return (time: Date) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    };
  }, []);

  const timeZone = useMemo(() => {
    return getTimezone(currDate);
  }, [currDate]);

  const formatReleaseDate = fields.eventDate
    ? formatSelectedDate({
        date: fields.eventDate,
      })
    : 'Drop end date and time';

  return (
    <View className="flex h-full w-full flex-col">
      <EventHeader
        icon={faMobile}
        title="Drop day message"
        description="Set a drop end date and schedule a message to your members."
      />
      <View
        className={twMerge(
          'mb-8 mt-2 h-[1px] w-full bg-vault_text/10',
          !fields.canEditSms ? 'mb-4' : 'mb-8',
        )}
      />
      {!fields.canEditSms && (
        <Text className="mb-4 text-base-s text-destructive300">
          Message editing has been disabled since the messages have already been sent
        </Text>
      )}

      <View className="item mt-2 flex w-full flex-row items-center justify-between gap-2">
        <View className="flex flex-col">
          <Text
            className={twMerge(
              'font-title !text-title-s font-medium text-vault_text',
              (!fields.shouldSendSms || !fields.canEditSms) && 'opacity-50',
            )}
          >
            Message
          </Text>
          <Text className="mt-1 !text-base-m text-vault_text/50">
            Sent to members at a specific date. Members can no longer RSVP after the message is
            sent.
          </Text>
        </View>
        <Toggle
          label=""
          onChange={e => {
            setField('shouldSendSms', e.target.checked);
            validateField('message');
          }}
          checked={fields.shouldSendSms}
          className="bg-acc w-20 text-vault_text"
          componentClassName={twMerge(
            'bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent',
            !fields.canEditSms && 'opacity-50',
          )}
          disabled={!fields.canEditSms}
        />
      </View>
      <textarea
        value={fields.message}
        name="message"
        aria-multiline="true"
        placeholder={`Yo it's ${artistName || '[Artist Name]'}. My new merch drop is out now!\n\nYou can get it at https://mystore.com`}
        className={twMerge(
          'no-scrollbar mt-4 min-h-[200px] resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50',
          errors.message ? 'border-destructive300' : 'focus:border-vault_text',
          (!fields.shouldSendSms || !fields.canEditSms) && 'opacity-50',
        )}
        onChange={e => {
          setField('message', e.target.value);
          setMessageCharCounter(e.target.value.length);
          validateField('message');
        }}
        onBlur={() => {
          validateField('message');
        }}
        disabled={!fields.shouldSendSms || !fields.canEditSms}
      />
      {fields.shouldSendSms && (
        <View className="flex flex-row items-center justify-end">
          <Text
            className={twMerge(
              'justify-right h-6 pt-2 text-right font-base !text-base-s tabular-nums',
              !!errors.message ? 'text-destructive300' : 'text-vault_text/50',
            )}
          >
            {messageCharCounter}/{RSVP_MESSAGE_MAX_CHARS}
          </Text>
        </View>
      )}

      <DatePicker
        value={formatReleaseDate}
        selected={fields.eventDate}
        onChange={date => {
          setField('eventDate', date);
        }}
        showTimeSelect
        className={twMerge(
          'mt-2 w-full rounded-md border border-solid border-vault_text/10 p-4 font-base !text-base-l text-vault_text outline-none',
          (!fields.shouldSendSms || !fields.canEditSms) && 'opacity-50',
        )}
        disabled={!fields.shouldSendSms || !fields.canEditSms}
        placeholderText="Now"
        filterTime={filterPassedTime}
        customInput={<DateInput isThemeEnabled />}
        minDate={currDate}
        timeIntervals={1}
      />

      <Text
        className={twMerge(
          'mb-4 h-6 pt-1 font-base !text-base-s tabular-nums text-vault_text/50',
          (!fields.shouldSendSms || !fields.canEditSms) && 'opacity-50',
        )}
      >
        {timeZone}
      </Text>

      <View className="mb-8 mt-4 flex w-full flex-col">
        <Text
          className={twMerge(
            'font-title !text-title-s font-medium text-vault_text',
            (!fields.shouldSendSms || !fields.canEditSms) && 'opacity-50',
          )}
        >
          Send to
        </Text>
        <Text className="mt-1 !text-base-m text-vault_text/50">
          You can send the message to people who RSVP'd or to you all current members
        </Text>

        <View
          className={twMerge(
            'mt-8 flex flex-col gap-6',
            (!fields.shouldSendSms || !fields.canEditSms) && 'opacity-50',
          )}
        >
          <View
            className={twMerge(
              'flex flex-row justify-between pb-2',
              !fields.shouldSendSms || !fields.canEditSms ? 'cursor-not-allowed' : 'cursor-pointer',
            )}
            onClick={() => {
              if (!fields.shouldSendSms || !fields.canEditSms) return;
              setField('sendToGroup', RsvpEventReleaseAnnouncementTarget.RsvpdUsers);
            }}
          >
            <Text className="!text-base-l text-vault_text/80">Members who RSVP'd</Text>
            <input
              type="radio"
              className="vault-theme-radio cursor-pointer"
              checked={fields.sendToGroup === RsvpEventReleaseAnnouncementTarget.RsvpdUsers}
              readOnly
            />
          </View>

          <View
            className={twMerge(
              'flex flex-row justify-between pb-2',
              !fields.shouldSendSms || !fields.canEditSms ? 'cursor-not-allowed' : 'cursor-pointer',
            )}
            onClick={() => {
              if (!fields.shouldSendSms || !fields.canEditSms) return;
              setField('sendToGroup', RsvpEventReleaseAnnouncementTarget.AllVaultMembers);
            }}
          >
            <Text className="!text-base-l text-vault_text/80">All members</Text>
            <input
              type="radio"
              className="vault-theme-radio cursor-pointer"
              checked={fields.sendToGroup === RsvpEventReleaseAnnouncementTarget.AllVaultMembers}
              readOnly
            />
          </View>
        </View>
      </View>
    </View>
  );
};
