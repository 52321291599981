import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useQuery } from '../../graphql/client';
import { AttachPayeeStatusDocument } from '../../graphql/generated';
import { Sentry } from '../../sentry';
import type { AcceptCollaborationBottomsheetProps } from '../../types/bottomsheetTypes';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { UserProfileImage } from '../user/UserProfileImage';
import { FullPageLoading } from '../views/FullPageLoading';

gql(/* GraphQL */ `
  query AttachPayeeStatus($input: QueryPayeeStatusFromSecretInput!) {
    payeeStatusFromSecret(input: $input) {
      __typename
      ... on QueryPayeeStatusFromSecretSuccess {
        data {
          availableUserAccount {
            email
            name
          }
          availableArtistAccounts {
            artistId
            email
            name
            profileImageUrl
          }
          lastSplit {
            content {
              contentTitle
              artistHandle
              artistName
            }
            percentBps
          }
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation AttachPayee($input: MutationAttachPayeeToUserInput!) {
    attachPayeeToUser(input: $input) {
      __typename
      ... on MutationAttachPayeeToUserSuccess {
        data {
          id
          email
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export function AcceptCollaborationBottomsheet({
  onConfirm,
  payeeSecretId,
  onError,
}: AcceptCollaborationBottomsheetProps) {
  const { loginStatus, loggedInUser } = useAuthContext();
  const { data } = useQuery(AttachPayeeStatusDocument, {
    variables: {
      input: {
        secretId: payeeSecretId,
      },
    },
    staleTime: 0,
    select(data) {
      return data.data.payeeStatusFromSecret;
    },
  });

  const { closeBottomsheet } = useBottomsheetContainer();

  const { openToast } = useToast();

  useEffect(() => {
    if (loginStatus !== 'LOGGED_IN') {
      closeBottomsheet('other');

      return;
    }

    if (!data) return;

    switch (data.__typename) {
      case 'QueryPayeeStatusFromSecretSuccess': {
        return;
      }
      case 'PayeeAlreadyAttachedError': {
        // We are good it's already attached
        closeBottomsheet('other');
        return;
      }
      case 'NotFoundError': {
        openToast({
          text: 'Invitation is currently not available',
          variant: 'warning',
        });
        Sentry.captureMessage(
          `Payee collaboration invitation link was merged and clicked again or is broken`,
          {
            extra: {
              data,
              payeeSecretId,
            },
            level: 'warning',
          },
        );
        closeBottomsheet('other');
        return;
      }
    }
  }, [data, onError, payeeSecretId, closeBottomsheet, loginStatus, openToast]);

  const [selection, setSelection] = useState<
    { type: 'user' } | { type: 'artist'; id: string } | null
  >(null);

  const [isLoading, setIsLoading] = useState(false);

  if (data?.__typename !== 'QueryPayeeStatusFromSecretSuccess')
    return <FullPageLoading withVaultTheme={false} />;

  const isUserSelected = selection?.type === 'user';

  return (
    <View className="flex h-full flex-col justify-around gap-4 bg-[rgba(14,18,19,1)] px-7 text-center align-middle">
      <h2 className="text-title-xl font-semibold">Accept collaborator invite</h2>
      <p className="text-base-l font-normal">
        {data.data.lastSplit?.content ? (
          <>
            You have been invited by{' '}
            <span className="font-medium">{data.data.lastSplit.content.artistName}</span> as a
            collaborator on{' '}
            {data.data.lastSplit.content.contentTitle ? (
              <span className="font-medium">{data.data.lastSplit.content.contentTitle}</span>
            ) : (
              'a song'
            )}{' '}
            with <span className="font-medium">{data.data.lastSplit.percentBps / 100}%</span> split
            earnings.
          </>
        ) : data.data.lastSplit?.percentBps ? (
          <>
            You have been invited as a collaborator on a song with{' '}
            <span className="font-medium">{data.data.lastSplit.percentBps}%</span> split earnings.
          </>
        ) : (
          `You have been invited as a collaborator on a song.`
        )}
      </p>
      {data.data.availableArtistAccounts ? (
        <>
          {data.data.availableArtistAccounts.map(artist => {
            const isSelected = selection?.type === 'artist' && selection.id === artist.artistId;

            return (
              <View
                key={artist.artistId}
                className={clsx(
                  'flex w-full cursor-pointer items-center justify-between gap-2 rounded-md border border-solid py-2',
                  isSelected ? 'border-yellow100' : 'border-base600',
                )}
                onClick={() => {
                  if (isSelected) return;

                  setSelection({
                    type: 'artist',
                    id: artist.artistId,
                  });
                }}
              >
                <View className="flex items-center gap-2 text-start">
                  <ArtistProfileImage
                    profileImageUrl={artist.profileImageUrl}
                    className="ml-3 h-10 w-10"
                  />
                  <View className="flex flex-col items-start justify-start">
                    <View className="flex items-center gap-1">
                      <Text className="text-base-l font-medium">{artist.name}</Text>
                      <FontAwesomeIcon
                        icon={faBadgeCheck}
                        className="select-none text-[12px] text-yellow100"
                      />
                    </View>
                    <Text className="text-base-s font-normal text-base400">{artist.email}</Text>
                  </View>
                </View>
                <View className="mr-4 flex items-center gap-2">
                  <Text className="mr-2 flex items-center justify-center whitespace-nowrap rounded-full bg-base700 px-2 py-1 text-center text-[11px] font-bold text-base500">
                    Managed account
                  </Text>
                  <input type="radio" className="vault-theme-radio" checked={isSelected} />
                </View>
              </View>
            );
          })}
          <View
            className={clsx(
              'flex w-full cursor-pointer items-center justify-between gap-2 rounded-md border border-solid py-2',
              isUserSelected ? 'border-yellow100' : 'border-base600',
            )}
            onClick={() => {
              if (isUserSelected) return;

              setSelection({ type: 'user' });
            }}
          >
            <View className="flex items-center gap-2 text-start">
              <UserProfileImage
                profileImageUrl={loggedInUser?.avatar?.url}
                className="ml-3 h-10 w-10"
                withVaultTheme={false}
              />
              <View className="flex flex-col items-start justify-start">
                <View className="flex items-center gap-1">
                  <Text className="text-base-l font-medium">
                    {data.data.availableUserAccount?.name ?? '<unnamed>'}
                  </Text>
                </View>
                <Text className="text-base-s font-normal text-base400">
                  {data.data.availableUserAccount?.email ?? '<no email>'}
                </Text>
              </View>
            </View>
            <View className="mr-4 flex items-center gap-2">
              <input
                type="radio"
                className="vault-theme-radio"
                checked={selection?.type === 'user'}
              />
            </View>
          </View>
        </>
      ) : (
        <>
          <p className="pt-4 text-base-l font-normal">
            Earnings will be associated with the following phone number
          </p>
          <View className="w-full rounded-md border border-solid border-base600 py-2 text-center text-title-xl">
            <span>{loggedInUser?.phone ?? 'xxx'}</span>
          </View>

          <View className="flex w-full items-center justify-center gap-2 text-center">
            <input
              type="checkbox"
              id="confirm-phone"
              className="custom-checkbox"
              checked={isUserSelected}
              onChange={() => {
                if (isUserSelected) {
                  setSelection(null);
                } else {
                  setSelection({ type: 'user' });
                }
              }}
            />
            <label htmlFor="confirm-phone" className="text-base-l">
              I confirm the phone number above
            </label>
          </View>
        </>
      )}
      <View className="flex w-full justify-around py-4">
        <View className="flex gap-2">
          <Button
            label="Accept"
            disabled={selection == null || isLoading}
            disabledClassName="opacity-50 cursor-not-allowed"
            loading={isLoading}
            buttonType="button"
            type="primary"
            onClick={() => {
              if (selection != null) {
                setIsLoading(true);
                onConfirm({
                  attachArtistId: selection.type === 'artist' ? selection.id : null,
                  onAttachSuccess() {
                    closeBottomsheet('button');
                  },
                }).finally(() => {
                  setIsLoading(false);
                });
              }
            }}
          />
          <Button
            label="Cancel"
            buttonType="button"
            type="primary"
            className="bg-neutral800 text-white"
            onClick={() => closeBottomsheet('button')}
          />
        </View>
      </View>
    </View>
  );
}
