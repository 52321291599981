import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy from '@uppy/core';

import { throttle } from 'lodash-es';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { createDeferredPromise } from '@soundxyz/utils/promise';
import { fetchQuery } from '../graphql/client';
import type { MediaTypeInput } from '../graphql/generated';
import {
  AbortMultipartUploadDocument,
  CompleteMultipartUploadDocument,
  CreateMultipartUploadDocument,
} from '../graphql/generated';
import { calculateChunkSize } from './fileUtils';

gql(/* GraphQL */ `
  mutation CreateMultipartUpload($input: MutationCreateMultipartUploadInput!) {
    createMultipartUpload(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on AwsRequestError {
        requestId
        message
      }
      ... on MutationCreateMultipartUploadSuccess {
        data {
          uploadId
          uploadKey
          signedUrls {
            partNumber
            url
          }
        }
      }
    }
  }

  mutation AbortMultipartUpload($input: MutationAbortMultipartUploadInput!) {
    abortMultipartUpload(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on AwsRequestError {
        requestId
        message
      }
      ... on MutationAbortMultipartUploadSuccess {
        data {
          __typename
          uploadKey
        }
      }
    }
  }

  mutation CompleteMultipartUpload($input: MutationCompleteMultipartUploadInput!) {
    completeMultipartUpload(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on AwsRequestError {
        requestId
        message
      }
      ... on MutationCompleteMultipartUploadSuccess {
        data {
          mediaId
          cdnUrl
        }
      }
    }
  }
`);

// 10MB Chunk for Multipart Upload
const THROTTLE_INTERVAL = 200;

const SignPartReturn = z.object({
  url: z.string(),
});

const ProcessedParts = z.array(
  z.object({
    ETag: z.string(),
    PartNumber: z.number(),
  }),
);

export async function uploadMultipartFile({
  file,
  mediaType,
  setProgress,
  artistId,
}: {
  file: File;
  mediaType: MediaTypeInput;
  setProgress?: (bytes: number) => void;
  artistId: string | null | undefined;
}) {
  const fileSize = file.size;
  const chunkSize = calculateChunkSize(fileSize);
  const numChunks = Math.ceil(fileSize / chunkSize);

  const createUploadData = await fetchQuery(CreateMultipartUploadDocument, {
    variables: {
      input: {
        fileName: getS3SafeKeyName(file.name),
        mediaType,
        totalPartsCount: numChunks,
        asArtistId: artistId,
      },
    },
    retry: 3,
  });

  if (
    createUploadData.data?.createMultipartUpload.__typename !==
    'MutationCreateMultipartUploadSuccess'
  ) {
    throw new Error(
      createUploadData.data?.createMultipartUpload.message ?? 'Error creating upload',
    );
  }

  const urls = createUploadData.data.createMultipartUpload.data.signedUrls.map(url => url.url);
  const uploadId = createUploadData.data.createMultipartUpload.data.uploadId;
  const uploadKey = createUploadData.data.createMultipartUpload.data.uploadKey;

  const uppy = new Uppy();

  const mediaIdPromise = createDeferredPromise<{
    mediaId: string;
    cdnUrl: string;
  }>();

  uppy.use(AwsS3Multipart, {
    abortMultipartUpload: async () => {
      await fetchQuery(AbortMultipartUploadDocument, {
        variables: { input: { uploadId, uploadKey } },
        retry: 3,
      }).catch(error => {
        mediaIdPromise.reject(error);

        throw error;
      });
    },
    createMultipartUpload: () => Promise.resolve({ uploadId, key: uploadKey }),
    getChunkSize: () => chunkSize,
    signPart: (_, partData) => {
      const partUrl = SignPartReturn.safeParse({ url: urls[partData.partNumber - 1] ?? '' });
      if (!partUrl.success) throw new Error('Invalid part URL');
      return partUrl.data;
    },
    completeMultipartUpload: async (_, { uploadId, key, parts }) => {
      try {
        const filteredParts = ProcessedParts.safeParse(
          parts.filter(part => !!part.ETag && !!part.PartNumber),
        );
        if (!filteredParts.success) throw new Error('Invalid parts');
        const processedParts = filteredParts.data
          .sort((partA, partB) => partA.PartNumber - partB.PartNumber)
          .map(part => ({
            etag: part.ETag,
            partNumber: part.PartNumber,
          }));

        const completeUploadData = await fetchQuery(CompleteMultipartUploadDocument, {
          variables: {
            input: {
              uploadId,
              uploadKey: key,
              parts: processedParts,
            },
          },
          retry: 3,
        });
        if (
          completeUploadData.data?.completeMultipartUpload.__typename !==
          'MutationCompleteMultipartUploadSuccess'
        ) {
          if (completeUploadData.data?.completeMultipartUpload.__typename === 'NotFoundError') {
            throw new Error('Error completing upload');
          }
          throw new Error(
            completeUploadData.data?.completeMultipartUpload.message ?? 'Error completing upload',
          );
        }

        mediaIdPromise.resolve(completeUploadData.data.completeMultipartUpload.data);
      } catch (error) {
        mediaIdPromise.reject(error);
        throw error;
      }

      return {};
    },
    listParts: () => [],
  });

  uppy.on(
    'upload-progress',
    throttle((_, progress) => {
      setProgress?.(progress.bytesUploaded);
    }, THROTTLE_INTERVAL),
  );

  uppy.addFile({ name: file.name, type: file.type, data: file });
  await uppy.upload();
  uppy.close();

  const { mediaId, cdnUrl } = await mediaIdPromise.promise;

  return { mediaId, cdnUrl };
}

function getS3SafeKeyName(name: string) {
  return name.replace(/[^0-9a-zA-Z!\-_\.\*\`]/gi, '_');
}

export function fileIdentifier(file: File) {
  return `${file.name}-${file.size}`;
}
