import { gql } from '@soundxyz/gql-string';
import { View } from '../../components/common/View';
import { ArtistEventFragmentDoc } from '../../graphql/generated';
import { type FragmentType, getFragment } from '../../graphql/generated';
import { ReleaseCampaignEventItem } from './items/ReleaseCampaignEventItem';
import { RsvpEventItem } from './items/RsvpEventItem';

gql(/* GraphQL */ `
  fragment ReleaseCampaignEventItem on ReleaseCampaign {
    __typename
    ...PublicReleaseCampaignEventItem
    ...PrivateReleaseCampaignEventItem
  }

  fragment PublicReleaseCampaignEventItem on ReleaseCampaignPublicInfo {
    id
    __typename
    title
    releaseDate

    linkValue
    status
    currentState
    initialReleaseImageUrl
    coverImage {
      id
      url
    }
    artist {
      id
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        url
      }
    }
  }

  fragment PrivateReleaseCampaignEventItem on ReleaseCampaignPrivateInfo {
    id
    __typename
    title
    releaseDate

    linkValue
    status
    currentState
    initialReleaseImageUrl
    announcement {
      id
      content
    }
    coverImage {
      id
      url
    }
    artist {
      id
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        url
      }
    }
  }
`);

export const EventItem = ({ eventData }: { eventData: FragmentType<ArtistEventFragmentDoc> }) => {
  const fragment = getFragment(ArtistEventFragmentDoc, eventData);

  const receiptCount =
    fragment.__typename === 'ArtistReleaseCampaignEvent' &&
    fragment.releaseCampaign.__typename === 'ReleaseCampaignPrivateInfo'
      ? fragment.releaseCampaign.receiptCount
      : null;

  if (fragment.__typename === 'ArtistReleaseCampaignEvent') {
    const { releaseCampaign } = fragment;
    return (
      <ReleaseCampaignEventItem
        eventId={fragment.id}
        releaseCampaign={releaseCampaign}
        receiptCount={receiptCount}
      />
    );
  }

  const { rsvpEvent } = fragment;
  return <RsvpEventItem eventId={fragment.id} rsvpEvent={rsvpEvent} />;
};

export const EventItemSkeleton = () => (
  <View className="flex h-20 w-full items-center justify-between gap-3">
    <View className="flex h-16 w-full justify-between gap-4">
      <View className="flex  aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-center gap-2 ">
        <View className="h-5 w-[38%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
          <View className="h-2 w-[22%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>
  </View>
);
