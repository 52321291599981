import { useMemo, useRef, useState } from 'react';
import { motion, type PanInfo, useMotionValue } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FragmentType,
  getFragment,
  MessageBubbleFragmentDoc,
  VaultMessageAttachmentFragmentDoc,
} from '../../graphql/generated';
import { useLatestRef } from '../../hooks/useLatestRef';
import { useStableCallback } from '../../hooks/useStableCallback';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { dateToTime } from '../../utils/dateUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { MessageCommentBubbleInner } from './MessageCommentBubbleInner';

export function MessageCommentBubble({
  hasTrackCommentsReadAccess,
  message,
  isVaultArtist,
  containerMarginRight,
}: {
  hasTrackCommentsReadAccess: boolean;
  message: FragmentType<MessageBubbleFragmentDoc>;
  isVaultArtist: boolean;
  containerMarginRight: string;
}) {
  const { user, createdAt, asArtist, vaultContent } = getFragment(
    MessageBubbleFragmentDoc,
    message,
  );

  const { loggedInUser } = useAuthContext();

  const isAuthor = asArtist
    ? asArtist.id === loggedInUser?.artist?.id
    : user.id === loggedInUser?.id;

  const messageHour = useMemo(() => {
    return dateToTime(createdAt);
  }, [createdAt]);

  const { openBottomsheet } = useBottomsheetContainer();

  const layoutRef = useRef<HTMLDivElement | null>(null);

  const x = useMotionValue(0);
  const initialX = useRef(0);
  const [isDragging, setIsDragging] = useState(false);

  const currentIsDragging = useLatestRef(isDragging);

  const handleDragStart = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    initialX.current = info.point.x;
  };

  const handleDrag = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const currentOffset = info.point.x - initialX.current;
    if (currentOffset > 0) {
      setIsDragging(true);
    } else {
      x.set(0); // dragging to the left, don't translate
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    x.set(0);
    initialX.current = 0;
  };

  const onSingleClick = useStableCallback(() => {
    setTimeout(() => {
      if (currentIsDragging.current || !hasTrackCommentsReadAccess) return;
      const vaultContentId = getFragment(VaultMessageAttachmentFragmentDoc, vaultContent[0])
        ?.vaultContent?.id;

      if (vaultContentId) {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: BOTTOMSHEET_TYPES.TRACK_COMMENTS,
            component: 'MessageCommentBubble',
            trackId: vaultContentId,
          },
        });
        openBottomsheet({
          type: 'TRACK_COMMENTS',
          shared: {
            withVaultTheme: true,
          },
          trackCommentsBottomsheetProps: {
            trackId: vaultContentId,
            autoFocusInput: false,
            withVaultTheme: true,
          },
        });
      }
    });
  });

  return (
    <View className="relative overflow-hidden pb-3">
      <motion.div
        className={twMerge(
          'relative ml-[-49px] flex items-center transition-all duration-300 ease-out',
          isDragging ? 'cursor-grabbing' : 'cursor-grab',
        )}
        drag="x"
        dragDirectionLock
        style={{ x, right: containerMarginRight }}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.3}
      >
        <MessageCommentBubbleInner
          isAuthor={isAuthor}
          isVaultArtist={isVaultArtist}
          message={message}
          onSingleClick={onSingleClick}
          layoutRef={layoutRef}
          type="default"
        />
        <Text className="ml-[10px] w-[50px] shrink-0 pl-[10px] !text-base-xs text-vault_text/50">
          {messageHour}
        </Text>
      </motion.div>
    </View>
  );
}
