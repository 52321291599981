import { createContext, useContext } from 'react';
import type { SpotifyPlayerState, WebPlaybackError } from '../types/spotify';

export type SpotifyContextType = {
  isPlaybackReady: boolean;
  player: Window['Spotify']['Player'] | null;
  deviceId: string | null;
  playbackState: SpotifyPlayerState | null;
  activeCampaignId: string | null;
  error: WebPlaybackError | null;
  setActiveCampaignId: (id: string | null) => void;
};

const defaultSpotifyContext: SpotifyContextType = {
  isPlaybackReady: false,
  player: null,
  deviceId: null,
  playbackState: null,
  activeCampaignId: null,
  error: null,
  setActiveCampaignId: () => {},
};

const SpotifyContext = createContext<SpotifyContextType>(defaultSpotifyContext);

const useSpotifyContext = () => useContext(SpotifyContext);

export { SpotifyContext, useSpotifyContext };
