import type { ComponentProps } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as SelectPrimitive from '@radix-ui/react-select';
import { twMerge } from 'tailwind-merge';
import { faAngleDown, faAngleUp } from '@soundxyz/font-awesome/pro-regular-svg-icons/index.mjs';

export type ItemProps = Partial<ComponentProps<typeof Item>>;

export type SelectProps = {
  onValueChange: (value: string) => void;
  value: string;
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  itemProps?: ItemProps;
  contentClassName?: string;
  className?: string;
  shouldHideIcon?: boolean;
} & ComponentProps<typeof SelectPrimitive.Root>;

const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      children,
      hasError,
      disabled,
      itemProps,
      value,
      contentClassName,
      className,
      shouldHideIcon = false,
      ...props
    },
    forwardedRef,
  ) => {
    const displayChildren = React.useMemo(() => {
      return !!props.placeholder ? (
        <>
          <Item value="" disabled={disabled} {...itemProps}>
            {props.placeholder ?? ''}
          </Item>
          {children}
        </>
      ) : (
        children
      );
    }, [children, props.placeholder, disabled, itemProps]);
    return (
      <SelectPrimitive.Root value={value} {...props}>
        <SelectPrimitive.SelectTrigger
          ref={forwardedRef}
          disabled={disabled}
          className={twMerge(
            'flex w-full items-center justify-between rounded-[6px] border-0 bg-base200 px-2 py-[5px] font-base text-base-m transition-all',
            'outline-none ring-0 placeholder:text-base500',
            '[&>span:first-child]:w-full [&>span>div:first-child]:px-0 [&>span>div:first-child]:pr-2',
            disabled ? 'cursor-not-allowed' : 'pointer-events-auto cursor-pointer',
            hasError
              ? 'bg-destructive50 ring-destructive50 hover:ring-1 hover:ring-destructive200 focus:ring-destructive300'
              : 'hover:border-base300 hover:bg-base50',
            value?.length > 0 ? 'text-base800' : 'text-base500',
            className,
          )}
        >
          <SelectPrimitive.Value />
          {!shouldHideIcon && (
            <SelectPrimitive.Icon>
              <FontAwesomeIcon icon={faAngleDown} />
            </SelectPrimitive.Icon>
          )}
        </SelectPrimitive.SelectTrigger>
        <SelectPrimitive.Content
          className={twMerge(
            'relative z-modalDropdown flex max-h-[var(--radix-select-content-available-height)] w-[var(--radix-select-trigger-width)] cursor-pointer justify-between overflow-hidden rounded-md border-0 bg-white shadow-primary',
            contentClassName,
          )}
          position="popper"
          sideOffset={10}
        >
          <SelectPrimitive.ScrollUpButton className="flex h-6 cursor-default items-center justify-center">
            <FontAwesomeIcon icon={faAngleUp} />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport className="flex flex-col gap-1 py-1">
            {displayChildren}
          </SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className="flex h-6 cursor-default items-center justify-center">
            <FontAwesomeIcon icon={faAngleDown} />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Root>
    );
  },
);
Select.displayName = 'Select';

const Item = React.forwardRef<
  HTMLDivElement,
  {
    value: string;
    disabled?: boolean;
    noPadding?: boolean;
    children: React.ReactNode;
    className?: string;
    dropDownClassName?: string;
  } & Partial<ComponentProps<typeof SelectPrimitive.Item>>
>(({ children, disabled, className, dropDownClassName, ...props }, forwardedRef) => {
  return (
    <SelectPrimitive.Item
      className={twMerge(
        'relative flex min-h-7 w-full select-none items-center justify-stretch rounded-none text-base-m md:text-base-l',
        'data-[highlighted]:bg-neutral100 data-[highlighted]:!outline-none',
        '[&>span]:w-full',
        disabled
          ? 'pointer-events-none bg-white text-neutral300 hover:bg-white focus:bg-white'
          : 'bg-white hover:bg-neutral100 focus:bg-neutral100',
        dropDownClassName,
      )}
      {...props}
      ref={forwardedRef}
      disabled={disabled}
    >
      <SelectPrimitive.ItemText>
        <div
          className={twMerge(
            'flex items-center justify-between px-3 py-1.5 text-base-m font-medium text-base800',
            className,
          )}
        >
          {children}
        </div>
      </SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
Item.displayName = 'Item';

export { Select, Item };
