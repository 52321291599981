import { formatDate } from 'date-fns';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  CampaignReceiptItemFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';
import { ReceiptUI } from './ReceiptUI';

gql(/* GraphQL */ `
  fragment CampaignReceiptItem on ArtistMembershipReceipt {
    __typename
    id
    createdAt
    serialNumber
    membership {
      serialNumber
    }
    user {
      id
      username
      displayName
    }
    artist {
      id
      name
      linkValue
    }
    ... on ReleaseCampaignMembershipReceipt {
      __typename
      releaseCampaign {
        id
        title
      }
    }
    ... on ArtistMembershipRsvpEventReceipt {
      rsvpEvent {
        __typename
        id
        title
      }
    }
  }
`);

export const ReceiptItem = ({
  receiptData,
}: {
  receiptData: FragmentType<CampaignReceiptItemFragmentDoc>;
}) => {
  const receipt = getFragment(CampaignReceiptItemFragmentDoc, receiptData);
  const { openBottomsheet } = useBottomsheetContainer();

  const isPresaveReceipt = receipt.__typename === 'ArtistMembershipPresaveReceipt';
  const isStreamingReceipt = receipt.__typename === 'ArtistMembershipPlayStreamReceipt';

  const isCampaignReceipt = isPresaveReceipt || isStreamingReceipt;

  const isRsvpReceipt = receipt.__typename === 'ArtistMembershipRsvpEventReceipt';

  const {
    serialNumber: receiptNumber,
    createdAt,
    user: { username, displayName },
    artist: { linkValue: artistHandle, name },
  } = receipt;

  if (!isCampaignReceipt && !isRsvpReceipt) {
    return null;
  }

  const title = isCampaignReceipt ? receipt.releaseCampaign.title : receipt.rsvpEvent.title;

  const receiptItemData = {
    title,
    receiptNumber,
    username: displayName ?? username ?? 'username',
    artistName: name,
    artistHandle,
    createdAt,
  };

  const receiptType = isStreamingReceipt ? 'streaming' : isRsvpReceipt ? 'rsvp' : 'pre-save';

  return (
    <View
      className="flex h-16 w-full cursor-pointer justify-start gap-4 transition-all duration-200 ease-in hover:opacity-80"
      onClick={() => {
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.RECEIPT,
          receiptBottomSheetProps: {
            id: receipt.id,
            type: receiptType,

            ...receiptItemData,
          },
        });
      }}
    >
      <div className="relative flex h-16 w-16">
        <div className=" absolute z-above2 flex h-16 w-16   bg-gradient-to-t from-vault_background via-vault_background/20 to-vault_background/10" />
        <View className="aspect-square h-[58px] w-16 flex-shrink-0 overflow-hidden  pt-1">
          <div className="scale-receipt-sm relative">
            <ReceiptUI {...receiptItemData} type={isStreamingReceipt ? 'streaming' : 'pre-save'} />
          </div>
        </View>
      </div>
      <View className="flex flex-col justify-center gap-2">
        <Text className="max-w-56 flex-shrink overflow-hidden text-ellipsis whitespace-nowrap font-title text-[18px]/[20px] font-medium text-vault_text">
          {title}
        </Text>
        <Text className="text-[12px] text-vault_text/50">
          {formatDate(createdAt, 'MMM d, y· p')}
        </Text>
      </View>
    </View>
  );
};

export const ReceiptItemSkeleton = () => (
  <View className="flex h-20 w-full items-center justify-center gap-3">
    <View className="flex h-16 w-full gap-4">
      <View className="flex  aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-center gap-2 ">
        <View className="h-5 w-[38%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>
  </View>
);
