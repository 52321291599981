import { proxy } from 'valtio';

/**
 * When attaching tracks on message sending, we only can pass around the track id, but we need the title to display it.
 * So we store the track titles in this dictionary.
 */
export const AttachTrackContentTitles = proxy<{
  trackTitlesById: Record<string, string | null>;
}>({
  trackTitlesById: {},
});
