import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../contexts/AuthContext';

import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import { AuthUserDocument, UpdateUpsellInterstitialsDocument } from '../graphql/generated';
import { Sentry } from '../sentry';
import { useSessionStorage } from './useSession';

gql(/* GraphQL */ `
  mutation UpdateUpsellInterstitials($input: MutationUpdateUpsellInterstitialsInput!) {
    updateUpsellInterstitials(input: $input) {
      firstChat
      firstSnippetShare
      firstTrack
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUpsellInterstitialsDocument],
  refetch: [AuthUserDocument],
});

export const SESSION_KEY = 'upsell-interstitials';

export function useUpsellInterstitials() {
  const { loggedInUser } = useAuthContext();

  const [wasShowedInSession, setShowedInSession] = useSessionStorage<boolean>(SESSION_KEY, false);

  const { mutateAsync } = useMutation(UpdateUpsellInterstitialsDocument, {
    retry: 3,
    onError: error => {
      Sentry.captureException(error, {
        extra: {
          message: 'Error calling updateUpsellInterstitials mutation',
        },
        tags: {
          type: 'updateUpsellInterstitials',
        },
      });
    },
  });

  const upsellInterstitials = loggedInUser?.upsellInterstitials;

  return {
    upsellInterstitials,
    updateUpsellInterstitials: mutateAsync,
    wasShowedInSession,
    setShowedInSession,
  };
}
