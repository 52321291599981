import { useVaultContentPagination } from '../../hooks/useVaultContent';
import { formatDateString } from '../../utils/textUtils';
import { View } from '../common/View';
import { MockTrackFile } from './MockTrackFile';
import { SkeletonTrackFile } from './TrackFile';

const MOCK_TRACK_FILES = [
  {
    title: 'HAL-2022',
    date: '12/9/23',
    duration: 194,
  },
  {
    title: 'We Culture ref1',
    date: '12/9/23',
    duration: 194,
  },
  {
    title: 'Untitled.wav',
    date: '12/9/23',
    duration: 194,
  },
  {
    title: "But You're Honest",
    date: '12/9/23',
    duration: 194,
  },
  {
    title: 'Everybody-2',
    date: '12/9/23',
    duration: 194,
  },
  {
    title: "Anderson .Paak vs JB 'Affection'",
    date: '12/9/23',
    duration: 194,
  },
  {
    title: 'MODERN SOUL extended mix, Live in Los Angeles',
    date: '12/9/23',
    duration: 194,
  },
  {
    title: 'Airport 2016',
    date: '12/9/23',
    duration: 194,
  },
];

export const MockHorizontalTrackFileCarousel = ({ vaultId }: { vaultId: string }) => {
  const { orderedList: vaultContents, isInitialLoading } = useVaultContentPagination({
    vaultId,
    isOwner: false,
  });

  if (isInitialLoading) {
    return (
      <View className="no-scrollbar flex w-full gap-4 overflow-x-auto">
        {Array.from({ length: 10 }).map((_, index) => (
          <View key={index} className="w-28 shrink-0">
            <SkeletonTrackFile />
          </View>
        ))}
      </View>
    );
  }

  if (vaultContents.length === 0) {
    return (
      <View className="no-scrollbar flex w-full gap-4 overflow-x-auto">
        {MOCK_TRACK_FILES.map((track, i) => (
          <View key={i} className="w-28 shrink-0">
            <MockTrackFile title={track.title} date={track.date} duration={track.duration} />
          </View>
        ))}
      </View>
    );
  }

  return (
    <View className="no-scrollbar flex w-full gap-4 overflow-x-auto">
      {vaultContents.map(track => {
        if (track.__typename !== 'VaultTrack') return null;

        return (
          <View key={track.id} className="w-28 shrink-0">
            <MockTrackFile
              title={track.title || 'Untitled.wav'}
              date={formatDateString({ date: track.createdAt, format: 'numerical_month_day_year' })}
              duration={track.duration}
            />
          </View>
        );
      })}
    </View>
  );
};
