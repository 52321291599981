import { gql } from '@soundxyz/gql-string';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistProfileSettingsDocument,
  AuthUserDocument,
  UpdateArtistDocument,
  UpdateUserDocument,
  UpdateUserRemoveFieldsDocument,
  UserSettingsViewFragmentDoc,
} from '../graphql/generated';

gql(/* GraphQL */ `
  mutation UpdateUser($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserSuccess {
        data {
          id
          ...userSettingsView
        }
      }
    }
  }

  mutation UpdateUserRemoveFields($input: MutationUpdateUserRemoveFieldsInput!) {
    updateUserRemoveFields(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserRemoveFieldsSuccess {
        data {
          id
          ...userSettingsView
        }
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserDocument],
  refetch: [AuthUserDocument, UserSettingsViewFragmentDoc],
});

RefetchOnComplete({
  trigger: [UpdateUserRemoveFieldsDocument],
  refetch: [AuthUserDocument, UserSettingsViewFragmentDoc],
});

RefetchOnComplete({
  trigger: [UpdateArtistDocument],
  refetch: [ArtistProfileSettingsDocument, AuthUserDocument],
});

export function useEditProfile() {
  const { openToast } = useToast();
  const { mutateAsync: updateSubscriberProfile } = useMutation(UpdateUserDocument, {
    onError: e => {
      openToast({
        text: e.message,
        variant: 'error',
      });
    },
  });

  const { mutateAsync: updateSubscriberProfileRemoveFields } = useMutation(
    UpdateUserRemoveFieldsDocument,
    {
      onError: e => {
        openToast({
          text: e.message,
          variant: 'error',
        });
      },
    },
  );

  const { mutateAsync: updateArtistProfile } = useMutation(UpdateArtistDocument, {
    onError: e => {
      openToast({
        text: e.message,
        variant: 'error',
      });
    },
  });

  return { updateSubscriberProfile, updateSubscriberProfileRemoveFields, updateArtistProfile };
}
