import type { FC, ReactNode } from 'react';
import React from 'react';
import { PrivyProvider } from '@privy-io/react-auth';
import { PRIVY_CLIENT_CONFIG } from '../constants/privyConstants';

const isCloudflarePages = window.location.hostname.endsWith('.id-a46.pages.dev');

export const PrivyLoginProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <PrivyProvider
      appId={import.meta.env.VITE_PRIVY_APP_ID}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      apiUrl={
        import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development' || isCloudflarePages
          ? null
          : 'https://privy.vault.fm'
      }
      config={PRIVY_CLIENT_CONFIG}
      clientId={
        isCloudflarePages
          ? import.meta.env.VITE_DEV_PRIVY_CLIENT_ID || import.meta.env.VITE_PRIVY_CLIENT_ID
          : import.meta.env.VITE_PRIVY_CLIENT_ID
      }
    >
      {children}
    </PrivyProvider>
  );
};
