import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const EventHeader = ({
  icon,
  title,
  description,
}: {
  icon: IconDefinition;
  title: string;
  description: React.ReactNode;
}) => {
  return (
    <View className="mb-8 flex flex-col items-center justify-center px-8 text-center">
      <View className="flex text-vault_text">
        <FontAwesomeIcon icon={icon} fontSize={36} />
      </View>
      <Text className="mt-5 text-[22px]/[26px] font-medium text-vault_text">{title}</Text>
      <Text className="mt-2 max-w-[350px] text-[14px]/[18px] text-vault_text/50 md2:max-w-[450px]">
        {description}
      </Text>
    </View>
  );
};
