import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faSearch } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { useQuery } from '../../graphql/client';
import { ReleaseCampaignContentType } from '../../graphql/generated';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { ThirdPartyReleaseInformationDocument } from '../../graphql/generated';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import {
  checkLinkValidity,
  extractSpotifyResourceIdFromUri,
  extractSpotifyResourceIdFromUrl,
  isValidAppleMusicLink,
  isValidISRC,
  isValidSpotifyLink,
  isValidSpotifyUri,
  isValidUPC,
} from '../../utils/linkUtils';
import { extractAppleMusicResourceId } from '../../utils/linkUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { getDSPName, parseContentType, validateField } from './helpers';
import { CampaignSteps, CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

gql(/* GraphQL */ `
  query ThirdPartyReleaseInformation($input: QueryThirdPartyReleaseInformationInput!) {
    thirdPartyReleaseInformation(input: $input) {
      __typename
      ... on QueryThirdPartyReleaseInformationSuccess {
        data {
          releaseImageUrl
          releaseTitle
          releaseArtistName
          urls {
            platform
            url
          }
        }
      }

      ... on Error {
        message
      }
    }
  }
`);

export const CampaignScanLinkView = () => {
  const { fields, setField } = useCampaignForm();

  const [_searchParams, setSearchParams] = useSearchParams();
  const [linkToScan, setLinkToScan] = useState(fields.isrc || fields.upc || '');
  const [linkType, setLinkType] = useState<'APPLE_MUSIC' | 'SPOTIFY' | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);
  const [contentType, setContentType] = useState<ReleaseCampaignContentType | null>(null);
  const [shouldQuery, setShouldQuery] = useState(false);

  useVaultTheme();

  const isPresave = fields.campaignType === CampaignType.Presave;

  const {
    data: scanLinkQuery,
    isLoading,
    error,
  } = useQuery(ThirdPartyReleaseInformationDocument, {
    variables: !!linkToScan &&
      !!contentType &&
      !!linkType && {
        input: {
          appleMusicResourceId:
            linkType === ThirdPartyPlatform.AppleMusic
              ? extractAppleMusicResourceId(linkToScan)
              : undefined,
          spotifyResourceId:
            linkType === ThirdPartyPlatform.Spotify
              ? isValidSpotifyLink(linkToScan)
                ? extractSpotifyResourceIdFromUrl(linkToScan)
                : extractSpotifyResourceIdFromUri(linkToScan)
              : undefined,
          resourceType: contentType,
        },
      },
    enabled: !errorMessage && shouldQuery,
    staleTime: 0,
    select(data) {
      return data.data.thirdPartyReleaseInformation;
    },
  });

  useEffect(() => {
    if (isPresave && linkToScan) {
      if (isValidISRC(linkToScan)) {
        setField('isrc', linkToScan);
        setField('contentType', ReleaseCampaignContentType.Track);
        setContentType(ReleaseCampaignContentType.Track);
      } else if (isValidUPC(linkToScan)) {
        setContentType(ReleaseCampaignContentType.Album);
        setField('contentType', ReleaseCampaignContentType.Album);
        setField('upc', linkToScan);
      } else {
        setField('upc', null);
        setField('isrc', null);
      }
    }

    if (isValidSpotifyLink(linkToScan)) {
      setLinkType(ThirdPartyPlatform.Spotify);
      setContentType(
        parseContentType({
          key: ThirdPartyPlatform.Spotify,
          uri: linkToScan,
        }),
      );
    } else if (isValidAppleMusicLink(linkToScan)) {
      setLinkType(ThirdPartyPlatform.AppleMusic);
      setContentType(
        parseContentType({
          key: ThirdPartyPlatform.AppleMusic,
          uri: linkToScan,
        }),
      );
    } else if (isValidSpotifyUri(linkToScan)) {
      setLinkType(ThirdPartyPlatform.Spotify);
      setContentType(
        parseContentType({
          key: ThirdPartyPlatform.Spotify,
          uri: linkToScan,
        }),
      );
    } else {
      setLinkType(null);
      setContentType(null);
    }
  }, [isPresave, linkToScan, setField]);

  const handleClick = useStableCallback(() => {
    setErrorMessage(null);
    if (isPresave && !linkType && !contentType) setSearchParams({ step: CampaignSteps.Setup });
    else {
      linkValidate();
      setShouldQuery(true);
    }
  });

  const isValidLink = checkLinkValidity(linkToScan, isPresave);
  const linkValidate = useStableCallback(() => {
    if (isValidLink || !linkToScan) {
      setErrorMessage(null);
      return;
    } else {
      setErrorMessage(
        isPresave
          ? 'Please enter a valid ISRC, UPC or Spotify url'
          : 'Please enter a valid Spotify or Apple Music url',
      );
      return;
    }
  });

  useEffect(() => {
    if (!shouldQuery || !contentType || !linkType) return;

    if (scanLinkQuery?.__typename === 'QueryThirdPartyReleaseInformationSuccess') {
      const { releaseImageUrl, releaseTitle, releaseArtistName, urls } = scanLinkQuery.data;

      validateField('releaseDate');
      setField('title', releaseTitle);
      validateField('title');
      if (releaseArtistName) setField('artist', releaseArtistName);
      if (releaseImageUrl) setField('thirdPartyImageUrl', releaseImageUrl);

      setField('contentType', contentType);
      const dspArray = urls.map(({ platform, url }) => ({
        key: platform,
        uri: url,
        showLink: true,
        buttonText: 'Play',
        name: getDSPName(platform),
      }));
      setSearchParams({ step: CampaignSteps.Setup });
      setField('dsps', dspArray);
      validateField('dsps');

      setShouldQuery(false);
    } else if (scanLinkQuery?.__typename === 'ValidationError') {
      setErrorMessage("We couldn't find your song or album. Please try again.");
      setShouldQuery(false);
    }
  }, [
    scanLinkQuery,
    shouldQuery,
    contentType,
    linkType,
    setField,
    setSearchParams,
    fields.campaignType,
    isPresave,
  ]);

  useEffect(() => {
    setShouldQuery(false);
    if (!!error?.message) {
      setErrorMessage("We couldn't find your song or album. Please try again.");
    }
  }, [error]);

  return (
    <View className="mx-5 flex flex-1 flex-col justify-between md2:max-w-[600px]">
      <View />

      <View className="flex w-full flex-1 flex-col items-center justify-center">
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            className="inline-block animate-spin rounded-full font-medium text-vault_text"
            fontSize={48}
          />
        ) : (
          <FontAwesomeIcon
            className="text-vault_text"
            icon={isPresave ? faLink : faSearch}
            fontSize={48}
          />
        )}
        <Text className="mt-8 text-center text-title-l font-medium text-vault_text">
          {isLoading ? 'Scanning...' : isPresave ? 'Link your song' : 'Scan for your song'}
        </Text>
        <Text className="mt-2 max-w-[250px] text-center text-base-m font-normal text-vault_text/50">
          {isLoading
            ? 'Takes up to 30 seconds'
            : isPresave
              ? 'Input the ISRC, UPC, or Spotify URL for your upcoming song or album.'
              : 'Enter a URL for your song on Spotify or Apple Music'}
        </Text>

        <View className="relative mt-5 flex w-full flex-col gap-2">
          <input
            className={clsx(
              'rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
              errorMessage
                ? 'border-destructive300'
                : 'border-vault_text/10 focus:border-vault_text',
            )}
            placeholder={isPresave ? 'IRSC, UPC, or Spotify URL' : 'https://'}
            value={linkToScan}
            onChange={e => {
              setLinkToScan(e.target.value);

              if (e.target.value.length > 0) {
                setErrorMessage(null);
              } else {
                linkValidate();
              }
            }}
            onBlur={linkValidate}
          />
          {errorMessage && (
            <Text className="absolute top-[58px] text-base-s text-destructive300">
              {errorMessage}
            </Text>
          )}
        </View>
      </View>

      {!isLoading && (
        <Button
          type="primary-themed"
          label={errorMessage ? (isValidLink ? 'Retry' : 'Scan') : 'Scan'}
          className={twMerge('mt-auto w-full', isPresave && 'mb-8')}
          disabled={
            !!errorMessage
              ? !isValidLink
              : isPresave
                ? !((contentType && linkType) || fields.upc || fields.isrc)
                : !contentType || !linkType || isLoading
          }
          disabledClassName="opacity-50 cursor-not-allowed"
          onClick={() => handleClick()}
        />
      )}
      {!isPresave && (
        <Text
          className="mb-8 mt-4 text-center text-base-m text-vault_text/50 hover:cursor-pointer"
          onClick={() => setSearchParams({ step: CampaignSteps.Setup })}
        >
          Skip
        </Text>
      )}
    </View>
  );
};
