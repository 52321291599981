import React, { type LegacyRef } from 'react';

import type { MediaType } from '../../../../graphql/generated';
import {
  type FragmentType,
  getFragment,
  VaultItemFragmentDoc,
} from '../../../../graphql/generated';
import { UserFolder } from './UserFolder';
import { UserImage } from './UserImage';
import { UserTrack } from './UserTrack';
import { UserVideo } from './UserVideo';

const VaultItemUserComponent = React.memo(
  ({
    item,
    allAssets,
    containerRef,
    artistHandle,
  }: {
    item: FragmentType<VaultItemFragmentDoc>;
    allAssets: Array<{
      id: string;
      type: MediaType;
      url: string;
      title: string;
    }>;
    containerRef?: LegacyRef<HTMLDivElement>;
    artistHandle: string;
  }) => {
    const itemInfo = getFragment(VaultItemFragmentDoc, item);

    switch (itemInfo.__typename) {
      case 'VaultTrack':
        return (
          <UserTrack track={itemInfo} containerRef={containerRef} artistHandle={artistHandle} />
        );
      case 'VaultFolder':
        return (
          <UserFolder
            folderInfo={itemInfo}
            containerRef={containerRef}
            isOwner={false}
            artistHandle={artistHandle}
          />
        );
      case 'VaultVideo':
        return (
          <UserVideo video={itemInfo} containerRef={containerRef} artistHandle={artistHandle} />
        );
      case 'VaultImage':
        return (
          <UserImage
            image={itemInfo}
            allAssets={allAssets}
            containerRef={containerRef}
            artistHandle={artistHandle}
          />
        );
      default:
        return <></>;
    }
  },
);

VaultItemUserComponent.displayName = 'VaultItemUser';

export const VaultItemUser = React.memo(VaultItemUserComponent);
