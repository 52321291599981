import React, { type ReactNode, type RefObject, useCallback, useState } from 'react';
import { Content, Overlay, Root } from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { useEscapeKeyListener } from '../hooks/useEscapeKeyListener';
import { BottomsheetProvider } from '../providers/BottomsheetProvider';
import { createContainer } from '../utils/unstated';
import { BottomsheetContainer } from './BottomsheetContext';

type AnimationState = {
  animate: boolean;
  animatingIn: boolean;
  sourceRef: RefObject<HTMLElement> | null;
  topThreshold?: number;
  bottomThreshold?: number;
};

const DEFAULT_THRESHOLD = 150;

const OverlayContext = createContainer(function OverlayContext() {
  const [children, setChildren] = useState<ReactNode | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [animation, setAnimation] = useState<AnimationState>({
    animate: false,
    animatingIn: true,
    sourceRef: null,
  });
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  const openOverlayWithAnimation = useCallback(
    (
      sourceRef: RefObject<HTMLElement>,
      _children: ReactNode,
      topThreshold = DEFAULT_THRESHOLD,
      bottomThreshold = DEFAULT_THRESHOLD,
    ) => {
      setAnimation({ animate: true, animatingIn: true, sourceRef, topThreshold, bottomThreshold });
      setChildren(_children);
      setIsOpen(true);
      setIsAnimationComplete(false);
    },
    [],
  );

  const openOverlay = useCallback((_children: ReactNode) => {
    setChildren(_children);
    setIsOpen(true);
    setIsAnimationComplete(false);
  }, []);

  const closeOverlay = useCallback(() => {
    setAnimation({ animate: false, animatingIn: true, sourceRef: null });
    setIsOpen(false);
    setIsAnimationComplete(false);
  }, []);

  useEscapeKeyListener(isOpen, closeOverlay);

  return {
    animation,
    openOverlay,
    openOverlayWithAnimation,
    children,
    closeOverlay,
    isOpen,
    isAnimationComplete, // Expose animation completion state
    setIsAnimationComplete, // Expose setter to update animation completion state
  };
});

const useOverlayContainer = () => OverlayContext.useContainer();

const OverlayContainer = () => {
  const { children, isOpen, closeOverlay, animation, setIsAnimationComplete } =
    useOverlayContainer();

  let overlayContent = children;
  let overlayClassNames =
    'fixed inset-0 z-overlay flex h-full max-h-screen w-full flex-col items-center justify-center sm:mx-[auto] backdrop-blur-[50px] bg-[rgba(14,18,19,0.10)]';

  if (animation.animate && animation.sourceRef?.current) {
    const sourceRect = animation.sourceRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const distanceFromTop = sourceRect.top;
    const distanceFromBottom = viewportHeight - sourceRect.bottom;
    let translateY = 0;

    const topThreshold = animation.topThreshold ?? DEFAULT_THRESHOLD;
    const bottomThreshold = animation.bottomThreshold ?? DEFAULT_THRESHOLD;

    if (distanceFromTop < distanceFromBottom) {
      if (distanceFromTop < topThreshold) translateY = topThreshold - distanceFromTop;
    } else {
      if (distanceFromBottom < bottomThreshold)
        translateY = -(bottomThreshold - distanceFromBottom);
    }

    overlayContent = (
      <div
        style={{
          position: 'absolute',
          top: sourceRect.top,
          left: sourceRect.left,
          transition: 'all 0.2s ease-out',
          opacity: 1,
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ y: translateY, opacity: 1 }}
          exit={{ x: 0, opacity: 0 }}
          onAnimationComplete={() => setIsAnimationComplete(true)}
        >
          {children}
        </motion.div>
      </div>
    );

    overlayClassNames = twMerge(
      overlayClassNames,
      animation.animatingIn ? 'animate-backdropBlurIn' : 'animate-backdropBlurOut',
    );
  }

  return (
    <Root open={isOpen}>
      <BottomsheetProvider>
        <Overlay className={overlayClassNames}>
          <Content onOpenAutoFocus={e => e.preventDefault()} onPointerDownOutside={closeOverlay}>
            {overlayContent}
          </Content>
        </Overlay>
        <BottomsheetContainer />
      </BottomsheetProvider>
    </Root>
  );
};

export { useOverlayContainer, OverlayContainer, OverlayContext };
