import React from 'react';
import { debounce } from 'lodash-es';
import { useMedia } from 'use-media';
import { useSafeLayoutEffect } from '../hooks/useSafeLayoutEffect';
import { createContainer } from '../utils/unstated';

const mediaQueries = {
  mobile: '(min-width: 340px)',
  embedMobile: '(max-width: 430px)',
  mobileLandscape: '(min-width: 480px)',
  tablet: '(min-width: 768px)',
  tabletLandscape: '(min-width: 1024px)',
  laptop: '(min-width: 1160px)',
  desktop: '(min-width: 1440px)',
  desktopMd: '(min-width: 1600px)',
  desktopLg: '(min-width: 1920px)',
  touch: '(pointer: coarse)',
  darkMode: '(prefers-color-scheme: dark)',
  prefersReducedMotion: '(prefers-reduced-motion: reduce)',
  uploadFlow: '(min-width: 900px)',
  md2: '(min-width: 870px)',
};
function MediaQueryContainer() {
  const [_hasAppMounted, setHasAppMounted] = React.useState(false);
  const mobile = useMedia(mediaQueries.mobile);
  const mobileLandscape = useMedia(mediaQueries.mobileLandscape);
  const embedMobile = useMedia(mediaQueries.embedMobile);
  const tablet = useMedia(mediaQueries.tablet);
  const tabletLandscape = useMedia(mediaQueries.tabletLandscape);
  const laptop = useMedia(mediaQueries.laptop);
  const desktop = useMedia(mediaQueries.desktop);
  const desktopMd = useMedia(mediaQueries.desktopMd);
  const desktopLg = useMedia(mediaQueries.desktopLg);
  const uploadFlow = useMedia(mediaQueries.uploadFlow);
  const md2 = useMedia(mediaQueries.md2);
  const [_windowSize, setWindowSize] = React.useState(getSize);

  function getSize() {
    const dimensions = {
      width: 0,
      height: 0,
    };

    if (typeof window === 'undefined') return dimensions;

    dimensions.width = window.innerWidth;
    dimensions.height = window.innerHeight;

    return dimensions;
  }

  // important to force update on mount and when the window size changes
  useSafeLayoutEffect(() => {
    setHasAppMounted(true);

    const handleResizeDebounced = debounce(function handleResize() {
      setWindowSize(getSize());
    }, 250);

    window.addEventListener('resize', handleResizeDebounced);
    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, []);

  return {
    mobile,
    mobileLandscape,
    embedMobile,
    tablet,
    tabletLandscape,
    laptop,
    desktop,
    desktopMd,
    desktopLg,
    uploadFlow,
    md2,
  };
}

export const MediaQuery = createContainer(MediaQueryContainer);

export const useMediaQuery = () => MediaQuery.useContainer();
