import type { FC } from 'react';
import React from 'react';
import type { CustomBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';

const CustomBottomSheet: FC<CustomBottomsheetProps> = ({ body }) => {
  return (
    <View className="mb-4 mt-5 flex w-full flex-col gap-y-[2px] overflow-hidden rounded-xl">
      {body}
    </View>
  );
};

export { CustomBottomSheet };
