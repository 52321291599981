import { createContext, type FC, type ReactNode, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import type { Appearance } from '@stripe/stripe-js';
import { twMerge } from 'tailwind-merge';
import { proxy, useSnapshot } from 'valtio';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { stripe } from '../clients/stripeClient';
import { Button } from '../components/buttons/Button';
import { STRIPE_APPEARANCE } from '../constants/stripeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { fetchQuery } from '../graphql/client';
import {
  CreateSubscriptionPaymentIntentDocument,
  type CreateSubscriptionPaymentIntentMutation,
} from '../graphql/generated';
import { useStableCallback } from '../hooks/useStableCallback';
import { hexToColorSpace, VaultThemeStore } from '../hooks/useVaultTheme';
import { LoginStatus } from '../types/authTypes';
import type { TypeFromGraphQLUnion } from '../types/gql';
import { getErrorInstance } from '../utils/errors';

gql(/* GraphQL */ `
  mutation CreateSubscriptionPaymentIntent($input: MutationCreateSubscriptionPaymentIntentInput!) {
    createSubscriptionPaymentIntent(input: $input) {
      __typename
      ... on MutationCreateSubscriptionPaymentIntentSuccess {
        data {
          id
          client_secret
          subscriptionId
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

type StripeContextType = {
  subscriptionId: string | null;
  clientSecret: string | null;
};

const StripeContext = createContext<StripeContextType>({
  subscriptionId: null,
  clientSecret: null,
});

const useStripeContext = () => useContext(StripeContext);

const stripeSubscriptionsData = proxy<
  Record<
    string,
    | {
        status: 'loading';
      }
    | {
        status: 'error';
        message: string;
      }
    | {
        status: 'success';
        data: TypeFromGraphQLUnion<
          CreateSubscriptionPaymentIntentMutation['createSubscriptionPaymentIntent'],
          'MutationCreateSubscriptionPaymentIntentSuccess'
        >['data'];
      }
    | null
  >
>({});

export async function startSubscriptionPaymentIntent({
  vaultId,
  referralCode,
  openToast,
}: {
  vaultId: string;
  referralCode: string | null;
  openToast: ReturnType<typeof useToast>['openToast'] | null;
}) {
  const alreadyExists = stripeSubscriptionsData[vaultId];

  if (!alreadyExists) {
    stripeSubscriptionsData[vaultId] = {
      status: 'loading',
    };

    const data = await fetchQuery(CreateSubscriptionPaymentIntentDocument, {
      variables: {
        input: {
          vaultId,
          referralCode,
        },
      },
      retry: 3,
      fetchOptions: {
        keepalive: true,
      },
    }).catch(error => {
      captureException(error, {
        extra: {
          vaultId,
        },
      });
      return {
        data: {
          createSubscriptionPaymentIntent: {
            __typename: 'UnhandledError',
            message: getErrorInstance(error).message,
          },
        },
      } as const;
    });

    if (
      data.data.createSubscriptionPaymentIntent.__typename ===
      'MutationCreateSubscriptionPaymentIntentSuccess'
    ) {
      stripeSubscriptionsData[vaultId] = {
        status: 'success',
        data: data.data.createSubscriptionPaymentIntent.data,
      };
    } else {
      if (openToast) {
        openToast({
          text: 'An error has occurred. Please try again later',
          variant: 'error',
          duration: 10000,
        });
      }

      stripeSubscriptionsData[vaultId] = {
        status: 'error',
        message: data.data.createSubscriptionPaymentIntent.message,
      };
    }
  } else if (alreadyExists.status === 'error') {
    clearSubscriptionPaymentIntent({ vaultId });
    return startSubscriptionPaymentIntent({ vaultId, referralCode, openToast });
  }
}

export function clearSubscriptionPaymentIntent({ vaultId }: { vaultId: string }) {
  stripeSubscriptionsData[vaultId] = null;
}

const StripeProvider: FC<{
  children: ReactNode;
  vaultId: string;
  referralCode: string | null;
  clientSecret?: string;
  withVaultTheme: boolean;
}> = ({ children, vaultId, referralCode, clientSecret, withVaultTheme }) => {
  const { loginStatus } = useAuthContext();

  const subscriptionIntent = useSnapshot(stripeSubscriptionsData)[vaultId];
  const vaultTheme = useSnapshot(VaultThemeStore);

  const { openToast } = useToast();

  const STRIPE_THEME_APPEARANCE = useStableCallback(() => {
    return {
      theme: 'night',
      variables: {
        fontSizeBase: '16px',
        fontWeightNormal: '500',
        spacingUnit: '4px',
        colorBackground: vaultTheme.backgroundColor,
        colorText: vaultTheme.textColor,
        colorPrimary: vaultTheme.textColor,
      },
      rules: {
        '.Input': {
          borderBottom: `1px solid rgba(${hexToColorSpace(vaultTheme.textColor)},0.05)`,
          boxShadow: 'none',
          border: 'none',
          borderRadius: '0',
        },
        '.Input::placeholder': {
          color: `rgba(${hexToColorSpace(vaultTheme.textColor)},0.5)`,
        },
        '.Input:focus': {
          boxShadow: 'none',
          border: 'none',
          borderBottom: `1px solid rgba(${hexToColorSpace(vaultTheme.textColor)},0.05)`,
        },
      },
    } satisfies Appearance;
  });

  useEffect(() => {
    if (loginStatus === LoginStatus.LOGGED_IN) {
      startSubscriptionPaymentIntent({ vaultId, referralCode, openToast });
    }
  }, [loginStatus, vaultId, openToast, referralCode]);

  const isLoading = subscriptionIntent?.status === 'loading';

  if (isLoading || !subscriptionIntent) {
    return (
      <FontAwesomeIcon
        icon={faSpinner}
        size="2xl"
        className={twMerge(
          'mt-12 inline-block animate-spin rounded-full font-medium',
          withVaultTheme ? 'text-vault_text/50' : 'text-base500',
        )}
      />
    );
  }

  return subscriptionIntent.status === 'success' ? (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: clientSecret ?? subscriptionIntent.data.client_secret ?? undefined,
        appearance: withVaultTheme ? STRIPE_THEME_APPEARANCE() : STRIPE_APPEARANCE,
      }}
    >
      <StripeContext.Provider
        value={{
          subscriptionId: subscriptionIntent.data.subscriptionId,
          clientSecret: clientSecret ?? subscriptionIntent.data.client_secret,
        }}
      >
        {children}
      </StripeContext.Provider>
    </Elements>
  ) : (
    <Button
      label="This artist cannot be subscribed to at this time. Please try again later"
      type="secondary"
      className={twMerge('my-4 w-full', withVaultTheme && 'bg-vault_text/10 text-vault_text')}
      disabled
    />
  );
};

export { StripeProvider, StripeContext, useStripeContext };
