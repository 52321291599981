import mixpanel from 'mixpanel-browser';

const MIXPANEL_PROJECT_TOKEN = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;

if (!MIXPANEL_PROJECT_TOKEN) {
  throw Error('Mixpanel project token is not found! Is ENV `VITE_MIXPANEL_PROJECT_TOKEN` defined?');
}

mixpanel.init(MIXPANEL_PROJECT_TOKEN ?? '', {
  /**
   * This is a cloudflare reverse proxy that points to Mixpanel API
   * It prevents Adblockers from blocking Mixpanel API requests
   *
   * You can find the code for this proxy here:
   * - https://dash.cloudflare.com/8eea6d7ded10d1e953ba9198d8399146/workers/services/view/vault-mixpanel-proxy/production
   *
   * The subdomain for this proxy server is @antochan cat's
   */
  api_host: 'https://cookies-n-cream.vault.fm',
});

if (import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development') {
  mixpanel.disable();
}

export { mixpanel as mixpanelClient };
