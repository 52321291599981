import { captureException } from '@sentry/react';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistMainVaultViewFragmentDoc,
  GetFolderInformationDocument,
  MoveToFolderDocument,
  RemoveVaultContentsDocument,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentPaginationDocument,
} from '../../graphql/generated';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { useStableCallback } from '../useStableCallback';

gql(/* GraphQL */ `
  mutation MoveToFolder($input: MutationMoveContentsToVaultFolderInput!) {
    moveContentsToVaultFolder(input: $input) {
      __typename
      ... on MutationMoveContentsToVaultFolderSuccess {
        data {
          __typename
          id
          vaultId
          title
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation RemoveVaultContents($input: MutationRemoveVaultContentsInput!) {
    removeVaultContents(input: $input) {
      __typename
      ... on MutationRemoveVaultContentsSuccess {
        data {
          __typename
          id
        }
      }

      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [MoveToFolderDocument, RemoveVaultContentsDocument],
  refetch: [
    VaultContentByFolderDocument,
    VaultContentPaginationDocument,
    ArtistMainVaultViewFragmentDoc,
    ArtistByHandleDocument,
    GetFolderInformationDocument,
    VaultContentByFolderPositionDocument,
  ],
});

export const useVaultContentActions = () => {
  const { openToast } = useToast();
  const { mutateAsync: moveToFolderMutate, isLoading: isMovingFolder } = useMutation(
    MoveToFolderDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (
          data.data.moveContentsToVaultFolder.__typename ===
          'MutationMoveContentsToVaultFolderSuccess'
        ) {
          const vaultContents = data.data.moveContentsToVaultFolder.data;
          trackEvent({
            type: EVENTS.MOVE_TO_FOLDER,
            properties: {
              contentIds: vaultContents.map(content => {
                return {
                  id: content.id,
                  type: content.__typename,
                };
              }),
            },
          });

          // clearSelection();
          return;
        }

        if (data.data.moveContentsToVaultFolder.__typename === 'ValidationError') {
          openToast({
            text: data.data.moveContentsToVaultFolder.message,
            variant: 'error',
          });
          return;
        }

        if (data.data.moveContentsToVaultFolder.__typename === 'NotFoundError') {
          openToast({
            text: 'The folder you are trying to move the files to does not exist.',
            variant: 'error',
          });
          return;
        }
      },
      onError: error => {
        captureException(error, {
          extra: {
            isMovingFolder: true,
          },
        });
        openToast({
          text: 'An error occurred while moving the files. Please try again later.',
          variant: 'error',
        });
      },
    },
  );

  const { mutateAsync: removeVaultContentsMutate, isLoading: isRemovingContent } = useMutation(
    RemoveVaultContentsDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (data.data.removeVaultContents.__typename === 'MutationRemoveVaultContentsSuccess') {
          const vaultContents = data.data.removeVaultContents.data;
          trackEvent({
            type: EVENTS.REMOVE_VAULT_CONTENTS,
            properties: {
              contentIds: vaultContents.map(content => {
                return {
                  id: content.id,
                  type: content.__typename,
                };
              }),
            },
          });
          // clearSelection();
          return;
        }

        if (data.data.removeVaultContents.__typename === 'NotFoundError') {
          openToast({
            text: 'The file you are trying to delete does not exist.',
            variant: 'error',
          });
          return;
        }
      },
      onError: error => {
        captureException(error, {
          extra: {
            isRemovingContent: true,
          },
        });
        openToast({
          text: 'An error occurred while deleting the file. Please try again later.',
          variant: 'error',
        });
      },
    },
  );

  const moveToFolder = useStableCallback(
    async ({
      contentIds,
      folderId,
      vaultId,
      onSuccess,
    }: {
      contentIds: string[];
      folderId?: string | null;
      vaultId: string;
      onSuccess?: () => void;
    }) => {
      return moveToFolderMutate(
        {
          input: {
            contentIds: contentIds,
            parentVaultContentId: folderId,
            vaultId: vaultId,
          },
        },
        {
          onSuccess: () => {
            onSuccess?.();
          },
        },
      );
    },
  );

  const removeContents = useStableCallback(
    async ({
      contentIds,
      vaultId,
      onSuccess,
    }: {
      contentIds: string[];
      vaultId: string;
      onSuccess?: () => void;
    }) => {
      return removeVaultContentsMutate(
        {
          input: {
            contentIds,
            vaultId,
          },
        },
        {
          onSuccess: () => {
            onSuccess?.();
          },
        },
      );
    },
  );

  return {
    moveToFolder,
    isMovingFolder,
    removeContents,
    isRemovingContent,
  };
};
