import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { stripe } from '../../clients/stripeClient';
import { AddPaymentMethodView } from '../../components/views/settings/AddPaymentMethodView';
import { STRIPE_APPEARANCE } from '../../constants/stripeConstants';

const AddPaymentMethodPage = () => {
  return (
    <Elements
      stripe={stripe}
      options={{ mode: 'setup', currency: 'usd', appearance: STRIPE_APPEARANCE }}
    >
      <AddPaymentMethodView />
    </Elements>
  );
};

export { AddPaymentMethodPage };
