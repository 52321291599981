import React, { useCallback } from 'react';
import { Portal, Root } from '@radix-ui/react-dialog';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { REPORT_ISSUE_URL } from '../../constants/urlConstants';
import { ModalContext, useModal } from '../../contexts/ModalContext';
import { AccessibleLink } from '../common/AccessibleLink';
import { Toast } from '../common/Toast';

const Fallback = () => {
  const { closeCancelModal, activeModal } = ModalContext.useContainer();
  const { resetBoundary } = useErrorBoundary();
  const [open, setOpen] = React.useState(true);

  const getCopy = (() => {
    switch (activeModal) {
      default:
        return 'Whoops! We ran into an issue.';
    }
  })();

  return (
    <Toast
      variant="error"
      text={
        <>
          {getCopy} Please{' '}
          <AccessibleLink
            absoluteUri={REPORT_ISSUE_URL}
            className="text-brand300 hover:text-brand600"
            type="external"
          >
            let us know
          </AccessibleLink>{' '}
          or try again
        </>
      }
      isToastOpen={open}
      setToastOpen={isOpen => {
        closeCancelModal();
        resetBoundary();
        setOpen(isOpen);
      }}
    />
  );
};

function GlobalModalComponent() {
  const { modalContent, activeModal, closeCancelModal } = useModal();

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) return;
      closeCancelModal();
    },
    [closeCancelModal],
  );

  const isOpen = activeModal !== null;

  return (
    <ErrorBoundary fallback={<Fallback />}>
      <Root open={isOpen} onOpenChange={handleOpenChange}>
        <Portal forceMount={isOpen || undefined}>{modalContent?.body}</Portal>
      </Root>
    </ErrorBoundary>
  );
}

export const GlobalModal = () => (
  <>
    <GlobalModalComponent />
  </>
);
