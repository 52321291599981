import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { VaultContentType } from '../../graphql/generated';
import { useVaultContentPagination } from '../../hooks/useVaultContent';
import { View } from '../common/View';
import { EmptyStateView } from '../views/EmptyStateView';
import { SkeletonTrackFile, TrackFile } from './TrackFile';

type HorizontalTrackFileCarouselProps = {
  vaultId: string;
  artist: { id: string; name: string; linkValue: string };
  selectedTrack: string | null;
  setSelectedTrack: (trackId: string | null) => void;
};

export const HorizontalTrackFileCarousel: React.FC<HorizontalTrackFileCarouselProps> = ({
  vaultId,
  artist,
  selectedTrack,
  setSelectedTrack,
}) => {
  const { loggedInUser } = useAuthContext();
  const { closeBottomsheet } = useBottomsheetContainer();
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const {
    orderedList: vaultContents,
    loadMoreNextPage,
    hasNextPage,
    isInitialLoading,
  } = useVaultContentPagination({
    vaultId,
    pageSize: 10,
    isOwner: loggedInUser?.artist?.id === artist.id,
    contentType: VaultContentType.Track,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      loadMoreNextPage();
    }
  }, [inView, hasNextPage, loadMoreNextPage]);

  if (vaultContents.length === 0 && !isInitialLoading) {
    return (
      <EmptyStateView
        title="Currently no tracks"
        subtitle={
          loggedInUser?.artist?.id === artist.id
            ? 'Add tracks to attach to your message'
            : `${artist.name} hasn't added any tracks yet.`
        }
        buttonText={loggedInUser?.artist?.id === artist.id ? 'Upload a new track' : undefined}
        buttonHref={`/${artist.linkValue}`}
        onButtonClick={closeBottomsheet}
        className="h-[200px] text-vault_text"
        buttonClassName="bg-vault_accent text-vault_accent_text"
        subtitleClassName="text-vault_text/50"
      />
    );
  }

  if (isInitialLoading) {
    return (
      <View className="no-scrollbar flex w-full flex-row gap-6 overflow-x-auto">
        <SkeletonTrackFile withVaultTheme />
        <SkeletonTrackFile withVaultTheme />
        <SkeletonTrackFile withVaultTheme />
        <SkeletonTrackFile withVaultTheme />
        <SkeletonTrackFile withVaultTheme />
        <SkeletonTrackFile withVaultTheme />
      </View>
    );
  }

  return (
    <View className="no-scrollbar flex w-full gap-4 overflow-x-auto">
      <AnimatePresence>
        {vaultContents.map((track, i) => {
          if (track.__typename !== 'VaultTrack') return null;

          return (
            <motion.div
              key={track.id}
              className="w-28 shrink-0"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.2 }}
            >
              <TrackFile
                track={track}
                type="message_attachment"
                isSelected={selectedTrack === track.id}
                onClick={() => setSelectedTrack(selectedTrack === track.id ? null : track.id)}
                containerRef={i === vaultContents.length - 1 ? ref : undefined}
                vaultContentId={track.id}
                withVaultTheme
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </View>
  );
};
