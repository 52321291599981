import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router';
import { faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { type ExecutionResultWithData, useMutation, useQuery } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  type FragmentType,
  getFragment,
  PresaveSpotifyDocument,
  type PresaveSpotifyInfoFragment,
  PresaveSpotifyInfoFragmentDoc,
  SpotifyPresaveStatusDocument,
  type SpotifyPresaveStatusQuery,
} from '../../../graphql/generated';
import {
  getRandomSpotifyState,
  SpotifyConnectState,
  useLinkSpotifyAccount,
  useSpotifyAuth,
} from '../../../hooks/spotify/useSpotifyAuth';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { createBatchingInputStore } from '../../../utils/batchInput';
import { Button } from '../../buttons/Button';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment PresaveSpotifyInfo on VaultTrack {
    id

    presaveConfiguration {
      id
      spotifyAlbumURI
      status
    }
  }

  query SpotifyPresaveStatus($input: QuerySpotifyPresaveStatusInput!) {
    spotifyPresaveStatus(input: $input) {
      __typename
      ... on QuerySpotifyPresaveStatusSuccess {
        data {
          presaveConfigId
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation PresaveSpotify($presaveConfigurationId: UUID!, $spotifyAuthCode: String) {
    spotifyPresaveVaultContent(
      presaveConfigurationId: $presaveConfigurationId
      spotifyAuthCode: $spotifyAuthCode
    ) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

const { useBatchedKey: useSpotifyPresaveStatusKey } = createBatchingInputStore({
  chunkLimit: 50,
});

RefetchOnComplete({
  trigger: [PresaveSpotifyDocument],
  refetch: [SpotifyPresaveStatusDocument],
});

export function useIsPresaved({ presaveConfigId }: { presaveConfigId: string | null | undefined }) {
  const spotifyAuth = useSpotifyAuth({ enabled: presaveConfigId != null });

  const { loggedInUser } = useAuthContext();

  const presaveConfigIds = useSpotifyPresaveStatusKey({
    key: presaveConfigId,
  });

  const { data: isPresaved = false, isLoading: isPresavedLoading } = useQuery(
    SpotifyPresaveStatusDocument,
    {
      staleTime: 0,
      variables: !!presaveConfigIds &&
        (!!loggedInUser?.spotifyAuthConnection?.spotifyUserId ||
          (spotifyAuth.type === 'already-connected' && !!spotifyAuth.authCode)) && {
          input: {
            presaveConfigIds,
            spotifyAuthCode: loggedInUser?.spotifyAuthConnection?.spotifyUserId
              ? null
              : spotifyAuth.authCode,
          },
        },
      select: useStableCallback((data: ExecutionResultWithData<SpotifyPresaveStatusQuery>) => {
        if (data.data.spotifyPresaveStatus.__typename !== 'QuerySpotifyPresaveStatusSuccess') {
          return false;
        }

        return data.data.spotifyPresaveStatus.data.some(
          value => value.presaveConfigId === presaveConfigId,
        );
      }),
    },
  );

  return {
    isPresaved,
    isPresavedLoading,
  };
}

function PresaveSpotifyButtonComponent({
  presaveConfig,
}: {
  presaveConfig: NonNullable<PresaveSpotifyInfoFragment['presaveConfiguration']>;
}) {
  const spotifyAuth = useSpotifyAuth({
    enabled: true,
  });
  const { loggedInUser } = useAuthContext();

  const { isPresaved, isPresavedLoading } = useIsPresaved({
    presaveConfigId: presaveConfig.id,
  });
  const { mutateAsync: presaveSpotify } = useMutation(PresaveSpotifyDocument, {
    retry: 3,
  });

  const { openToast } = useToast();

  const { value } = SpotifyConnectState.useStore();

  const isSpotifyConnected =
    !!loggedInUser?.spotifyAuthConnection?.spotifyUserId ||
    spotifyAuth.type === 'already-connected';

  const presave = useStableCallback(() => {
    if (loggedInUser?.spotifyAuthConnection?.spotifyUserId) {
      return presaveSpotify({
        presaveConfigurationId: presaveConfig.id,
      })
        .then(result => {
          if (
            result.data.spotifyPresaveVaultContent.__typename !==
            'MutationSpotifyPresaveVaultContentSuccess'
          ) {
            return openToast({
              variant: 'error',
              text: result.data.spotifyPresaveVaultContent.message,
            });
          }

          openToast({
            variant: 'success',
            text: 'Presaved successfully',
          });
        })
        .catch(error => {
          captureException(error, {
            extra: {
              presaveConfig,
            },
          });
          openToast({
            variant: 'error',
            text: 'Presave failed. Please try again later.',
          });
        });
    } else if (spotifyAuth.type === 'already-connected') {
      return presaveSpotify({
        presaveConfigurationId: presaveConfig.id,
        spotifyAuthCode: spotifyAuth.authCode,
      })
        .then(result => {
          if (
            result.data.spotifyPresaveVaultContent.__typename !==
            'MutationSpotifyPresaveVaultContentSuccess'
          ) {
            return openToast({
              variant: 'error',
              text: result.data.spotifyPresaveVaultContent.message,
            });
          }

          openToast({
            variant: 'success',
            text: loggedInUser
              ? 'Presaved successfully'
              : 'Presaved successfully. Sign in to claim your rewards.',
          });
        })
        .catch(error => {
          captureException(error, {
            extra: {
              presaveConfig,
            },
          });
          openToast({
            variant: 'error',
            text: 'Presave failed. Please try again later.',
          });
        });
    }

    return;
  });

  const justConnectedSpotify = value?.justConnected;

  const linkSpotify = useLinkSpotifyAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (justConnectedSpotify && isSpotifyConnected) {
      SpotifyConnectState.produceExistingState(
        draft => {
          draft.justConnected = false;
        },
        {
          code: null,
          state: getRandomSpotifyState(),
          justConnected: false,
        },
      );

      presave()?.then(() => {
        linkSpotify().then(linkResult => {
          if (linkResult.type === 'no-user') {
            navigate(linkResult.signInPath);
          }
        });
      });
    }
  }, [justConnectedSpotify, presave, isSpotifyConnected, linkSpotify, navigate]);

  return (
    <View className="my-3 flex w-full flex-row justify-center">
      <Button
        label={isPresaved ? 'Presaved' : 'Presave'}
        type="primary"
        buttonType="button"
        isExternal={spotifyAuth.link != null}
        className="flex w-full self-center text-base-l font-medium text-black md:w-[156px]"
        disabledClassName="opacity-30"
        disabled={isPresaved || isPresavedLoading}
        leadingIcon={faSpotify}
        href={loggedInUser?.spotifyAuthConnection?.spotifyUserId ? undefined : spotifyAuth.link}
        onClick={() => {
          presave();
        }}
      />
    </View>
  );
}

export function PresaveSpotifyButton({
  track: trackFragment,
}: {
  track: FragmentType<PresaveSpotifyInfoFragmentDoc> | null | undefined;
}) {
  const track = getFragment(PresaveSpotifyInfoFragmentDoc, trackFragment);

  if (
    !track?.presaveConfiguration?.spotifyAlbumURI ||
    track.presaveConfiguration.status === 'INACTIVE'
  ) {
    return null;
  }

  return <PresaveSpotifyButtonComponent presaveConfig={track.presaveConfiguration} />;
}
