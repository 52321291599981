import { useEffect } from 'react';

import { captureException } from '@sentry/react';
import { proxy } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { fetchGQL, useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  GetUnreadAnnouncementsCountDocument,
  UpdateVaultUnreadAnnouncementCountDocument,
  VaultUpdateCountsDocument,
} from '../../graphql/generated';
import { useStableCallback } from '../useStableCallback';

gql(/* GraphQL */ `
  mutation UpdateVaultUnreadAnnouncementCount($vaultId: UUID!) {
    setUserLastViewedTimeAnnouncement(vaultId: $vaultId) {
      __typename
      ... on MutationSetUserLastViewedTimeAnnouncementSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateVaultUnreadAnnouncementCountDocument],
  refetch: [GetUnreadAnnouncementsCountDocument, VaultUpdateCountsDocument],
  throttled: '5 seconds',
});

export const UpdatingVaultAnnouncementEngagement = proxy({
  inProgress: 0,
});

export function useVaultAnnouncementEngagement({
  vaultId,
  triggerQuery,
}: {
  vaultId: string | undefined;
  triggerQuery: boolean;
}) {
  const { loggedInUser } = useAuthContext();

  const { mutateAsync, isLoading } = useMutation(UpdateVaultUnreadAnnouncementCountDocument, {
    retry: 3,
  });

  const setUserEngagement = useStableCallback(() => {
    if (!vaultId || !loggedInUser) return;

    ++UpdatingVaultAnnouncementEngagement.inProgress;

    mutateAsync({ vaultId })
      .catch(error => {
        captureException(error, {
          extra: {
            vaultId,
            loggedInUser,
          },
          tags: {
            type: 'UpdateVaultUnreadAnnouncementCountMutation',
          },
        });
      })
      .finally(() => {
        --UpdatingVaultAnnouncementEngagement.inProgress;
      });
  });

  /*
   * We need to call this mutation twice, once when the user enters the announcement page,
   * and another when the user leaves the announcement page
   */
  useEffect(() => {
    if (!loggedInUser || !vaultId || !triggerQuery) return;

    const engagement = setTimeout(() => {
      setUserEngagement();
    }, 1000);

    return () => {
      setUserEngagement();

      clearTimeout(engagement);
    };
  }, [loggedInUser, vaultId, setUserEngagement, triggerQuery]);

  useEffect(() => {
    if (!loggedInUser || !vaultId || !triggerQuery) return;

    const updateAnnouncementReadCount = () => {
      ++UpdatingVaultAnnouncementEngagement.inProgress;
      fetchGQL(UpdateVaultUnreadAnnouncementCountDocument, {
        variables: { vaultId },
        keepalive: true,
      })
        .catch(error => {
          captureException(error, {
            extra: {
              vaultId,
              loggedInUser,
            },
            tags: {
              type: 'UpdateVaultUnreadAnnouncementCountMutation',
            },
          });
        })
        .finally(() => {
          --UpdatingVaultAnnouncementEngagement.inProgress;
        });
    };

    window.addEventListener('beforeunload', updateAnnouncementReadCount);

    return () => {
      window.removeEventListener('beforeunload', updateAnnouncementReadCount);
    };
  }, [loggedInUser, vaultId, setUserEngagement, triggerQuery]);

  return { setUserEngagement, isLoading };
}
