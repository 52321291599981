import { useSnapshot } from 'valtio';
import {
  addContentId,
  clearSelectContentState,
  removeContentId,
  selectContentState,
  setContentIds,
  setIsSelecting,
} from './selectStore';

export const useSelectVaultContent = () => {
  const { contentIds, isSelecting } = useSnapshot(selectContentState, {
    sync: true,
  });

  return {
    selectedContentIds: contentIds,
    isSelecting,
    setContentIds,
    addContent: addContentId,
    removeContent: removeContentId,
    setIsSelecting,
    clearSelection: clearSelectContentState,
  };
};
