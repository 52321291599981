import { GraphQLReactWS } from '@soundxyz/graphql-react-ws';
import { randomisedExponentialBackoff } from '../utils/sleep';
import { getAuthToken } from './authToken';
import { graphqlClientHeaders } from './headers';

const GRAPHQL_API_WS_URL_CONST = import.meta.env.VITE_GRAPHQL_API_WS_URL;

if (!GRAPHQL_API_WS_URL_CONST) {
  throw Error('No VITE_GRAPHQL_API_WS_URL environment variable set!');
}

export const GATEWAY_API_WS_URL = new URL(GRAPHQL_API_WS_URL_CONST);

export const {
  useSubscription,
  subscriptionStores,
  Effects: SubscriptionEffects,
} = GraphQLReactWS({
  graphqlWsOptions: {
    url: GATEWAY_API_WS_URL.href,
    async connectionParams() {
      return {
        ...(await getAuthToken()),
        ...graphqlClientHeaders,
      };
    },
    shouldRetry() {
      return true;
    },
    retryAttempts: Infinity,
    retryWait: randomisedExponentialBackoff,
  },
});

export type {
  ExecutionResultWithData,
  ExecutionResultWithErrors,
  OnData,
  OnError,
} from '@soundxyz/graphql-react-ws';
