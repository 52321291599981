import type { FC, ReactNode } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { faCreditCard } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  type FragmentType,
  getFragment,
  PaymentMethodRowFragmentDoc,
} from '../../graphql/generated';
import { Text } from '../common/Text';
import { View } from '../common/View';

gql(/* GraphQL */ `
  fragment paymentMethodRow on PaymentMethod {
    id
    last4
    brand
    expMonth
    expYear
  }
`);

type Props = {
  paymentMethod: FragmentType<PaymentMethodRowFragmentDoc>;
  actionComponent?: ReactNode;
  className?: string;
  withVaultTheme: boolean;
};

const PaymentMethodRow: FC<Props> = ({
  paymentMethod,
  actionComponent,
  className,
  withVaultTheme,
}) => {
  const { brand, last4, expMonth, expYear } = getFragment(
    PaymentMethodRowFragmentDoc,
    paymentMethod,
  );

  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row justify-between rounded-xl px-[28px] py-[20px]',
        withVaultTheme ? 'bg-transparent' : 'bg-base800',
        className,
      )}
    >
      <View className="flex flex-row items-center">
        <FontAwesomeIcon
          icon={faCreditCard}
          className={twMerge('mr-[12px]', withVaultTheme && 'text-vault_text')}
        />
        <Text
          className={twMerge(
            'mr-[12px] font-title text-[16px]/[20px] font-medium',
            withVaultTheme ? 'text-vault_text' : 'text-white',
          )}
        >
          {capitalize(brand)}
        </Text>
        <Text
          className={twMerge(
            'font-base !text-base-m font-normal',
            withVaultTheme ? 'text-vault_text' : 'text-white',
          )}
        >
          {last4} • {expMonth}/{expYear % 100}
        </Text>
      </View>
      {actionComponent != null && actionComponent}
    </View>
  );
};

export { PaymentMethodRow };
