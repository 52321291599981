import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
import { Toggle } from '../Toggle';

export function HookToggle<T extends FieldValues>({
  control,
  name,
  label,
  className,
}: {
  name: Path<T>;
  control: Control<T>;
  label: string;
  className?: string;
}) {
  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Toggle
          label={label}
          className={className}
          onChange={e => onChange(e.target.checked)}
          checked={!!value}
        />
      )}
    />
  );
}
