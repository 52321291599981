import type { PrivyClientConfig } from '@privy-io/react-auth';
import { VAULT_LOGO_SVG } from './imageConstants';

export const PRIVY_CLIENT_CONFIG: PrivyClientConfig = {
  // @ts-expect-error headless is an internal prop (documentation: https://docs.privy.io/guide/guides/headless-react-auth#login-with-email-or-sms)
  headless: true,
  loginMethods: ['sms'],
  appearance: {
    theme: 'dark',
    accentColor: '#E3F41D',
    logo: VAULT_LOGO_SVG,
    showWalletLoginFirst: false,
  },
  embeddedWallets: {
    createOnLogin: 'all-users',
  },
};
