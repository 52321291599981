import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, type ButtonProps } from '../../components/buttons/Button';
import { View } from '../../components/common/View';
import type { EventType } from '../../types/eventTypes';

export function TopNavButton<Event extends EventType>({
  showBlur,
  ...rest
}: Omit<ButtonProps<Event>, 'label'> & { showBlur: boolean }) {
  const className = getTopNavButtonClassName(showBlur);

  const button = (
    <Button {...rest} iconOnly label="" className={twMerge(className, rest.className)} />
  );

  return showBlur ? button : <BlurContainer>{button}</BlurContainer>;
}

export function BlurContainer({ children }: { children: ReactNode }) {
  return (
    <View className="flex aspect-square h-[36px] w-[36px] items-center justify-center rounded-full bg-vault_background md2:h-[40px] md2:w-[40px]">
      {children}
    </View>
  );
}

export function getTopNavButtonClassName(showBlur: boolean) {
  return showBlur
    ? 'text-[20px] text-vault_text'
    : 'aspect-square rounded-full text-[14px] text-vault_text bg-vault_text/10 hover:bg-vault_text/20 hover:!opacity-100 backdrop-blur-2xl md2:h-[40px] md2:w-[40px] h-[36px] w-[36px]';
}
