import React, { type FC } from 'react';

const FormErrorIndicator: FC = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
      <path
        d="M13 24C8.6875 24 4.75 21.75 2.59375 18C0.4375 14.2969 0.4375 9.75 2.59375 6C4.75 2.29688 8.6875 0 13 0C17.2656 0 21.2031 2.29688 23.3594 6C25.5156 9.75 25.5156 14.2969 23.3594 18C21.2031 21.75 17.2656 24 13 24ZM13 6C12.3438 6 11.875 6.51562 11.875 7.125V12.375C11.875 13.0312 12.3438 13.5 13 13.5C13.6094 13.5 14.125 13.0312 14.125 12.375V7.125C14.125 6.51562 13.6094 6 13 6ZM11.5 16.5C11.5 17.3438 12.1562 18 13 18C13.7969 18 14.5 17.3438 14.5 16.5C14.5 15.7031 13.7969 15 13 15C12.1562 15 11.5 15.7031 11.5 16.5Z"
        fill="#EF4444"
      />
    </svg>
  );
};

export { FormErrorIndicator };
