import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMusic } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { INSTAGRAM_ICON, SPOTIFY_ICON, TIKTOK_ICON } from '../../constants/imageConstants';
import type { AboutArtistBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { getMonthAndYear } from '../../utils/dateUtils';
import { pluralizeText } from '../../utils/textUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { View } from '../common/View';

export function AboutArtistBottomSheet({
  vaultId,
  contentCount,
  artist: {
    customWebsiteUrl,
    id: artistId,
    createdAt,
    instagramHandle,
    name,
    spotifyUrl,
    tiktokHandle,
    profileImageUri,
    description,
  },
}: AboutArtistBottomsheetProps) {
  const hasUrl =
    instagramHandle != null ||
    spotifyUrl != null ||
    tiktokHandle != null ||
    customWebsiteUrl != null;

  const joinedAt = useMemo(() => getMonthAndYear(new Date(createdAt)), [createdAt]);

  return (
    <View className="flex h-full w-full flex-col items-center justify-center overflow-auto pb-8">
      <ArtistProfileImage profileImageUrl={profileImageUri} className="w-[100px]" />

      <h2 className="mt-5 line-clamp-1 font-title text-title-xl font-medium text-white">{name}</h2>

      {hasUrl && (
        <View className="mt-3 flex flex-row gap-4">
          {customWebsiteUrl && (
            <Button
              label="globe"
              leadingIcon={faGlobe}
              href={customWebsiteUrl}
              isExternal
              className="text-[24px] text-white"
              iconOnly
              event={{
                type: EVENTS.ARTIST_SOCIAL,
                properties: {
                  vaultId,
                  artistId,
                  link: customWebsiteUrl,
                  type: 'other',
                },
              }}
            />
          )}
          {spotifyUrl && (
            <Button
              label="spotify"
              className="text-white"
              iconOnly
              leadingImage={SPOTIFY_ICON}
              imageSize={24}
              isExternal
              href={spotifyUrl}
              event={{
                type: EVENTS.ARTIST_SOCIAL,
                properties: {
                  vaultId,
                  artistId,
                  link: spotifyUrl,
                  type: 'spotify',
                },
              }}
            />
          )}
          {instagramHandle != null && (
            <Button
              label="instagram"
              className="text-white"
              iconOnly
              leadingImage={INSTAGRAM_ICON}
              imageSize={24}
              isExternal
              href={`https://www.instagram.com/${instagramHandle.replace('@', '')}`}
              event={{
                type: EVENTS.ARTIST_SOCIAL,
                properties: {
                  vaultId,
                  artistId,
                  link: `https://www.instagram.com/${instagramHandle.replace('@', '')}`,
                  type: 'instagram',
                },
              }}
            />
          )}
          {tiktokHandle != null && (
            <Button
              label="tiktok"
              className="text-white"
              iconOnly
              leadingImage={TIKTOK_ICON}
              imageSize={24}
              isExternal
              href={`https://www.tiktok.com/@${tiktokHandle.replace('@', '')}`}
              event={{
                type: EVENTS.ARTIST_SOCIAL,
                properties: {
                  vaultId,
                  artistId,
                  link: `https://www.tiktok.com/@${tiktokHandle.replace('@', '')}`,
                  type: 'tiktok',
                },
              }}
            />
          )}
        </View>
      )}

      <span className="mt-3 line-clamp-1 font-base text-base-m font-medium text-base400">
        Member since {joinedAt}
      </span>

      <span className="mt-5 whitespace-pre-wrap break-words px-2 font-base text-base-m font-medium text-white">
        {description}
      </span>

      <View className="mt-4 flex flex-col gap-3 self-start px-3 md2:self-center">
        <View className="flex items-center gap-3">
          <View className="flex h-5 w-5 items-center justify-center">
            <FontAwesomeIcon icon={faMusic} fontSize={16} className="text-center text-base400" />
          </View>
          <span className="line-clamp-1 text-left font-base text-base-l font-normal text-base400">
            {millify(contentCount)} {pluralizeText({ text: 'track', count: contentCount })}
          </span>
        </View>
      </View>
    </View>
  );
}
