import { useEffect } from 'react';
import msFn from 'ms';
import { Navigate, useParams } from 'react-router';
import { ClaimReceiptViewSkeleton } from '../../../components/campaign/ClaimReceiptView';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { ClaimPageView } from '../../../components/rsvp/ClaimPageView';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useQuery } from '../../../graphql/client';
import {
  getFragment,
  MembershipReceiptFragmentDoc,
  RsvpDropViewFragmentDoc,
  RsvpEventBySlugDocument,
} from '../../../graphql/generated';
import { useLastMembershipReceipt } from '../../../hooks/campaign/useLastMembershipReceipt';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';

const fiveMinutes = msFn('5 minutes');

// In the future I think this page will serve as a general claim receipt page for all types of drop
// Checking the typename of the drop to choose what to render
export function ClaimPage() {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { setShowMenu } = useMenuContainer();
  const { dropSlug } = useParams<{
    dropSlug: string;
  }>();

  useVaultTheme();

  const { data, isLoading, isError, refetch } = useQuery(RsvpEventBySlugDocument, {
    cacheTime: 0,
    staleTime: 0,
    variables: !!artistHandle &&
      !!dropSlug && {
        artistHandle: artistHandle.toLowerCase(),
        eventLinkValue: dropSlug,
      },
    filterQueryKey: {
      dropSlug,
      artistHandle,
    },
    // Refetch to always get latest drop
    refetchInterval: fiveMinutes,
  });

  const dropFrag = getFragment(RsvpDropViewFragmentDoc, data?.data.rsvpEventByLink);
  const drop =
    dropFrag?.__typename === 'PublicRsvpEvent' || dropFrag?.__typename === 'RsvpEventPrivateInfo'
      ? dropFrag
      : null;

  const { data: lastReceiptData, isLoading: isLoadingReceipt } = useLastMembershipReceipt({
    artistHandle,
    receiptType: 'ArtistMembershipRsvpEventReceipt',
    releaseCampaignId: undefined,
    rsvpEventId: drop?.id,
    source: null,
    staleTime: 0,
    enabled: true,
  });

  const lastReceipt = getFragment(
    MembershipReceiptFragmentDoc,
    lastReceiptData?.data.lastMembershipReceipt,
  );

  const isArtistOwner = artistHandle
    ? loggedInUser?.adminArtists?.find(({ artistLinks }) => artistLinks.includes(artistHandle))
    : false;

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  if (dropSlug == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError) {
    return (
      <View className="flex h-full w-full items-center justify-center bg-vault_background">
        <ErrorView onRetryClick={refetch} loggingType="claim_drop_receipt_page" withVaultTheme />
      </View>
    );
  }

  if (
    isLoading ||
    isLoadingReceipt ||
    loginStatus === LoginStatus.LOADING ||
    lastReceipt === undefined
  ) {
    return <ClaimReceiptViewSkeleton />;
  }

  if (data?.data.rsvpEventByLink == null || isArtistOwner || lastReceipt === null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <ClaimPageView dropFrag={data.data.rsvpEventByLink} receipt={lastReceipt} />;
}
