export function getLocationText(location: {
  city: string | null;
  country: string | null;
  region: string | null;
}) {
  if (location.country === 'United States') {
    return [location.region, location.city, location.country].filter(Boolean).join(', ');
  }

  return [location.city, location.region, location.country].filter(Boolean).join(', ');
}
