export const FAKE_NORMALIZED_PEAKS = [
  5, 3, 1, 7, 10, 19, 15, 12, 20, 18, 11, 9, 8, 13, 5, 17, 14, 2, 4, 16, 6, 3, 7, 1, 9, 17, 12, 8,
  15, 18, 5, 11, 10, 13, 20, 14, 19, 6, 2, 4, 16, 7, 18, 9, 1, 12, 20, 15, 11, 3, 5, 17, 14, 13, 10,
  4, 8, 19, 6, 2, 27, 45, 33, 58, 68, 83, 75, 90, 100, 95, 85, 60, 70, 55, 80, 65, 40, 50, 30, 25,
  42, 56, 63, 78, 88, 93, 97, 85, 77, 62, 72, 67, 53, 49, 64, 79, 89, 84, 91, 96, 81, 69, 74, 59,
  54, 69, 34, 39, 44, 29, 35, 51, 46, 71, 86, 92, 98, 94, 99, 87, 73, 38, 43, 28, 24, 41, 36, 31,
  26, 21, 47, 52, 57, 82, 76, 61, 66, 21, 26, 31, 10, 5, 15, 2, 13, 18, 7, 3, 12, 1, 16, 20, 11, 6,
  17, 8, 19, 14, 9, 4, 13, 10, 7, 3, 15, 1, 18, 20, 12, 16, 4, 6, 9, 2, 17, 5, 14, 19, 11, 8, 5, 10,
  2, 14, 1, 17, 7, 12, 20, 16, 18, 9, 15, 13, 3, 4, 6, 11, 19, 8,
];
