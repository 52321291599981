import type { FC } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faArrowUpRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { AccessibleLink } from '../../components/common/AccessibleLink';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';

const AboutSettingsPage: FC = () => {
  return (
    <SettingsLayout title="About Vault">
      <View className="mt-2 flex w-full flex-col gap-4 md:gap-5">
        <AboutItem href="/settings/about-vault/about" label="What is Vault" />
        <AboutItem href={TOS_URL} label="Terms and conditions" isExternal />
        <AboutItem href={PRIVACY_POLICY_URL} label="Privacy" isExternal />
      </View>
    </SettingsLayout>
  );
};

const AboutItem = ({
  href,
  label,
  isExternal = false,
}: {
  href: string;
  label: string;
  isExternal?: boolean;
}) => {
  const item = (
    <View className="transition-colors flex w-full justify-between text-base-l text-base50 duration-300 ease-in-out hover:text-base300">
      <Text>{label}</Text>
      <FontAwesomeIcon icon={faArrowUpRight} />
    </View>
  );
  if (isExternal) {
    return (
      <AccessibleLink type="external" absoluteUri={href} className="w-full  no-underline">
        {item}
      </AccessibleLink>
    );
  }
  return (
    <Link to={href} className="w-full no-underline">
      {item}
    </Link>
  );
};

export { AboutSettingsPage };
