import { getAccessToken } from '@privy-io/react-auth';
import { Sentry } from '../sentry';

export async function getAuthToken() {
  const authToken = await getAccessToken().catch(error => {
    Sentry.captureException(error, {
      tags: {
        type: 'getAuthToken',
      },
    });

    return null;
  });

  if (!authToken) return {};

  return {
    'auth-token': authToken,
  };
}
