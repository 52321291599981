import type { FC } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../components/buttons/Button';
import { View } from '../../components/common/View';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { Logo } from '../../components/svg/Logo';
import { SubscribeSuccessView } from '../../components/views/SubscribeSuccessView';
import { ROUTES } from '../../constants/routeConstants';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { StripeProvider } from '../../providers/StripeProvider';

const SubscribeSuccessPage: FC = () => {
  const { artistHandle } = useArtistHandle();
  const [searchParams] = useSearchParams();

  const clientSecret = searchParams.get('payment_intent_client_secret');
  const vaultId = searchParams.get('vaultId');
  const code = searchParams.get('code');
  const subscriptionId = searchParams.get('subscriptionId');

  if (artistHandle == null || clientSecret == null || vaultId == null || subscriptionId == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={
        <Button
          label=""
          labelComponent={
            <View className="flex h-[48px] w-[48px] flex-col items-center justify-center">
              <Logo variant="default" />
            </View>
          }
        />
      }
    >
      <StripeProvider
        vaultId={vaultId}
        referralCode={code}
        clientSecret={clientSecret}
        withVaultTheme={false}
      >
        <SubscribeSuccessView
          clientSecret={clientSecret}
          vaultId={vaultId}
          artistHandle={artistHandle.toLowerCase()}
          subscriptionId={subscriptionId}
        />
      </StripeProvider>
    </DefaultLayout>
  );
};

export { SubscribeSuccessPage };
