let workerInstance: Worker | null = null;

export const getWorker = (): Worker => {
  if (!workerInstance) {
    workerInstance = new Worker(new URL('../components/audioRecorder/worker.js', import.meta.url), {
      type: 'module',
    });
  }
  return workerInstance;
};

export const terminateWorker = (): void => {
  if (workerInstance) {
    workerInstance.terminate();
    workerInstance = null;
  }
};
