import type { FC, LegacyRef } from 'react';
import React from 'react';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';

import { useSnapshot } from 'valtio/react';
import { gql } from '@soundxyz/gql-string';
import { loadTrack, useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../audio/AudioPosition';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistMainVaultViewFragmentDoc,
  type FragmentType,
  getFragment,
  RemoveVaultContentsDocument,
  TrackRowInfoFragmentDoc,
  VaultContentPaginationDocument,
} from '../../graphql/generated';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getDurationAsTime, isFileConsideredNew } from '../../utils/dateUtils';
import { LockedPlayButton, PlayButtonView } from '../audioPlayer/PlayButtonView';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { Waveform } from '../waveform/Waveform';
import { PlayIndicatorBars } from './PlayIndicatorBars';

gql(/* GraphQL */ `
  fragment TrackRowInfo on VaultTrack {
    __typename
    id
    title
    createdAt

    normalizedPeaks
    duration

    parentVaultContentId

    vaultId
    vault {
      id
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      isUserArtistAdmin
    }

    featureAccess {
      feature {
        __typename
      }
    }
    isFullVersionAvailable
    freeTierSnippet {
      id
      duration
    }
    ...WaveformTrackInfo
  }
`);

RefetchOnComplete({
  trigger: [RemoveVaultContentsDocument],
  refetch: [VaultContentPaginationDocument, ArtistMainVaultViewFragmentDoc, ArtistByHandleDocument],
});

type Props = {
  waveFormClass?: string;
  className?: string;
  track: FragmentType<TrackRowInfoFragmentDoc>;
  lastViewedTime: Date | null;
  containerRef?: LegacyRef<HTMLDivElement>;
  isLocked: boolean;
  isAuthor?: boolean;
};

export const TrackRow: FC<Props> = ({
  track,
  waveFormClass,
  className,
  lastViewedTime,
  containerRef,
  isLocked,
  isAuthor,
}) => {
  const { pathname } = useLocation();
  const { playing, activeTrackId, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();

  const trackInfo = getFragment(TrackRowInfoFragmentDoc, track);

  const vaultTheme = useSnapshot(VaultThemeStore);

  const {
    id,
    title,
    createdAt,
    duration,
    vaultId,
    isFullVersionAvailable,
    freeTierSnippet,
    parentVaultContentId,
  } = trackInfo;

  const isNew = isFileConsideredNew({
    createdAt,
    lastViewedTime,
  });

  const isSnippet = !isFullVersionAvailable;

  const isPlayDisabled = false;

  const isPlaying = playing && activeTrackId === id;

  const onPlayClick = () => {
    if (activeTrackId !== id) {
      loadTrack({
        trackId: id,
        vaultId,
        component: 'message_attachment',
        folderId: parentVaultContentId,
      });
    } else {
      activeTrack != null &&
        trackEvent({
          type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
          properties: {
            trackId: activeTrack.id,
            vaultId: activeTrack.vault.id,
            artistId: activeTrack.vault.artist?.id,
            percentComplete,
            isPreview: !activeTrack.isFullVersionAvailable,
            component: 'message_attachment',
          },
          pathname,
        });

      togglePlayPause();
    }
  };

  return (
    <View
      className={twMerge(
        'my-1 box-border flex flex-col rounded-md bg-[#1f1f1f] p-[16px]',
        isAuthor
          ? 'bg-vault_accent_text/30'
          : vaultTheme.mode === 'light'
            ? 'bg-vault_text/30'
            : 'bg-vault_accent_text/30',
        className,
      )}
      containerRef={containerRef}
    >
      <View className="flex flex-row items-start">
        <View className="box-border flex max-w-full flex-1 flex-col">
          <View className="mr-3 flex flex-row items-center justify-start gap-1">
            {isPlaying && <PlayIndicatorBars className="mr-[2px]" isAuthor={!!isAuthor} />}
            <Text
              className={twMerge(
                'mr-1 line-clamp-1 font-base !text-base-m font-semibold',
                isAuthor ? 'text-vault_accent_text' : 'text-vault_text',
              )}
            >
              {title ?? 'Unnamed'}
            </Text>

            {isSnippet ? (
              <Text
                className={twMerge(
                  'flex h-4 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 text-[8px]',
                  isAuthor
                    ? 'border-vault_accent_text text-vault_accent_text'
                    : 'border-vault_text text-vault_text',
                )}
              >
                Snippet
              </Text>
            ) : (
              isNew && (
                <View className="rounded-full bg-vault_accent px-1.5 py-0.5 font-base !text-base-xs font-semibold text-vault_accent_text">
                  New
                </View>
              )
            )}
          </View>
          <View
            className={twMerge(
              'font-base',
              isAuthor
                ? 'text-[11px]/[14px] text-vault_accent_text'
                : 'text-[11px]/[14px] text-vault_text',
            )}
          >
            {isSnippet
              ? !!freeTierSnippet
                ? getDurationAsTime(freeTierSnippet.duration)
                : ''
              : getDurationAsTime(duration)}
          </View>
        </View>
        {isLocked ? (
          <LockedPlayButton />
        ) : (
          <PlayButtonView
            isPlaying={isPlaying}
            isDisabled={isPlayDisabled}
            className={isAuthor ? 'text-vault_accent_text' : 'text-vault_text'}
            onClick={e => {
              e.stopPropagation();
              onPlayClick();
            }}
          />
        )}
      </View>
      <View className="mt-[10px] flex w-full flex-row items-center gap-4">
        <div className="flex flex-1">
          <Waveform
            track={trackInfo}
            className={twMerge('flex-1', waveFormClass)}
            height={24}
            isDisabled={isLocked || isPlayDisabled}
            isAuthor={!!isAuthor}
          />
        </div>
      </View>
    </View>
  );
};

export function TrackRowSkeleton() {
  return (
    <View className="box-border flex w-full flex-1 flex-col items-center">
      <LoadingSkeleton className="mb-[20px] h-[112px] w-full bg-vault_text/10" />
    </View>
  );
}
