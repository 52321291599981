import { captureMessage } from '@sentry/react';
import { getErrorInstance } from './errors';
import { randomisedExponentialBackoff } from './sleep';

const MAX_ATTEMPTS = 4;

export async function wrapSendCode(sendCode: () => Promise<void>) {
  let attempt = 0;

  let lastError: Error | null = null;
  while (attempt < MAX_ATTEMPTS) {
    attempt++;
    try {
      await sendCode();

      if (attempt > 1) {
        captureMessage('Successfully sent code after retry', {
          extra: {
            lastError,
            attempt,
          },
          level: 'info',
        });
      }

      return;
    } catch (errorRaw) {
      const error = getErrorInstance(errorRaw);

      lastError = error;

      captureMessage('Error sending code on retry', {
        extra: {
          error,
          attempt,
        },
        level: 'warning',
      });

      if (attempt < MAX_ATTEMPTS) {
        await randomisedExponentialBackoff(attempt);
      }
    }
  }

  if (lastError) {
    throw lastError;
  }
}
