/**
 * From https://github.com/tim-soft/use-double-click/blob/master/src/index.js
 */

import { type MutableRefObject, useEffect } from 'react';

export const useDoubleClick = ({
  ref,
  latency = 300,
  onSingleClick = () => null,
  onDoubleClick = () => null,
}: {
  ref: MutableRefObject<HTMLElement | null>;
  latency?: number;
  onSingleClick?: (e: Event) => void;
  onDoubleClick?: (e: Event) => void;
}) => {
  useEffect(() => {
    if (!ref.current) return;

    const clickRef = ref.current;
    let clickCount = 0;
    let tapCount = 0;

    let clickTimeoutDoubleClick: ReturnType<typeof setTimeout> | undefined;
    let tapTimeoutDoubleClick: ReturnType<typeof setTimeout> | undefined;

    const handleTouchEnd = (e: Event) => {
      tapCount += 1;

      tapTimeoutDoubleClick = setTimeout(() => {
        if (tapCount === 2) {
          clearTimeout(clickTimeoutDoubleClick);
          onDoubleClick(e);
        }

        tapCount = 0;
      }, latency);
    };

    const handleClick = (e: Event) => {
      clickCount += 1;

      clickTimeoutDoubleClick = setTimeout(() => {
        if (clickCount === 1) onSingleClick(e);
        else if (clickCount === 2) {
          clearTimeout(tapTimeoutDoubleClick);
          onDoubleClick(e);
        }

        clickCount = 0;
      }, latency);
    };

    // Add event listener for click events
    clickRef.addEventListener('click', handleClick);
    clickRef.addEventListener('touchend', handleTouchEnd);

    // Remove event listener
    return () => {
      clickRef.removeEventListener('click', handleClick);
      clickRef.removeEventListener('touchend', handleTouchEnd);
    };
  }, [latency, onDoubleClick, onSingleClick, ref]);
};
