import { twMerge } from 'tailwind-merge';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  getFragment,
  TrackCommentRowFragmentDoc,
  type TrackContentByIdQuery,
} from '../../graphql/generated';
import { generateHashFromUserId } from '../../hooks/useUserDisplayName';
import { EVENTS } from '../../types/eventTypes';
import type { TypeFromGraphQLUnion } from '../../types/gql';
import { trackEvent } from '../../utils/analyticsUtils';
import { pluralizeText } from '../../utils/textUtils';
import { AnimateIn } from '../common/AnimateIn';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { UserProfileImage } from '../user/UserProfileImage';

export function TrackCommentPreviewRow({
  track,
  withVaultTheme,
}: {
  track: TypeFromGraphQLUnion<NonNullable<TrackContentByIdQuery['vaultContentById']>, 'VaultTrack'>;
  withVaultTheme: boolean;
}) {
  const { loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  const isEmptyState = track.commentPinnedMessage == null;
  const headerText =
    isEmptyState || track.commentMessageCount === 0
      ? 'Track comments'
      : `${track.commentMessageCount} track ${pluralizeText({ count: track.commentMessageCount, text: 'comment' })}`;
  const commentFrag = getFragment(TrackCommentRowFragmentDoc, track.commentPinnedMessage);

  const onCommentPress = ({ autoFocusInput }: { autoFocusInput: boolean }) => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.TRACK_COMMENTS,
        component: 'TrackCommentPreviewRow',
        artistId: track.vault.artist?.id,
        trackId: track.id,
      },
    });
    openBottomsheet({
      type: 'TRACK_COMMENTS',
      shared: {
        withVaultTheme,
      },
      trackCommentsBottomsheetProps: {
        trackId: track.id,
        autoFocusInput,
        withVaultTheme,
      },
    });
  };
  return (
    <AnimateIn
      key={track.id}
      from="translate-y-[200px] opacity-0"
      to="opacity-100 translate-y-0"
      duration={800}
    >
      <View
        onClick={() => {
          onCommentPress({ autoFocusInput: false });
        }}
        className={twMerge(
          'mx-2 mb-2 flex flex-col items-start justify-center gap-3 rounded-xl p-4',
          withVaultTheme ? 'bg-vault_text/20' : 'bg-base800',
        )}
      >
        <Text className={twMerge('!text-base-l font-medium', withVaultTheme && 'text-vault_text')}>
          {headerText}
        </Text>
        {commentFrag && (
          <View className="flex flex-row items-center gap-3">
            <Text
              className={twMerge('line-clamp-2 !text-base-m', withVaultTheme && 'text-vault_text')}
            >
              <span className="pr-1 font-semibold">
                {commentFrag.asArtist?.linkValue ||
                  commentFrag.user.username ||
                  generateHashFromUserId(commentFrag.user.id)}
              </span>
              {commentFrag.content}
            </Text>
          </View>
        )}
        <View className="flex flex-row items-center gap-[10px] pt-1">
          <UserProfileImage
            className="h-[24px] w-[24px]"
            profileImageUrl={loggedInUser?.artist?.profileImage?.url || loggedInUser?.avatar?.url}
            withVaultTheme={withVaultTheme}
            fallbackColor={
              loggedInUser?.artist?.profileImage?.dominantColor ||
              loggedInUser?.avatar?.dominantColor
            }
          />
          <input
            className={twMerge(
              'w-full border-0 bg-transparent font-base !text-base-m focus:font-normal focus:outline-none',
              withVaultTheme ? 'text-vault_text placeholder:text-vault_text/50' : 'text-white',
            )}
            placeholder="Add a comment..."
            readOnly
            onClick={ev => {
              ev.stopPropagation();
              ev.preventDefault();

              onCommentPress({ autoFocusInput: true });
            }}
          />
        </View>
      </View>
    </AnimateIn>
  );
}
