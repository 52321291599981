import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

export type Pathname = `/${string}`;

type BaseAccessibleLinkProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  tabIndex?: number;
  download?: string;
  disabled?: boolean;
};

type ExternalAccessibleLinkProps = {
  absoluteUri: string;
};

type InternalAccessibleLinkProps = {
  pathnameUri: Pathname;
  prefetch?: boolean;
};

type AccessibleLinkProps = BaseAccessibleLinkProps &
  (
    | ({ type?: 'internal' } & InternalAccessibleLinkProps)
    | ({ type: 'external' } & ExternalAccessibleLinkProps)
  );

export const AccessibleLink = (props: AccessibleLinkProps) => {
  const { className, children, onClick, tabIndex, download, disabled } = props;
  const href = props.type === 'external' ? props.absoluteUri : props.pathnameUri;

  const isExternal = useMemo(() => {
    return !!(href?.startsWith('http') || href?.startsWith('mailto'));
  }, [href]);

  if (disabled) {
    return (
      <a aria-disabled={disabled} className={className} tabIndex={tabIndex}>
        {children}
      </a>
    );
  }

  if (isExternal) {
    return (
      <a
        className={className}
        href={href}
        rel="noopener noreferrer"
        onClick={onClick}
        target="_blank"
        tabIndex={tabIndex}
        download={download}
      >
        {children}
      </a>
    );
  }

  return (
    <Link className={className} to={href} onClick={onClick} tabIndex={tabIndex} download={download}>
      {children}
    </Link>
  );
};
