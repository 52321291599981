import z from 'zod';

const ERRORS = {
  email: {
    non_unique: 'Email has already been verified by another account',
    invalid: 'Please provide a valid email address',
  },
};

const emailSchema = z
  .string({ required_error: ERRORS.email.invalid })
  .email(ERRORS.email.invalid)
  .or(z.literal(''));

const validateEmail = (email: string) => {
  const data = emailSchema.safeParse(email);

  return data.success;
};

const constructQueryParams = (params: {
  [key: string]: string | number | boolean | null | undefined;
}): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      searchParams.append(key, String(value));
    }
  });

  return searchParams.toString().replace(/\+/g, '%20');
};

export { emailSchema, ERRORS, validateEmail, constructQueryParams };
