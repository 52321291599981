import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { fetchQuery, resetOperations, useMutation } from '../../graphql/client';
import {
  CheckMembershipForRsvpDocument,
  LastMembershipReceiptDocument,
  MembershipReceiptFragmentDoc,
  RsvpDropViewFragmentDoc,
  RsvpEventStatus,
  RsvpPrivateDropFragmentDoc,
  RsvpPublicDropFragmentDoc,
  RsvpToEventDocument,
} from '../../graphql/generated';
import { type FragmentType, getFragment } from '../../graphql/generated';
import { useLastMembershipReceipt } from '../../hooks/campaign/useLastMembershipReceipt';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import { Sentry } from '../../sentry';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { LinkifyText } from '../common/LinkifyText';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { UserProfileImage } from '../user/UserProfileImage';
import { SignInForm } from './SignInForm';
import { VerifyForm } from './VerifyForm';
import type { RsvpState } from './schema';

gql(/* GraphQL */ `
  fragment RsvpPublicDrop on PublicRsvpEvent {
    eventDate
    title
    coverImage {
      id
      nonCompressedUrl: url
      compressedCoverImage: childrenMedia(filter: STANDARD_COMPRESSION) {
        id
        compressedUrl: url
      }
    }
    description
    status
  }

  fragment RsvpPrivateDrop on RsvpEventPrivateInfo {
    eventDate
    title
    coverImage {
      id
      nonCompressedUrl: url
      compressedCoverImage: childrenMedia(filter: STANDARD_COMPRESSION) {
        id
        compressedUrl: url
      }
    }
    description
    status
  }

  fragment RsvpDropView on RsvpEvent {
    id
    __typename
    linkValue
    artist {
      id
      name
      linkValue
      isAuthUserAdmin
      membershipCardImage {
        id
        url
      }
      profileImage {
        id
        url
      }
      mainVault {
        id
        activeSubscription {
          id
          ...ActiveSubscriptionFeatures
        }
        isUserArtistAdmin
      }
    }
    ... on PublicRsvpEvent {
      id
      ...RsvpPublicDrop
    }
    ... on RsvpEventPrivateInfo {
      id
      ...RsvpPrivateDrop
    }
  }

  mutation RsvpToEvent($input: MutationRsvpToEventInput!) {
    rsvpToEvent(input: $input) {
      __typename
      ... on Error {
        __typename
        message
      }
    }
  }

  query CheckMembershipForRsvp($input: QueryArtistByLinkInput!) {
    artistByLink(input: $input) {
      id
      isAuthUserAdmin
      mainVault {
        id
        activeSubscription {
          id
        }
      }
    }
  }
`);

/**
 * IMPORTANT NOTE ON TAILWIND
 * Apply all desktop styles with the following logic:
 * !isPreview && "md2:class"
 * the Preview is a FAKE mobile UI, if you don't do this it will look like desktop
 */
export function RsvpDropView(
  props:
    | {
        dropFrag: FragmentType<RsvpDropViewFragmentDoc>;
        isPreview: false;
      }
    | {
        fields: RsvpState['fields'];
        isPreview: true;
      },
) {
  const [view, setView] = useState<'sign_in' | 'verify' | 'auth' | 'claimed'>('sign_in');

  const { isDesktop, height: windowHeight } = useWindow();
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const isContentTaller = useIsContentTaller(leftRef, rightRef);
  const [paddingTop, setPaddingTop] = useState<number | undefined>();

  const { openToast } = useToast();
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();

  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');

  const { subscribeFreeTier, isSubscribingFreeTier } = useFreeTier();

  const { artistHandle } = useArtistHandle();
  const { isPreview } = props;

  const { loggedInUser, loginStatus } = useAuthContext();

  const { mutateAsync: rsvpToEvent, isLoading: isRSVPingToEvent } = useMutation(
    RsvpToEventDocument,
    {
      onSuccess: () => {
        resetOperations({
          operations: [LastMembershipReceiptDocument],
        });
      },
      onError: e => {
        openToast({
          text: e.message,
          variant: 'error',
        });
        Sentry.captureException(e, {
          extra: {
            message: e.message,
          },
          tags: {
            type: 'rsvpToEvent',
          },
        });
      },
    },
  );

  const dropFrag = isPreview ? null : getFragment(RsvpDropViewFragmentDoc, props.dropFrag);
  const publicDrop =
    dropFrag?.__typename === 'PublicRsvpEvent'
      ? getFragment(RsvpPublicDropFragmentDoc, dropFrag)
      : null;
  const privateDrop =
    dropFrag?.__typename === 'RsvpEventPrivateInfo'
      ? getFragment(RsvpPrivateDropFragmentDoc, dropFrag)
      : null;
  const drop = publicDrop || privateDrop;

  const { data: lastReceiptData } = useLastMembershipReceipt({
    artistHandle: dropFrag?.artist.linkValue,
    receiptType: 'ArtistMembershipRsvpEventReceipt',
    releaseCampaignId: undefined,
    rsvpEventId: dropFrag?.id,
    source: null,
    enabled: !isPreview,
    staleTime: 0,
  });

  const dropEnded = useMemo(() => {
    if (!drop?.eventDate) return false;
    const eventDate = new Date(drop.eventDate);
    const currentDate = new Date();
    return eventDate < currentDate;
  }, [drop?.eventDate]);

  const artistName = useMemo(() => {
    return isPreview
      ? loggedInUser?.artist?.name || loggedInUser?.artist?.mainLinkValue
      : dropFrag?.artist.name || dropFrag?.artist.linkValue;
  }, [
    dropFrag?.artist.linkValue,
    dropFrag?.artist.name,
    isPreview,
    loggedInUser?.artist?.mainLinkValue,
    loggedInUser?.artist?.name,
  ]);

  const artistImageUrl = isPreview
    ? loggedInUser?.artist?.profileImage?.url
    : dropFrag?.artist.profileImage?.url;

  const artistLinkValue = isPreview ? undefined : dropFrag?.artist.linkValue;

  const coverImageUrl = isPreview
    ? props.fields.image || props.fields.image || loggedInUser?.artist?.profileImage?.url
    : drop?.coverImage?.compressedCoverImage[0]?.compressedUrl ??
      drop?.coverImage?.nonCompressedUrl ??
      dropFrag?.artist.profileImage?.url;

  const title = isPreview ? props.fields.title : drop?.title;

  const description = isPreview ? props.fields.description : drop?.description;

  const isOwner = !!dropFrag?.artist.isAuthUserAdmin;
  const hasActiveSubscription = !!dropFrag?.artist.mainVault?.activeSubscription?.id || isOwner;

  const textRef = useRef<HTMLDivElement>(null);

  const randomUUID = useMemo(() => crypto.randomUUID(), []);

  const rsvpToEventAction = useStableCallback(async () => {
    if (!dropFrag) return;

    try {
      const result = await rsvpToEvent({
        input: {
          rsvpEventId: dropFrag.id,
        },
      });

      if (result.data.rsvpToEvent.__typename === 'MutationRsvpToEventSuccess') {
        navigate(artistNavigationPath(dropFrag.artist.linkValue, `/d/${dropFrag.linkValue}/claim`));
      } else if (result.data.rsvpToEvent.__typename === 'NotFoundError') {
        navigate(artistNavigationPath(dropFrag.artist.linkValue, '/'));
        openToast({
          text: 'The drop is not currently available.',
          variant: 'error',
        });
      } else {
        openToast({
          text: result.data.rsvpToEvent.message,
          variant: 'error',
        });
      }
    } catch (error) {
      openToast({
        text: 'There was an error RSVPing to the drop. Please try again.',
        variant: 'error',
      });
    }
  });

  const handleRsvpToEvent = useStableCallback(async () => {
    if (!dropFrag?.id) return;

    const { data } = await fetchQuery(CheckMembershipForRsvpDocument, {
      variables: {
        input: {
          link: dropFrag.artist.linkValue,
        },
      },
    });

    const isArtistOwner = !!data.artistByLink?.isAuthUserAdmin;

    const hasActiveSubscription =
      !!data.artistByLink?.mainVault?.activeSubscription?.id || isArtistOwner;

    // If the user is the owner (not an admin), display a toast
    if (isArtistOwner) {
      openToast({
        text: 'Artists cannot RSVP to their own drops.',
        variant: 'primary',
      });
      return;
    }

    trackEvent({
      type: EVENTS.RSVP_TO_EVENT,
      properties: {
        dropId: dropFrag.id,
      },
    });

    // First we need to check if the user is a free member of the vault
    if (hasActiveSubscription) {
      rsvpToEventAction();
    } else {
      // Join for free and then rsvp to the drop
      await subscribeFreeTier({
        input: {
          vaultId: dropFrag.artist.mainVault.id,
          inviteCode: invite,
          sourceRsvpEventId: dropFrag.id,
        },
      });

      rsvpToEventAction();
    }
  });

  const background = useMemo(() => {
    return (
      <>
        <View className="absolute inset-0 z-base h-full w-screen overflow-hidden">
          {coverImageUrl != null ? (
            <Image
              src={coverImageUrl}
              alt="Blurred Cover Image"
              className="h-full w-screen object-cover opacity-75 blur-2xl filter"
            />
          ) : (
            <UserPlaceholderImage
              id={randomUUID}
              className="h-full w-screen object-cover opacity-75 blur-2xl filter"
            />
          )}
        </View>

        <View className="absolute bottom-0 z-base block h-full w-screen bg-gradient-to-b from-transparent to-black" />
      </>
    );
  }, [coverImageUrl, randomUUID]);

  const membershipPagePath = isOwner
    ? artistNavigationPath(artistLinkValue, '/dashboard')
    : artistNavigationPath(artistLinkValue, '/membership');

  const renderContent = useMemo(() => {
    if (loginStatus === LoginStatus.LOADING) {
      return <LoadingSkeleton className="h-[174px] w-full rounded-[24px]" />;
    }

    switch (view) {
      case 'claimed':
        return (
          <View
            className={twMerge(
              'mt-7 box-border flex h-[174px] min-h-[174px] w-full max-w-[364px] flex-col items-center justify-center rounded-[24px] bg-white/10 p-4 backdrop-blur-2xl',
              !isPreview && 'md2:max-w-none',
            )}
          >
            <Text className="text-center font-title text-[22px] font-medium text-white">
              Already RSVP'd
            </Text>

            <Button
              type="primary"
              label="See receipt"
              className="mt-4 w-full"
              onClick={() => {
                const lastReceipt = getFragment(
                  MembershipReceiptFragmentDoc,
                  lastReceiptData?.data.lastMembershipReceipt,
                );

                if (!lastReceipt || !artistLinkValue) return;

                navigate(membershipPagePath);

                openBottomsheet({
                  type: BOTTOMSHEET_TYPES.RECEIPT,
                  receiptBottomSheetProps: {
                    id: lastReceipt.id,
                    type: 'rsvp',
                    artistHandle: artistLinkValue,
                    createdAt: lastReceipt.createdAt,
                    artistName: lastReceipt.membership.artist.name,
                    receiptNumber: lastReceipt.serialNumber,
                    title: drop?.title || 'RSVP Drop',
                    username:
                      lastReceipt.user.displayName ?? lastReceipt.user.username ?? 'username',
                  },
                });
              }}
            />
          </View>
        );
      case 'auth':
        return (
          <View
            className={twMerge(
              'mt-5 box-border flex h-[174px] min-h-[174px] w-full max-w-[364px] flex-col items-center justify-center rounded-[24px] bg-white/10 p-4 backdrop-blur-2xl',
              !isPreview && 'md2:max-w-none',
            )}
          >
            <Text className="text-center font-title text-[22px] font-medium text-white">
              {dropEnded ? 'Drop ended' : 'Get notified'}
            </Text>

            {dropEnded ? (
              <Button
                type="primary"
                label={hasActiveSubscription ? 'Go to vault' : 'Join for free'}
                className="mt-4 w-full"
                onClick={async () => {
                  // Join for free and then go to the membership page
                  if (!hasActiveSubscription) {
                    if (dropFrag) {
                      await subscribeFreeTier({
                        input: {
                          vaultId: dropFrag.artist.mainVault.id,
                          inviteCode: invite,
                          sourceRsvpEventId: dropFrag.id,
                        },
                      });
                    }
                  }

                  navigate(membershipPagePath);
                }}
              />
            ) : (
              <>
                <View className="mt-4 box-border flex w-full items-stretch">
                  <View className="flex w-full min-w-0 flex-col rounded-l-full border border-solid border-white/20 py-4">
                    <View className="relative box-border flex h-full items-center gap-3 overflow-hidden pl-[17px] pr-3">
                      <UserProfileImage
                        className="h-[24px] w-[24px]"
                        profileImageUrl={
                          loggedInUser?.artist?.profileImage?.smallImageMedia?.[0]?.url ||
                          loggedInUser?.artist?.profileImage?.url ||
                          loggedInUser?.avatar?.smallImageMedia?.[0]?.url ||
                          loggedInUser?.avatar?.url
                        }
                        fallbackColor={
                          loggedInUser?.artist?.profileImage?.dominantColor ||
                          loggedInUser?.avatar?.dominantColor
                        }
                        withVaultTheme={false}
                      />

                      <Text className="min-w-0 flex-1 truncate font-base text-[16px]/[20px] font-medium text-white/90">
                        {loggedInUser?.artist?.name ||
                          loggedInUser?.displayName ||
                          loggedInUser?.username ||
                          'Username'}
                      </Text>
                    </View>
                  </View>

                  <Button
                    label="RSVP"
                    type="primary"
                    buttonType="submit"
                    loading={isRSVPingToEvent || isSubscribingFreeTier}
                    disabled={isRSVPingToEvent || isSubscribingFreeTier}
                    onClick={handleRsvpToEvent}
                    iconOnly={isRSVPingToEvent || isSubscribingFreeTier}
                    className="w-[80px] min-w-[80px] flex-1 flex-shrink-0 !rounded-l-none border border-solid border-yellow100 md2:w-[112px] md2:min-w-[112px]"
                  />
                </View>

                <Text className="mt-4 w-full text-center font-base text-[12px]/[14px] font-normal text-white/40">
                  You will get notified via text
                  <br />
                  when the drop is live
                </Text>
              </>
            )}
          </View>
        );
      case 'sign_in':
        return (
          <SignInForm
            setView={setView}
            dropEnded={dropEnded}
            disabled={isPreview}
            isPreview={isPreview}
          />
        );
      case 'verify':
        return <VerifyForm setView={setView} onConfirm={handleRsvpToEvent} isPreview={isPreview} />;
    }
  }, [
    artistLinkValue,
    drop?.title,
    dropEnded,
    dropFrag,
    handleRsvpToEvent,
    hasActiveSubscription,
    invite,
    isPreview,
    isRSVPingToEvent,
    isSubscribingFreeTier,
    lastReceiptData?.data.lastMembershipReceipt,
    loggedInUser?.artist?.name,
    loggedInUser?.artist?.profileImage?.dominantColor,
    loggedInUser?.artist?.profileImage?.smallImageMedia,
    loggedInUser?.artist?.profileImage?.url,
    loggedInUser?.avatar?.dominantColor,
    loggedInUser?.avatar?.smallImageMedia,
    loggedInUser?.avatar?.url,
    loggedInUser?.displayName,
    loggedInUser?.username,
    loginStatus,
    membershipPagePath,
    navigate,
    openBottomsheet,
    subscribeFreeTier,
    view,
  ]);

  useEffect(() => {
    if (!!loggedInUser?.id && !isPreview) {
      if (lastReceiptData?.data.lastMembershipReceipt?.id) {
        setView('claimed');
      } else {
        setView('auth');
      }
    }
  }, [isPreview, lastReceiptData?.data.lastMembershipReceipt?.id, loggedInUser?.id]);

  useEffect(() => {
    if (isPreview) return;

    if (drop?.status !== RsvpEventStatus.Active || dropFrag?.__typename === 'InactiveRsvpEvent') {
      if (loggedInUser?.artist?.id === dropFrag?.artist.id && dropFrag?.id) {
        navigate(artistNavigationPath(artistHandle, '/dashboard'));
      } else {
        navigate(ROUTES.NOT_FOUND);
      }
    }
  }, [
    artistHandle,
    drop,
    dropFrag?.__typename,
    dropFrag?.artist.id,
    dropFrag?.id,
    isPreview,
    loggedInUser?.artist?.id,
    navigate,
  ]);

  // Used to achieve a fake items-center justify-center when the description is too long
  useEffect(() => {
    if (leftRef.current && rightRef.current && isDesktop) {
      const leftHeight = leftRef.current.clientHeight;

      const padding = Math.max(0, ((windowHeight ?? window.innerHeight) - leftHeight) / 2);

      setPaddingTop(padding);
    }
  }, [leftRef, rightRef, isDesktop, windowHeight]);

  return (
    <View
      className={twMerge(
        'box-border flex min-h-screen w-full flex-col items-center justify-start overflow-hidden pt-[30px]',
        !isPreview && ' md2:flex-row md2:items-start md2:justify-center md2:pt-0',
      )}
    >
      {background}

      {/* Loading state on top to allow css calculations */}
      {(isContentTaller === null || paddingTop === undefined) && (
        <View className="absolute inset-0 z-base hidden h-screen w-screen md2:block">
          <RsvpDropViewSkeleton />
        </View>
      )}

      <View
        className={twMerge(
          'no-scrollbar z-above1 box-border flex w-full flex-col items-center justify-center overflow-y-auto px-8',
          isPreview
            ? 'md2:px-24'
            : 'md2:max-w-[934px] md2:flex-row md2:gap-16 md2:overflow-y-hidden md2:px-0',
          !isPreview && isContentTaller && 'md2:items-start',
        )}
        style={!isPreview && isDesktop ? { paddingTop } : undefined}
      >
        <View
          id="left"
          containerRef={leftRef}
          className={twMerge(
            'relative flex w-full max-w-[364px] items-center justify-center overflow-hidden',
            !isPreview && 'md2:sticky md2:top-0 md2:max-w-[480px] md2:justify-end md2:px-0',
          )}
        >
          {coverImageUrl != null ? (
            <Image
              src={coverImageUrl}
              alt="Cover Image"
              className={twMerge(
                'aspect-square max-h-[364px] w-full max-w-[364px] rounded-xl object-cover',
                !isPreview && 'md2:max-h-[480px] md2:max-w-[480px]',
              )}
            />
          ) : (
            <UserPlaceholderImage
              id={randomUUID}
              className={twMerge(
                'aspect-square max-h-[364px] w-full max-w-[364px] rounded-xl',
                !isPreview && 'md2:max-h-[480px] md2:max-w-[480px]',
              )}
              svgClassName="rounded-xl"
            />
          )}
        </View>

        <View
          id="right"
          containerRef={rightRef}
          className={twMerge(
            'z-above1 flex w-full flex-1 flex-col items-center pb-16',
            !isPreview && 'md2:max-w-[390px]',
            !isPreview && !isContentTaller && 'md2:pb-0',
          )}
        >
          {dropEnded && (
            <View
              className={twMerge(
                'mt-5 box-border w-full max-w-[364px] rounded-md bg-yellow100 px-3 py-2',
                !isPreview && 'md2:mb-2 md2:mt-0 md2:max-w-none',
              )}
            >
              <Text className="font-base text-[12px] font-medium text-black">
                <strong>Drop ended.</strong>
                {hasActiveSubscription
                  ? ' Stay tuned for future drops.'
                  : ' Join to get updates on new drops.'}
              </Text>
            </View>
          )}

          <Text
            className={twMerge(
              'mt-4 box-border line-clamp-2 w-full max-w-[364px] text-left font-base text-[30px] font-semibold text-white',
              !isPreview && 'md2:mt-0 md2:max-w-none md2:text-[40px]',
            )}
          >
            {title}
          </Text>

          <View className={twMerge('w-full max-w-[364px]', !isPreview && 'md2:max-w-none')}>
            <a
              className={twMerge(
                'mt-2 box-border flex w-auto items-center justify-start space-x-2 text-white/80 no-underline',
                !isPreview && 'md2:mt-1',
              )}
              href={
                !!artistLinkValue?.trim() ? artistNavigationPath(artistLinkValue, '/') : undefined
              }
            >
              <ArtistProfileImage
                className={twMerge('h-6 w-6', !isPreview && 'md2:h-8 md2:w-8')}
                profileImageUrl={artistImageUrl}
                withVaultTheme
              />

              <Text className="line-clamp-1 font-base text-[14px]/[20px] font-semibold text-white/80">
                {artistName}
              </Text>
            </a>
          </View>

          {renderContent}

          {!!description && description.trim().length > 0 && (
            <View
              className={twMerge(
                'mt-6 box-border flex w-full max-w-[364px] flex-col items-center justify-center text-left text-white',
                !isPreview && 'md2:max-w-none',
              )}
            >
              <Text className="mb-3 w-full text-left font-title text-[22px] font-medium">
                Additional details
              </Text>

              <LinkifyText className="text-white">
                <View
                  className={twMerge(
                    'w-full whitespace-pre-line text-[14px]/[18px] font-normal',
                    !isPreview && 'md2:text-[16px]/[20px]',
                  )}
                  containerRef={textRef}
                >
                  {description}
                </View>
              </LinkifyText>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

function useIsContentTaller(leftRef: React.RefObject<Element>, rightRef: React.RefObject<Element>) {
  const [isRightTaller, setIsRightTaller] = useState<boolean | null>(null);
  const initialCheckDone = useRef(false);
  const prevIsRightTaller = useRef<boolean | null>(null);

  useLayoutEffect(() => {
    if (leftRef.current == null || rightRef.current == null) return;

    function updateHeightComparison() {
      const leftHeight = leftRef.current?.clientHeight ?? 0;
      const rightHeight = rightRef.current?.clientHeight ?? 0;
      const newIsRightTaller = rightHeight > leftHeight;

      // Only update state if the new value is different from the previous value
      if (prevIsRightTaller.current !== newIsRightTaller) {
        setIsRightTaller(newIsRightTaller);
        prevIsRightTaller.current = newIsRightTaller;
      }
    }

    const leftObserver = new ResizeObserver(updateHeightComparison);
    const rightObserver = new ResizeObserver(updateHeightComparison);

    leftObserver.observe(leftRef.current);
    rightObserver.observe(rightRef.current);

    // Initial check
    if (!initialCheckDone.current) {
      updateHeightComparison();
      initialCheckDone.current = true;
    }

    return () => {
      leftObserver.disconnect();
      rightObserver.disconnect();
    };
  }, [leftRef, rightRef]);

  return isRightTaller;
}

export const RsvpDropViewSkeleton = () => {
  return (
    <View className="box-border flex min-h-screen w-full flex-col items-center justify-start overflow-hidden pt-[30px] md2:flex-row md2:justify-center md2:pt-0">
      <View
        className={twMerge(
          'no-scrollbar z-above1 box-border flex w-full flex-col items-center justify-center overflow-y-auto px-6',
          'md2:max-w-[934px] md2:flex-row md2:gap-16 md2:overflow-y-hidden md2:px-0',
        )}
      >
        <LoadingSkeleton className="relative flex aspect-square h-auto w-full items-center justify-center overflow-hidden rounded-xl md2:sticky md2:top-16 md2:max-w-[480px]" />

        <View className="z-above1 mt-6 flex w-full flex-1 flex-col items-center justify-center pb-16 md2:mt-0 md2:max-w-[390px] md2:items-start md2:pb-0">
          <LoadingSkeleton className="h-[30px] w-[120px]" />

          <View className="mt-2 flex items-center justify-center space-x-2">
            <LoadingSkeleton className="aspect-square h-[24px] rounded-full" />
            <LoadingSkeleton className="h-[24px] w-[100px]" />
          </View>

          <View className="mt-8 flex w-3/4 flex-row items-center justify-center text-center text-base400 md2:w-full md2:justify-start md2:text-left">
            <LoadingSkeleton className="h-[30px] w-[250px]" />
          </View>
        </View>
      </View>
    </View>
  );
};
