import { type FC } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { View } from '../../components/common/View';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { MembershipConfirmationView } from '../../components/views/MembershipView';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

gql(/* GraphQL */ `
  mutation UpdateUserOnboarding($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserSuccess {
        data {
          id
          username
        }
      }
    }
  }
`);

export const OnboardingUsernamePage: FC = () => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const artistHandle = searchParams.get('artistHandle');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const redirect = searchParams.get('redirect');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');

  const navigationPath = useStableCallback(
    ({ artistHandle }: { artistHandle?: string | null }): string => {
      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          trackId,
          invite,
        });

        return artistNavigationPath(artistHandle, redirect ? `/${redirect}` : '/', queryParams);
      }

      return ROUTES.VAULTS;
    },
  );

  const navigateTo = navigationPath({
    artistHandle,
  });

  const { isLoading: isLoadingArtist, data: artistData } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const artistFragment = artistData?.data?.artistLink?.artist;
  const artist = artistFragment
    ? {
        linkValue: artistFragment?.linkValue,
        name: artistFragment?.name,
        membershipImageUrl: artistFragment?.membershipCardImage?.url,
      }
    : undefined;

  if (loginStatus === LoginStatus.LOADING || isLoadingArtist) {
    return <OnboardingUsernameSkeleton isArtistPresent={!!artistHandle} />;
  } else if (loggedInUser?.username && !artistData) {
    return <Navigate to={navigateTo} />;
  } else {
    return (
      <MembershipConfirmationView
        vaultId={artistFragment?.mainVaultId}
        isLoading={false}
        artist={artist}
        loggedInUserUsername={loggedInUser?.username}
        loginStatus={loginStatus}
        inviteCode={invite}
        smsCampaignResponseShortcode={smsCampaignResponseShortcode}
        sourceReleaseCampaignId={sourceReleaseCampaignId}
        onComplete={() => {
          navigate(navigateTo);
        }}
      />
    );
  }
};

const OnboardingUsernameSkeleton: FC<{ isArtistPresent: boolean }> = ({ isArtistPresent }) => {
  return (
    <DefaultLayout
      withVaultTheme={isArtistPresent}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      contentClassName="mt-4"
      shouldShowFullScreenOnDesktop
    >
      <View className="mx-6 my-3 box-content flex w-full flex-col items-center">
        {isArtistPresent ? (
          <LoadingSkeleton className="mb-5 h-72 w-1/3 rounded-md bg-vault_text/10" />
        ) : null}
        <LoadingSkeleton
          className={twMerge(
            'mb-5 h-8 w-1/2 rounded-md',
            isArtistPresent ? 'bg-vault_text/10' : 'bg-neutral700',
          )}
        />
        <LoadingSkeleton
          className={twMerge(
            'mb-8 h-12 w-2/3 rounded-md',
            isArtistPresent ? 'bg-vault_text/10' : 'bg-neutral700',
          )}
        />

        <LoadingSkeleton
          className={twMerge(
            'mt-6 h-12 w-5/6 rounded-md',
            isArtistPresent ? 'bg-vault_text/10' : 'bg-neutral700',
          )}
        />
        {isArtistPresent ? (
          <LoadingSkeleton className="mt-6 h-14 w-5/6 rounded-md bg-vault_text/10" />
        ) : null}
        <LoadingSkeleton
          className={twMerge(
            'mt-8 h-12 w-20 rounded-xl',
            isArtistPresent ? 'bg-vault_text/10' : 'bg-neutral700',
          )}
        />
      </View>
    </DefaultLayout>
  );
};
