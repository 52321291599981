import { proxy } from 'valtio';
import * as z from 'zod';

export const SelectContentSchema = z.object({
  contentIds: z.array(z.string()),
  isSelecting: z.boolean(),
});

type SelectContentState = z.infer<typeof SelectContentSchema>;

export const initialSelectContentState = () =>
  ({
    contentIds: [],
    isSelecting: false,
  }) satisfies SelectContentState;

export const selectContentState: SelectContentState = proxy(initialSelectContentState());

export const setContentIds = (contentIds: string[]) => {
  selectContentState.contentIds = contentIds;
};

export const addContentId = (contentId: string) => {
  if (selectContentState.contentIds.includes(contentId)) {
    return;
  }

  selectContentState.contentIds = [...selectContentState.contentIds, contentId];
};

export const removeContentId = (contentId: string) => {
  selectContentState.contentIds = selectContentState.contentIds.filter(id => id !== contentId);
};

export const setIsSelecting = (isSelecting: boolean) => {
  selectContentState.isSelecting = isSelecting;
};

export const clearSelectContentState = () => {
  selectContentState.contentIds = [];
  selectContentState.isSelecting = false;
};
