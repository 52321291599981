const REPORT_ISSUE_URL = 'https://help.sound.xyz/hc/en-us/requests/new';

const TOS_URL =
  'https://vaultfm.notion.site/Terms-of-Service-3793616d15b748a4b14fffec062dd7df?pvs=4';

const PRIVACY_POLICY_URL =
  'https://vaultfm.notion.site/Privacy-Policy-04c026ab60904e438e5f5a23f531c770?pvs=4';

export { REPORT_ISSUE_URL, TOS_URL, PRIVACY_POLICY_URL };

export const DEPLOYMENT_HOSTNAMES = (import.meta.env.VITE_DEPLOYMENT_HOSTNAME as string).split(',');

const windowLocationHostname = window.location.hostname;

export const IS_FINAL_DEPLOYMENT = DEPLOYMENT_HOSTNAMES.some(
  v => v === windowLocationHostname || windowLocationHostname.endsWith(`.${v}`),
);

export const VAULT_FM_INSTAGRAM_URL = 'https://www.instagram.com/vaultdotfm';

export const VAULT_FM_TIKTOK_URL = 'https://www.tiktok.com/@vaultdotfm';

export const VAULT_FM_TWITTER_URL = 'https://twitter.com/vaultdotfm';

export const VAULT_FM_APP_STORE_URL =
  'https://apps.apple.com/app/vault-unreleased-music/id6478879577';
