import type { FC } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faComment, faMusic } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMobile, faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTicket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { SubscribeWelcomeMessageBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

const SubscribeWelcomeMessageBottomSheet: FC<SubscribeWelcomeMessageBottomsheetProps> = ({
  artistAvatarUrl,
  monthlySubPrice,
  onConfirm,
  artistHandle,
  isJoiningFreeTier,
  title,
  buttonText,
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();

  const fullAccessItems = [
    {
      icon: faMusic,
      text: 'Full access to unreleased music',
      color: 'text-base300',
    },
    {
      icon: faComment,
      text: 'Private group chat access',
      color: 'text-base300',
    },
  ];

  const freeTierItems = [
    {
      icon: faWaveformLines,
      text: 'Listen to unreleased music',
      color: 'text-base300',
    },
    {
      icon: faMobile,
      text: 'Text updates for new drops',
      color: 'text-base300',
    },
    {
      icon: faTicket,
      text: 'First access & discounts to shows',
      color: 'text-base300',
    },
  ];

  const renderContent = () => {
    const items = isJoiningFreeTier ? freeTierItems : fullAccessItems;
    return (
      <View className="mb-4 flex flex-col justify-center gap-4 p-3">
        {items.map((item, index) => (
          <View
            key={index}
            className={twMerge('flex w-full flex-row items-center gap-4', item.color)}
          >
            <View className="flex h-4 w-4 items-center justify-center">
              <FontAwesomeIcon icon={item.icon} className={item.color} />
            </View>
            <Text className="text-base-m md2:text-base-l">{item.text}</Text>
          </View>
        ))}
      </View>
    );
  };

  return (
    <View className="box-border w-full items-center px-4">
      <ArtistProfileImage
        profileImageUrl={artistAvatarUrl}
        className="mx-[auto] mb-4 mt-5 block h-20 w-20 rounded-full"
      />

      <Text className="my-4 text-center font-title text-title-l font-medium">
        {title
          ? title
          : isJoiningFreeTier
            ? 'Welcome to the vault'
            : 'Subscribe to unlock the vault'}
      </Text>

      <View className="mx-auto max-w-[358px]">
        {renderContent()}

        <View className="my-4 flex flex-col">
          <View className="flex w-full flex-row">
            <Button
              type="primary"
              label={
                buttonText
                  ? buttonText
                  : isJoiningFreeTier
                    ? 'Got it'
                    : `Continue for ${monthlySubPrice}/month`
              }
              onClick={e => {
                onConfirm(e);
                closeBottomsheet();
              }}
              className="text-4/[20px] flex-1 text-base900"
              event={{
                type: isJoiningFreeTier ? EVENTS.CONT_TO_JOIN_FREE_TIER : EVENTS.CONT_TO_SUBSCRIBE,
                properties: { artistHandle },
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export { SubscribeWelcomeMessageBottomSheet };
