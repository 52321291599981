import type { Appearance } from '@stripe/stripe-js';

export const STRIPE_APPEARANCE: Appearance = {
  theme: 'night',
  variables: {
    fontSizeBase: '16px',
    fontWeightNormal: '500',
    colorBackground: '#0E1213',
    colorPrimary: '#FFFFFF',
    spacingUnit: '4px',
  },
  rules: {
    '.Input': {
      borderBottom: '1px solid #333',
      boxShadow: 'none',
      border: 'none',
      borderRadius: '0',
    },
    '.Input:focus': {
      boxShadow: 'none',
      border: 'none',
      borderBottom: '1px solid #333',
    },
  },
};

export const DEFAULT_PRICE = 4.99;
