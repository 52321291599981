const getMediaSession = () => {
  if (typeof navigator === 'undefined' || !('mediaSession' in navigator)) return null;
  // eslint-disable-next-line compat/compat
  return navigator.mediaSession;
};

export const setPlaybackState = (playing: boolean) => {
  const mediaSession = getMediaSession();
  if (!mediaSession) return;

  mediaSession.playbackState = playing ? 'playing' : 'paused';
};

export const setMetadata = (metadata: MediaMetadataInit) => {
  const mediaSession = getMediaSession();
  if (!mediaSession) return;

  // we already ensure that mediaSession is available
  // eslint-disable-next-line compat/compat
  mediaSession.metadata = new MediaMetadata(metadata);
};

const setActionHandler = (action: MediaSessionAction, callback: MediaSessionActionHandler) => {
  const mediaSession = getMediaSession();
  if (!mediaSession) return;

  mediaSession.setActionHandler(action, callback);
};

export const setPlayActionHandler = (callback: () => void) => {
  setActionHandler('play', callback);
};

export const setPauseActionHandler = (callback: () => void) => {
  setActionHandler('pause', callback);
};

export const setPrevActionHandler = (callback: () => void) => {
  setActionHandler('previoustrack', callback);
};

export const setNextActionHandler = (callback: () => void) => {
  setActionHandler('nexttrack', callback);
};

export const setSeekActionHandler = (callback: (position: number) => void) => {
  setActionHandler('seekto', ({ seekTime }: MediaSessionActionDetails) => {
    if (seekTime) callback(seekTime);
  });
};

export const setSeekBackwardActionHandler = (callback: (position: number) => void) => {
  setActionHandler('seekbackward', ({ seekOffset }: MediaSessionActionDetails) => {
    if (seekOffset) callback(-1 * seekOffset);
  });
};

export const setSeekForwardActionHandler = (callback: (position: number) => void) => {
  setActionHandler('seekforward', ({ seekOffset }: MediaSessionActionDetails) => {
    if (seekOffset) callback(seekOffset);
  });
};
