import type { Maybe } from '@soundxyz/utils/helpers';

const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

const sum = (arr: number[]) => {
  return arr.reduce((acc, cur) => acc + cur, 0);
};

const chunkFill = (chunkNumber: number, chunkSize: number, arr: number[]) => {
  const len = Number(chunkNumber);
  let idx = 0;
  let idy = 0;
  const result = [];

  // While our current chunk index is less than our desired length...
  while (idx < len) {
    if (idy >= arr.length) {
      break;
    }
    let chunk;
    // If this is the last chunk to be created
    // capture all of the remaining datapoints
    if (idx === len - 1) {
      chunk = arr.slice(idy);
    } else {
      // Otherwise, capture the next chunk of points
      // and update the idy for future point capture
      chunk = arr.slice(idy, (idy += chunkSize));
    }
    // Get the average amplitude value of the collected points
    // and add 5 (essentially for visual normalization,
    // you don't want silence to have a height of 0%)
    const ampVal = Math.round(sum(chunk) / chunk.length + 5);

    // Add your new averaged point to the results array
    // so long as it's odd as we need a central point for
    // a nice symmetrical reflection
    result.push(ampVal % 2 ? ampVal : ampVal + 1);
    idx += 1;
  }
  return result;
};

export { notEmpty, sum, chunkFill };

export function getFromList<ListValue, CallbackValue>(
  list: readonly ListValue[] | null | undefined,
  cb: (value: ListValue, index: number) => CallbackValue | false | null | undefined | void,
): CallbackValue | null {
  if (!list) return null;

  let index = 0;
  for (const listValue of list) {
    const value = cb(listValue, index++);

    if (value != null && value !== false) return value;
  }

  return null;
}

export function getManyFromList<ListValue, CallbackValue>(
  list: Maybe<readonly ListValue[]>,
  cb: (value: ListValue, index: number) => Maybe<CallbackValue> | false | void,
): CallbackValue[] {
  const values: CallbackValue[] = [];

  if (!list) return values;

  let index = 0;
  for (const listValue of list) {
    const value = cb(listValue, index++);

    if (value != null && value !== false) values.push(value);
  }

  return values;
}
