import React from 'react';
import { twMerge } from 'tailwind-merge';
import { View } from '../../common/View';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';

export function VaultItemSkeleton({ className }: { className?: string }) {
  return (
    <View className={twMerge('flex h-[170px] flex-grow flex-col gap-3 pb-4', className)}>
      <LoadingSkeleton className={twMerge('h-[110px] w-full rounded-md bg-vault_text/10')} />

      <View className="flex w-full flex-col">
        <LoadingSkeleton className={twMerge('mb-1 h-[18px] w-[80%] rounded-lg bg-vault_text/10')} />
        <LoadingSkeleton className={twMerge('h-3 w-[45px] rounded-lg bg-vault_text/10')} />
      </View>
    </View>
  );
}

export function VaultItemsSkeleton({ itemCount }: { itemCount: number }) {
  return (
    <>
      {Array.from({ length: itemCount }).map((_, index) => {
        return <VaultItemSkeleton key={`${index}-VAULT_ITEM_SKELETON`} />;
      })}
    </>
  );
}

export function RowItemsSkeleton({ rows }: { rows: number }) {
  return (
    <View className="-mt-1 w-full">
      {Array.from({ length: rows }).map((_, index) => {
        return (
          <View key={index} className="flex w-full flex-row items-center justify-between gap-4">
            <VaultItemSkeleton />
            <VaultItemSkeleton />
            <VaultItemSkeleton className="hidden md2:flex" />
          </View>
        );
      })}
    </View>
  );
}
