import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { GraphQLReactQueryProvider } from './graphql/client';
import { MainRouter } from './routers/MainRouter';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export function App() {
  return (
    <div className="h-full w-[100vw]">
      <GraphQLReactQueryProvider>
        <MainRouter />
      </GraphQLReactQueryProvider>
    </div>
  );
}
