export const ACCEPTED_AUDIO_MIME_TYPES = {
  'audio/wav': ['.wav'],
  'audio/x-wav': ['.x-wav'],
  'audio/vnd.wave': ['.wav'],
  'audio/mpeg': ['.mp3'],
  'audio/mp4': ['.m4a'],
  'audio/x-m4a': ['.m4a'],
  'audio/m4a': ['.m4a'],
};

export const ACCEPTED_IMAGE_TYPES = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/heif': ['.heif'],
};

export const ACCEPTED_VIDEO_TYPES = {
  'video/quicktime': ['.mov', '.mp4'],
  'video/mp4': ['.mp4'],
};

export const VALID_MP4_MIME_TYPES = ['video/mp4', 'video/quicktime'] as const;

export const VALID_WAV_MIME_TYPES = ['audio/wav', 'audio/x-wav', 'audio/vnd.wave'] as const;
export const VALID_MP3_MIME_TYPES = ['audio/mpeg'] as const;
export const VALID_M4A_MIME_TYPES = ['audio/mp4', 'audio/x-m4a', 'audio/m4a'] as const;

export const MAX_AUDIO_UPLOAD_SIZE_BYTES = 2147483648;
export const MAX_AUDIO_UPLOAD_SIZE_TEXT = '2GB';
