import { useRef, useState } from 'react';
import type { Maybe } from '@soundxyz/utils';
import { useToast } from '../contexts/ToastContext';
import { useStableCallback } from './useStableCallback';

export function useCopy({ text, successMessage }: { text: Maybe<string>; successMessage: string }) {
  const { openToast } = useToast();

  const copiedTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [isCopied, setIsCopied] = useState(false);

  const copy = useStableCallback(async () => {
    if (!text) return;

    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      openToast({
        text: 'Copy failed. Check browser permissions.',
        variant: 'error',
      });

      return;
    }

    setIsCopied(true);

    openToast({
      text: successMessage,
    });

    clearTimeout(copiedTimeout.current);
    copiedTimeout.current = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  });

  return {
    isCopied,
    copy,
  };
}
