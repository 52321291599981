import type { FC } from 'react';
import React from 'react';
import { faPen } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCreditCard } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Button } from '../../components/buttons/Button';
import { DropdownEllipsis } from '../../components/common/Dropdown';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { PaymentMethodRow } from '../../components/payment/PaymentMethodRow';
import { PaymentMethodStore } from '../../components/views/settings/AddPaymentMethodView';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import { GetPaymentMethodsDocument } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';

gql(/* GraphQL */ `
  query GetPaymentMethods {
    paymentMethods {
      id
      ...paymentMethodRow
    }
  }

  mutation RemovePaymentMethod($input: MutationRemovePaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      id
    }
  }
`);

const ManagePaymentMethodsPage: FC = () => {
  const { loginStatus, authError, loggedInUser } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { data, isLoading, isError, refetch } = useQuery(GetPaymentMethodsDocument, {
    staleTime: 0,
    enabled: loginStatus === LoginStatus.LOGGED_IN,
  });

  if (isError || authError) {
    return (
      <SettingsLayout
        title="Payment method"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="manage_payment_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isLoading || data == null || loginStatus === LoginStatus.LOADING) {
    return (
      <SettingsLayout title="Payment method">
        <SkeletonManagePaymentMethods />
      </SettingsLayout>
    );
  }

  const paymentMethods = data?.data.paymentMethods;

  return (
    <SettingsLayout title="Payment method">
      {paymentMethods != null && paymentMethods.length > 0 ? (
        paymentMethods.map(paymentMethod => {
          const buttons: ActionBottomsheetProps['buttons'] = [
            {
              label: 'Update card',
              leadingIcon: faPen,
              className: 'font-title font-normal text-title-s text-white',
              type: 'secondary',
              href: '/settings/methods/update',
              onClick: () => {
                closeBottomsheet();

                if (!loggedInUser) return;

                const userId = loggedInUser.id;

                PaymentMethodStore.produceExistingState(
                  draft => {
                    draft[userId] ||= {
                      currentPaymentMethodId: paymentMethod.id,
                    };

                    draft[userId]!.currentPaymentMethodId = paymentMethod.id;
                  },
                  {
                    [userId]: {
                      currentPaymentMethodId: paymentMethod.id,
                    },
                  },
                );
              },
            },
          ];

          const onClick = () => {
            openBottomsheet({
              type: 'ACTION',
              actionBottomsheetProps: {
                buttons,
                withVaultTheme: false,
              },
            });
          };
          return (
            <PaymentMethodRow
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              className="mb-4"
              withVaultTheme={false}
              actionComponent={
                <DropdownEllipsis
                  desktopClassname="text-[16px]/[20px]"
                  onClick={onClick}
                  buttons={buttons}
                  dropdownType="Manage Payment Method"
                />
              }
            />
          );
        })
      ) : (
        <Button
          label="Add new card"
          type="secondary"
          leadingIcon={faCreditCard}
          className="py-[20px] font-title text-[16px]/[20px] font-medium text-white"
          href="add"
          event={{ type: EVENTS.ADD_PAYMENT_METHOD, properties: null }}
          onClick={() => {
            if (!loggedInUser) return;

            const userId = loggedInUser.id;

            PaymentMethodStore.produceExistingState(
              draft => {
                draft[userId] ||= {
                  currentPaymentMethodId: null,
                };

                draft[userId]!.currentPaymentMethodId = null;
              },
              {
                [userId]: {
                  currentPaymentMethodId: null,
                },
              },
            );
          }}
        />
      )}
    </SettingsLayout>
  );
};

const SkeletonManagePaymentMethods: FC = () => {
  return (
    <>
      <LoadingSkeleton className="mb-4 h-[60px] w-full" />
      <LoadingSkeleton className="mb-4 h-[60px] w-full" />
      <LoadingSkeleton className="mb-4 h-[60px] w-full" />
    </>
  );
};

export { ManagePaymentMethodsPage };
