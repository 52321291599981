import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../contexts/AuthContext';
import { useInfiniteQuery, useMutation, useQuery } from '../graphql/client';
import {
  GetMySubscribersDocument,
  MySubScribersCsvUrlDocument,
  MySubscribersSummaryDocument,
  SortDirection,
  SubscriptionTierLevel,
  VaultSubscriptionStatus,
} from '../graphql/generated';

const filtersSchema = z.object({
  sort: z.nativeEnum(SortDirection),
  tierLevel: z.nativeEnum(SubscriptionTierLevel).nullable(),
});

export type FiltersSchema = z.infer<typeof filtersSchema>;

export const initialFilters = {
  sort: 'DESC',
  tierLevel: null,
} satisfies FiltersSchema;

export const filtersState = proxy<FiltersSchema>(initialFilters);

export const resetFilters = () => {
  filtersState.sort = initialFilters.sort;
  filtersState.tierLevel = initialFilters.tierLevel;
};

gql(/* GraphQL */ `
  fragment MemberRow on PublicVaultSubscription {
    id
    createdAt
    status
    email
    phone
    artistMembership {
      receipts
    }
    user {
      id
      ...userRow
    }
    isTrial
    tier {
      __typename
    }
    userLocation {
      city
      country
      region
    }
    vaultSubscriptionSourceText
    vaultSubscriptionSourceType
    joinedViaReferralCode {
      ...referralCodeInfo
    }
    artist {
      id
      mainVault {
        id
        type
      }
    }
  }
`);

gql(/* GraphQL */ `
  query GetMySubscribers(
    $vaultId: UUID!
    $after: String
    $first: Int!
    $status: VaultSubscriptionStatus
    $tierLevel: SubscriptionTierLevel
    $sort: SortDirection!
  ) {
    mySubscribers(
      vaultId: $vaultId
      after: $after
      first: $first
      status: $status
      tierLevel: $tierLevel
      sort: $sort
    ) {
      edges {
        cursor
        node {
          id
          ...MemberRow
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  mutation MySubScribersCsvUrl($vaultId: UUID!) {
    getMySubscribersCsvUrl(vaultId: $vaultId) {
      __typename
      ... on MutationGetMySubscribersCsvUrlSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  query MySubscribersSummary($vaultId: UUID!) {
    mySubscribersSummary(vaultId: $vaultId) {
      activeSubscriptionsCount
      activePaidSubscriptionsCount
      activeFreeSubscriptionsCount
      activeFreeSubscriptionsCountLast7Days
      activePaidSubscriptionsCountLast7Days
      activeSubscriptionsCountLast7Days
    }
    vaultFromId(vaultId: $vaultId) {
      id
      type
    }
  }
`);

const LIMIT = 20;

export function useMySubscribers({
  limit,
  sort: passedSort,
  tierLevel: passedTierLevel,
}: {
  limit?: number;
  sort?: SortDirection;
  tierLevel?: SubscriptionTierLevel;
} = {}) {
  const { loggedInUser } = useAuthContext();

  const { sort, tierLevel } = useSnapshot(filtersState);

  const {
    orderedList: subscribers,
    hasNextPage,
    isInitialLoading,
    isError,
    isLoadingError,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(GetMySubscribersDocument, {
    enabled: loggedInUser != null && loggedInUser?.artist?.mainVaultId != null,
    staleTime: 0,
    filterQueryKey: {
      sort: passedSort ?? sort,
      tierLevel: passedTierLevel ?? tierLevel,
    },
    getNextPageParam: ({ data }) => {
      return (
        data.mySubscribers.pageInfo.hasNextPage && {
          after: data.mySubscribers.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: limit ?? LIMIT,
        vaultId: loggedInUser?.artist?.mainVaultId ?? '',
        sort: passedSort ?? sort,
        status: VaultSubscriptionStatus.Active,
        tierLevel: passedTierLevel ?? tierLevel,
      };
    },
    list: ({ mySubscribers }) => {
      return mySubscribers.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  const { isLoading, data } = useQuery(MySubscribersSummaryDocument, {
    staleTime: 0,
    variables: !!loggedInUser?.artist?.mainVaultId && { vaultId: loggedInUser.artist.mainVaultId },
  });

  const { mutate } = useMutation(MySubScribersCsvUrlDocument, {
    onSuccess: result => {
      if (
        result.data.getMySubscribersCsvUrl?.__typename === 'MutationGetMySubscribersCsvUrlSuccess'
      ) {
        window.open(result.data.getMySubscribersCsvUrl.data, '_blank');
      }
    },
  });

  return {
    exportCsv: mutate,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isInitialLoading,
    isLoadingError,
    isLoadingSummary: isLoading,
    refetch,
    subscribers,
    subscribersSummary: data,
  };
}
