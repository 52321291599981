import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faBank, faCoins } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function ActivateFreemiumPrompt({
  type,
  artistLinkValue,
  withVaultTheme,
}: {
  type: 'payouts' | 'earnings' | 'splits';
  artistLinkValue: string;
  withVaultTheme: boolean;
}) {
  const mailtoLink = `mailto:help@vault.fm?subject=Add%20a%20Paid%20Tier&body=Hello,%20I%20would%20like%20to%20add%20a%20paid%20tier%20to%20my%20account.%20My%20artist%20handle%20is:%20${encodeURIComponent(artistLinkValue)}.`;

  const data = () => {
    switch (type) {
      case 'payouts':
        return {
          title: 'Add a paid tier to start earning!',
          description:
            'Set up a monthly subscription tier for fans to access full-length songs, early access, and more. Contact us to start earning!',
          icon: faBank,
        };
      case 'earnings':
        return {
          title: 'Add a paid tier to start earning!',
          description:
            'Set up a monthly subscription tier for fans to access full-length songs, early access, and more. Contact us to start earning!',
          icon: faCoins,
        };
      case 'splits':
        return {
          title: 'Add a paid tier to start earning!',
          description:
            'Earnings from your paid tier will be automatically distributed to your collaborators. Contact us to add a paid tier!',
          icon: faCoins,
        };
    }
  };

  return (
    <View className="mt-6 flex flex-col gap-6 p-8">
      <FontAwesomeIcon
        icon={data().icon}
        size="4x"
        className={withVaultTheme ? 'text-vault_text' : 'text-white'}
      />
      <View className="flex flex-col items-center justify-center gap-2 text-center">
        <Text
          className={twMerge(
            '!text-title-s font-medium',
            withVaultTheme ? 'text-vault_text' : 'text-white',
          )}
        >
          {data().title}
        </Text>
        <Text
          className={twMerge(
            'font-base !text-base-m',
            withVaultTheme ? 'text-vault_text/50' : 'text-base400',
          )}
        >
          {data().description}
        </Text>
        <Button
          type="secondary"
          label="Contact us"
          className={twMerge(
            'mt-2 w-[150px] justify-center rounded-full py-3',
            withVaultTheme && 'bg-vault_accent text-vault_accent_text',
          )}
          onClick={() => (window.location.href = mailtoLink)}
        />
      </View>
    </View>
  );
}
