import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  EditTrackSnippetFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { SNIPPET_DURATION, SnippetWaveform } from '../waveform/SnippetWaveform';

gql(/* GraphQL */ `
  fragment EditTrackSnippet on VaultTrack {
    id
    duration
    title
    freeTierSnippet {
      id
      startAt
      endAt
    }
    vault {
      id
      artistProfile {
        id
        name
      }
      isUserArtistAdmin
    }
    isFullVersionAvailable
    ...WaveformTrackInfo
  }
`);

export function EditTrackSnippetView({
  vaultTrackFrag,
  onTrim,
}: {
  vaultTrackFrag: FragmentType<EditTrackSnippetFragmentDoc>;
  onTrim: ({ start, end }: { start: number; end: number }) => void;
}) {
  const track = getFragment(EditTrackSnippetFragmentDoc, vaultTrackFrag);
  const { title, vault, freeTierSnippet } = track;

  const initialStartAt = freeTierSnippet?.startAt ?? 0;
  const initialSnippetEnd = freeTierSnippet?.endAt ?? initialStartAt + SNIPPET_DURATION;

  return (
    <View className="no-scrollbar w-full">
      <View
        className={twMerge(
          'flex min-h-[calc(100vh-170px)] flex-col items-center px-[16px]',
          isMobile && 'min-h-[calc(100vh-200px)]',
        )}
      >
        <View className="box-border flex flex-1 flex-col items-center justify-center">
          <FontAwesomeIcon icon={faWaveformLines} className="text-[120px] text-vault_text" />
        </View>
        <View className="mb-9 flex w-5/6 flex-col items-start gap-3 self-start">
          <Text className="line-clamp-1 font-title !text-title-xl font-medium text-vault_text">
            {title}
          </Text>

          <Text className="line-clamp-1 font-base !text-base-l font-medium text-vault_text">
            {vault.artistProfile?.name || 'Unknown Artist'}
          </Text>
        </View>

        <View className="relative h-full w-full">
          <SnippetWaveform
            initialSnippetStart={initialStartAt}
            track={track}
            onTrim={onTrim}
            initialSnippetEnd={initialSnippetEnd}
          />
        </View>
      </View>
    </View>
  );
}
