import { useState } from 'react';
import { parse, stringify } from 'superjson';
import { Sentry } from '../sentry';
import { useStableCallback } from './useStableCallback';

export const setSessionItem = (key: string, value: unknown): void => {
  window.sessionStorage.setItem(key, stringify(value));
};

export const getSessionItem = <T>(key: string): T | null => {
  const item = window.sessionStorage.getItem(key);
  if (!item) return null;
  try {
    return parse(item) as T;
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        key,
        description: 'Error parsing sessionStorage item',
      },
      tags: {
        type: 'getSessionItem',
      },
    });
    return null;
  }
};

export const removeSessionItem = (key: string): void => {
  window.sessionStorage.removeItem(key);
};

export const useSessionStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = getSessionItem<T>(key);
    return item !== null ? item : initialValue;
  });

  const setValue = useStableCallback((value: T) => {
    setStoredValue(value);
    setSessionItem(key, value);
  });

  const removeValue = useStableCallback(() => {
    setStoredValue(initialValue);
    removeSessionItem(key);
  });

  return [storedValue, setValue, removeValue] as const;
};
