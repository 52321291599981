import type { FC } from 'react';
import type { ArtistTrackNoteBottomsheetProps } from '../../types/bottomsheetTypes';
import { formatDateString } from '../../utils/textUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const ArtistTrackNoteBottomsheet: FC<ArtistTrackNoteBottomsheetProps> = ({
  artistName,
  caption,
  trackDate,
}) => {
  return (
    <View className="mx-4 box-border flex w-[calc(100%-32px)] flex-col rounded-xl bg-black p-[16px]">
      <Text className="mb-[16px] text-center font-title font-medium text-white max-md2:text-title-m md2:text-title-xl">
        Artist's Notes
      </Text>

      <View className="bg-base800 p-[16px]">
        <View className="mb-3 flex flex-row justify-between">
          <View className="flex flex-row items-end">
            <Text className="mr-[4px] font-base text-[16px]/[20px] font-semibold text-white">
              {artistName ?? 'Unnamed'}
            </Text>
            <Text className="font-base text-base-s font-normal text-white opacity-60">
              {formatDateString({
                date: trackDate,
                format: 'numerical_month_day_year',
              })}
            </Text>
          </View>
          {/* TODO: bring back ellipsis when we know what to do with it */}
          {/* <Button
              label=""
              className="text-[20px] text-white"
              leadingIcon={faEllipsis}
              iconOnly
            /> */}
        </View>
        <Text className="whitespace-pre-wrap break-words font-base text-[16px]/[20px] font-normal text-white">
          {caption}
        </Text>
      </View>
    </View>
  );
};
