import * as z from 'zod';

const simpleHandleRefinement = (value: string) => {
  return !value.includes('/') || value === '';
};

export const customizeMenuValidationSchema = z.object({
  // For customize vault
  accentColor: z.string().nullish(),
  backgroundColor: z.string().nullish(),
  logoMediaId: z.string().nullish(),
  shouldRemoveLogo: z.boolean(),
  // For customize artist profile
  profileImageMediaId: z.string().nullish(),
  name: z
    .string()
    .min(1, { message: 'A name is required' })
    .max(50, { message: 'Max length 50 characters' }),
  customWebsiteUrl: z
    .pipeline(
      z.string().transform(value => {
        if (!value) return value;

        if (!value.startsWith('http')) {
          return `https://${value}`;
        }

        return value;
      }),
      z.string().url({ message: 'Invalid URL' }),
    )
    .nullish()
    .or(z.literal('')),
  spotifyUrl: z
    .string()
    .url({ message: 'Invalid Spotify URL' })
    .refine(value => value.startsWith('https://open.spotify.com/') || value === '', {
      message: 'Invalid Spotify URL',
    })
    .nullish()
    .or(z.literal('')),
  instagramHandle: z
    .string()
    .transform(value => {
      try {
        const url = new URL(value);

        if (url.hostname.endsWith('instagram.com')) {
          return url.pathname.split('/')[1] || value;
        }

        return value;
      } catch (error) {
        return value;
      }
    })
    .refine(simpleHandleRefinement, {
      message: 'Invalid handle',
    })
    .nullish(),
  tiktokHandle: z
    .string()
    .transform(value => {
      try {
        const url = new URL(value);

        if (url.hostname.endsWith('tiktok.com')) {
          return url.pathname.split('/')[1] || value;
        }

        return value;
      } catch (error) {
        return value;
      }
    })
    .refine(simpleHandleRefinement, {
      message: 'Invalid handle',
    })
    .nullish(),
});

export type CustomizeMenuValidationSchema = z.infer<typeof customizeMenuValidationSchema>;
