//import * as Sentry from '@sentry/react';
import { merge } from 'lodash-es';
import { proxy, snapshot, subscribe, useSnapshot } from 'valtio';
import * as z from 'zod';
import { ENVIRONMENT } from '@soundxyz/utils/const';
import { PersistenceStorage } from '../utils/storeUtils';
import { AudioEngineHTML5, AudioEngineHTML5Schema } from './AudioEngineHTML5';
import { AudioMeta, AudioMetaSchema } from './AudioMeta';
import { AudioQueue, AudioQueueSchema } from './AudioQueue';

const STORAGE_KEY = `audio-controller-v1${
  ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT
}` as const;

const PersistentAudioEngineHTML5Schema = AudioEngineHTML5Schema.pick({ muted: true, volume: true });
const PersistentAudioMetaSchema = AudioMetaSchema.pick({
  repeatMode: true,
  //activePlaylistId: true,
  //activePlaylistReleaseIds: true,
});
const PersistentAudioQueueSchema = AudioQueueSchema.omit({});

const PersistentAudioSchema = z.object({
  audioEngineHTML5: PersistentAudioEngineHTML5Schema,
  audioMeta: PersistentAudioMetaSchema,
  audioQueue: PersistentAudioQueueSchema,
  isReady: z.boolean(),
});

type PersistentAudioType = (typeof PersistentAudioSchema)['_output'];

export const PersistentAudio = proxy({
  audioEngineHTML5: AudioEngineHTML5,
  audioMeta: AudioMeta,
  audioQueue: AudioQueue,
  isReady: false,
});

export const usePersistentAudio = () => useSnapshot(PersistentAudio);

const AudioPersistence = PersistenceStorage({
  key: STORAGE_KEY,
  schema: PersistentAudioSchema,
});

function saveDataSnapshot() {
  AudioPersistence.set(snapshot(PersistentAudio));
}

if (typeof window !== 'undefined') {
  AudioPersistence.initialValue
    .then(
      state => {
        if (!state) {
          return;
        }

        merge<PersistentAudioType, PersistentAudioType, Partial<PersistentAudioType>>(
          PersistentAudio,
          state,
          {
            // Always reset to false when initializing so the audio queue changes don't propagate out too early
            isReady: false,
            // Avoid overwriting the active track if it's already set
            // This prevents overwriting user triggered audio when persisted audio loads
            audioQueue: {
              ...state.audioQueue,
            },
          },
        );
      } /*Sentry.captureException*/,
    )
    //   .catch(Sentry.captureException)
    .finally(() => {
      PersistentAudio.isReady = true;
      let pendingStorageSet: ReturnType<typeof setTimeout>;

      subscribe(PersistentAudio, () => {
        clearTimeout(pendingStorageSet);
        // Debounce storage updates to minimize CPU Usage on frequent state updates
        pendingStorageSet = setTimeout(() => {
          saveDataSnapshot();
        }, 0);
      });
    });
}
