import { useParams } from 'react-router-dom';
import { getSubdomain, isValidSubdomain } from '../utils/subdomainUtils';

export const useArtistHandle = () => {
  const { artistHandle: paramArtistHandle } = useParams();
  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const artistHandle = subdomainArtistHandle || paramArtistHandle;
  const isSubdomain = !!subdomainArtistHandle;

  return { artistHandle, isSubdomain };
};
