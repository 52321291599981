import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';

interface VisualViewportReturnType {
  width: number;
  height: number;
}

/**
 * React effect hook that calculates the visual portion of the screen excluding
 * on-screen keyboards, areas outside of a pinch-zoom area, or any other on-screen
 * artifact that doesn't scale with the dimensions of a page.
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API
 * Invokes on window resize and mount.
 * window?.visualViewport will be undefined if the api isn't supported.
 * This hook falls back to window.innerWidth and window.innerHeight if that's the case.
 */
export const useVisualViewport = ({
  delayDebounce = 200,
}: {
  delayDebounce?: number;
} = {}): VisualViewportReturnType => {
  const [viewport, setViewport] = useState({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    setViewport({
      width: window?.visualViewport?.width || window?.innerWidth || 0,
      height: window?.visualViewport?.height || window?.innerHeight || 0,
    });
  }, []);

  const debouncedHandleResize = useMemo(
    () =>
      delayDebounce <= 0
        ? handleResize
        : debounce(handleResize, delayDebounce, {
            leading: true,
            trailing: true,
          }),
    [handleResize, delayDebounce],
  );

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize, handleResize]);

  return viewport;
};
