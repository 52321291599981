import React from 'react';
import { twMerge } from 'tailwind-merge';

export const PlayIndicatorBars = ({
  className,
  isAuthor,
}: {
  className?: string;
  isAuthor: boolean;
}) => {
  const sharedBarStyles = twMerge(
    'block h-4 w-[2px] flex-shrink-0 origin-center rounded-full transition-all duration-100 ease-in-out',
    isAuthor ? 'bg-vault_text' : 'bg-vault_accent',
  );

  return (
    <div
      className={twMerge('flex flex-shrink-0 items-center justify-between gap-[0.8px]', className)}
    >
      <div
        className={twMerge(
          sharedBarStyles,
          'h-1',
          ' animate-[playPulse_0.5s_ease-in-out_-0.9s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-2',
          'animate-[playPulse_0.5s_ease-in-out_-0.5s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-3',
          'animate-[playPulse_0.8s_ease-in-out_-0.4s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-2',
          'animate-[playPulse_0.9s_ease-in-out_-0.3s_infinite_alternate] ',
        )}
      />
    </div>
  );
};

export const PlayContentWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="flex max-w-full items-center gap-1">{children}</div>
);

export const LargePlayIndicatorBars = ({
  className,
  isPaused,
  sharedBarClassName,
  withVaultTheme,
}: {
  className?: string;
  isPaused: boolean;
  sharedBarClassName?: string;
  withVaultTheme?: boolean;
}) => {
  const sharedBarStyles = twMerge(
    'block h-8 w-[2px] flex-shrink-0 origin-center rounded-full transition-all duration-100 ease-in-out',
    withVaultTheme ? 'bg-vault_accent' : 'bg-yellow100',
    isPaused ? '[animation-play-state:paused]' : '[animation-play-state:running]',
    sharedBarClassName,
  );

  return (
    <div
      className={twMerge('flex flex-shrink-0 items-center justify-between gap-[2.67px]', className)}
    >
      <div
        className={twMerge(
          sharedBarStyles,
          'h-1',
          ' animate-[playPulse_0.3s_ease-in-out_-0.9s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-2',
          'animate-[playPulse_0.4s_ease-in-out_-0.5s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-4',
          'animate-[playPulse_0.7s_ease-in-out_-0.4s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-6',
          'animate-[playPulse_0.8s_ease-in-out_-0.3s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-3',
          'animate-[playPulse_0.9s_ease-in-out_-0.4s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-5',
          'animate-[playPulse_0.6s_ease-in-out_-0.3s_infinite_alternate] ',
        )}
      />
      <div
        className={twMerge(
          sharedBarStyles,
          'h-1',
          ' animate-[playPulse_0.4s_ease-in-out_-0.9s_infinite_alternate] ',
        )}
      />
    </div>
  );
};
