import { useCallback, useEffect, useRef, useState } from 'react';

export const useAudio = () => {
  const audioRef = useRef(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const play = useCallback(() => {
    if (isReady) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }, [isReady]);

  const pause = useCallback(() => {
    audioRef.current.pause();
    setIsPlaying(false);
  }, []);

  const load = useCallback((url: string) => {
    if (audioRef.current.src) {
      audioRef.current.pause();
    }
    audioRef.current.src = url;
    audioRef.current.load();
    setIsReady(false);
    audioRef.current.addEventListener('canplay', () => setIsReady(true));
    return () => audioRef.current.removeEventListener('canplay', () => setIsReady(true));
  }, []);

  const seek = useCallback(
    (time: number) => {
      if (isReady) {
        audioRef.current.currentTime = time;
      }
    },
    [isReady],
  );

  useEffect(() => {
    const handleAudioEnd = () => {
      setIsPlaying(false);
    };

    const currentAudio = audioRef.current;
    currentAudio.addEventListener('ended', handleAudioEnd);

    return () => {
      currentAudio.removeEventListener('ended', handleAudioEnd);
    };
  }, []);

  return { play, pause, load, seek, audioRef, isReady, isPlaying };
};
