import React, { useEffect, useMemo, useState } from 'react';
import { type UseFormSetValue } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { COLOR } from '../../../constants/colorConstants';
import { type CustomizeVaultViewFragment } from '../../../graphql/generated';
import { useWindow } from '../../../hooks/useWindow';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { DesktopColorPickers, MobileColorPickers } from './ColorPickers';
import { LogoUpload } from './LogoUpload';
import { type CustomizeMenuValidationSchema } from './schema';

gql(/* GraphQL */ `
  fragment customizeVaultView on Vault {
    id
    accentColor
    backgroundColor
    artistId
    artistProfile {
      id
      profileImage {
        id
        url
        mediaType
      }
    }
    logoImage {
      id
      url
      mediaType
    }
  }
`);

export function CustomizeVaultTheme({
  setActionPending,
  isVisible,
  vault,
  temporaryLogoUrl,
  setTemporaryLogoUrl,
  isCustomizing,
  isSubmitting,
  setValue,
  accents,
  formAccentColor,
  formBackgroundColor,
}: {
  setActionPending: (isPending: boolean) => void;
  isVisible: boolean;
  vault: CustomizeVaultViewFragment;
  temporaryLogoUrl: string | null;
  setTemporaryLogoUrl: (value: string | null) => void;
  isCustomizing: boolean;
  isSubmitting: boolean;
  setValue: UseFormSetValue<CustomizeMenuValidationSchema>;
  accents: string[] | null;
  formBackgroundColor: string | null | undefined;
  formAccentColor: string | null | undefined;
}) {
  const { isDesktop } = useWindow();

  const [openPicker, setOpenPicker] = useState<'background' | 'accent' | null>(null);
  const [showSuggestedAccents, setShowSuggestedAccents] = useState(false);
  const isActionPending = isSubmitting || isCustomizing;

  useEffect(() => {
    setActionPending(isActionPending);
  }, [isActionPending, setActionPending]);

  const memoizedSuggestedAccents = useMemo(() => {
    return (
      <View className="relative animate-fadeIn gap-4 rounded-[20px] bg-base800 px-7 py-5">
        <div className="flex flex-row items-start justify-between">
          <div className="mr-[10px]">
            <Text className="font-title text-title-s">Suggested accent colors</Text>

            <Text className="font-base text-base-m text-base500">
              Works best with your background
            </Text>
          </div>
          <Button
            leadingIcon={faXmark}
            iconOnly
            onClick={() => {
              setShowSuggestedAccents(false);
            }}
            label="Close"
            className="text-[24px] text-white"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>
        <View className="mt-5 flex flex-row items-start gap-4">
          {accents?.map(color => (
            <div
              key={color}
              className={twMerge(
                'flex h-7 w-7 rounded-full border border-solid border-white hover:cursor-pointer',
              )}
              style={{ backgroundColor: color }}
              onClick={() => {
                setValue('accentColor', color, { shouldDirty: true });
              }}
            />
          ))}
        </View>
      </View>
    );
  }, [accents, setValue]);

  if (!vault.artistId) {
    return null;
  }

  return (
    <View className={twMerge('flex w-full flex-col gap-6', isVisible ? 'visible' : 'hidden')}>
      <LogoUpload
        artistId={vault.artistId}
        savedLogoUrl={temporaryLogoUrl ?? vault.logoImage?.url}
        onLogoUpload={media => {
          setTemporaryLogoUrl(media.cdnUrl);
          setValue('logoMediaId', media.mediaId, { shouldDirty: true });
          setValue('shouldRemoveLogo', false, { shouldDirty: true });
        }}
        onLogoRemove={() => {
          setTemporaryLogoUrl(null);
          setValue('logoMediaId', null, { shouldDirty: true });
          setValue('shouldRemoveLogo', true, { shouldDirty: true });
        }}
      />

      {isDesktop ? (
        <DesktopColorPickers
          backgroundColor={formBackgroundColor || vault.backgroundColor || COLOR.black}
          accentColor={formAccentColor || vault.accentColor || COLOR.yellow100}
          openPicker={openPicker}
          setOpenPicker={setOpenPicker}
          setShowSuggestedAccents={setShowSuggestedAccents}
          setColor={color => {
            if (openPicker) {
              setValue(openPicker === 'accent' ? 'accentColor' : 'backgroundColor', color, {
                shouldDirty: true,
              });
            }
          }}
        />
      ) : (
        <MobileColorPickers
          backgroundColor={formBackgroundColor || vault.backgroundColor || COLOR.black}
          accentColor={formAccentColor || vault.accentColor || COLOR.yellow100}
          setShowSuggestedAccents={setShowSuggestedAccents}
          setBackgroundColor={color => {
            setValue('backgroundColor', color, {
              shouldDirty: true,
            });
          }}
          setAccentColor={color => {
            setValue('accentColor', color, {
              shouldDirty: true,
            });
          }}
        />
      )}

      {showSuggestedAccents && !!accents && memoizedSuggestedAccents}
    </View>
  );
}
