import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import type { StatsigOptions } from 'statsig-react';
import { Statsig, StatsigProvider } from 'statsig-react';

import { typedEntries } from '@soundxyz/utils/entries';
import { FEATURE_GATE_OVERRIDES, FEATURE_GATES } from '../constants/flagConstants';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';
import { useSearchParams } from '../hooks/params';
import { useStatsigUser } from '../hooks/statsigUser';

const options: StatsigOptions = {
  /**
   * This is a cloudflare reverse proxy that points to the statsig API
   * It prevents Adblockers from blocking the statsig SDK
   *
   * You can find the code for this proxy here:
   * - https://dash.cloudflare.com/8eea6d7ded10d1e953ba9198d8399146/workers/services/view/api-statsig-proxy/production/triggers#custom-domains
   *
   * The the subdomain for this proxy is @wdobry Dog's name which is inspired from https://www.netflix.com/title/81263662
   */
  api: 'https://kokosh.sound.xyz/v1/',
  environment: {
    tier:
      // Rename DEVELOPMENT to PREVIEW just for Statsig, otherwise non prod feature flags will not work
      import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development'
        ? 'preview'
        : import.meta.env.VITE_DEPLOY_ENVIRONMENT,
  },
};

const statsigKeyEnv = import.meta.env.VITE_STATSIG_KEY;

if (!statsigKeyEnv) throw new Error('VITE_STATSIG_KEY is not defined');

const statsigKey = statsigKeyEnv;

export function SoundStatsigProvider({ children }: { children: ReactNode }) {
  const { user: statsigUser } = useStatsigUser();
  const { feature_gates } = useSearchParams();

  // Override any feature gates that are explicitly set in the URL
  // In the form of ?feature_gates=my_feature:1,other_feature:0
  useEffect(() => {
    // Clear out existing overrides to prevent persisting between sessions
    Statsig.removeGateOverride();
    for (const [key, value] of typedEntries(FEATURE_GATE_OVERRIDES)) {
      if (value != null) {
        Statsig.overrideGate(FEATURE_GATES[key], value);
      }
    }
    // Skip if on prod or there are not gates present
    if (!feature_gates || IS_FINAL_DEPLOYMENT) return;
    const overrides = feature_gates.split(',');
    overrides.forEach(override => {
      const [gate, enabled] = override.split(':');
      if (!gate || !enabled) return;
      Statsig.overrideGate(gate, enabled === '1');
    });

    return () => Statsig.removeGateOverride();
  }, [feature_gates]);

  return (
    <StatsigProvider
      sdkKey={statsigKey}
      waitForInitialization={false}
      user={statsigUser}
      options={options}
    >
      {children}
    </StatsigProvider>
  );
}
