import { useEffect } from 'react';
import { proxy } from 'valtio';
import { z } from 'zod';
import { gql } from '@soundxyz/gql-string';
import { COLOR } from '../constants/colorConstants';
import { fetchQuery, useQuery } from '../graphql/client';
import {
  VaultThemeByArtistHandleDocument,
  VaultThemeByVaultIdDocument,
} from '../graphql/generated';
import { PersistenceStorage } from '../utils/storeUtils';
import { useArtistHandle } from './useArtistHandle';

gql(/* GraphQL */ `
  query VaultThemeByArtistHandle($input: QueryArtistByLinkInput!) {
    artistByLink(input: $input) {
      id
      mainVaultId
      mainVault {
        id
        type
        accentColor
        backgroundColor
        logoImage {
          id
          url
        }
      }
      profileImage {
        id
        url
      }
      name
      linkValue
    }
  }

  query VaultThemeByVaultId($vaultId: UUID!) {
    vaultFromId(vaultId: $vaultId) {
      id
      type
      accentColor
      backgroundColor
      logoImage {
        id
        url
      }
      artistProfile {
        id
        name
        profileImage {
          id
          url
        }
      }
    }
  }
`);

const VaultThemeSchema = z.object({
  backgroundColor: z.string(),
  accentColor: z.string(),
  textColor: z.string(),
});

const VaultThemeStorage = PersistenceStorage({
  schema: z.record(z.string(), VaultThemeSchema),
  key: 'vaultTheme',
});

type VaultThemeState = {
  logoMediaUrl: string | null;
  profileImageUrl: string | null;
  mode: 'light' | 'dark';
  accentColor: string;
  textColor: string;
  backgroundColor: string;
  name: string;
};

const initialState: VaultThemeState = {
  logoMediaUrl: null,
  profileImageUrl: null,
  textColor: COLOR.white,
  accentColor: COLOR.yellow100,
  backgroundColor: COLOR.black,
  mode: 'dark',
  name: '',
};

export const VaultThemeStore: VaultThemeState = proxy(initialState);

// Note: Use this hook on each page that needs to use the Vault theme
export function useVaultTheme({ enabled = true }: { enabled?: boolean } = {}) {
  const { artistHandle } = useArtistHandle();

  const { data, isLoading } = useQuery(VaultThemeByArtistHandleDocument, {
    staleTime: 10000,
    variables: !!artistHandle && { input: { link: artistHandle } },
    filterQueryKey: { artistHandle },
    enabled: !!artistHandle && enabled,
    select(data) {
      return data?.data.artistByLink;
    },
  });

  useEffect(() => {
    if (!isLoading || !artistHandle) {
      return;
    }

    (async () => {
      const vaultThemes = await VaultThemeStorage.get();
      if (vaultThemes == null) {
        return;
      }

      const savedVaultTheme = vaultThemes[artistHandle];
      if (savedVaultTheme == null) {
        return;
      }

      setVaultTheme({
        newAccentColor: savedVaultTheme.accentColor,
        newBackgroundColor: savedVaultTheme.backgroundColor,
        newLogoUrl: undefined,
        newProfileImageUrl: undefined,
        newName: undefined,
      });
    })();
  }, [artistHandle, enabled, isLoading]);

  useEffect(() => {
    if (data == null || !artistHandle) {
      return;
    }

    VaultThemeStorage.produceExistingState(draft => {
      draft[artistHandle] = {
        accentColor: data.mainVault.accentColor,
        backgroundColor: data.mainVault.backgroundColor,
        textColor: getTextColor(data.mainVault.backgroundColor),
      };
    }, {});

    setVaultTheme({
      newAccentColor: data?.mainVault.accentColor,
      newBackgroundColor: data?.mainVault.backgroundColor,
      newLogoUrl: data?.mainVault.logoImage?.url,
      newProfileImageUrl: data?.profileImage?.url,
      newName: data?.name,
    });
  }, [artistHandle, data]);
}

export function setVaultTheme({
  newAccentColor,
  newBackgroundColor,
  newLogoUrl,
  newProfileImageUrl,
  newName,
}: {
  newAccentColor: string | undefined;
  newBackgroundColor: string | undefined;
  newLogoUrl: string | null | undefined;
  newProfileImageUrl: string | null | undefined;
  newName: string | undefined;
}) {
  const backgroundColor: string = newBackgroundColor ?? COLOR.black;
  const textColor: string = backgroundColor ? getTextColor(backgroundColor) : COLOR.white;
  const textOppositeColor: string = textColor === COLOR.white ? COLOR.black : COLOR.white;
  const accentColor: string = newAccentColor ?? COLOR.yellow100;
  const accentTextColor: string = accentColor ? getTextColor(accentColor) : COLOR.black;

  // Mandatory to inject the colors in Color Space format (R,G,B) in order to use tailwind opacity modifier syntax
  // More information here: https://tailwindcss.com/docs/customizing-colors#using-css-variables
  injectCSSTheme({
    'vault-background-color': hexToColorSpace(backgroundColor),
    'vault-text-color': hexToColorSpace(textColor),
    'vault-text-opposite-color': hexToColorSpace(textOppositeColor),
    'vault-accent-color': hexToColorSpace(accentColor),
    'vault-accent-text-color': hexToColorSpace(accentTextColor),
  });

  VaultThemeStore.mode = textColor === COLOR.white ? 'dark' : 'light';
  VaultThemeStore.accentColor = accentColor;
  VaultThemeStore.textColor = textColor;
  VaultThemeStore.backgroundColor = backgroundColor;
  VaultThemeStore.logoMediaUrl = newLogoUrl ?? null;
  VaultThemeStore.profileImageUrl = newProfileImageUrl ?? null;
  VaultThemeStore.name = newName ?? '';
}

export function getTextColor(backgroundColor: string) {
  if (backgroundColor.startsWith('#')) {
    backgroundColor = backgroundColor.slice(1);
  }

  if (backgroundColor.length === 3) {
    backgroundColor = backgroundColor
      .split('')
      .map(c => c + c)
      .join('');
  }

  const r = parseInt(backgroundColor.substr(0, 2), 16);
  const g = parseInt(backgroundColor.substr(2, 2), 16);
  const b = parseInt(backgroundColor.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return white or black text color based on luminance
  return luminance >= 0.5 ? COLOR.black : COLOR.white;
}

export function hexToColorSpace(hex: string) {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(c => c + c)
      .join('');
  }

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return `${r},${g},${b}` as const;
}

export function injectCSSTheme(theme: Record<string, string>) {
  const style = document.createElement('style');

  const cssVariables = Object.entries(theme)
    .map(([key, value]) => `--${key}: ${value};`)
    .join(' ');

  style.innerHTML = `:root { ${cssVariables} }`;
  document.head.appendChild(style);

  return () => {
    document.head.removeChild(style);
  };
}

export function resetVaultTheme({ vaultId }: { vaultId: string }) {
  fetchQuery(VaultThemeByVaultIdDocument, {
    staleTime: 10000,
    variables: { vaultId },
    filterQueryKey: { vaultId },
  }).then(({ data }) => {
    setVaultTheme({
      newAccentColor: data.vaultFromId?.accentColor,
      newBackgroundColor: data.vaultFromId?.backgroundColor,
      newLogoUrl: data.vaultFromId?.logoImage?.url,
      newProfileImageUrl: data.vaultFromId?.artistProfile?.profileImage?.url,
      newName: data.vaultFromId?.artistProfile?.name,
    });
  });
}
