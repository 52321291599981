import { useEffect, useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { useImmerReducer } from 'use-immer';
import { proxy, useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { type ExecutionResultWithData, gql } from '@soundxyz/graphql-react-query';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useInfiniteQuery } from '../../../graphql/client';
import {
  type FragmentType,
  getFragment,
  makeFragmentData,
  TrackCommentRowFragmentDoc,
  type TrackCommentsQuery,
  TrackCommentThreadPreviewFragmentDoc,
  TrackThreadCommentsDocument,
  type TrackThreadCommentsQuery,
} from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { getManyFromList } from '../../../utils/arrayUtils';

export const TrackCommentsOwnCommentsStore = proxy<{
  [contentId: string]: Map<string, ExecutionResultWithData<TrackCommentsQuery>>;
}>({});

export const useTrackCommentsOwnComments = ({ vaultContentId }: { vaultContentId: string }) =>
  useSnapshot(TrackCommentsOwnCommentsStore)[vaultContentId];

export function getTrackCommentsOwnComments(vaultContentId: string) {
  const existing = TrackCommentsOwnCommentsStore[vaultContentId];

  if (existing) return existing;

  TrackCommentsOwnCommentsStore[vaultContentId] = proxyMap();

  return TrackCommentsOwnCommentsStore[vaultContentId]!;
}

gql(/* GraphQL */ `
  query TrackThreadComments($messageId: UUID!, $asArtistId: UUID, $after: String, $first: Int!) {
    threadMessages(threadRootId: $messageId, first: $first, after: $after) {
      edges {
        node {
          id
          createdAt
          ...TrackCommentRow
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const PAGE_SIZE = 5;

type HideStateStatus = { status: 'preview' } | { status: 'collapsed' } | { status: 'expanded' };

const LastHideState: Record<string, HideStateStatus> = {};

export function useTrackCommentReplies({
  vaultContentId,
  comment,
}: {
  vaultContentId: string;

  comment: FragmentType<TrackCommentRowFragmentDoc>;
}) {
  const { loggedInUser } = useAuthContext();

  const asArtistId = loggedInUser?.artist?.id;

  const {
    threadMessagesPreview,
    id: commentId,
    threadMessagesCount,
  } = getFragment(TrackCommentRowFragmentDoc, comment);

  const [state, dispatch] = useImmerReducer<
    HideStateStatus,
    { action: 'expand' | 'collapse' | 'more' },
    undefined
  >(
    (draft, action) => {
      switch (action.action) {
        case 'collapse': {
          if (draft.status === 'collapsed') return;

          return {
            status: 'collapsed',
          };
        }
        case 'expand': {
          if (draft.status === 'expanded') return;

          return {
            status: 'expanded',
          };
        }
        case 'more': {
          if (draft.status === 'expanded') return;

          return {
            status: 'expanded',
          };
        }
        default: {
          return;
        }
      }
    },
    undefined,
    () => {
      const lastState = LastHideState[commentId];
      if (lastState) return lastState;

      if (threadMessagesCount > 0) {
        return { status: 'preview' };
      }

      return { status: 'expanded', after: null };
    },
  );

  useEffect(() => {
    LastHideState[commentId] = state;
  }, [state, commentId]);

  const ownComments = useTrackCommentsOwnComments({ vaultContentId });

  const previewReplies = useMemo(() => {
    return getManyFromList(Array.from(threadMessagesPreview || []), comment => {
      const fragmentData = getFragment(TrackCommentThreadPreviewFragmentDoc, comment);
      return {
        ...makeFragmentData(
          {
            id: fragmentData.id,
            createdAt: fragmentData.createdAt,
            content: fragmentData.content,
            source: fragmentData.source,
            activeSubscriptionTier: fragmentData.activeSubscriptionTier,
            threadRootId: fragmentData.threadRootId,
            threadMessagesCount: fragmentData.threadMessagesCount,
            asArtist:
              fragmentData.asArtist != null
                ? {
                    ...fragmentData.asArtist,
                    profileImage:
                      fragmentData.asArtist.profileImage != null
                        ? {
                            ...fragmentData.asArtist.profileImage,
                            dominantColor: null,
                          }
                        : null,
                  }
                : null,
            user: {
              ...fragmentData.user,
              avatar:
                fragmentData.user.avatar != null
                  ? {
                      ...fragmentData.user.avatar,
                      dominantColor: null,
                    }
                  : null,
            },
            reactionsSummary: fragmentData.reactionsSummary,
            replyTo: fragmentData.replyTo,
            threadMessagesPreview: [],
            artistReactions: fragmentData.artistReactions,
            myReactionsInfo: fragmentData.myReactionsInfo,
            updatedAt: fragmentData.updatedAt,
          },
          TrackCommentRowFragmentDoc,
        ),
        ...fragmentData,
      };
    });
  }, [threadMessagesPreview]);

  const ownReplies = useMemo(() => {
    return orderBy(
      getManyFromList(Array.from(ownComments?.values() || []), comment => {
        const node = comment.data.vaultContentComments.edges[0]?.node;
        const fragmentData = getFragment(TrackCommentRowFragmentDoc, node);

        if (fragmentData?.threadRootId !== commentId) return null;

        return node;
      }),

      reply => reply.createdAt,
      'desc',
    );
  }, [ownComments, commentId]);

  const isCollapsed = state.status === 'collapsed';

  const customPages = useMemo((): ExecutionResultWithData<TrackThreadCommentsQuery>[] => {
    if (isCollapsed) return [];

    return [
      {
        data: {
          threadMessages: {
            edges: previewReplies.map(reply => {
              return {
                node: reply,
              };
            }),
            pageInfo: {
              endCursor: null,
              hasNextPage: true,
            },
          },
        },
      },
      {
        data: {
          threadMessages: {
            edges: ownReplies.map(reply => {
              return {
                node: reply,
              };
            }),
            pageInfo: {
              endCursor: null,
              hasNextPage: true,
            },
          },
        },
      },
    ];
  }, [previewReplies, ownReplies, isCollapsed]);

  const { orderedList, isInitialLoading, isFetchingNextPage, loadMoreNextPage } = useInfiniteQuery(
    TrackThreadCommentsDocument,
    {
      filterQueryKey: {
        commentId,
        isCollapsed,
      },
      enabled: state.status === 'expanded' && threadMessagesCount > 0,
      customPages,
      variables({ pageParam }) {
        return {
          messageId: commentId,
          asArtistId,
          after: pageParam?.after,
          first: PAGE_SIZE,
        };
      },
      list({ threadMessages }) {
        return threadMessages.edges.map(edge => edge.node);
      },
      staleTime: 0,
      uniq({ id }) {
        return id;
      },
      getNextPageParam({ data }) {
        return (
          data.threadMessages.pageInfo.hasNextPage && {
            after: data.threadMessages.pageInfo.endCursor,
            first: PAGE_SIZE,
          }
        );
      },
      onFetchCompleted(result) {
        /**
         * Update the "ownComments" store to always have the latest data available
         */
        const TrackCommentsOwnComments = getTrackCommentsOwnComments(vaultContentId);
        for (const fetchedComment of result.data.threadMessages.edges) {
          const ownCommentEdge = TrackCommentsOwnComments.get(fetchedComment.node.id)?.data
            .vaultContentComments.edges[0];
          if (ownCommentEdge) {
            ownCommentEdge.node = fetchedComment.node;
          }
        }
      },
      order: [
        [v => v.createdAt, v => v.id],
        ['desc', 'asc'],
      ],
    },
  );

  const handleViewMoreHideReplies = useStableCallback(() => {
    switch (state.status) {
      case 'collapsed': {
        dispatch({
          action: 'expand',
        });
        loadMoreNextPage();

        break;
      }
      case 'preview':
      case 'expanded': {
        if (orderedList.length >= threadMessagesCount) {
          dispatch({
            action: 'collapse',
          });
        } else {
          dispatch({
            action: 'expand',
          });
          loadMoreNextPage();
        }

        break;
      }
    }
  });

  return {
    state,
    isInitialLoading,
    isFetchingNextPage,
    handleViewMoreHideReplies,
    orderedList,
  };
}
