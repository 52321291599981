const storeFunctionCall: Record<string, Promise<unknown>> = {};

export function IdempotentFunctionCall<T>(
  cb: () => Promise<T>,
  {
    key,
    clearOnFinish,
  }: {
    key: string | string[];
    clearOnFinish: boolean;
  },
): Promise<T> {
  const keyString = key.toString();
  const existingValue = storeFunctionCall[keyString];

  if (existingValue) return existingValue as Promise<T>;

  const newValue = cb();

  storeFunctionCall[keyString] = newValue;

  if (clearOnFinish) {
    newValue.finally(() => {
      delete storeFunctionCall[keyString];
    });
  }

  return newValue;
}
