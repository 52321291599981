import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useFadedText } from '../../hooks/useFadedText';

interface MarqueeProps {
  children: React.ReactNode;
  className?: string;
}

export const Marquee = ({ children, className }: MarqueeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isOverflowing = useFadedText(containerRef, contentRef);

  const textAnimation = (() => {
    const animationDurationSeconds =
      ((contentRef.current?.scrollWidth || 0) / (containerRef.current?.clientWidth || 0)) * 10;
    return {
      animation: `marqueeAnimation ${animationDurationSeconds}s linear 0s infinite normal none`,
    };
  })();

  return (
    // Renders a right fade on server side to avoid flash on first render, which is replaced
    // by either no fade if no overflow, or scrolling text with fade on both sides on overflow
    <div
      ref={containerRef}
      className={twMerge(
        'max-w-100vw inline-flex h-full w-full overflow-hidden whitespace-nowrap',

        isOverflowing === undefined ? 'marquee-right' : isOverflowing && 'marquee-fade',
        className,
      )}
    >
      {!!isOverflowing ? (
        <>
          <div className="inline-block" style={textAnimation} ref={contentRef}>
            {children}
          </div>
          <div className="inline-block w-[20px] flex-shrink-0" />
          <div className="inline-block" style={textAnimation}>
            {children}
          </div>
          <div className="inline-block w-[20px] flex-shrink-0" />
          <div className="inline-block" style={textAnimation}>
            {children}
          </div>
          <div className="inline-block w-[20px] flex-shrink-0" />
        </>
      ) : (
        <div className="inline-block" ref={contentRef}>
          {children}
        </div>
      )}
    </div>
  );
};
