import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCheck } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ReleaseCampaignState } from '../../graphql/generated';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { getDSPColor, getDSPIcon } from './helpers';
import { CampaignType, type DSPInfo } from './schema';

export const DSPRow = ({
  disabled,
  dspInfo,
  isPreview,
  loading,
  onClick,
  type,
  campaignCurrentState,
}: {
  disabled: boolean;
  dspInfo: DSPInfo;
  isPreview: boolean;
  loading: boolean;
  onClick: (() => void) | undefined;
  type: CampaignType;
  campaignCurrentState: ReleaseCampaignState | undefined;
}) => {
  const icon = getDSPIcon(dspInfo.key);
  const color = getDSPColor(dspInfo.key);

  const isStreamingCampaign =
    type === CampaignType.Stream || campaignCurrentState === ReleaseCampaignState.Streaming;

  const isPresaved =
    !isStreamingCampaign &&
    (dspInfo.buttonText === 'Pre-saved' || dspInfo.buttonText === 'Pre-added');

  return (
    <View className="flex w-full flex-row justify-between">
      <View className="flex max-w-[55%] flex-row items-center gap-3">
        <FontAwesomeIcon className="h-6 w-6" icon={icon} color={color} />
        <Text className="line-clamp-1 text-base-l font-semibold text-white">{dspInfo.name}</Text>
      </View>

      <Button
        className={twMerge(
          'min-w-[92px] max-w-[50%] rounded-full border border-solid border-transparent bg-white/20 px-4 py-2.5 font-title text-[14px] font-medium text-white hover:bg-white/30',
          isPresaved && 'border-white/20 bg-transparent hover:!bg-transparent',
        )}
        linkClassName="min-w-[92px] max-w-[50%]"
        label={dspInfo.buttonText}
        disabled={disabled || isPresaved}
        loading={loading}
        leadingIcon={isPresaved ? faCheck : undefined}
        disabledClassName="opacity-50 hover:bg-white/20"
        labelClassName="line-clamp-1 break-all"
        onClick={
          isStreamingCampaign && !isPreview ? () => window.open(dspInfo.uri, '_blank') : onClick
        }
      />
    </View>
  );
};
