import { truncate } from 'lodash-es';
import type { ExecutionResultWithData } from '@soundxyz/graphql-react-query';
import { GraphQLReactQueryClient } from '@soundxyz/graphql-react-query';
import {
  QueryClient,
  QueryClientProvider,
  useQuery as useReactQuery,
} from '@soundxyz/graphql-react-query/reactQuery';
import { IS_TEST } from '@soundxyz/utils/const';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';
import { Sentry } from '../sentry';
import { getAuthToken } from './authToken';
import type { OperationNames, Operations } from './generated/documents';
import { graphqlClientHeaders } from './headers';

declare global {
  interface ImportMetaEnv {
    VITE_GRAPHQL_API_URL: string;
  }
}

const GRAPHQL_API_URL: string =
  window.location.protocol === 'https:' && IS_FINAL_DEPLOYMENT
    ? '/graphql'
    : import.meta.env.VITE_GRAPHQL_API_URL;

export { type ExecutionResultWithData, QueryClient, QueryClientProvider, useReactQuery };
export type { ResultOf, VariablesOf } from '@soundxyz/gql-string';

export const GQLReactQuery = GraphQLReactQueryClient<Operations, OperationNames>({
  endpoint: GRAPHQL_API_URL,
  headers: graphqlClientHeaders,
  getPartialHeaders: getAuthToken,
  fetcher: IS_TEST
    ? ({ query }) => {
        throw Error(`Root fetcher mock has not been set for ${truncate(query, { length: 40 })}`);
      }
    : undefined,
  clientConfig: IS_TEST
    ? {
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      }
    : {},
  skipAbort: true,
  graphqlFetcherConfig: {
    onErrorWithoutData({ error }) {
      const errorMessages =
        error.name === 'MultipleGraphQLErrors' ? error.errors.map(e => e.message) : [error.message];

      Sentry.captureException(error, {
        extra: {
          ...error,
          errorMessages,
        },
        level: errorMessages.every(v => v === 'Not authorized!') ? 'debug' : 'error',
        tags: {
          type: error.name,
        },
      });
      throw error;
    },
    onFetchNetworkError(error) {
      Sentry.captureException(error, {
        extra: {
          ...error,
        },
        tags: {
          type: error.name,
        },
        level: [
          'TypeError: Load failed',
          'AbortError: The user aborted a request.',
          'Request timed out',
          'TypeError: NetworkError when attempting to fetch resource.',
          'TypeError: Failed to fetch',
        ].includes(error.originalError.message)
          ? 'debug'
          : 'warning',
      });

      throw error;
    },
    onUnexpectedPayload(error) {
      Sentry.captureException(error, {
        extra: {
          ...error,
          bodyJson: error.name === 'FetchNetworkUnexpectedNonJsonPayload' ? null : error.body,
          bodyText: error.name === 'FetchNetworkUnexpectedNonJsonPayload' ? error.textBody : null,
          responseStatus: error.response.status,
          responseStatusText: error.response.statusText,
        },
        tags: {
          type: error.name,
        },
        level: 'error',
      });

      throw error;
    },
  },
});

export const {
  GraphQLReactQueryProvider,
  client: GraphQLReactQuery,
  fetchGQL,
  invalidateOperations,
  resetOperations,
  useInfiniteQuery,
  useMutation,
  useQuery,
  setQueryData,
  setInfiniteQueryData,
  infiniteQueryKey,
  fetchQuery,
  prefetchQuery,
  Effects: ReactQueryEffects,
} = GQLReactQuery;
