import type { FC } from 'react';
import { useState } from 'react';
import { Content, Overlay, Root } from '@radix-ui/react-dialog';
import { useSwipeable } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';
import { faTimes } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import type { BottomsheetProps } from '../../types/bottomsheetTypes';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { MembershipConfirmationBottomsheet } from '../modals/MembershipConfirmationBottomsheet';
import { AboutArtistBottomSheet } from './AboutArtistBottomSheet';
import { AcceptCollaborationBottomsheet } from './AcceptCollaborationBottomSheet';
import { ActionBottomsheet } from './ActionBottomsheet';
import { AddTrackAttachmentBottomsheet } from './AddTrackAttachmentBottomsheet';
import { ArtistMediaAttachmentBottomsheet } from './ArtistMediaAttachmentBottomSheet';
import { ArtistTrackNoteBottomsheet } from './ArtistTrackNoteBottomSheet';
import { ChooseLocationBottomsheet } from './ChooseLocationBottomsheet';
import { ConfirmationBottomsheet } from './ConfirmationBottomsheet';
import { ConnectStreamingPlatformBottomSheet } from './ConnectStreamingPlatformBottomSheet';
import { CustomBottomSheet } from './Custom';
import { CustomizeVaultBottomsheet } from './CustomizeVaultBottomsheet';
import { DspBottomSheet } from './DspBottomSheet';
import { EventCreateSuccessBottomsheet } from './EventCreateSuccessBottomSheet';
import { ExitFlowBottomsheet } from './ExitFlowBottomsheet';
import { FilterMembersBottomsheet } from './FilterMembersBottomsheet';
import { GetAppBottomsheet } from './GetAppBottomSheet';
import { JoinVaultBottomsheet } from './JoinVaultBottomsheet';
import { MessageReactionBottomsheet } from './MessageReactionsBottomsheet';
import { ProfilePictureBottomsheet } from './ProfilePictureBottomSheet';
import { ReceiptBottomSheet } from './ReceiptBottomSheet';
import { RsvpCreateSuccessBottomsheet } from './RsvpCreateSuccessBottomSheet';
import { SearchGoogleLocationBottomSheet } from './SearchGoogleLocationBottomSheet';
import { ShareBottomsheet } from './ShareBottomsheet';
import { ShareSnippetBottomsheet } from './ShareSnippetBottomsheet';
import { ShareSnippetInterstitialBottomsheet } from './ShareSnippetInterstitialBottomsheet';
import { SubscribeWelcomeMessageBottomSheet } from './SubscribeWelcomeMessageBottomSheet';
import { SubscribersFiltersBottomsheet } from './SubscribersFiltersBottomsheet';
import { TrackCommentsBottomsheet } from './TrackCommentsBottomSheet';
import { UserProfileBottomsheet } from './UserProfileBottomSheet';
import { UserReferralFeatureBottomsheet } from './UserReferralFeatureBottomSheet';

type Props = {
  bottomsheetProps: BottomsheetProps | null;
  closeBottomsheet: (type?: 'swipe' | 'pointer' | 'other') => void;
  isOpen: boolean;
  isExtraBottomsheet: boolean;
};

const BaseBottomsheet: FC<Props> = ({
  bottomsheetProps,
  closeBottomsheet,
  isOpen,
  isExtraBottomsheet,
}) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const handlers = useSwipeable({
    onSwipedDown: () => {
      if (!isScrolling && !bottomsheetProps?.shared?.preventSwipeToDismiss) {
        closeBottomsheet('swipe');
      }
    },
    onSwiped: () => {
      setIsScrolling(false);
    },
    trackMouse: true,
  });

  const fitContent =
    bottomsheetProps?.type === 'SHARE' || !!bottomsheetProps?.actionBottomsheetProps?.className;

  const withVaultTheme =
    !!bottomsheetProps?.actionBottomsheetProps?.withVaultTheme ||
    !!bottomsheetProps?.shared?.withVaultTheme ||
    !!bottomsheetProps?.userProfileBottomsheetProps?.withVaultTheme;

  return (
    <Root open={bottomsheetProps != null}>
      <Overlay
        className={twMerge(
          'fixed inset-0 mx-[auto] grid h-full max-h-screen w-full',
          'flex flex-col items-center justify-center backdrop-blur-xl md2:w-full',
          'max-md2:bg-[rgba(0,0,0,0.6)] md2:bg-[rgba(32,32,32,0.6)]',
          isExtraBottomsheet ? 'z-extraBottomsheet' : 'z-bottomsheet',
        )}
      >
        <Content
          onOpenAutoFocus={(e: Event) => e.preventDefault()}
          className={twMerge(
            'no-scrollbar z-menu flex flex-col items-center justify-start overflow-y-scroll px-[16px] py-[10px]',
            withVaultTheme ? 'bg-vault_background' : 'bg-black',
            'max-md2:absolute max-md2:bottom-0 max-md2:left-0 max-md2:right-0 max-md2:max-h-full max-md2:rounded-t-[10vw] max-md2:shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_-8px_28px_rgba(0,0,0,0.28)]',
            'rounded-[16px] max-md2:rounded-b-none md2:min-h-[330px] md2:w-[530px]',
            isOpen
              ? 'max-md2:origin-bottom max-md2:animate-openBottomsheet'
              : 'max-md2:animate-closeBottomsheet',
            fitContent && 'md2:min-h-[unset] md2:w-[unset]',
            !!bottomsheetProps?.shared?.showFullScreen && 'max-md2:top-0 max-md2:rounded-t-none',
          )}
          onPointerDownOutside={() => {
            if (bottomsheetProps?.shared?.preventOutsideAutoClose) return;

            closeBottomsheet('pointer');
          }}
          {...handlers}
        >
          {!bottomsheetProps?.shared?.hidePulleyBar && (
            <View
              className={twMerge(
                'max-md2:my-[10px] max-md2:h-[4px] max-md2:w-[36px] max-md2:rounded-full ',
                withVaultTheme ? 'bg-vault_background' : 'max-md2:bg-white',
              )}
            />
          )}
          {!bottomsheetProps?.shared?.hideCloseBottomsheetButton ? (
            <View className="flex w-full flex-row justify-end max-md2:hidden">
              <Button
                iconOnly
                label=""
                leadingIcon={faTimes}
                className={twMerge(
                  'text-[20px]',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
                onClick={e => {
                  e.stopPropagation();
                  closeBottomsheet();
                }}
              />
            </View>
          ) : (
            <View className="h-0 md:h-5" />
          )}
          {bottomsheetProps != null && (
            <Bottomsheet {...bottomsheetProps} setIsScrolling={setIsScrolling} />
          )}
        </Content>
      </Overlay>
    </Root>
  );
};

const Bottomsheet: FC<BottomsheetProps & { setIsScrolling: (isScrolling: boolean) => void }> = ({
  aboutArtistBottomsheetProps,
  actionBottomsheetProps,
  addTrackAttachmentBottomsheetProps,
  artistMediaAttachmentBottomsheetProps,
  artistTrackNoteBottomsheetProps,
  confirmationBottomsheetProps,
  customBottomsheetProps,
  exitFlowBottomsheetProps,
  messageReactionBottomsheetProps,
  profilePictureBottomsheetProps,
  setIsScrolling,
  shareBottomsheetProps,
  shareSnippetBottomsheetProps,
  shareSnippetInterstitialBottomsheetProps,
  subscribeWelcomeMessageBottomsheetProps,
  subscribersFiltersBottomsheetProps,
  type,
  userProfileBottomsheetProps,
  acceptCollaborationBottomsheetProps,
  getAppBottomsheetProps,
  trackCommentsBottomsheetProps,
  userReferralFeatureBottomsheetProps,
  joinVaultBottomsheetProps,
  membershipConfirmationBottomsheetProps,
  customizeVaultBottomsheetProps,
  dspBottomsheetProps,
  eventCreateSuccessBottomsheetProps,
  rsvpCreateSuccessBottomsheetProps,
  receiptBottomSheetProps,
  connectStreamingPlatformBottomSheetProps,
  searchGoogleLocationBottomsheetProps,
  chooseLocationBottomsheetProps,
  filterMembersBottomsheetProps,
}) => {
  switch (type) {
    case 'ACTION': {
      if (actionBottomsheetProps == null) {
        throw new Error('actionBottomsheetProps must be set to open bottomsheet of type ACTION');
      }
      return <ActionBottomsheet {...actionBottomsheetProps} />;
    }
    case 'ADD_TRACK_ATTACHMENT':
      if (addTrackAttachmentBottomsheetProps == null) {
        throw new Error(
          'addTrackAttachmentBottomsheetProps must be set to open bottomsheet of type ADD_TRACK_ATTACHMENT',
        );
      }
      return (
        <AddTrackAttachmentBottomsheet
          {...addTrackAttachmentBottomsheetProps}
          setIsScrolling={setIsScrolling}
        />
      );
    case 'CONFIRMATION': {
      if (confirmationBottomsheetProps == null) {
        throw new Error(
          'confirmationBottomsheetProps must be set to open bottomsheet of type CONFIRMATION',
        );
      }
      return <ConfirmationBottomsheet {...confirmationBottomsheetProps} />;
    }

    case 'CUSTOM': {
      if (customBottomsheetProps == null) {
        throw new Error('customBottomsheetProps must be set to open bottomsheet of type CUSTOM');
      }
      return <CustomBottomSheet {...customBottomsheetProps} />;
    }
    case 'EXIT_FLOW': {
      if (exitFlowBottomsheetProps == null) {
        throw new Error(
          'exitFlowBottomsheetProps must be set to open bottomsheet of type EXIT_FLOW',
        );
      }
      return <ExitFlowBottomsheet {...exitFlowBottomsheetProps} />;
    }
    case 'SHARE': {
      if (shareBottomsheetProps == null) {
        throw new Error('shareBottomsheetProps must be set to open bottomsheet of type SHARE');
      }
      return <ShareBottomsheet {...shareBottomsheetProps} />;
    }
    case 'SHARE_SNIPPET': {
      if (shareSnippetBottomsheetProps == null) {
        throw new Error(
          'shareSnippetBottomsheetProps must be set to open bottomsheet of type SHARE_SNIPPET',
        );
      }
      return <ShareSnippetBottomsheet {...shareSnippetBottomsheetProps} />;
    }
    case 'SHARE_SNIPPET_INTERSTITIAL': {
      if (shareSnippetInterstitialBottomsheetProps == null) {
        throw new Error(
          'shareSnippetInterstitialBottomsheetProps must be set to open bottomsheet of type SHARE_SNIPPET_INTERSTITIAL',
        );
      }
      return <ShareSnippetInterstitialBottomsheet {...shareSnippetInterstitialBottomsheetProps} />;
    }
    case 'SUBSCRIBE_WELCOME_MESSAGE':
      if (subscribeWelcomeMessageBottomsheetProps == null) {
        throw new Error(
          'subscribeWelcomeMessageBottomsheetProps must be set to open bottomsheet of type SUBSCRIBE_WELCOME_MESSAGE',
        );
      }
      return <SubscribeWelcomeMessageBottomSheet {...subscribeWelcomeMessageBottomsheetProps} />;
    case 'PROFILE_PICTURE':
      if (profilePictureBottomsheetProps == null) {
        throw new Error(
          'profilePictureBottomsheetProps must be set to open bottomsheet of type PROFILE_PICTURE',
        );
      }
      return <ProfilePictureBottomsheet {...profilePictureBottomsheetProps} />;
    case 'USER_PROFILE':
      if (userProfileBottomsheetProps == null) {
        throw new Error(
          'userProfileBottomsheetProps must be set to open bottomsheet of type USER_PROFILE',
        );
      }
      return <UserProfileBottomsheet {...userProfileBottomsheetProps} />;
    case 'MESSAGE_REACTION':
      if (messageReactionBottomsheetProps == null) {
        throw new Error(
          'messageReactionBottomsheetProps must be set to open bottomsheet of type MESSAGE_REACTION',
        );
      }
      return (
        <MessageReactionBottomsheet
          {...messageReactionBottomsheetProps}
          setIsScrolling={setIsScrolling}
        />
      );
    case 'ARTIST_TRACK_NOTE':
      if (artistTrackNoteBottomsheetProps == null) {
        throw new Error(
          'artistTrackNoteBottomsheetProps must be set to open bottomsheet of type ARTIST_TRACK_NOTE',
        );
      }
      return <ArtistTrackNoteBottomsheet {...artistTrackNoteBottomsheetProps} />;
    case 'ARTIST_MEDIA_ATTACHMENT':
      if (artistMediaAttachmentBottomsheetProps == null) {
        throw new Error(
          'artistMediaAttachmentBottomsheetProps must be set to open bottomsheet of type ARTIST_MEDIA_ATTACHMENT',
        );
      }
      return <ArtistMediaAttachmentBottomsheet {...artistMediaAttachmentBottomsheetProps} />;
    case 'ABOUT_ARTIST':
      if (aboutArtistBottomsheetProps == null) {
        throw new Error(
          'aboutArtistBottomsheetProps must be set to open bottomsheet of type ABOUT_ARTIST',
        );
      }
      return <AboutArtistBottomSheet {...aboutArtistBottomsheetProps} />;
    case 'ACCEPT_COLLABORATION': {
      if (acceptCollaborationBottomsheetProps == null) {
        throw new Error(
          'acceptCollaborationBottomsheetProps must be set to open bottomsheet of type ACCEPT_COLLABORATION',
        );
      }
      return <AcceptCollaborationBottomsheet {...acceptCollaborationBottomsheetProps} />;
    }
    case 'GET_APP': {
      if (getAppBottomsheetProps == null) {
        throw new Error('getAppBottomsheetProps must be set to open bottomsheet of type GET_APP');
      }
      return <GetAppBottomsheet {...getAppBottomsheetProps} />;
    }
    case 'TRACK_COMMENTS': {
      if (trackCommentsBottomsheetProps == null) {
        throw new Error(
          'trackCommentsBottomsheetProps must be set to open bottomsheet of type TRACK_COMMENTS',
        );
      }
      return (
        <TrackCommentsBottomsheet
          {...trackCommentsBottomsheetProps}
          setIsScrolling={setIsScrolling}
        />
      );
    }
    case 'SUBSCRIBERS_FILTERS': {
      if (subscribersFiltersBottomsheetProps == null) {
        throw new Error(
          'subscribersFiltersBottomsheetProps must be set to open bottomsheet of type SUBSCRIBERS_FILTERS',
        );
      }
      return <SubscribersFiltersBottomsheet {...subscribersFiltersBottomsheetProps} />;
    }
    case 'USER_REFERRAL_FEATURE': {
      if (userReferralFeatureBottomsheetProps == null) {
        throw new Error(
          'userReferralFeatureBottomsheetProps must be set to open bottomsheet of type USER_REFERRAL_FEATURE',
        );
      }

      return <UserReferralFeatureBottomsheet {...userReferralFeatureBottomsheetProps} />;
    }

    case 'JOIN_VAULT': {
      if (joinVaultBottomsheetProps == null) {
        throw new Error(
          'joinVaultBottomsheetProps must be set to open bottomsheet of type JOIN_VAULT',
        );
      }
      return <JoinVaultBottomsheet {...joinVaultBottomsheetProps} />;
    }

    case 'MEMBERSHIP_CONFIRMATION': {
      if (membershipConfirmationBottomsheetProps == null) {
        throw new Error(
          'membershipConfirmationBottomsheetProps must be set to open bottomsheet of type MEMBERSHIP_CONFIRMATION',
        );
      }
      return <MembershipConfirmationBottomsheet {...membershipConfirmationBottomsheetProps} />;
    }

    case 'CUSTOMIZE_VAULT': {
      if (customizeVaultBottomsheetProps == null) {
        throw new Error(
          'customizeVaultBottomsheetProps must be set to open bottomsheet of type CUSTOMIZE_VAULT',
        );
      }
      return <CustomizeVaultBottomsheet {...customizeVaultBottomsheetProps} />;
    }

    case 'DSP': {
      if (dspBottomsheetProps == null) {
        throw new Error('dspBottomsheetProps must be set to open bottomsheet of type DSP');
      }
      return <DspBottomSheet {...dspBottomsheetProps} />;
    }

    case 'EVENT_CREATE_SUCCESS': {
      if (eventCreateSuccessBottomsheetProps == null) {
        throw new Error(
          'eventCreateSuccessBottomsheetProps must be set to open bottomsheet of type DSP',
        );
      }
      return <EventCreateSuccessBottomsheet {...eventCreateSuccessBottomsheetProps} />;
    }

    case 'RSVP_CREATE_SUCCESS': {
      if (rsvpCreateSuccessBottomsheetProps == null) {
        throw new Error(
          'rsvpCreateSuccessBottomsheetProps must be set to open bottomsheet of type DSP',
        );
      }
      return <RsvpCreateSuccessBottomsheet {...rsvpCreateSuccessBottomsheetProps} />;
    }

    case 'RECEIPT': {
      if (receiptBottomSheetProps == null) {
        throw new Error('receiptBottomSheetProps must be set to open bottomsheet of type RECEIPT');
      }
      return <ReceiptBottomSheet {...receiptBottomSheetProps} />;
    }

    case 'CONNECT_STREAMING_PLATFORM': {
      if (connectStreamingPlatformBottomSheetProps == null) {
        throw new Error(
          'connectStreamingPlatformBottomSheetProps must be set to open bottomsheet of type CONNECT_STREAMING_PLATFORM',
        );
      }
      return <ConnectStreamingPlatformBottomSheet {...connectStreamingPlatformBottomSheetProps} />;
    }

    case 'SEARCH_GOOGLE_LOCATION': {
      if (searchGoogleLocationBottomsheetProps == null) {
        throw new Error(
          'searchGoogleLocationBottomsheetProps must be set to open bottomsheet of type SEARCH_GOOGLE_LOCATION',
        );
      }
      return <SearchGoogleLocationBottomSheet {...searchGoogleLocationBottomsheetProps} />;
    }
    case 'CHOOSE_LOCATION': {
      if (chooseLocationBottomsheetProps == null) {
        throw new Error(
          'chooseLocationBottomsheetProps must be set to open bottomsheet of type CHOOSE_LOCATION',
        );
      }
      return (
        <ChooseLocationBottomsheet
          {...chooseLocationBottomsheetProps}
          setIsScrolling={setIsScrolling}
        />
      );
    }
    case 'FILTER_MEMBERS': {
      if (filterMembersBottomsheetProps == null) {
        throw new Error(
          'filterMembersBottomsheetProps must be set to open bottomsheet of type FILTER_MEMBERS',
        );
      }
      return <FilterMembersBottomsheet {...filterMembersBottomsheetProps} />;
    }

    default: {
      passiveExhaustiveGuard(type);
      return null;
    }
  }
};

export { BaseBottomsheet };
