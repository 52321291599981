import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import {
  faComment,
  faHome,
  faIdCard,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faVault } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faComment as faCommentSolid,
  faHome as faHomeSolid,
  faIdCard as faIdCardSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faVault as faVaultSolid } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useAudioController } from '../../audio/AudioController';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument, FeatureTypename, TierTypename } from '../../graphql/generated';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { type EventObject, EVENTS, type EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../../utils/subdomainUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { CreateButton } from '../vault/CreateButton';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';

export function SecondaryMenu({
  selectedHandleMemo,
}: {
  selectedHandleMemo: string | null | undefined;
}) {
  const location = useLocation();

  const { setIsSelecting } = useSelectVaultContent();
  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);

  const { loggedInUser } = useAuthContext();
  const { folderId } = useMenuContainer();
  const isOwner =
    !!selectedHandleMemo && !!loggedInUser?.artist?.artistLinks.includes(selectedHandleMemo);

  const { hideAudioPlayer, activeTrackId } = useAudioController();
  const hasAudioPlayer = activeTrackId !== null && !hideAudioPlayer;

  const { data } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!selectedHandleMemo && {
      link: selectedHandleMemo.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: selectedHandleMemo != null,
  });

  const mainVault = data?.data.artistLink?.artist.mainVault;
  const messageChannelId = mainVault?.messageChannelId;
  const vaultId = mainVault?.id;

  const hasActiveSubscription = !!mainVault?.activeSubscription?.id;
  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: mainVault?.activeSubscription,
    isOwner,
  });

  const chatAvailableForFreeUsers = mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatEnabled =
    (isOwner || hasChatReadAccess || !!chatAvailableForFreeUsers) && !!loggedInUser?.id;

  const { unreadCount: messageNotificationCount } = useBatchedVaultMessageUnreadCount({
    messageChannelId,
    vaultId,
    enabled: chatEnabled,
  });

  const showMembershipTab = !isOwner && (membershipV2Enabled || hasActiveSubscription);

  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const isSubdomain = !!subdomainArtistHandle;

  const isHomeSelected = membershipV2Enabled
    ? isSubdomain
      ? isOwner
        ? location.pathname === '/dashboard' || location.pathname === '/dashboard/'
        : location.pathname == '/'
      : isOwner
        ? location.pathname.endsWith(`/${selectedHandleMemo}/dashboard`) ||
          location.pathname.endsWith(`/${selectedHandleMemo}/dashboard/`)
        : location.pathname.endsWith(`/${selectedHandleMemo}`) ||
          location.pathname.endsWith(`/${selectedHandleMemo}/`)
    : isSubdomain
      ? location.pathname === '/'
      : location.pathname.endsWith(`/${selectedHandleMemo}`) ||
        location.pathname.endsWith(`/${selectedHandleMemo}/`);
  const isChatSelected = location.pathname.includes('/chat');

  const isMembershipSelected = location.pathname.includes('/membership');

  const showVaultTab = isOwner && membershipV2Enabled;

  const isVaultSelected = location.pathname.includes('/vault');

  const isNotFoundPage = location.pathname.includes('/404');

  useEffect(() => {
    if (!isOwner) {
      setIsSelecting(false);
    }
  }, [isOwner, setIsSelecting]);

  // This prevents the menu from showing on non vault pages
  if (selectedHandleMemo == null || !vaultId || isNotFoundPage) {
    return null;
  }

  return (
    <View className="no-scrollbar absolute bottom-0 left-[72px] top-0 z-above2 box-border flex w-[200px] flex-col items-start justify-between overflow-y-scroll bg-vault_background pb-0 pl-8 pt-6">
      <View
        className={twMerge(
          'mt-[6px] flex w-full flex-col overflow-hidden',
          hasAudioPlayer && 'mb-[90px]',
        )}
      >
        <View className="flex flex-col gap-1 overflow-auto scrollbar-thin scrollbar-none scrollbar-track-transparent scrollbar-thumb-neutral700 scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-1">
          <MenuItem
            href={artistNavigationPath(
              selectedHandleMemo,
              isOwner && membershipV2Enabled ? '/dashboard' : '/',
            )}
            icon={isHomeSelected ? faHomeSolid : faHome}
            label="Home"
            selected={isHomeSelected}
            showCountIndicator={false}
            event={{
              type: EVENTS.SECONDARY_MENU_NAVIGATE,
              properties: { artistHandle: selectedHandleMemo, path: '/' },
            }}
          />

          {showVaultTab && (
            <MenuItem
              href={artistNavigationPath(selectedHandleMemo, '/vault')}
              icon={isVaultSelected ? faVaultSolid : faVault}
              label="Vault"
              selected={isVaultSelected}
              showCountIndicator={false}
              event={{
                type: EVENTS.SECONDARY_MENU_NAVIGATE,
                properties: { artistHandle: selectedHandleMemo, path: '/vault' },
              }}
            />
          )}

          <MenuItem
            href={artistNavigationPath(selectedHandleMemo, '/chat')}
            icon={isChatSelected ? faCommentSolid : faComment}
            label="Chat"
            selected={isChatSelected}
            showCountIndicator={!!messageNotificationCount && hasChatReadAccess}
            event={{
              type: EVENTS.SECONDARY_MENU_NAVIGATE,
              properties: { artistHandle: selectedHandleMemo, path: '/chat' },
            }}
          />

          {showMembershipTab && (
            <MenuItem
              href={artistNavigationPath(selectedHandleMemo, '/membership')}
              icon={isMembershipSelected ? faIdCardSolid : faIdCard}
              label="Membership"
              selected={isMembershipSelected}
              showCountIndicator={false}
              event={{
                type: EVENTS.SECONDARY_MENU_NAVIGATE,
                properties: { artistHandle: selectedHandleMemo, path: '/membership' },
              }}
            />
          )}

          {isOwner && (
            <CreateButton
              artistHandle={selectedHandleMemo}
              mainVaultId={vaultId}
              folderId={folderId}
            />
          )}
        </View>
      </View>
    </View>
  );
}

function MenuItem<Event extends EventType>(
  props: {
    icon: IconDefinition;
    label: string;
    selected: boolean;
    showCountIndicator: boolean;
  } & ({ onClick: () => void } | { event: EventObject<Event> | undefined; href: string }),
) {
  const location = useLocation();

  return (
    <View className="relative">
      <Button
        href={'href' in props ? props.href : undefined}
        className={twMerge(
          'flex h-14 max-h-14 min-h-14 w-full flex-row items-center justify-start gap-3 rounded-xl p-3 !font-base !text-[16px] !font-medium outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
          props.selected ? 'text-vault_text' : 'text-vault_text/50',
          'hover:bg-vault_text/10',
        )}
        label={props.label}
        leadingIcon={props.icon}
        leadingIconClassName="mr-1 aspect-square w-8 text-[24px]"
        onClick={() => {
          if ('event' in props && !!props.event) {
            trackEvent({ ...props.event, pathname: location.pathname });
          } else if ('onClick' in props) {
            props.onClick();
          }
        }}
      />

      {props.showCountIndicator && (
        <View className="absolute left-[30px] top-[14px] h-2 w-2 items-center justify-center rounded-full border-2 border-solid border-vault_background bg-vault_text" />
      )}
    </View>
  );
}
