import * as z from 'zod';
import { TEXT_MESSAGE_LIMIT } from '../../constants/phoneConstants';
import { RsvpEventReleaseAnnouncementTarget } from '../../graphql/generated';

export const RSVP_MESSAGE_MAX_CHARS = TEXT_MESSAGE_LIMIT;

export enum RSVPEventSteps {
  Intro = 'intro',
  Setup = 'setup',
  Release = 'release',
  Preview = 'preview',
}

export const CAMPAIGN_ERRORS = {
  title: {
    required: 'Title must not be empty.',
  },
  message: {
    max: `Message must be less than ${RSVP_MESSAGE_MAX_CHARS} characters.`,
  },
};

// Define the validation schema for the fields
export const RsvpFieldSchema = z.object({
  mediaId: z.string().nullable(),
  image: z.string().nullable(),
  title: z.string().min(1, { message: CAMPAIGN_ERRORS.title.required }),
  description: z.string().optional(),
  eventDate: z.date().nullable(),
  message: z.string().max(RSVP_MESSAGE_MAX_CHARS, {
    message: CAMPAIGN_ERRORS.message.max,
  }),
  sendToGroup: z.nativeEnum(RsvpEventReleaseAnnouncementTarget),
  shouldSendSms: z.boolean(),
  canEditSms: z.boolean(),
  shouldSendThankyouMessage: z.boolean(),
  thankyouMessage: z.string().max(RSVP_MESSAGE_MAX_CHARS, {
    message: CAMPAIGN_ERRORS.message.max,
  }),
  isThankYouMessageCustomized: z.boolean(),
  isEditMode: z.boolean(),
});

// Define the schema for the errors
const RsvpErrorsSchema = z.object({
  title: z.string().nullish(),
  message: z.string().nullish(),
  thankyouMessage: z.string().nullish(),
});

// Combine the fields and errors schemas into one
export const RsvpSchema = z.object({
  fields: RsvpFieldSchema,
  errors: RsvpErrorsSchema,
});

// Infer the TypeScript types from the schema
export type RsvpState = z.infer<typeof RsvpSchema>;
export type RsvpStateFields = z.infer<typeof RsvpFieldSchema>;
export type RsvpErrors = z.infer<typeof RsvpErrorsSchema>;
