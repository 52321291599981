const MAX_PREVIEW_PERC = 0.99;
const BAR_WIDTH = 3;
const BAR_MARGIN = 2;

const SONG_TOP_WAVE_HEIGHT = 0.64;
const SONG_BOTTOM_WAVE_HEIGHT = 0.48;
const WAVE_HEIGHT = 3 / 5;

export {
  MAX_PREVIEW_PERC,
  BAR_WIDTH,
  BAR_MARGIN,
  SONG_TOP_WAVE_HEIGHT,
  SONG_BOTTOM_WAVE_HEIGHT,
  WAVE_HEIGHT,
};
