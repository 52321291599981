import type { FC, LegacyRef } from 'react';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { isIOS, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faComment } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCoins } from '@soundxyz/font-awesome/pro-regular-svg-icons';

import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPen } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faClock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { heardVaults, loadTrack, useAudioController } from '../../audio/AudioController';
import { pause, togglePlayPause } from '../../audio/AudioEngineHTML5';
import { AudioMeta, setActiveTrackId } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FragmentType,
  getFragment,
  TierTypename,
  TrackFileInfoFragmentDoc,
} from '../../graphql/generated';
import { useBatchedTracksViewed } from '../../hooks/useBatchedTracksViewed';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { useVaultContentActions } from '../../hooks/vault/useVaultContentActions';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getDurationAsTime } from '../../utils/dateUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';
import { DropdownEllipsis } from '../common/Dropdown';
import { SelectButton } from '../common/SelectButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { LargePlayIndicatorBars } from './PlayIndicatorBars';

gql(/* GraphQL */ `
  fragment TrackFileInfo on VaultTrack {
    __typename
    id
    title
    linkValue
    createdAt

    parentVaultContentId
    normalizedPeaks
    vaultId
    vault {
      id
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
      isUserArtistAdmin
      artistId
    }
    duration
    commentMessageCount
    featureAccess {
      feature {
        __typename
      }
    }
    isFullVersionAvailable
  }
`);

type Props = {
  type: 'artist_page' | 'message_attachment';
  isOwner?: boolean;
  track: FragmentType<TrackFileInfoFragmentDoc>;
  vaultContentId: string;
  artistLinkValue?: string;
  containerRef?: LegacyRef<HTMLDivElement>;
  isSelected?: boolean;
  withUpsellInterstitial?: boolean;
  onClick?: () => void;
  artistName?: string;
  withVaultTheme?: boolean;
};

let firstClick = true;

export const TrackFile: FC<Props> = ({
  track,
  vaultContentId,
  artistLinkValue,
  type,
  containerRef,
  isSelected = false,
  withUpsellInterstitial = false,
  onClick,
  artistName,
  withVaultTheme,
}) => {
  const { loggedInUser } = useAuthContext();

  const heardVaultIds = useSnapshot(heardVaults).vaultIds;
  const { loginStatus } = useAuthContext();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const { pathname } = useLocation();

  const { playing, activeTrackId, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();

  const { removeContents } = useVaultContentActions();

  const {
    id: trackId,
    title,
    createdAt,
    vaultId,
    duration,
    featureAccess,
    parentVaultContentId,
    vault: { activeSubscription, isUserArtistAdmin, artistId },
    linkValue: trackLinkValue,
  } = getFragment(TrackFileInfoFragmentDoc, track);

  const isOwner = isUserArtistAdmin;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: activeSubscription,
    isOwner,
  });

  const { isViewed } = useBatchedTracksViewed({ vaultContentId: trackId });

  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;

  const showUpsellInterstitial =
    withUpsellInterstitial &&
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const isPlaying = playing && activeTrackId === trackId;

  const isNew =
    type === 'artist_page' &&
    !heardVaultIds.has(trackId) &&
    activeSubscription != null &&
    activeSubscription.createdAt < createdAt &&
    isViewed === false;

  const onEditSnippetClick = useStableCallback(() => {
    closeBottomsheet();
  });

  const onEditTrackClick = useStableCallback(() => {
    closeBottomsheet();
  });

  const onRemoveTrackClick = useStableCallback(async () => {
    await removeContents({
      contentIds: [vaultContentId],
      vaultId,
      onSuccess: () => {
        if (AudioMeta.activeTrackId === vaultContentId) {
          pause();
          setActiveTrackId(null);
        }
      },
    });
  });

  const shareLink = useMemo(() => {
    const trackLink = trackLinkValue ? `/t/${trackLinkValue}` : `/${trackId}`;
    return generateShareLink({
      artistLinkValue,
      path: trackLink,
      inviteCode: loggedInUser?.adminArtists?.some(adminArtist => adminArtist.artistId === artistId)
        ? null
        : loggedInUser?.inviteCode,
    });
  }, [artistLinkValue, trackId, loggedInUser, artistId, trackLinkValue]);

  const buttons = useMemo(() => {
    const list: ActionBottomsheetProps['buttons'] = [];

    artistName != null &&
      list.push({
        leadingIcon: faArrowUpFromBracket,
        label: 'Share',
        type: 'secondary',
        onClick: () =>
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SHARE,
            shared: {
              withVaultTheme,
            },
            shareBottomsheetProps: {
              entity: 'song',
              link: shareLink,
              artistName,
              withVaultTheme: !!withVaultTheme,
            },
          }),
      });

    list.push({
      leadingIcon: faComment,
      label: 'Post to chat',
      type: 'secondary',
      onClick: closeBottomsheet,
      href: artistNavigationPath(artistLinkValue, '/chat', `track=${vaultContentId}`),
      event: { type: EVENTS.POST_TO_CHAT, properties: { trackId: trackId, vaultId } },
    });

    list.push({
      leadingIcon: faClock,
      label: 'Edit snippet',
      type: 'secondary',
      onClick: onEditSnippetClick,
      href: artistNavigationPath(artistLinkValue, `/snippet/${vaultContentId}`),
      event: { type: EVENTS.EDIT_TRACK_SNIPPET, properties: { trackId: trackId, vaultId } },
    });

    list.push({
      leadingIcon: faPen,
      label: 'Edit track details',
      type: 'secondary',
      onClick: onEditTrackClick,
      href: artistNavigationPath(artistLinkValue, `/edit/${vaultContentId}`),
    });

    list.push({
      leadingIcon: faTrash,
      label: 'Remove track',
      type: 'secondary',
      requireConfirmation: true,
      confirmationSubText: 'Are you sure you want to remove this track?',
      className: 'text-destructive300',
      onClick: onRemoveTrackClick,
      event: { type: EVENTS.REMOVE_TRACK, properties: { trackId: trackId, vaultId } },
    });

    list.splice(2, 0, {
      leadingIcon: faCoins,
      label: 'Edit splits',
      type: 'secondary',
      onClick: closeBottomsheet,
      href: artistNavigationPath(artistLinkValue, `/splits/${vaultContentId}`),
    });

    return list;
  }, [
    artistName,
    closeBottomsheet,
    artistLinkValue,
    vaultContentId,
    trackId,
    vaultId,
    onEditSnippetClick,
    onEditTrackClick,
    onRemoveTrackClick,
    openBottomsheet,
    withVaultTheme,
    shareLink,
  ]);

  const onEllipsisClick = () => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        withVaultTheme: false,
      },
    });
  };

  const onPlayClick = () => {
    if (isLocked) {
      return;
    }

    const handlePlayLogic = () => {
      if (activeTrackId !== trackId) {
        loadTrack({
          trackId: trackId,
          vaultId,
          component: type,
          folderId: parentVaultContentId,
        });

        if (firstClick) {
          firstClick = false;
          setTimeout(() => {
            loadTrack({
              trackId: trackId,
              vaultId,
              component: type,
              folderId: parentVaultContentId,
            });
          }, 200);
        }
      } else {
        activeTrack != null &&
          trackEvent({
            type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
            properties: {
              trackId: activeTrack.id,
              vaultId: activeTrack.vault.id,
              artistId: activeTrack.vault.artist?.id,
              percentComplete,
              isPreview: !activeTrack.isFullVersionAvailable,
              component: type,
            },
            pathname,
          });

        togglePlayPause();
      }
    };

    handlePlayLogic();

    if (showUpsellInterstitial) {
      setTimeout(() => {
        openBottomsheet({
          type: 'GET_APP',
          getAppBottomsheetProps: {
            vaultId,
            interstitial: 'first_track',
            onContinue: handlePlayLogic,
          },
        });

        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'GET_APP',
            vaultId,
            interstitial: 'first_track',
          },
        });
      }, 2000);
    }
  };

  const clickHandler = onClick ?? onPlayClick;

  return (
    <View
      className="flex w-full max-w-full select-none flex-col items-center"
      containerRef={containerRef}
    >
      <View
        className={twMerge(
          'relative mb-[12px] flex h-[80px] cursor-pointer flex-col items-center justify-center gap-1.5 bg-cover pt-[4px]',
          withVaultTheme ? 'w-[67px] bg-no-repeat' : ' w-[60px]',
          withVaultTheme
            ? vaultTheme.mode === 'light'
              ? 'bg-new-file-background-black'
              : 'bg-new-file-background-white'
            : 'bg-file-background',
        )}
        onClick={clickHandler}
      >
        <AnimatePresence>
          {isNew && (
            <motion.div
              className={twMerge(
                'absolute left-[32px] top-[1px] rounded-full p-[2px] px-[6px] font-base !text-base-xs font-semibold',
                withVaultTheme
                  ? 'bg-vault_accent text-vault_accent_text'
                  : 'bg-yellow100 text-black',
              )}
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>

        {activeTrackId === trackId && type !== 'message_attachment' ? (
          <LargePlayIndicatorBars isPaused={!isPlaying} withVaultTheme={withVaultTheme} />
        ) : (
          <FontAwesomeIcon
            icon={faWaveformLines}
            className={twMerge(
              'text-[24px]',
              withVaultTheme
                ? type === 'message_attachment'
                  ? 'text-vault_accent'
                  : 'text-vault_text'
                : type === 'message_attachment'
                  ? 'text-yellow100'
                  : 'text-white',
              isLocked && 'mt-3',
            )}
          />
        )}
        {isLocked ? (
          <FontAwesomeIcon
            icon={faLock}
            className={twMerge(
              'text-[11px]',
              withVaultTheme ? 'text-vault_text/50' : 'text-base400',
            )}
          />
        ) : (
          <>
            {!isOwner && activeSubscriptionFeatures?.tier !== TierTypename.PaidTier && (
              <Text
                className={twMerge(
                  '!text-base-xs',
                  withVaultTheme ? 'text-vault_text/50' : 'text-base500',
                )}
              >
                {featureAccess.some(access => access.feature.__typename === 'FreeVaultContent')
                  ? ''
                  : 'Snippet'}
              </Text>
            )}
          </>
        )}

        {type === 'message_attachment' && <SelectButton isSelected={isSelected} />}
      </View>

      <Text
        className={twMerge(
          'mb-[4px] w-full cursor-pointer overflow-hidden truncate text-center font-base !text-base-m font-semibold',
          withVaultTheme ? 'text-vault_text' : 'text-white',
        )}
        onClick={clickHandler}
      >
        {title ?? 'Unnamed'}
      </Text>
      <Text
        className={twMerge(
          'mb-[4px] flex-1 font-base !text-base-xs font-normal',
          withVaultTheme ? 'text-vault_text/50' : 'text-base500',
        )}
      >
        {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
      </Text>
      <Text
        className={twMerge(
          'mb-[4px] flex-1 font-base !text-base-xs font-normal',
          withVaultTheme ? 'text-vault_text/50' : 'text-base500',
        )}
      >
        {getDurationAsTime(duration)}
      </Text>
      {isOwner && type === 'artist_page' && (
        <DropdownEllipsis
          desktopClassname={withVaultTheme ? 'text-vault_text/50' : 'text-base500'}
          onClick={onEllipsisClick}
          buttons={buttons}
          dropdownType="Manage Track File"
        />
      )}
    </View>
  );
};

export const SkeletonTrackFile = ({ withVaultTheme }: { withVaultTheme?: boolean }) => {
  return (
    <View className="mb-3 flex flex-col items-center">
      <LoadingSkeleton
        className={twMerge(
          'mb-2 flex h-[80px] w-[60px] flex-col items-center justify-center rounded-xl bg-file-background bg-cover pt-[4px]',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
      <LoadingSkeleton
        className={twMerge('mb-1 h-[16px] w-[80px]', withVaultTheme && 'bg-vault_text/10')}
      />
      <LoadingSkeleton
        className={twMerge('mb-1 h-[12px] w-[60px]', withVaultTheme && 'bg-vault_text/10')}
      />
      <LoadingSkeleton
        className={twMerge('h-[12px] w-[45px]', withVaultTheme && 'bg-vault_text/10')}
      />
    </View>
  );
};
