import type { FC } from 'react';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { ExitFlowBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

const ExitFlowBottomsheet: FC<ExitFlowBottomsheetProps> = ({ onConfirm, event }) => {
  const { closeBottomsheet } = useBottomsheetContainer();

  return (
    <View className="flex w-full flex-col items-center justify-between md2:h-full md2:py-4">
      <Text className="mb-[16px] text-center font-title font-medium text-white max-md2:text-title-m md2:text-title-xl">
        Are you sure?
      </Text>
      <Text className="mb-[16px] text-center font-base text-[18px]/[24px] font-normal text-white">
        If you go back, your updates will be discarded.
      </Text>
      <View className="flex flex-row max-md2:mb-[16px] md2:w-[calc(100%-20px)]">
        <Button
          type="secondary"
          label="Discard"
          onClick={e => {
            onConfirm(e);
            closeBottomsheet();
          }}
          className="mr-[8px] w-[unset] flex-1 justify-center rounded-full text-destructive300"
          event={{ type: EVENTS.EXIT_DISCARD, properties: { event } }}
        />
        <Button
          type="primary"
          label="Cancel"
          onClick={closeBottomsheet}
          className="ml-[8px] flex-1"
          event={{ type: EVENTS.EXIT_CANCEL, properties: { event } }}
        />
      </View>
    </View>
  );
};

export { ExitFlowBottomsheet };
