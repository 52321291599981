import { useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../components/buttons/BackButton';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../components/metatags/MetatagsHeader';
import { SkeletonVaultInvitesView, VaultInviteView } from '../components/views/VaultInviteView';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useQuery } from '../graphql/client';
import {
  GetArtistInvitesPageDocument,
  TopVaultInvitersDocument,
  UserVaultInviteStatsDocument,
} from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { LoginStatus } from '../types/authTypes';
import { constructQueryParams } from '../utils/stringUtils';

gql(/* GraphQL */ `
  query GetArtistInvitesPage($input: QueryArtistByLinkInput!) {
    artistByLink(input: $input) {
      id
      name
      profileImage {
        id
        url
      }
      mainVaultId
      ...ArtistInviteView
    }
  }

  query UserVaultInviteStats($vaultId: UUID!) {
    userVaultInviteStats(vaultId: $vaultId) {
      ...VaultInviteStats
    }
  }

  query TopVaultInviters($vaultId: UUID!) {
    topVaultInviters(vaultId: $vaultId) {
      ...TopInviters
    }
  }
`);

export function InvitesPage() {
  const navigate = useNavigate();
  const { artistHandle } = useArtistHandle();

  const { loggedInUser, loginStatus } = useAuthContext();

  const scrollRef = useRef<HTMLDivElement>(null);

  useVaultTheme();

  const {
    isError,
    isLoading: loadingArtist,
    data,
    refetch,
  } = useQuery(GetArtistInvitesPageDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      input: { link: artistHandle.toLowerCase() },
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const {
    data: statsData,
    isLoading: loadingStats,
    isError: statsError,
  } = useQuery(UserVaultInviteStatsDocument, {
    staleTime: 0,
    variables: !!data?.data.artistByLink?.mainVaultId && {
      vaultId: data?.data.artistByLink?.mainVaultId,
    },
    enabled: loginStatus === LoginStatus.LOGGED_IN,
  });

  const {
    data: topInvitersData,
    isLoading: loadingTopInviters,
    isError: topInvitersError,
  } = useQuery(TopVaultInvitersDocument, {
    staleTime: 0,
    variables: !!data?.data.artistByLink?.mainVaultId && {
      vaultId: data?.data.artistByLink?.mainVaultId,
    },
    enabled: !!loggedInUser?.id,
  });

  useSetMetaHeaders({
    title: data?.data.artistByLink ? `${data.data.artistByLink.name}'s Vault` : null,
    imageUrl: data?.data.artistByLink?.profileImage?.url,
  });

  const isLoading = loadingArtist || loadingStats || loadingTopInviters;

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({ artistHandle });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [artistHandle, loggedInUser, loggedInUser?.username, loginStatus, navigate]);

  useEffect(() => {
    if (loginStatus === LoginStatus.LOADING) return;

    if (loginStatus !== LoginStatus.LOGGED_IN) {
      navigate(ROUTES.NOT_FOUND);
    }
  }, [artistHandle, loggedInUser, loggedInUser?.username, loginStatus, navigate]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError || statsError || topInvitersError) {
    return (
      <DefaultLayout
        withVaultTheme
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={<BackButton className="text-vault_text md2:hidden" />}
        ref={scrollRef}
        shouldSkipMargin
        showBorder
        showRoundedTop
        contentClassName="bg-vault_text/3"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="vault_page"
            withVaultTheme
          />
        }
      />
    );
  }

  if (isLoading || data == null || statsData == null || topInvitersData == null) {
    return (
      <DefaultLayout
        withVaultTheme
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={<BackButton className="text-vault_text md2:hidden" />}
        ref={scrollRef}
        shouldSkipMargin
        showBorder
        showRoundedTop
        contentClassName="bg-vault_text/3"
      >
        <SkeletonVaultInvitesView />
      </DefaultLayout>
    );
  }

  if (
    data.data.artistByLink == null ||
    statsData.data.userVaultInviteStats == null ||
    topInvitersData.data.topVaultInviters == null
  ) {
    // TODO: Handle artist is null
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={<BackButton className="text-vault_text md2:hidden" />}
      ref={scrollRef}
      shouldSkipMargin
      isHeaderTransparent
      showBorder
      showRoundedTop
    >
      <VaultInviteView
        artist={data.data.artistByLink}
        stats={statsData?.data.userVaultInviteStats}
        topInviters={topInvitersData.data.topVaultInviters}
      />
    </DefaultLayout>
  );
}
