import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faRightToBracket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { ArtistAdminRole } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { normalizePath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { maskPhoneNumber } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserProfileImage } from '../user/UserProfileImage';

export const MenuUser = ({
  selectedHandle,
  closeAll,
}: {
  selectedHandle?: string | null;
  closeAll: () => void;
}) => {
  const { loggedInUser, loginStatus, isArtist } = useAuthContext();
  const isMainArtistRole = isArtist && loggedInUser?.artist?.role === ArtistAdminRole.Artist;
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const withVaultTheme = !!selectedHandle;

  return loggedInUser != null ? (
    <UserRow
      avatarUrl={
        isMainArtistRole ? loggedInUser.artist?.profileImage?.url : loggedInUser.avatar?.url
      }
      fallbackColor={
        isMainArtistRole
          ? loggedInUser.artist?.profileImage?.dominantColor
          : loggedInUser.avatar?.dominantColor
      }
      username={
        isMainArtistRole
          ? loggedInUser.artist?.name
          : loggedInUser.displayName ?? loggedInUser.username
      }
      subText={
        isMainArtistRole
          ? `@${loggedInUser.artist?.mainLinkValue}`
          : !!loggedInUser.displayName
            ? `@${loggedInUser?.username}` || maskPhoneNumber(loggedInUser?.phone)
            : null
      }
      isActualArtist={isMainArtistRole}
      artistLinkValue={loggedInUser.artist?.mainLinkValue}
      withVaultTheme={withVaultTheme}
    />
  ) : loginStatus === LoginStatus.LOADING ? (
    <View className="mb-3 flex w-full flex-row items-center justify-between rounded-xl">
      <View className="flex flex-row">
        <LoadingSkeleton
          className={twMerge(
            'mr-4 aspect-square w-12 rounded-full',
            withVaultTheme && 'bg-vault_text/10',
          )}
        />
        <View className="flex flex-col justify-center overflow-hidden transition-all ease-in-out md2:w-0 md2:group-hover/menu:w-auto">
          <LoadingSkeleton
            className={twMerge('h-4 w-[60px]', withVaultTheme && 'bg-vault_text/10')}
          />
        </View>
      </View>
      <LoadingSkeleton
        className={twMerge(
          'h-4 w-[60px] overflow-hidden pl-0 transition-all ease-in-out md2:w-0 md2:group-hover/menu:w-[60px]',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    </View>
  ) : (
    <>
      <View className="md2:group-hover/menu:pr-3">
        <Button
          href={`${ROUTES.SIGN_IN}?${constructQueryParams({
            artistHandle: selectedHandle,
            invite,
            c: smsCampaignResponseShortcode,
            openBottomSheet: selectedHandle ? 'freeTierModal' : undefined,
          })}`}
          onClick={closeAll}
          className={twMerge(
            'mb-4 w-full justify-center rounded-full px-7 py-3 font-title !text-[16px]/[20px] font-medium md2:ml-2 md2:h-8 md2:w-8 md2:items-center md2:p-3 md2:group-hover/menu:w-full',
            withVaultTheme ? 'bg-vault_text text-vault_text_opposite' : 'bg-white text-black',
          )}
          label="Log in"
          linkClassName="w-full"
          leadingIcon={faRightToBracket}
          labelClassName="w-full transition-all overflow-hidden ease-in-out md2:opacity-0 md2:group-hover/menu:opacity-100"
          leadingIconClassName="md2:mr-0 md2:group-hover/menu:mr-2"
        />
      </View>
    </>
  );
};

const UserRow = ({
  avatarUrl,
  username,
  subText,
  isActualArtist,
  artistLinkValue,
  withVaultTheme,
  fallbackColor,
}: {
  avatarUrl: string | null | undefined;
  username: string | null | undefined;
  subText: string | null | undefined;
  isActualArtist: boolean;
  artistLinkValue: string | null | undefined;
  withVaultTheme: boolean;
  fallbackColor?: string | null;
}) => {
  const row = (
    <View className="flex items-center gap-3 py-3 pl-3 md2:pl-2">
      <UserProfileImage
        profileImageUrl={avatarUrl}
        className={twMerge('h-8 w-8 rounded-full', withVaultTheme && 'text-vault_text/30')}
        withVaultTheme={withVaultTheme}
        fallbackColor={fallbackColor}
      />
      <View className="flex flex-col gap-0.5 overflow-hidden md2:w-0 md2:opacity-0 md2:group-hover/menu:w-auto md2:group-hover/menu:opacity-100">
        <View className="flex items-center gap-1">
          <Text
            className={twMerge(
              'max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-base text-[14px] font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            {username}
          </Text>
          {isActualArtist && (
            <FontAwesomeIcon
              icon={faBadgeCheck}
              className={twMerge(
                'select-none text-[12px]',
                withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
              )}
            />
          )}
        </View>
        <View className="flex gap-1">
          {!!subText && (
            <Text
              className={twMerge(
                'max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-base text-[12px] font-medium',
                withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
              )}
            >
              {subText}
            </Text>
          )}
          {isActualArtist && (
            <Text
              className={twMerge(
                'whitespace-nowrap text-[12px] font-medium',
                withVaultTheme ? 'text-vault_text/50' : 'text-base400',
              )}
            >
              &middot; View vault
            </Text>
          )}
        </View>
      </View>
    </View>
  );

  if (isActualArtist && !!artistLinkValue) {
    return (
      <Link
        className="cursor-pointer no-underline hover:opacity-80"
        to={normalizePath(`/${artistLinkValue}`)}
      >
        {row}
      </Link>
    );
  }
  return row;
};
