import type { FC, ReactNode } from 'react';
import React from 'react';
import { MenuContainer, MenuContext } from '../contexts/MenuContext';

const MenuProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <MenuContext.Provider>
      <MenuContainer />
      {children}
    </MenuContext.Provider>
  );
};

export { MenuProvider };
