import { twMerge } from 'tailwind-merge';
import { faInstagram, faTiktok, faXTwitter } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { Button } from '../components/buttons/Button';
import { Image } from '../components/common/Image';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { ViewHeader } from '../components/common/ViewHeader';
import { Logo, TitleLogo } from '../components/svg/Logo';
import {
  LANDING_BANNER,
  LANDING_DIRECT_TO_FANS,
  LANDING_INNER_CIRCLE,
  LANDING_ONBOARD_AUDIENCE,
  LANDING_RECEIPTS_INNER_CIRCLE,
} from '../constants/imageConstants';
import { ROUTES } from '../constants/routeConstants';
import {
  PRIVACY_POLICY_URL,
  TOS_URL,
  VAULT_FM_INSTAGRAM_URL,
  VAULT_FM_TIKTOK_URL,
  VAULT_FM_TWITTER_URL,
} from '../constants/urlConstants';

export const LandingPage = () => {
  return (
    <View className="no-scrollbar flex h-full w-full flex-col items-center overflow-x-hidden">
      <View className="flex w-full flex-col items-center">
        <ViewHeader
          left={
            <View className="h-[48px] md2:ml-2">
              <TitleLogo />
            </View>
          }
          right={
            <Button
              href={`${ROUTES.SIGN_IN}`}
              type="primary"
              label="Sign in"
              className="w-max rounded-full px-[26px] py-[12px]"
              linkClassName="w-max"
            />
          }
          className="z-above3 w-full max-w-[1344px] bg-black pt-10 outline-none max-md2:sticky max-md2:top-0"
          gridClassName="md:mx-12"
        />
        <View className="flex max-w-[1344px] flex-col md:px-4">
          <Hero />
          <InnerCircleSection />
          <FeatureSection
            title="Go direct-to-fan"
            description="The era of relying on social media platforms to reach your audience is over. It's your music and your fans — stop renting attention from platforms."
            imagePosition="right"
            featurePoints={[
              {
                title: 'Text your audience.',
                description: 'Message your fans instantly, or schedule one for later.',
              },
              {
                title: 'Get your own number.',
                description:
                  'Choose your area code so fans always get your texts from the same number.',
              },
              {
                title: 'Unified inbox.',
                description:
                  'Easily send, analyze, and respond to messages from a single web and app interface.',
              },
              {
                title: 'Fully own your data.',
                description:
                  'You have complete access to your audience: phones, emails, location, and more.',
              },
            ]}
            component={
              <Image
                src={LANDING_DIRECT_TO_FANS}
                alt="directToFans"
                className="flex w-full translate-y-12 object-cover md:w-1/2"
              />
            }
          />
          <FeatureSection
            className="mt-16 md:mt-32"
            title="Easily onboard your audience"
            description="Growing mailing lists is boring. On Vault, you can leverage your music, content, and key events to onboard your fans."
            imagePosition="left"
            featurePoints={[
              {
                title: 'New music releases.',
                description:
                  "Create a page for fans to pre-save and stream your new music. They'll get notified on release day.",
              },
              {
                title: 'Tickets and merch.',
                description:
                  'Promote upcoming shows and merch drops. Fans RSVP to get first access when it drops.',
              },
              {
                title: 'Content-driven growth.',
                description:
                  'Fans can join your Vault for exclusive access to unreleased songs, videos, and images.',
              },
            ]}
            component={
              <View className="flex w-full items-center justify-center md:w-1/2">
                <Image
                  src={LANDING_ONBOARD_AUDIENCE}
                  alt="onboard"
                  className="h-auto max-w-full object-contain"
                />
              </View>
            }
          />
          <FeatureSection
            className="mt-16 md:mt-0"
            title="Treat fans like your inner circle"
            description="Break the mold of passive fandom by nurturing deep and engaging relationships with your audience."
            imagePosition="right"
            featurePoints={[
              {
                title: 'Bring fans into your creative process.',
                description:
                  'Share music, images, and videos via text as you would with a manager or friend. No judgment - only you can see view counts.',
              },
              {
                title: 'Engage your fans.',
                description: 'Message in a group chat with all fans or through one-on-one DMs.',
              },
              {
                title: 'Personal invitations.',
                description:
                  "Announcing a show or a merch drop on Vault feels like you're personally reaching out.",
              },
            ]}
            component={
              <View className="flex w-full -translate-x-6 items-center justify-center md:w-1/2 md:translate-x-0">
                <Image
                  src={LANDING_INNER_CIRCLE}
                  alt="onboard"
                  className="h-auto max-w-full object-contain"
                />
              </View>
            }
          />
          <FeatureSection
            className="mt-16 md:mt-20"
            title="It pays to be in the inner circle"
            description="When fans feel like they're in the inner circle — and not just a transaction — they're far more likely to spend. We’ll help you identify your most loyal fans."
            imagePosition="left"
            featurePoints={[
              {
                title: 'Show me the receipts.',
                description:
                  'Fans collect receipts for supporting you, through pre-saving or streaming new releases, attending shows, or purchasing merch.',
              },
              {
                title: 'Build loyalty through status.',
                description:
                  'Fans earn membership status by consistently supporting you — like credit card platinum status.',
              },
              {
                title: 'Identify your top fans.',
                description:
                  'Reward top fans with exclusive content, early access, and access to you.',
              },
            ]}
            component={
              <View className="flex w-full translate-x-6 items-center justify-center md:w-1/2 md:translate-x-0">
                <Image
                  src={LANDING_RECEIPTS_INNER_CIRCLE}
                  alt="onboard"
                  className="h-auto max-w-full object-contain"
                />
              </View>
            }
          />
        </View>
        <LandingPageFooter />
      </View>
    </View>
  );
};

const Hero = () => {
  return (
    <View className="px-4 md:mt-8 md:px-0">
      <View className="relative flex aspect-[0.7] flex-col items-start justify-center rounded-xl px-6 text-white md:aspect-[1.7] md:px-16">
        <View className="absolute inset-0 rounded-xl bg-white bg-opacity-10" />
        <View
          className="absolute inset-0 rounded-xl"
          style={{
            backgroundImage: `url(${LANDING_BANNER})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <View className="relative z-above2 max-w-[775px]">
          {/* Content wrapper */}
          <h1 className="mb-4 text-title-xl font-bold md:text-title-xxl">
            Stop relying on social media algorithms or paid advertising
          </h1>
          <p className="mb-8 text-base-l font-light md:text-base-xl">
            Vault empowers you to build a direct relationship with your audience and reach them at
            any time.
          </p>
          <Button
            className="flex cursor-pointer items-center gap-2 rounded-full border-solid border-white/40 bg-transparent px-6 py-3 font-medium text-white transition-all duration-200 ease-in-out hover:bg-white hover:text-black"
            href={VAULT_FM_INSTAGRAM_URL}
            leadingIcon={faInstagram}
            leadingIconClassName="text-[20px]"
            label="DM for early access"
            labelClassName="text-base-l"
          />
        </View>
      </View>
    </View>
  );
};

const InnerCircleSection = () => {
  return (
    <View>
      <View className="relative mb-4 mt-6 flex flex-col items-center justify-center rounded-xl px-6 text-center text-title-l font-normal text-white md:mb-32 md:mt-24 md:px-16 md:text-title-xl">
        <Text className="mb-4 max-w-[600px]">
          Fans want to feel part of your{' '}
          <span className="font-semibold text-yellow100">inner circle.</span> Vault makes it easy
          for you to treat them like family, not transactions.
        </Text>
      </View>
    </View>
  );
};

const FeatureSection = ({
  className,
  title,
  description,
  imagePosition,
  featurePoints,
  component,
}: {
  className?: string;
  title: string;
  description: string;
  imagePosition: 'left' | 'right';
  featurePoints: { title: string; description: string }[];
  component: React.ReactNode;
}) => {
  return (
    <View
      className={twMerge(
        'flex items-center justify-between gap-8 px-4 md:flex-row md:px-8',
        imagePosition === 'left' ? 'flex-col' : 'flex-col-reverse',
        className,
      )}
    >
      {imagePosition === 'left' && component}
      <View className="w-full space-y-6 px-4 md:w-1/2 md:px-12">
        <Text className="w-full text-title-xl font-bold md:text-title-xxl md2:w-[75%]">
          {title}
        </Text>
        <Text className="text-base-l text-white/60">{description}</Text>
        <View className="space-y-6">
          {featurePoints.map((point, index) => (
            <View key={index}>
              <Text className="font-semibold">{point.title}</Text>
              <Text className="font-normal text-white/60">{point.description}</Text>
            </View>
          ))}
        </View>
      </View>
      {imagePosition === 'right' && component}
    </View>
  );
};

const LandingPageFooter = () => {
  return (
    <View className="relative mt-16 flex w-full justify-center overflow-hidden">
      <View className="z-10 max-w-[1000px] flex-[1.5] px-4 py-16 md:flex-[2]">
        <Logo variant="default" />
        <Text className="mb-8 mt-4 max-w-[250px] text-title-xl font-bold text-yellow100 md:max-w-[450px] md:text-title-xxl">
          Let us know if you'd like to chat!
        </Text>
        <Text className="mb-8 text-base-l text-white">
          <a
            href={VAULT_FM_INSTAGRAM_URL}
            className="text-white underline transition-all duration-200 ease-in-out hover:text-yellow100"
          >
            DM us on Instagram
          </a>{' '}
          for early access
        </Text>
      </View>
      <View
        className={twMerge(
          'absolute bottom-0 left-1/2 aspect-[2/1] w-[120%] rounded-full',
          'translate-x-[-50%] translate-y-[60%] bg-yellow100 md:translate-y-[90%]',
          'opacity-10 blur-[125px]',
        )}
      />
    </View>
  );
};

export function Footer() {
  return (
    <View className="mb-5 flex flex-col items-center gap-5">
      <View className="flex flex-row gap-5">
        <Button
          label=""
          iconOnly
          className="text-[20px] text-base400"
          leadingIcon={faInstagram}
          href={VAULT_FM_INSTAGRAM_URL}
          isExternal
        />
        <Button
          label=""
          iconOnly
          className="text-[20px] text-base400"
          leadingIcon={faTiktok}
          href={VAULT_FM_TIKTOK_URL}
          isExternal
        />
        <Button
          label=""
          iconOnly
          className="text-[20px] text-base400"
          leadingIcon={faXTwitter}
          href={VAULT_FM_TWITTER_URL}
          isExternal
        />
      </View>
      <View className="flex flex-row items-center gap-4">
        <a
          className="font-base text-title-xs text-base400 no-underline"
          href={PRIVACY_POLICY_URL}
          target="_blank"
        >
          Privacy Policy
        </a>
        <Text className="font-base text-title-xs text-base400">|</Text>
        <a
          className="font-base text-title-xs text-base400 no-underline"
          href={TOS_URL}
          target="_blank"
        >
          Terms of Service
        </a>
      </View>
      <Text className="mx-4 text-center font-base text-title-xs text-base400">
        Copyright © 2024 Vault.
        <br />
        All rights reserved.
      </Text>
    </View>
  );
}
