import { throttle } from 'lodash-es';
import ms, { type StringValue } from 'ms';
import type { ResultOf, StringDocumentNode } from '@soundxyz/gql-string';
import type { EffectCallback as EffectCallbackRQ } from '@soundxyz/graphql-react-query';
import type { EffectCallback as EffectCallbackWS } from '@soundxyz/graphql-react-ws/client';
import type { NonEmptyPlural } from '@soundxyz/utils/const';

import { invalidateOperations, ReactQueryEffects } from './client';
import type { ExecutionResultWithData } from './wsClient';
import { SubscriptionEffects } from './wsClient';

type EffectCallbackParameter<Data = Record<string, unknown>> = {
  operation: StringDocumentNode;
  result: ExecutionResultWithData<Data>;
  variables?: Record<string, unknown>;
};

type RefetchOperationsOnCompleteParameters = {
  trigger: NonEmptyPlural<StringDocumentNode>;
  refetch: NonEmptyPlural<StringDocumentNode> | (() => void);
  filter?: undefined;
  throttled?: StringValue;
} & Omit<Parameters<typeof invalidateOperations>[0], 'operations'>;

type RefetchOperationsOnCompleteParametersFilter<Trigger extends StringDocumentNode> = {
  trigger: NonEmptyPlural<Trigger>;
  refetch: NonEmptyPlural<StringDocumentNode> | (() => void);
  filter: (data: EffectCallbackParameter<ResultOf<Trigger>>) => unknown;
  throttled?: StringValue;
} & Omit<Parameters<typeof invalidateOperations>[0], 'operations'>;

export function RefetchOnComplete<Trigger extends StringDocumentNode>(
  params: RefetchOperationsOnCompleteParametersFilter<Trigger>,
): () => void;
export function RefetchOnComplete(params: RefetchOperationsOnCompleteParameters): () => void;
export function RefetchOnComplete<Trigger extends StringDocumentNode>({
  trigger,
  refetch,
  throttled,
  filter,
  ...invalidateOptions
}: RefetchOperationsOnCompleteParametersFilter<Trigger> | RefetchOperationsOnCompleteParameters) {
  const triggerList = Array.isArray(trigger) ? trigger : ([trigger] as const);
  const toRefetch =
    typeof refetch === 'function'
      ? refetch
      : Array.isArray(refetch)
        ? refetch
        : ([refetch] as const);

  function invalidate() {
    if (typeof toRefetch === 'function') {
      toRefetch();
      return;
    }

    invalidateOperations({
      operations: toRefetch,
      ...invalidateOptions,
    });
  }

  const refetchCallback = throttled
    ? throttle(invalidate, ms(throttled), {
        leading: true,
        trailing: true,
      })
    : invalidate;

  const handleEffect: EffectCallbackRQ<Record<string, unknown>, Record<string, unknown>> &
    EffectCallbackWS<Record<string, unknown>, Record<string, unknown>> = function handleEffect(
    param,
  ) {
    if (
      filter &&
      !filter(
        // @ts-expect-error TypeScript isn't able to keep the generic across these scopes
        param,
      )
    )
      return;

    return refetchCallback();
  };

  const unsubscriptions = new Set<() => void>();

  for (const triggerOperation of triggerList) {
    unsubscriptions.add(ReactQueryEffects.onCompleted(triggerOperation, handleEffect));

    unsubscriptions.add(SubscriptionEffects.onCompleted(triggerOperation, handleEffect));
  }

  return function unsubscribe() {
    for (const effectUnsubscribe of unsubscriptions) {
      effectUnsubscribe();
    }
    unsubscriptions.clear();
  };
}
