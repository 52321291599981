import { useMemo } from 'react';
import { gql } from '@soundxyz/gql-string';
import {
  ActiveSubscriptionFeaturesFragmentDoc,
  EnabledFeatureFragmentDoc,
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierEnabledFeaturesFragmentDoc,
  TierTypename,
} from '../graphql/generated';

gql(/* GraphQL */ `
  fragment EnabledFeature on TierToFeature {
    feature {
      __typename
    }
  }

  fragment TierEnabledFeatures on VaultTier {
    __typename
    enabledFeatures {
      ...EnabledFeature
    }
  }

  fragment ActiveSubscriptionFeatures on VaultSubscription {
    tier {
      ...TierEnabledFeatures
    }
  }
`);

export function parseEnabledFeatures({
  enabledFeatures,
}: {
  enabledFeatures: FragmentType<EnabledFeatureFragmentDoc>[];
}) {
  const info = getFragment(EnabledFeatureFragmentDoc, enabledFeatures);

  return info.reduce(
    (
      acc: {
        [feature in (typeof info)[number]['feature']['__typename']]?: true;
      },
      feature,
    ) => {
      acc[feature.feature.__typename] = true;
      return acc;
    },
    {},
  );
}

export function parseTierEnabledFeatures({
  tier,
}: {
  tier: FragmentType<TierEnabledFeaturesFragmentDoc>;
}) {
  const infoTier = getFragment(TierEnabledFeaturesFragmentDoc, tier);

  return {
    tier: infoTier.__typename,
    enabledFeatures: parseEnabledFeatures(infoTier),
  };
}

export function parseActiveSubscriptionFeatures({
  subscription,
  isOwner,
}: {
  subscription: FragmentType<ActiveSubscriptionFeaturesFragmentDoc> | null | undefined;
  isOwner: boolean;
}) {
  if (isOwner) {
    // Return all features enabled if the user is the owner
    const allFeaturesEnabled = Object.values(FeatureTypename).reduce(
      (acc: { [key in FeatureTypename]?: true }, feature) => {
        acc[feature] = true;
        return acc;
      },
      {},
    );
    return {
      tier: TierTypename.PaidTier, // Default to PaidTier for the owner
      enabledFeatures: allFeaturesEnabled,
    };
  }

  if (!subscription) return null;

  const info = getFragment(ActiveSubscriptionFeaturesFragmentDoc, subscription);
  return parseTierEnabledFeatures(info);
}

export function useActiveSubscriptionFeatures({
  subscription,
  isOwner = false,
}: {
  subscription: FragmentType<ActiveSubscriptionFeaturesFragmentDoc> | null | undefined;
  isOwner: boolean;
}) {
  return useMemo(() => {
    return parseActiveSubscriptionFeatures({ subscription, isOwner });
  }, [isOwner, subscription]);
}

export type TierFeatures = ReturnType<typeof useActiveSubscriptionFeatures>;
