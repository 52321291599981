import { proxy } from 'valtio';
import { proxySet } from 'valtio/utils';

// Store prefetched audio files so we don't prefetch them again
export type Store = {
  audioFiles: Set<string>;
};

export const store = proxy<Store>({
  audioFiles: proxySet(),
});
