import { ReleaseCampaignStatus } from '../../graphql/generated';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const CampaignStatusBanner = ({
  campaignId,
  status,
  onMakePublic,
}: {
  campaignId: string | undefined;
  status: ReleaseCampaignStatus | undefined;
  onMakePublic: () => void;
}) => {
  if (status === ReleaseCampaignStatus.Active || !status || !campaignId) return null;

  return (
    <View className="z-above1 flex justify-center max-md2:w-full">
      <View className="flex w-full justify-center bg-vault_accent text-center text-vault_accent_text md2:ml-[2px] md2:w-[602px] md2:max-lt:ml-[312px]">
        <Text className="py-[6px] text-[14px]">Your campaign is currently private.</Text>
        <Button
          className="ml-[4px] cursor-pointer py-[6px] text-[14px] font-bold text-vault_accent_text underline"
          onClick={onMakePublic}
          label="Make public"
        />
      </View>
    </View>
  );
};
