const maximumWait = 10_000; // 10s

export async function randomisedExponentialBackoff(retries: number) {
  let retryDelay = 1000; // start with 1s delay
  for (let i = 0; i < retries; i++) {
    retryDelay *= 2;
  }
  await new Promise(resolve =>
    setTimeout(
      resolve,
      Math.min(retryDelay, maximumWait) +
        // add random timeout from 300ms to 3s
        Math.floor(Math.random() * (3000 - 300) + 300),
    ),
  );
}
