import type { FC, ReactNode } from 'react';
import React from 'react';
import { OverlayContainer, OverlayContext } from '../contexts/OverlayContext';

const OverlayProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <OverlayContext.Provider>
      <OverlayContainer />
      {children}
    </OverlayContext.Provider>
  );
};

export { OverlayProvider };
