import { type LegacyRef, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLongPress } from 'use-long-press';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import { FolderItemFragmentDoc } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { FolderItem } from '../Folder';
import { type ContentOption, ContentOptions } from '../artist/ContentOptions';

export const UserFolder = ({
  folderInfo,
  isOwner,
  containerRef,
  artistHandle,
}: {
  folderInfo: FragmentType<FolderItemFragmentDoc> & { id: string };
  isOwner: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const { md2 } = MediaQuery.useContainer();
  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    setIsOpen(true);
  })();
  const [menuHovered, setMenuHovered] = useState(false);

  const navigate = useNavigate();
  const {
    linkValue,
    id: folderId,
    vault: { id: vaultId },
  } = getFragment(FolderItemFragmentDoc, folderInfo);

  const { loggedInUser } = useAuthContext();

  const link = useMemo(() => {
    const path = linkValue ? `/f/${linkValue}` : `/folder/${folderId}`;
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: loggedInUser?.adminArtists?.some(
        adminArtist => adminArtist.artistMainVaultId === vaultId,
      )
        ? null
        : loggedInUser?.inviteCode,
    });
  }, [linkValue, folderId, artistHandle, loggedInUser, vaultId]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Track link copied to clipboard!',
  });

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
  ];

  const onClick = () =>
    navigate(
      artistNavigationPath(
        artistHandle,
        linkValue != null ? `/f/${linkValue}` : `/folder/${folderInfo.id}`,
      ),
    );

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={false}
      onLongPress={onLongPress}
      onClick={() => {
        if (!isOpen) {
          return onClick();
        }
      }}
      selectedFile={false}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={
        <FolderItem
          folderInfo={folderInfo}
          containerRef={containerRef}
          isOwner={isOwner}
          artistHandle={artistHandle}
          editingFolder={null}
          hasEllipsis={md2}
          onClick={null}
        />
      }
      disabled={false}
      disableHover
    />
  );
};
