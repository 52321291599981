import { twMerge } from 'tailwind-merge';

export function Radio({ withVaultTheme, checked }: { withVaultTheme: boolean; checked: boolean }) {
  return (
    <input
      type="radio"
      className={twMerge('!m-0', withVaultTheme ? 'vault-theme-radio' : 'default-radio')}
      checked={checked}
    />
  );
}
