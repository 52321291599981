import { captureException } from '@sentry/react';
import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';

const ParamsStoreSchema = z
  .object({
    feature_gates: z.string(),
    psid: z.string(),
    openBottomSheet: z.string(),
    c: z.string(),
    invite: z.string(),
    step: z.literal('view').or(z.literal('claim')),
  })
  .partial();

const parsedInitialParams = ParamsStoreSchema.safeParse(
  Object.fromEntries(new URL(window.location.href).searchParams.entries()),
);

if (!parsedInitialParams.success) {
  captureException(parsedInitialParams.error, {
    extra: {
      data: parsedInitialParams,
      url: window.location.href,
    },
    level: 'error',
  });
}

export const ParamsStore = proxy<(typeof ParamsStoreSchema)['_output']>(
  parsedInitialParams.success ? parsedInitialParams.data : {},
);

export const useSearchParams = () => useSnapshot(ParamsStore);
