import type { FC } from 'react';
import { faImage, faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { ProfilePictureBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { View } from '../common/View';

const ProfilePictureBottomsheet: FC<ProfilePictureBottomsheetProps> = ({
  onChooseFromLibrary,
  onRemoveProfilePicture,
  allowRemoval,
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();
  return (
    <View className="box-border flex w-full flex-col items-center px-4">
      <Button
        leadingIcon={faImage}
        type="secondary"
        label="Choose from library"
        className="mb-[16px] mt-[20px] font-title text-[16px]/[20px] font-medium text-white"
        onClick={() => {
          onChooseFromLibrary();
          closeBottomsheet();
        }}
        event={{ type: EVENTS.EDIT_PROFILE_PICTURE, properties: null }}
      />
      {allowRemoval && (
        <Button
          leadingIcon={faTrash}
          type="secondary"
          label="Remove current picture"
          className="mb-[20px] font-title text-[16px]/[20px] font-medium text-destructive100"
          onClick={() => {
            onRemoveProfilePicture();
            closeBottomsheet();
          }}
          event={{ type: EVENTS.REMOVE_PROFILE_PICTURE, properties: null }}
        />
      )}
    </View>
  );
};

export { ProfilePictureBottomsheet };
