import { HowItWorks, PhoneImageSlide } from '../event/HowItWorks';

const RSVP_CAROUSEL_DATA = [
  {
    title: 'Collect RSVPs',
    description: 'Announce to your audience and collect RSVPs for your upcoming drop.',
    component: 'collect',
  },
  {
    title: 'Notify fans',
    description:
      'Notify your audience when tickets go on sale, when merch drops, or general updates.',
    component: 'notify',
  },
  {
    title: 'Show me the receipts!',
    description:
      'Fans get a receipt for RSVPing, so you can identify your most loyal and engaged fans.',
    component: 'receipt',
  },
];

export const RsvpHowItWorksView = () => {
  const renderCarouselContent = (component: string | undefined) => {
    if (!component) return null;
    // Placeholder content for RSVP carousel
    switch (component) {
      case 'collect':
        return (
          <PhoneImageSlide
            imageUri="https://d3jznoddta6qri.cloudfront.net/public/rsvp-how-it-works-1.png"
            loading="eager"
          />
        );
      case 'notify':
        return (
          <PhoneImageSlide
            imageUri="https://d3jznoddta6qri.cloudfront.net/public/rsvp-how-it-works-2.png"
            loading="lazy"
          />
        );
      case 'receipt':
        return (
          <PhoneImageSlide
            imageUri="https://d3jznoddta6qri.cloudfront.net/public/rsvp-how-it-works-3.png"
            loading="lazy"
          />
        );
      default:
        return null;
    }
  };

  return (
    <HowItWorks carouselData={RSVP_CAROUSEL_DATA} renderCarouselContent={renderCarouselContent} />
  );
};
