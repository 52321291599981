import type { ChangeEvent } from 'react';
import React, { useRef, useState } from 'react';
import { captureException } from '@sentry/react';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import type { Maybe } from '@soundxyz/utils/src/helpers';
import { useToast } from '../../../contexts/ToastContext';
import { MediaType } from '../../../graphql/generated';
import { uploadMultipartFile } from '../../../utils/s3Utils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { ArtistLogo } from '../../vault/items/artist/ArtistLogo';

export function LogoUpload({
  artistId,
  savedLogoUrl,
  onLogoUpload,
  onLogoRemove,
}: {
  artistId: string;
  savedLogoUrl: Maybe<string>;
  onLogoUpload: (media: { mediaId: string; cdnUrl: string }) => void;
  onLogoRemove: () => void;
}) {
  const [logo, setLogo] = useState(savedLogoUrl);
  const [isUploading, setIsUploading] = useState(false);
  const { openToast } = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadLogo = async (file: File) => {
    setIsUploading(true);
    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: MediaType.Image,
        artistId,
      });
      setLogo(cdnUrl);
      onLogoUpload({
        mediaId,
        cdnUrl,
      });
      openToast({
        text: 'Logo uploaded successfully!',
        variant: 'success',
      });
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'LogoUpload',
        },
      });
      openToast({
        text: `There was an error uploading your logo. ${error}`,
        variant: 'error',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        uploadLogo(file);
      } else {
        openToast({
          text: 'Please select an image file.',
          variant: 'error',
        });
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveLogo = () => {
    setLogo(null);
    onLogoRemove();
  };

  return (
    <View className="box-border flex w-full flex-col">
      <Text className="mb-4 font-title text-title-s font-medium">Logo</Text>
      <View className="flex flex-col rounded-[20px] bg-base800">
        {logo ? (
          <div className="bg-gray-800 box-border flex h-[127px] w-full flex-col items-center justify-start gap-1 rounded-md p-3">
            <Button
              leadingIcon={faXmark}
              iconOnly
              onClick={handleRemoveLogo}
              label="Remove"
              className="items-center justify-center self-end rounded-full text-[24px] text-white"
            />
            <ArtistLogo alt="Artist logo" src={logo} />
          </div>
        ) : (
          <div className="border-gray-700 bg-gray-800 flex cursor-pointer flex-col items-center justify-center px-6 py-4">
            <input
              ref={fileInputRef}
              id="logo-file-input"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept="image/*"
              disabled={isUploading}
            />
            <Button
              label={isUploading ? 'Uploading...' : 'Upload logo'}
              type="primary"
              className="bg-base700 text-white"
              disabled={isUploading}
              onClick={handleButtonClick}
            />
            <p className="line -mx-3 mt-2 text-center font-base text-base-m leading-[20px] text-base500">
              For best results use an image with transparent background. Max 300x70px size.
            </p>
          </div>
        )}
      </View>
    </View>
  );
}
