import type { FC } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faChevronRight } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { MONTHS } from '../../constants/dateConstants';
import { EarningsRowFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';

gql(/* GraphQL */ `
  fragment earningsRow on Earnings {
    id
    month
    year
    amount
  }
`);

type Props = {
  earning: FragmentType<EarningsRowFragmentDoc>;
  bottomRef?: (node?: Element | null | undefined) => void;
  onClick?: () => void;
};

const EarningRow: FC<Props> = ({ earning, bottomRef, onClick }) => {
  const { amount, month, year } = getFragment(EarningsRowFragmentDoc, earning);

  const textMonth = (MONTHS[month] ?? 'Jan').slice(0, 3);
  const status = 'Paid';

  return (
    <View
      className={twMerge(
        'flex w-full flex-row items-center justify-between border-solid border-x-transparent border-b-base800 border-t-transparent py-[20px] ',
        onClick != null && 'cursor-pointer',
      )}
      containerRef={bottomRef}
      onClick={onClick}
    >
      <View className="flex flex-col pr-[8px]">
        <Text className="font-title text-title-s font-medium text-white">
          {textMonth} {year}
        </Text>
      </View>
      <View className="flex flex-row items-center">
        <Text className="rounded-full bg-yellow100 px-[6px] py-[2px] font-base text-base-m font-semibold text-black">
          {status}
        </Text>
        <Text className="mx-[8px] font-base text-base-l font-normal text-white">
          {`$${amount.toFixed(2)}`}
        </Text>

        {onClick != null && (
          <FontAwesomeIcon icon={faChevronRight} size="1x" className="text-white" />
        )}
      </View>
    </View>
  );
};

const SkeletonEarningRow = () => {
  return (
    <View className="flex w-full flex-row items-center justify-between border-solid border-x-transparent border-b-transparent border-t-base800 py-[20px]">
      <View className="flex flex-col pr-[8px]">
        <LoadingSkeleton className="mb-2 h-5 w-[100px]" />
        <LoadingSkeleton className="h-5 w-[100px]" />
      </View>
      <View className="flex flex-row items-center">
        <LoadingSkeleton className="mr-2 h-5 w-[100px]" />
        <LoadingSkeleton className="h-5 w-[50px]" />
      </View>
    </View>
  );
};

export { EarningRow, SkeletonEarningRow };
