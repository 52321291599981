import { useState } from 'react';
import { useSafeLayoutEffect } from './useSafeLayoutEffect';

export function useIsClamped(ref: React.RefObject<Element>) {
  const [isClamped, setIsClamped] = useState(false);

  useSafeLayoutEffect(() => {
    if (ref.current == null) return;

    function cb() {
      setIsClamped((ref.current?.scrollHeight ?? 0) > (ref.current?.clientHeight ?? 0));
    }
    const observer = new ResizeObserver(cb);

    observer.observe(ref.current);

    cb();

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return { isClamped };
}
