export const CF_ACCESS_CLIENT_SECRET: string | undefined = import.meta.env
  .VITE_CF_ACCESS_CLIENT_SECRET;
export const CF_ACCESS_CLIENT_ID: string | undefined = import.meta.env.VITE_CF_ACCESS_CLIENT_ID;

export const HAS_CLOUDFLARE_ACCESS: boolean = !!(CF_ACCESS_CLIENT_ID && CF_ACCESS_CLIENT_SECRET);

const vaultSource = `web-${window.location.host}-${import.meta.env.VITE_COMMIT_DATE}`;

export const baseHeaders: {
  'CF-Access-Client-Id'?: string;
  'CF-Access-Client-Secret'?: string;
  'x-vault-request-source': string;
} = HAS_CLOUDFLARE_ACCESS
  ? {
      'CF-Access-Client-Id': CF_ACCESS_CLIENT_ID,
      'CF-Access-Client-Secret': CF_ACCESS_CLIENT_SECRET,
      'x-vault-request-source': vaultSource,
    }
  : {
      'x-vault-request-source': vaultSource,
    };
