import { useCallback, useState } from 'react';
import { useInterval } from './useInterval';
import { getSecondsFromExpiry, getTimeFromSeconds } from './useTimer';

export function getSecondsFromPrevTime(prevTime: number, shouldRound: boolean) {
  const now = new Date().getTime();
  const milliSecondsDistance = now - prevTime;
  if (milliSecondsDistance > 0) {
    const val = milliSecondsDistance / 1000;
    return shouldRound ? Math.round(val) : val;
  }
  return 0;
}

// Based on https://github.com/amrlabib/react-timer-hook/blob/master/src/useStopwatch.js
export function useStopwatch({ autoStart }: { autoStart: boolean }) {
  const [passedSeconds, setPassedSeconds] = useState(0);
  const [prevTime, setPrevTime] = useState(new Date());
  const [seconds, setSeconds] = useState(
    passedSeconds + getSecondsFromPrevTime(prevTime.getTime() || 0, true),
  );
  const [isRunning, setIsRunning] = useState(autoStart);

  useInterval(
    () => {
      setSeconds(passedSeconds + getSecondsFromPrevTime(prevTime.getTime(), true));
    },
    isRunning ? 1000 : null,
  );

  const start = useCallback(() => {
    const newPrevTime = new Date();
    setPrevTime(newPrevTime);
    setIsRunning(true);
    setSeconds(passedSeconds + getSecondsFromPrevTime(newPrevTime.getTime(), true));
  }, [passedSeconds]);

  const pause = useCallback(() => {
    setPassedSeconds(seconds);
    setIsRunning(false);
  }, [seconds]);

  const reset = useCallback((offset = 0, newAutoStart = true) => {
    const newPassedSeconds = getSecondsFromExpiry(offset, true) || 0;
    const newPrevTime = new Date();
    setPrevTime(newPrevTime);
    setPassedSeconds(newPassedSeconds);
    setIsRunning(newAutoStart);
    setSeconds(newPassedSeconds + getSecondsFromPrevTime(newPrevTime.getTime(), true));
  }, []);

  return {
    ...getTimeFromSeconds(seconds),
    start,
    pause,
    reset,
    isRunning,
  };
}
