import { useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { invalidateOperations, useMutation } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  CreateSetupIntentDocument,
  GetPaymentMethodsDocument,
  ManageSubscriptionFragmentDoc,
  UpdatePaymentMethodDocument,
} from '../../../graphql/generated';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { EVENTS } from '../../../types/eventTypes';
import { PersistenceStorage } from '../../../utils/storeUtils';
import { Button } from '../../buttons/Button';
import { SettingsLayout } from '../../layouts/SettingsLayout';

gql(/* GraphQL */ `
  mutation CreateSetupIntent {
    createSetupIntent {
      __typename
      ... on MutationCreateSetupIntentSuccess {
        data {
          id
          clientSecret
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation UpdatePaymentMethod($input: MutationUpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input)
  }
`);

RefetchOnComplete({
  trigger: [UpdatePaymentMethodDocument],
  refetch: [GetPaymentMethodsDocument, ManageSubscriptionFragmentDoc],
});

export const PaymentMethodStore = PersistenceStorage({
  schema: z.record(
    z.string({
      description: 'User identifier',
    }),
    z.object({
      currentPaymentMethodId: z.string().nullish(),
    }),
  ),
  key: 'currentPaymentMethod',
  eager: false,
});

const AddPaymentMethodView = ({ title = 'Add new card' }: { title?: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { openToast } = useToast();

  const {
    mutate,
    data,
    isLoading: setupLoading,
    isError,
  } = useMutation(CreateSetupIntentDocument, {});
  const [isLoading, setIsLoading] = useState(false);

  const { loggedInUser } = useAuthContext();

  const userId = loggedInUser?.id;

  const { mutate: updatePaymentMethod } = useMutation(UpdatePaymentMethodDocument, {
    onSuccess: () => {
      openToast({
        text: 'Your card has been updated for future subscriptions',
        variant: 'success',
      });
    },
  });

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const onSave = async () => {
    // remove old payment method if it exists
    if (
      stripe == null ||
      elements == null ||
      isError ||
      data?.data.createSetupIntent.__typename !== 'MutationCreateSetupIntentSuccess'
    ) {
      return;
    }
    const { clientSecret } = data.data.createSetupIntent.data;

    setIsLoading(true);

    await elements.submit();

    if (isMounted.current) {
      const ret = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          //Shouldn't happen
          return_url: `${window.location.origin}/settings/methods`,
        },
      });

      if (isMounted.current) {
        setIsLoading(false);

        if (ret.error == null) {
          invalidateOperations({
            operations: [GetPaymentMethodsDocument],
          });
          if (window.history.state.idx != null && window.history.state.idx > 0) {
            navigate(-1);
          } else {
            navigate('/');
          }
        }
      }

      if (ret.setupIntent?.payment_method && typeof ret.setupIntent?.payment_method === 'string') {
        const currentPaymentMethodId = userId
          ? await PaymentMethodStore.get().then(v => v?.[userId]?.currentPaymentMethodId)
          : null;

        updatePaymentMethod({
          input: {
            id: ret.setupIntent?.payment_method,
            currentPaymentMethodId,
          },
        });
      }
    }
  };

  return (
    <SettingsLayout
      title={title}
      right={
        <Button
          label="Save"
          className="font-base text-[16px]/[20px] font-semibold text-yellow100"
          onClick={onSave}
          loading={isLoading}
          disabled={setupLoading}
          event={{ type: EVENTS.SAVE_PAYMENT_METHOD, properties: null }}
        />
      }
    >
      <PaymentElement className="mb-5 w-full" />
    </SettingsLayout>
  );
};

export { AddPaymentMethodView };
