import type { ReactNode } from 'react';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { View } from './View';

type Props = {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  className?: string;
  gridClassName?: string;
  leftClassName?: string;
  rightClassName?: string;
  centerClassName?: string;
};

export const ViewHeader = forwardRef<HTMLDivElement, Props>(
  (
    {
      left,
      center,
      right,
      className,
      gridClassName,
      leftClassName,
      centerClassName,
      rightClassName,
    },
    ref,
  ) => {
    return (
      <View className={twMerge('w-full pb-5', className)} containerRef={ref}>
        <View className={twMerge('mx-[16px] grid grid-cols-5 items-center', gridClassName)}>
          <View className={twMerge('col-span-1 row-span-1', leftClassName)}>{left}</View>
          <View className={twMerge('col-span-3 row-span-1 flex justify-center', centerClassName)}>
            {center}
          </View>
          <View className={twMerge('col-span-1 row-span-1 flex justify-end', rightClassName)}>
            {right}
          </View>
        </View>
      </View>
    );
  },
);
