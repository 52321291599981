import { useMemo } from 'react';
import { gql } from '@soundxyz/gql-string';
import { type ExecutionResultWithData, useQuery } from '../graphql/client';
import {
  GetBatchedTracksViewedDocument,
  type GetBatchedTracksViewedQuery,
  type GetBatchedTracksViewedQueryVariables,
} from '../graphql/generated';
import { createBatchingInputStore } from '../utils/batchInput';
import { useStableCallback } from './useStableCallback';

gql(/* GraphQL */ `
  query GetBatchedTracksViewed($input: QueryVaultContentsAreSeenInput!) {
    vaultContentsAreSeen(input: $input) {
      __typename
      vaultContentId
      viewed
    }
  }
`);

const { useBatchedKey } = createBatchingInputStore({
  chunkLimit: 25,
});

export function useBatchedTracksViewed({
  vaultContentId,
  enabled = true,
}: {
  vaultContentId: string;
  enabled?: boolean;
}) {
  const ids = useBatchedKey({
    key: vaultContentId,
  });

  const variables = useMemo<GetBatchedTracksViewedQueryVariables | false>(() => {
    if (!ids) return false;

    return {
      input: {
        contentIds: ids,
      },
    };
  }, [ids]);

  const isQueryEnabled = enabled && !!variables;

  const { data: isViewed, isLoading } = useQuery(GetBatchedTracksViewedDocument, {
    enabled: isQueryEnabled,
    variables,
    staleTime: 0,
    select: useStableCallback((data: ExecutionResultWithData<GetBatchedTracksViewedQuery>) => {
      return data.data.vaultContentsAreSeen.find(
        ({ vaultContentId: dataVaultContentId }) => vaultContentId === dataVaultContentId,
      )?.viewed;
    }),
  });

  return { isViewed, isLoading };
}
