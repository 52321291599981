import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';

export const RepeatMode = {
  NO_REPEAT: 'NO_REPEAT',
  REPEAT_ONE: 'REPEAT_ONE',
  REPEAT_ALL: 'REPEAT_ALL',
} as const;

export type RepeatMode = (typeof RepeatMode)[keyof typeof RepeatMode];

export const AudioMetaSchema = z.object({
  activeTrackId: z.nullable(z.string()),

  activeVaultId: z.nullable(z.string()),

  activeFolderId: z.nullable(z.string()),

  repeatMode: z.nativeEnum(RepeatMode),
  seekEnabled: z.boolean(),
  loadingActiveTrack: z.boolean(),
  playNextTrackOnEnd: z.boolean(),
  hideAudioPlayer: z.boolean(),
  disableNextPrev: z.boolean(),
  playbackId: z.nullable(z.string()),

  isFullVersionAvailable: z.boolean(),
  hideExpandedPlayer: z.boolean(),
});

type AudioMetaType = (typeof AudioMetaSchema)['_output'];

const initialAudioMeta: AudioMetaType = {
  activeTrackId: null,

  activeVaultId: null,

  activeFolderId: null,

  repeatMode: RepeatMode.NO_REPEAT,
  seekEnabled: true,
  loadingActiveTrack: false,
  playNextTrackOnEnd: true,
  hideAudioPlayer: false,
  disableNextPrev: false,
  playbackId: null,

  isFullVersionAvailable: false,
  hideExpandedPlayer: true,
};

export const AudioMeta = proxy(initialAudioMeta);

export const setActiveTrackId = (activeTrackId: string | null) => {
  AudioMeta.activeTrackId = activeTrackId;
  if (activeTrackId && AudioMeta.hideAudioPlayer) {
    setHideAudioPlayer(false);
  }

  if (!activeTrackId) {
    setActiveVaultId(null);
    setActiveFolderId(null);
  }
};

export const setActiveVaultId = (activeVaultId: string | null) => {
  AudioMeta.activeVaultId = activeVaultId;
};

export const setActiveFolderId = (activeFolderId: string | null) => {
  AudioMeta.activeFolderId = activeFolderId;
};

export const setRepeatMode = (repeatMode: RepeatMode) => {
  AudioMeta.repeatMode = repeatMode;
};

export const setSeekEnabled = (seekEnabled: boolean) => {
  AudioMeta.seekEnabled = seekEnabled;
};

export const setLoadingActiveTrack = (loadingActiveTrack: boolean) => {
  AudioMeta.loadingActiveTrack = loadingActiveTrack;
};

export const setPlayNextTrackOnEnd = (playNextTrackOnEnd: boolean) => {
  AudioMeta.playNextTrackOnEnd = playNextTrackOnEnd;
};

export const setHideAudioPlayer = (hideAudioPlayer: boolean) => {
  AudioMeta.hideAudioPlayer = hideAudioPlayer;
};

export const setDisableNextPrev = (disableNextPrev: boolean) => {
  AudioMeta.disableNextPrev = disableNextPrev;
};

export const setPlaybackId = (playbackId: string) => {
  AudioMeta.playbackId = playbackId;
};

/*export const setActivePlaylist = (playlistId: string | null, releaseIds: string[]) => {
  AudioMeta.activePlaylistId = playlistId;
  AudioMeta.activePlaylistReleaseIds = releaseIds;
};*/

export const setIsFullVersionAvailable = (isFullVersionAvailable: boolean) => {
  AudioMeta.isFullVersionAvailable = isFullVersionAvailable;
};

export const setHideExpandedPlayer = (hideExpandedPlayer: boolean) => {
  AudioMeta.hideExpandedPlayer = hideExpandedPlayer;
};

export const useAudioMeta = () => useSnapshot(AudioMeta);
