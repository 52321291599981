import { proxy, useSnapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { AudioEngineHTML5, audioEngineInstance, useAudioEngineHTML5 } from './AudioEngineHTML5';

const AudioPosition = proxy<{ position: number }>({ position: 0 });

export const setPosition = (position: number) => {
  const rounded = Math.round(position);

  if (AudioPosition.position !== rounded) {
    AudioPosition.position = rounded;
  }
};

const FRAME_RATE = 30;

let animationFrame: number;
let tick = Date.now();
const calculatePosition = () => {
  const newTick = Date.now();
  if (newTick - tick >= FRAME_RATE / 1000) {
    tick = newTick;
    setPosition(audioEngineInstance.position);
  }
  animationFrame = requestAnimationFrame(calculatePosition);
};

subscribeKey(AudioEngineHTML5, 'playing', playing => {
  if (playing) {
    animationFrame = requestAnimationFrame(calculatePosition);
  } else if (animationFrame) {
    cancelAnimationFrame(animationFrame);
  }
});

export const useAudioPosition = () => {
  const { position } = useSnapshot(AudioPosition);
  const { duration } = useAudioEngineHTML5();

  return {
    percentComplete: (position / duration) * 100 || 0,
    position,
  };
};
