import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faPauseCircle } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlayCircle } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import type { OnClickType } from '../buttons/Button';

export function LockedPlayButton() {
  return (
    <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-vault_text p-2">
      <FontAwesomeIcon
        icon={faLock}
        className="flex h-[18px] w-[18px] items-center text-vault_text_opposite"
        fill="true"
      />
    </div>
  );
}

export function PlayButtonView({
  isPlaying,
  onClick,
  isDisabled,
  size = 40,
  className,
}: {
  isPlaying: boolean;
  onClick?: OnClickType;
  isDisabled: boolean;
  size?: number;
  className?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      className={twMerge(!isDisabled && 'cursor-pointer')}
      onClick={isDisabled ? undefined : onClick}
    >
      <FontAwesomeIcon
        icon={isPlaying ? faPauseCircle : faPlayCircle}
        className={twMerge('flex items-center', className)}
        fill="true"
      />
    </svg>
  );
}
