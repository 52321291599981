import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { LinkifyText } from '../common/LinkifyText';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function PreviewMassMessageView({
  message,
  artistName,
  artistProfileImageUrl,
}: {
  message: string;
  artistName: string;
  artistProfileImageUrl: string | null | undefined;
}) {
  return (
    <View className="box-border h-[717px] w-[339px] overflow-hidden rounded-[39px] border-[8px] border-solid border-base800 bg-white">
      <View className="flex w-full flex-col items-center justify-start gap-1 bg-[#F1F1F2] pb-3 pt-6">
        <ArtistProfileImage
          profileImageUrl={artistProfileImageUrl}
          className="h-[60px] w-[60px] rounded-full"
        />
        <Text className="font-base text-[12px]/[15px] text-[#000]/50">{artistName}</Text>
      </View>
      {!!message && (
        <View className="no-scrollbar flex max-h-[calc(100%-120px)] w-full flex-col items-start overflow-y-scroll">
          <View className="relative mx-5 mt-4 box-border max-w-[calc(100%-40px)] rounded-[20px] bg-[#E9E9EB] px-3 py-2">
            <span className="absolute bottom-0 left-[0px] h-[17px] w-[17px]">
              <BubbleBottom />
            </span>
            <LinkifyText className="text-[#0085FF]">
              <span className="relative z-above2 whitespace-pre-wrap break-words text-[#000]">
                {message}
              </span>
            </LinkifyText>
          </View>
        </View>
      )}
    </View>
  );
}

export function BubbleBottom() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M5 10.5C4.49857 13.5086 1.66667 16.3333 0 17C6.4 17 10.5 14.8333 11.5 13.5L16.5 15L16 0H5.5V2V4V4.5C5.5 5.5 5.5 7.5 5 10.5Z"
        fill="#E9E9EB"
      />
    </svg>
  );
}
