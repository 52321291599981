/**
 * Display the user name in the following order of preference:
 * 1. artistName
 * 2. userDisplayName
 * 3. userUsername
 * 4. hash generated from userId
 */
export function useUserDisplayName({
  artistName,
  userDisplayName,
  userUsername,
  userId,
}: {
  artistName: string | null | undefined;
  userDisplayName: string | null | undefined;
  userId: string;
  userUsername: string | null | undefined;
}) {
  return artistName || userDisplayName || userUsername || generateHashFromUserId(userId);
}

export function generateHashFromUserId(userId: string, length = 10): string {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let hash = '';
  for (let i = 0; i < userId.length; i++) {
    const charCode = userId.charCodeAt(i);
    hash += chars[charCode % chars.length];
  }
  return `@user_${hash.slice(0, length)}`;
}
