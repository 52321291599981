import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrivy } from '@privy-io/react-auth';
import { captureException } from '@sentry/react';
import { Navigate, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { faApple, faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import {
  faArrowRightFromBracket,
  faBan,
  faToolbox,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faBank,
  faBell,
  faCreditCard,
  faDollarSquare,
  faMobile,
  faPen,
  faReceipt,
  faUsers,
  faVault,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import { mixpanelClient } from '../../clients/mixpanelClient';
import { ArtistProfileImage } from '../../components/artist/ArtistProfileImage';
import { Button } from '../../components/buttons/Button';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { UserPlaceholderImage } from '../../components/user/UserPlaceholderImage';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { type AuthContextType, useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import {
  DeleteAccountDocument,
  ResetTestPhoneUserDocument,
  SpotifyLinkDocument,
  SpotifyUnlinkDocument,
} from '../../graphql/generated';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { useStableCallback } from '../../hooks/useStableCallback';
import { Sentry } from '../../sentry';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  mutation DeleteAccount {
    deleteAccount {
      __typename
      id
    }
  }
`);

export function SettingsPage() {
  const { loginStatus, loggedInUser } = useAuthContext();
  const { logout } = usePrivy();
  const { mutateAsync: resetTestUser } = useMutation(ResetTestPhoneUserDocument, {});
  const { mutateAsync: deleteAccountMutation, isLoading: deletingAccount } = useMutation(
    DeleteAccountDocument,
    {},
  );
  const { closeMenu, setIsAccountOpen } = useMenuContainer();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const isDevPrev =
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development' ||
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'preview';

  const onDeleteClick = useCallback(() => {
    openBottomsheet({
      type: 'CONFIRMATION',
      confirmationBottomsheetProps: {
        event: 'Delete Account',
        title: 'Are you sure you want to delete your account?',
        confirmType: 'delete',
        buttonContainerClassName: 'w-full',
        customContent: (
          <Text className="mb-8 mt-2 text-center text-base-l md2:-mt-5 md2:mb-0">
            Deleted accounts cannot be recovered <br className="hidden md2:block" />
            and will lose chat and subscription history
          </Text>
        ),
        confirmButtonText: 'Delete Account',
        onConfirm: async () => {
          try {
            const { data } = await deleteAccountMutation({});
            if (!!data) {
              const userId = data.deleteAccount.id;
              trackEvent({
                type: EVENTS.DELETE_ACCOUNT,
                properties: { userId },
              });
              await logout();
              navigate(ROUTES.SIGN_IN);
              openToast({
                text: 'Your account has been deleted.',
                variant: 'success',
              });
            }
          } catch (error) {
            Sentry.captureException(error);
            openToast({
              text: 'There was an error deleting your account. Please try again.',
              variant: 'error',
            });
          }
        },
      },
    });
  }, [deleteAccountMutation, logout, navigate, openBottomsheet, openToast]);

  const onLogoutClick = () => {
    closeMenu();
    setIsAccountOpen(false);
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        subText: 'Are you sure you want to logout?',
        onConfirm: async () => {
          trackEvent({ type: EVENTS.SIGN_OUT, properties: null, pathname: location.pathname });
          mixpanelClient.reset();
          if (loggedInUser?.isTestPhoneUser) resetTestUser({}); // reset test phone users
          await logout();
          navigate(ROUTES.VAULTS);
        },
      },
    });
  };

  const renderLabelComponent = useCallback((label: string) => {
    return <Text className="text-[16px]/[20px]">{label}</Text>;
  }, []);

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (loggedInUser?.artist) {
    return (
      <SettingsArtist
        loggedInUser={loggedInUser}
        onDeleteClick={onDeleteClick}
        deletingAccount={deletingAccount}
      />
    );
  }

  return (
    <SettingsLayout title="Settings" extend>
      <View className="flex w-full flex-col pb-16">
        <View className="mb-4 flex w-full flex-col gap-4">
          <Heading header="Account" subheader="Your personal information and preferences." />
        </View>

        <Button
          label="Edit my profile"
          labelComponent={renderLabelComponent('Edit my profile')}
          type="secondary"
          className="mb-[1px]"
          position="top"
          leadingIcon={faPen}
          leadingIconClassName="w-[20px]"
          href="profile"
          event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'edit_profile' } }}
        />

        {isDevPrev && loggedInUser?.isTestPhoneUser && (
          <Button
            label="Test User Tools"
            labelComponent={renderLabelComponent('Test User Tools')}
            type="secondary"
            position="middle"
            className="mb-[1px]"
            leadingIcon={faToolbox}
            leadingIconClassName="w-[20px]"
            href="test-user-settings"
          />
        )}

        <Button
          label="Memberships"
          labelComponent={renderLabelComponent('Memberships')}
          type="secondary"
          position="middle"
          className="mb-[1px]"
          leadingIcon={faUsers}
          leadingIconClassName="w-[20px]"
          href="memberships"
          event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'subscriptions' } }}
        />

        <Button
          label="Billing history"
          labelComponent={renderLabelComponent('Billing history')}
          type="secondary"
          position="middle"
          className="mb-[1px]"
          leadingIcon={faReceipt}
          leadingIconClassName="w-[20px]"
          href="billing"
          event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'billings' } }}
        />

        <Button
          label="Payment method"
          labelComponent={renderLabelComponent('Payment method')}
          type="secondary"
          position="middle"
          className="mb-[1px]"
          leadingIcon={faCreditCard}
          leadingIconClassName="w-[20px]"
          href="methods"
          event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'methods' } }}
        />

        {loggedInUser?.payee != null && (
          <Button
            label="Collaborator payouts"
            labelComponent={renderLabelComponent('Collaborator payouts')}
            type="secondary"
            className="mb-[32px]"
            position="bottom"
            leadingIcon={faBank}
            leadingIconClassName="w-[20px]"
            href="collaborator-payouts"
            event={{
              type: EVENTS.SETTINGS_NAVIGATE,
              properties: { type: 'collaborator-payouts' },
            }}
          />
        )}

        <Button
          label="Notification settings"
          labelComponent={renderLabelComponent('Notification settings')}
          type="secondary"
          position="middle"
          className="mb-[1px]"
          leadingIcon={faBell}
          leadingIconClassName="w-[20px]"
          href="notification-settings"
        />

        <SpotifyConnect loggedInUser={loggedInUser} />
        <AppleMusicConnect />

        <Button
          label="Delete account"
          labelComponent={renderLabelComponent('Delete account')}
          type="secondary"
          position="bottom"
          leadingIcon={faBan}
          leadingIconClassName="w-[20px]"
          onClick={onDeleteClick}
          loading={deletingAccount}
          disabled={deletingAccount}
        />

        <View className="mt-4 flex w-full flex-col">
          <Button
            label="Sign out"
            labelComponent={renderLabelComponent('Sign out')}
            type="secondary"
            position="individual"
            leadingIcon={faArrowRightFromBracket}
            leadingIconClassName="w-[20px]"
            onClick={onLogoutClick}
            event={{ type: EVENTS.SIGN_OUT, properties: null }}
          />
        </View>
      </View>

      <Footer />
    </SettingsLayout>
  );
}

function AppleMusicConnect() {
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);
  const { openBottomsheet } = useBottomsheetContainer();

  const appleMusicAuth = useAppleMusicAuth({
    enabled: appleMusicConnectEnabled,
  });

  if (!appleMusicConnectEnabled) return null;

  if (appleMusicAuth.type === 'loading') {
    return (
      <Button
        label="Connect Apple Music"
        type="secondary"
        className="mb-[1px]"
        position="middle"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        disabled
        disabledClassName="opacity-70"
        loading
      />
    );
  }

  if (appleMusicAuth.type === 'connected-without-api-confirmation') {
    return (
      <Button
        label="Reconnect Apple Music"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.connectWithApi}
      />
    );
  }

  if (appleMusicAuth.type === 'apple-music-already-linked') {
    return (
      <Button
        label="Disconnect Apple Music"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={() => {
          openBottomsheet({
            type: 'CONFIRMATION',
            confirmationBottomsheetProps: {
              subText: `Are you sure you want to disconnect your Apple Music account?`,
              confirmButtonText: 'Disconnect',
              confirmType: 'delete',
              onConfirm: () => {
                appleMusicAuth.unlink();
              },
            },
          });
        }}
      />
    );
  }

  if (appleMusicAuth.type === 'connect') {
    return (
      <Button
        label="Connect Apple Music"
        type="secondary"
        className="mb-[1px]"
        position="middle"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.connect}
      />
    );
  }

  if (appleMusicAuth.type === 'link-apple-music') {
    return (
      <Button
        label="Link Apple Music"
        type="secondary"
        className="mb-[1px]"
        position="middle"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.link}
      />
    );
  }

  if (appleMusicAuth.type === 'apple-music-connected-without-user') {
    return (
      <Button
        label="Apple Music Connected"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        disabled
        disabledClassName="opacity-70"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
      />
    );
  }

  passiveExhaustiveGuard(appleMusicAuth);

  return null;
}

function SpotifyConnect({ loggedInUser }: { loggedInUser: AuthContextType['loggedInUser'] }) {
  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);

  const spotifyAuth = useSpotifyAuth({
    enabled: spotifyConnectEnabled,
  });

  const { mutateAsync: spotifyLink, isLoading: spotifyIsLinking } = useMutation(
    SpotifyLinkDocument,
    {
      retry: 5,
    },
  );

  const { openToast } = useToast();

  const { openBottomsheet } = useBottomsheetContainer();

  const { mutateAsync: unlinkSpotify } = useMutation(SpotifyUnlinkDocument, {
    retry: 5,
  });

  if (!spotifyConnectEnabled) return null;

  if (loggedInUser?.spotifyAuthConnection?.spotifyUserId) {
    return (
      <Button
        label="Disconnect Spotify"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faSpotify}
        leadingIconClassName="w-[20px]"
        onClick={() => {
          openBottomsheet({
            type: 'CONFIRMATION',
            confirmationBottomsheetProps: {
              subText: `Are you sure you want to disconnect your Spotify account?`,
              confirmButtonText: 'Disconnect',
              confirmType: 'delete',
              onConfirm: () => {
                unlinkSpotify({})
                  .then(() => {
                    openToast({
                      text: 'Spotify account disconnected',
                      variant: 'success',
                    });
                  })
                  .catch(error => {
                    captureException(error, {
                      extra: {
                        spotifyAuth,
                      },
                    });
                    openToast({
                      text: "Couldn't unlink Spotify account. Please try again later.",
                      variant: 'error',
                    });
                  });
              },
            },
          });
        }}
      />
    );
  }

  return (
    <Button
      label={spotifyAuth.type === 'already-connected' ? 'Link Spotify' : 'Connect Spotify'}
      type="secondary"
      position="middle"
      className="mb-[1px]"
      disabled={spotifyAuth.type === 'loading' || spotifyIsLinking}
      disabledClassName="opacity-70"
      loading={spotifyAuth.type === 'loading' || spotifyIsLinking}
      href={spotifyAuth.link}
      isExternal={!!spotifyAuth.link}
      leadingIcon={faSpotify}
      leadingIconClassName="w-[20px]"
      onClick={() => {
        if (spotifyAuth.type !== 'already-connected') return;

        spotifyLink({
          authCode: spotifyAuth.authCode,
          overrideAccount: false,
        })
          .then(result => {
            if (result.data.linkSpotify.__typename !== 'MutationLinkSpotifySuccess') {
              if (result.data.linkSpotify.__typename === 'SpotifyAlreadyLinkedError') {
                return openBottomsheet({
                  type: 'CONFIRMATION',
                  confirmationBottomsheetProps: {
                    title: 'Spotify already linked',
                    subText:
                      'Your Spotify account is already linked to another Vault account. Would you like to link it to this account instead?',
                    onConfirm() {
                      spotifyLink({
                        authCode: spotifyAuth.authCode,
                        overrideAccount: true,
                      }).then(result => {
                        if (result.data.linkSpotify.__typename === 'MutationLinkSpotifySuccess') {
                          openToast({
                            text: 'Spotify account linked',
                            variant: 'success',
                          });
                        } else {
                          openToast({
                            text: result.data.linkSpotify.message,
                            variant: 'error',
                          });
                        }
                      });
                    },
                  },
                });
              }

              openToast({
                text: result.data.linkSpotify.message,
                variant: 'error',
              });
            } else {
              openToast({
                text: 'Spotify account linked',
                variant: 'success',
              });
            }
          })
          .catch(error => {
            captureException(error, {
              extra: {
                spotifyAuth,
              },
            });
            openToast({
              text: "Couldn't link Spotify account. Please try again later.",
              variant: 'error',
            });
          });
      }}
    />
  );
}

function SettingsArtist({
  loggedInUser,
  onDeleteClick,
  deletingAccount,
}: {
  loggedInUser: NonNullable<AuthContextType['loggedInUser']>;
  onDeleteClick: () => void;
  deletingAccount: boolean;
}) {
  const { logout } = usePrivy();
  const { mutateAsync: resetTestUser } = useMutation(ResetTestPhoneUserDocument, {});

  const { closeMenu, setIsAccountOpen, openVaultCustomization } = useMenuContainer();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);

  const artist = loggedInUser.artist;
  const renderLabelComponent = useCallback((label: string) => {
    return <Text className="text-[16px]">{label}</Text>;
  }, []);

  const onLogoutClick = useStableCallback(() => {
    closeMenu();
    setIsAccountOpen(false);
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        subText: 'Are you sure you want to logout?',
        onConfirm: async () => {
          trackEvent({ type: EVENTS.SIGN_OUT, properties: null, pathname: location.pathname });
          mixpanelClient.reset();
          if (loggedInUser?.isTestPhoneUser) resetTestUser({}); // reset test phone users
          await logout();
          navigate(ROUTES.VAULTS);
        },
      },
    });
  });

  const randomUUID = useMemo(() => uuidv4(), []);

  return (
    <SettingsLayout title="Settings" extend>
      <View className="flex w-full flex-col pb-16">
        <View className="flex w-full flex-col gap-4">
          <Heading header="Vaults" subheader="Customize and manage your vault." />
          <View className="flex w-full flex-col">
            <View className="relative box-border flex h-[80px] w-full items-center justify-start overflow-hidden rounded-xl rounded-b-none p-4">
              {artist?.profileImage?.url != null ? (
                <Image
                  src={artist.profileImage.url}
                  alt="Blurred Track/Album Cover"
                  className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
                />
              ) : (
                <UserPlaceholderImage
                  id={randomUUID}
                  className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
                />
              )}

              <View className="z-above1 flex items-center gap-3">
                <ArtistProfileImage
                  profileImageUrl={artist?.profileImage?.url}
                  className="h-12 w-12 text-white"
                  withVaultTheme={false}
                />

                <View className="flex items-center gap-2">
                  <Text className="line-clamp-1 font-title text-[24px] font-medium text-white">
                    {artist?.name || artist?.mainLinkValue || 'Unnamed'}
                  </Text>

                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    className="select-none text-[16px] text-white"
                  />
                </View>
              </View>
            </View>
            {!!artist?.mainVaultId && !!artist.mainVaultId && (
              <Button
                label="Customize vault"
                labelComponent={renderLabelComponent('Customize vault')}
                type="secondary"
                className="mb-[1px]"
                position="middle"
                leadingIcon={faVault}
                leadingIconClassName="w-[20px]"
                onClick={() => {
                  navigate(
                    artistNavigationPath(
                      artist.mainLinkValue,
                      membershipV2Enabled ? '/vault' : '/',
                    ),
                  );
                  openVaultCustomization({ vaultId: artist.mainVaultId });
                }}
                event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'customize_vault' } }}
              />
            )}
            <Button
              label="SMS settings"
              labelComponent={renderLabelComponent('SMS settings')}
              type="secondary"
              className="mb-[1px]"
              position="middle"
              leadingIcon={faMobile}
              leadingIconClassName="w-[20px]"
              href="sms-settings"
              event={{
                type: EVENTS.SETTINGS_NAVIGATE,
                properties: { type: 'manage_sms' },
              }}
            />
            <Button
              label="Members"
              labelComponent={renderLabelComponent('Members')}
              type="secondary"
              className="mb-[1px]"
              position="middle"
              leadingIcon={faUsers}
              leadingIconClassName="w-[20px]"
              href="/settings/members"
              event={{
                type: EVENTS.SETTINGS_NAVIGATE,
                properties: { type: 'subscribers' },
              }}
            />
            <Button
              label="Earnings"
              labelComponent={renderLabelComponent('Earnings')}
              type="secondary"
              position="middle"
              className="mb-[1px]"
              leadingIcon={faDollarSquare}
              leadingIconClassName="w-[20px]"
              href="artist-earnings"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'earnings' } }}
            />

            <Button
              label="Payouts"
              labelComponent={renderLabelComponent('Payouts')}
              type="secondary"
              className="mb-[32px]"
              position="bottom"
              leadingIcon={faBank}
              leadingIconClassName="w-[20px]"
              href="payouts"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'payouts' } }}
            />
          </View>
        </View>
        <View className="flex w-full flex-col gap-4">
          <Heading header="Account" subheader="Your personal information and preferences." />

          <View className="flex w-full flex-col">
            <Button
              label="Edit profile"
              labelComponent={renderLabelComponent('Edit profile')}
              type="secondary"
              className="mb-[1px]"
              position="top"
              leadingIcon={faPen}
              leadingIconClassName="w-[20px]"
              href="profile"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'edit_profile' } }}
            />

            <Button
              label="Memberships"
              labelComponent={renderLabelComponent('Memberships')}
              type="secondary"
              position="middle"
              className="mb-[1px]"
              leadingIcon={faUsers}
              leadingIconClassName="w-[20px]"
              href="memberships"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'subscriptions' } }}
            />

            <Button
              label="Billing history"
              labelComponent={renderLabelComponent('Billing history')}
              type="secondary"
              position="middle"
              className="mb-[1px]"
              leadingIcon={faReceipt}
              leadingIconClassName="w-[20px]"
              href="billing"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'billings' } }}
            />

            <Button
              label="Payment methods"
              labelComponent={renderLabelComponent('Payment methods')}
              type="secondary"
              position="middle"
              className="mb-[1px]"
              leadingIcon={faCreditCard}
              leadingIconClassName="w-[20px]"
              href="methods"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'methods' } }}
            />

            {loggedInUser.payee != null && (
              <>
                <Button
                  label="Collaborator earnings"
                  labelComponent={renderLabelComponent('Collaborator earnings')}
                  type="secondary"
                  position="middle"
                  className="mb-[1px]"
                  leadingIcon={faDollarSquare}
                  leadingIconClassName="w-[20px]"
                  href="earnings"
                  event={{
                    type: EVENTS.SETTINGS_NAVIGATE,
                    properties: { type: 'collaborator-earnings' },
                  }}
                />
                <Button
                  label="Collaborator payouts"
                  labelComponent={renderLabelComponent('Collaborator payouts')}
                  type="secondary"
                  className="mb-[1px]"
                  position="middle"
                  leadingIcon={faBank}
                  leadingIconClassName="w-[20px]"
                  href="collaborator-payouts"
                  event={{
                    type: EVENTS.SETTINGS_NAVIGATE,
                    properties: { type: 'collaborator-payouts' },
                  }}
                />
              </>
            )}

            <Button
              label="Notification settings"
              labelComponent={renderLabelComponent('Notification settings')}
              type="secondary"
              position="middle"
              className="mb-[1px]"
              leadingIcon={faBell}
              leadingIconClassName="w-[20px]"
              href="notification-settings"
              event={{
                type: EVENTS.SETTINGS_NAVIGATE,
                properties: { type: 'notification-settings' },
              }}
            />

            <SpotifyConnect loggedInUser={loggedInUser} />
            <AppleMusicConnect />

            <Button
              label="Delete account"
              labelComponent={renderLabelComponent('Delete account')}
              type="secondary"
              position="bottom"
              leadingIcon={faBan}
              leadingIconClassName="w-[20px]"
              onClick={onDeleteClick}
              loading={deletingAccount}
              disabled={deletingAccount}
            />
          </View>

          <View className="mt-4 flex w-full flex-col">
            <Button
              label="Sign out"
              labelComponent={renderLabelComponent('Sign out')}
              type="secondary"
              position="individual"
              leadingIcon={faArrowRightFromBracket}
              leadingIconClassName="w-[20px]"
              onClick={onLogoutClick}
              event={{ type: EVENTS.SIGN_OUT, properties: null }}
            />
          </View>
        </View>
      </View>

      <Footer />
    </SettingsLayout>
  );
}

function Footer() {
  return (
    <View className="mt-auto flex w-full items-center justify-between pb-4">
      <a
        href={PRIVACY_POLICY_URL}
        target="_blank"
        className="font-base text-[14px] font-medium text-white/50 no-underline"
      >
        Privacy Policy
      </a>

      <a
        href={TOS_URL}
        target="_blank"
        className="font-base text-[14px] font-medium text-white/50 no-underline"
      >
        Terms
      </a>
    </View>
  );
}

function Heading({ header, subheader }: { header: string; subheader: string }) {
  return (
    <View className="mx-2 flex w-full flex-col items-start gap-1">
      <Text className="text-title-m font-medium">{header}</Text>
      <Text className="font-base text-[14px] font-medium text-white/50">{subheader}</Text>
    </View>
  );
}
