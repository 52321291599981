import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faSquareCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { View } from '../common/View';

export function Checkbox({
  selected,
  withVaultTheme = false,
  onClick,
}: {
  selected: boolean;
  withVaultTheme: boolean;
  onClick?: () => void;
}) {
  return (
    <View className="h-5 min-h-5 w-5 min-w-5 cursor-pointer" onClick={onClick}>
      <FontAwesomeIcon
        icon={selected ? faSquareCheck : faSquare}
        fontSize={20}
        className={
          withVaultTheme
            ? selected
              ? 'text-vault_accent'
              : 'text-vault_text/70'
            : selected
              ? 'text-yellow100'
              : 'text-white'
        }
      />
    </View>
  );
}
