import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { useSafeLayoutEffect } from './useSafeLayoutEffect';

/**
 * Measures if the text is scrollable and returns a boolean
 * Returns undefined if the text is not mounted yet, which can be used for SSR fallbacks
 */
export const useFadedText = (
  wrapper: React.RefObject<HTMLElement>,
  text: React.RefObject<HTMLElement>,
  isLoading = false,
) => {
  const [isScrollable, setIsScrollable] = useState<boolean | undefined>(undefined);
  const [mounted, setMounted] = useState(false);

  const updateScroll = useCallback(() => {
    if (wrapper.current && text.current && mounted) {
      // We want to check the width of the container
      const containerWidth = wrapper?.current?.clientWidth || 0;
      // We want to check the width of the text, even if some of it is hidden
      const textWidth = text?.current?.scrollWidth || 0;

      if (containerWidth <= textWidth) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }
  }, [mounted, text, wrapper]);

  const debouncedUpdateScroll = debounce(updateScroll, 100);

  useSafeLayoutEffect(() => {
    window.addEventListener('resize', debouncedUpdateScroll);

    // Trigger updateScroll function on first render
    debouncedUpdateScroll();

    // Remove listener
    return () => window.removeEventListener('resize', debouncedUpdateScroll);
  }, [
    isLoading,
    updateScroll,
    // Some non-resize related events might cause dimensions of both wrapper and text to change
    // Eg: when loading styles is deferred
    // To avoid showing cut off text when this happens, we need to recalculate if the content should scroll
    text.current?.clientWidth,
    wrapper.current?.scrollWidth,
    mounted,
  ]);

  useEffect(() => {
    setMounted(true);
  }, [updateScroll]);

  return isScrollable;
};
