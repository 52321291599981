import linkifyit, { type FullRule } from 'linkify-it';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';
import { ReleaseCampaignContentType } from '../graphql/generated';

export const createNewLinkifyIt = () => {
  return new linkifyit();
};

export const addLinkifyItSchema = (linkifyIt: linkifyit, schema: string, rule: FullRule) => {
  linkifyIt.add(schema, rule);
};

export function generateShareLink({
  artistLinkValue,
  inviteCode = '',
  path = '',
}: {
  artistLinkValue: string | undefined | null;
  inviteCode: string | undefined | null;
  path: string | undefined | null;
}) {
  if (!artistLinkValue) return window.location.href;

  const url = new URL(window.location.href);
  const { host } = url;
  const isSubdomainPresent = host.startsWith(`${artistLinkValue}.`);

  if (IS_FINAL_DEPLOYMENT) {
    if (!isSubdomainPresent) {
      url.host = `${artistLinkValue}.${host}`;
    }
    url.pathname = path || '';
  } else {
    if (isSubdomainPresent) {
      url.host = host.replace(`${artistLinkValue}.`, '');
    }
    url.pathname = `/${artistLinkValue}${path || ''}`;
  }

  url.search = '';

  if (inviteCode) {
    url.searchParams.set('invite', inviteCode);
  }

  return url.href;
}

export function isValidSpotifyLink(url: string): boolean {
  const spotifyRegex = /^https:\/\/open\.spotify\.com\/(album|track)\/[a-zA-Z0-9]+(\?.*)?$/;
  return spotifyRegex.test(url);
}

export function createSpotifyUrl({
  id,
  type,
}: {
  id: string;
  type: ReleaseCampaignContentType;
}): string {
  return `https://open.spotify.com/${type === ReleaseCampaignContentType.Album ? 'album' : 'track'}/${id}`;
}

export function isValidSpotifyUri(uri: string): boolean {
  const spotifyRegex = /^spotify:(album|track):[a-zA-Z0-9]+$/;
  return spotifyRegex.test(uri);
}

export function isValidAppleMusicLink(url: string): boolean {
  const appleMusicRegex =
    /^https:\/\/music\.apple\.com\/[a-z]{2}\/(album|song)\/[^\/]+\/\d+(\?i=\d+)?$/;
  return appleMusicRegex.test(url);
}

export function extractAppleMusicResourceId(url: string | undefined | null): string | undefined {
  if (!url || !isValidAppleMusicLink(url)) return undefined;

  const urlObject = new URL(url);
  const pathParts = urlObject.pathname.split('/');
  const lastPathPart = pathParts[pathParts.length - 1];

  // Check for 'i' query parameter (track within album)
  const trackId = urlObject.searchParams.get('i');
  if (trackId) {
    return trackId;
  }

  // If no 'i' parameter, return the last numeric part of the path
  const match = lastPathPart?.match(/\d+/);
  return match ? match[0] : undefined;
}

export function extractSpotifyResourceIdFromUrl(
  url: string | undefined | null,
): string | undefined {
  if (!url || !isValidSpotifyLink(url)) return undefined;

  const urlObject = new URL(url);
  const pathParts = urlObject.pathname.split('/');

  // The ID is the last part of the pathname
  return pathParts[pathParts.length - 1];
}

export function extractSpotifyResourceIdFromUri(
  uri: string | undefined | null,
): string | undefined {
  if (!uri || !isValidSpotifyUri(uri)) return undefined;

  const uriParts = uri.split(':');
  return uriParts[2];
}

export function isValidISRC(isrc: string): boolean {
  const isrcRegex = /^[a-zA-Z]{2}[a-zA-Z0-9]{3}[0-9]{7}$/;
  return isrcRegex.test(isrc);
}

export function isValidUPC(value: string): boolean {
  // If the value is 14 characters long, remove the first digit
  const code = value.length === 14 ? value.slice(1) : value;

  const digits = () => /^\d{8,13}$/g.test(code);
  const validlengths = [8, 12, 13];
  if (!digits() || !validlengths.includes(code.length)) return false;

  let checksum = 0;
  const codelist = code.split('');
  const checkdigit = parseInt(codelist.pop() ?? '0', 10);
  codelist.map((value, index) => {
    const digit = parseInt(value, 10);
    if (code.length % 2 === 1) checksum += index % 2 ? digit * 3 : digit;
    else checksum += index % 2 ? digit : digit * 3;
  });

  let check = checksum % 10;
  if (check !== 0) check = 10 - check;
  if (check === checkdigit) return true;
  return false;
}

export const checkLinkValidity = (linkToScan: string, isPresave: boolean) => {
  if (isPresave) {
    return (
      isValidSpotifyLink(linkToScan) ||
      isValidAppleMusicLink(linkToScan) ||
      isValidISRC(linkToScan) ||
      isValidUPC(linkToScan)
    );
  } else {
    return isValidSpotifyLink(linkToScan) || isValidAppleMusicLink(linkToScan);
  }
};
