import { type FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { EditTrackSplitsView } from '../../components/views/EditTrackSplitsView';
import { useToast } from '../../contexts/ToastContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { validateEmail } from '../../utils/stringUtils';
import { setSplitData, SplitData, type SplitDetails } from './TrackSplitsPage';

export const EditTrackSplitsPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openToast } = useToast();

  const { artistHandle } = useArtistHandle();
  const { vaultContentId } = useParams();

  const id = searchParams.get('id');

  useVaultTheme();

  const currentSplitDetails =
    vaultContentId != null && id != null ? SplitData[vaultContentId]?.[id] : null;

  const [name, setName] = useState<string>(currentSplitDetails?.name || '');
  const [email, setEmail] = useState<string>(currentSplitDetails?.emailAddress || '');
  const [role, setRole] = useState<string>(currentSplitDetails?.role || '');
  const [splitPercent, setSplitPercent] = useState<string>(
    (currentSplitDetails != null ? currentSplitDetails?.percentBps / 100 : 0).toString(),
  );
  const [errorText, setErrorText] = useState<string | null>(null);

  useSetMetaHeaders({
    title: 'Editing track splits',
  });

  useEffect(() => {
    if (!vaultContentId || !artistHandle) return;

    /**
     * Redirect to the splits page if the email address is not found in the split data for edits
     */
    if (!id || !SplitData[vaultContentId]?.[id]) {
      navigate(artistNavigationPath(artistHandle, `/splits/${vaultContentId}`));
    }
  }, [vaultContentId, navigate, id, artistHandle]);

  const onSaveClick = useStableCallback(async () => {
    if (!vaultContentId || !id || !artistHandle) return;

    const numberSplitPercent = Number(splitPercent);

    const cleanNumberSplitsPercent = Number(numberSplitPercent.toFixed(2));

    const data = {
      id,
      name,
      emailAddress: email,
      role,
      percentBps: Math.floor(cleanNumberSplitsPercent * 100),
      isOwnSplit: currentSplitDetails?.isOwnSplit ?? false,
      isNew: false,
    } satisfies SplitDetails;

    if (email && !validateEmail(email)) {
      const error = 'Please enter a valid email address';
      trackEvent({
        type: 'Save Split Change Validation Error',
        properties: {
          artistHandle,
          vaultContentId,
          data,
          error,
        },
      });
      setErrorText(error);
      return;
    }

    if (!isFinite(numberSplitPercent)) {
      const error = 'Split percent must be a number';
      setErrorText(error);
      trackEvent({
        type: 'Save Split Change Validation Error',
        properties: {
          artistHandle,
          vaultContentId,
          data,
          error,
        },
      });
      return;
    }

    if (!name || !email || !role) {
      const error = 'Please fill out all fields';
      trackEvent({
        type: 'Save Split Change Validation Error',
        properties: {
          artistHandle,
          vaultContentId,
          data,
          error,
        },
      });
      setErrorText(error);
      return;
    }

    if (Number(splitPercent) < 0) {
      const error = 'Split percent must be at least 0';
      trackEvent({
        type: 'Save Split Change Validation Error',
        properties: {
          artistHandle,
          vaultContentId,
          data,
          error,
        },
      });
      setErrorText(error);
      return;
    }

    trackEvent({
      type: 'Save Split Change',
      properties: {
        artistHandle,
        vaultContentId,
        data,
      },
    });

    setSplitData({
      ...data,
      vaultContentId,
      isNew: false,
    });
    openToast({
      text: 'Your split percentage has been adjusted.',
    });
    navigate(-1);
  });

  /**
   * Getting redirected on an effect, this is to prevent flashing
   */
  if (id && !currentSplitDetails) return null;

  if (id == null || vaultContentId == null || artistHandle == null) return null;

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      headerLeft={<BackButton className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">
          Add collaborator
        </Text>
      }
      headerRight={
        <div onClick={onSaveClick}>
          <Text className="font-title !text-base-m font-medium text-vault_accent hover:cursor-pointer active:opacity-70">
            Save
          </Text>
        </div>
      }
    >
      <EditTrackSplitsView
        vaultContentId={vaultContentId}
        splitId={id}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        role={role}
        setRole={setRole}
        splitPercent={splitPercent}
        setSplitPercent={setSplitPercent}
        isOwnSplit={currentSplitDetails?.isOwnSplit ?? false}
      />
      {errorText != null && (
        <Text className="mt-3 text-center font-base text-base-m font-normal text-destructive300">
          {errorText}
        </Text>
      )}
    </DefaultLayout>
  );
};
