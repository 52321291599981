import React, { type FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faChevronDown } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { SPLIT_ROLES } from '../../constants/splitConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { SplitData } from '../../screens/vault/TrackSplitsPage';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  vaultContentId: string;
  splitId: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  splitPercent: string;
  setSplitPercent: React.Dispatch<React.SetStateAction<string>>;
  isOwnSplit: boolean;
};

export const EditTrackSplitsView: FC<Props> = ({
  vaultContentId,
  splitId,
  name,
  setName,
  email,
  setEmail,
  role,
  setRole,
  splitPercent,
  setSplitPercent,
  isOwnSplit,
}: Props) => {
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();

  const onRemoveClick = () => {
    openBottomsheet({
      type: 'CONFIRMATION',
      confirmationBottomsheetProps: {
        onConfirm: () => {
          const split = SplitData[vaultContentId];

          if (!split) return;

          delete split[splitId];

          navigate(-1);
        },
        subText: `Are you sure you want to remove this split${name.length > 0 ? ` for ${name}` : ''}?`,
      },
    });
  };

  return (
    <View className="w-full">
      <View className="mx-4 my-3 flex flex-col gap-5">
        <NameInput name={name} setName={setName} />
        <InputDivider />
        <EmailInput email={email} setEmail={setEmail} isOwnSplit={isOwnSplit} />
        <InputDivider />
        <RoleInput role={role} setRole={setRole} />
        <InputDivider />
        <SplitPercentInput splitPercent={splitPercent} setSplitPercent={setSplitPercent} />
        <InputDivider />
        {!isOwnSplit && (
          <View className="flex justify-center" onClick={onRemoveClick}>
            <Text className="flex-shrink !text-base-l font-normal text-destructive300 hover:cursor-pointer">
              Remove collaborator
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const NameInput = ({
  name,
  setName,
}: {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <View className="flex w-full flex-col">
      <Text className="mb-4 text-[16px] font-normal text-vault_text">Name</Text>
      <input
        className="h-[24px] w-full border-0 bg-vault_background !text-base-l text-vault_text focus:font-normal focus:outline-none"
        placeholder="Enter name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
    </View>
  );
};

const EmailInput = ({
  email,
  setEmail,
  isOwnSplit,
}: {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isOwnSplit: boolean;
}) => {
  return (
    <View className="flex w-full flex-col">
      <Text className="mb-4 text-[16px] font-normal text-vault_text">Email</Text>
      <input
        className={clsx(
          'h-[24px] w-full resize-none border-0 bg-vault_background font-base !text-base-l focus:font-normal focus:outline-none',
          isOwnSplit ? 'text-vault_text/50' : 'text-vault_text',
        )}
        placeholder="Enter email"
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        disabled={isOwnSplit}
      />
    </View>
  );
};

// turn into drop down
const RoleInput = ({
  role,
  setRole,
}: {
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const onSelectRoleOption = (role: string) => {
    setRole(role);
    closeBottomsheet();
  };

  const buttons: ActionBottomsheetProps['buttons'] = Object.values(SPLIT_ROLES).map(role => ({
    label: role,
    onClick: () => onSelectRoleOption(role),
    type: 'secondary',
  }));

  const onDropdownClick = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        withVaultTheme: false,
      },
    });
  });

  return (
    <View className="flex w-full flex-col">
      <Text className="mb-4 text-[16px] font-normal text-vault_text">Role</Text>
      <View
        className="flex w-full flex-row justify-between hover:cursor-pointer"
        onClick={onDropdownClick}
      >
        <Text
          className={twMerge(
            'h-[24px] w-full resize-none border-0 font-base !text-base-l focus:font-normal focus:outline-none',
            'bg-vault_background text-vault_text',
            role.length === 0 && 'text-vault_text/50',
          )}
        >
          {role.length > 0 ? role : `Enter role`}
        </Text>
        <FontAwesomeIcon icon={faChevronDown} className="text-vault_text/50" />
      </View>
    </View>
  );
};

const SplitPercentInput = ({
  splitPercent,
  setSplitPercent,
}: {
  splitPercent: string;
  setSplitPercent: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <View className="flex w-full flex-col">
      <Text className="mb-4 text-[16px] font-normal text-vault_text">Split (%)</Text>
      <input
        className={twMerge(
          'h-[24px] w-full resize-none border-0 font-base !text-base-l [appearance:textfield] focus:font-normal focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
          'bg-vault_background text-vault_text',
        )}
        placeholder="Enter split percentage"
        value={splitPercent}
        onChange={e => setSplitPercent(e.target.value)}
        type="number"
      />
    </View>
  );
};

const InputDivider = () => {
  return <View className="flex h-[1px] w-full flex-row bg-vault_text/5" />;
};
