import {
  type ChangeEventHandler,
  type FormEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoginWithSms } from '@privy-io/react-auth';
import * as Popover from '@radix-ui/react-popover';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { useLocation, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { proxy } from 'valtio';
import { faArrowRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { View } from '../../components/common/View';
import { COLOR } from '../../constants/colorConstants';
import { VAULT_LOGO_WHITE_PNG } from '../../constants/imageConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { ArtistLandingFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { getTextColor, hexToColorSpace } from '../../hooks/useVaultTheme';
import { SignInStore, THIRTY_SECONDS_IN_MS } from '../../screens/auth/store';
import { Sentry } from '../../sentry';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { isGrayScale } from '../../utils/colorUtils';
import { getFullDate } from '../../utils/dateUtils';
import { wrapSendCode } from '../../utils/privy';
import { constructQueryParams } from '../../utils/stringUtils';
import { formatSerialNumber } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { ErrorView } from '../error/ErrorView';
import { CodeInput } from '../forms/CodeInput';
import { Item, Select } from '../forms/Select';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';

gql(/* GraphQL */ `
  fragment artistLanding on Artist {
    id
    name
    linkValue
    profileImage {
      id
      url
      dominantColor
    }
  }
`);

export const lurkState = proxy({ isLurking: false });

export function VaultLandingView({
  artistFrag,
}: {
  artistFrag: FragmentType<typeof ArtistLandingFragmentDoc>;
}) {
  const [view, setView] = useState<'join' | 'verify'>('join');

  const { id, name, profileImage } = getFragment(ArtistLandingFragmentDoc, artistFrag);

  const dominantColor = profileImage?.dominantColor;

  const randomMemberId = useMemo(
    () => formatSerialNumber(Math.floor(Math.random() * 10000000)),
    [],
  );

  const joinedAt = useMemo(() => getFullDate(new Date()), []);

  const background = useMemo(() => {
    return (
      <>
        {profileImage?.url != null ? (
          <Image
            src={profileImage.url}
            alt="Blurred Profile Image"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-70 blur-3xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={id}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-80 blur-3xl filter"
          />
        )}

        <View
          className="absolute bottom-0 z-base aspect-square h-full w-full bg-gradient-to-b from-transparent to-black"
          style={{
            '--tw-gradient-from': `rgba(${hexToColorSpace(dominantColor ?? COLOR.black)},0.1) var(--tw-gradient-from-position)`,
          }}
        />
      </>
    );
  }, [dominantColor, id, profileImage?.url]);

  if (view === 'verify') {
    return (
      <View className="box-border flex h-full w-full items-center justify-center overflow-hidden px-6">
        {background}

        <View className="no-scrollbar relative z-above1 mb-11 h-[390px] max-h-fit min-h-[390px] w-full flex-col scroll-auto rounded-xl bg-white p-3 md2:h-[422px] md2:min-h-[422px] md2:max-w-[412px] md2:p-4">
          <Button
            label=""
            leadingIcon={faAngleLeft}
            className="mt-2 h-6 w-6 self-start text-[21px] text-black"
            onClick={() => setView('join')}
            iconOnly
          />

          <VerifyForm dominantColor={dominantColor} />
        </View>
      </View>
    );
  }

  return (
    <View className="box-border flex h-full w-full items-center justify-center overflow-hidden px-6">
      {background}

      <View className="no-scrollbar relative z-above1 mb-11 h-[438px] max-h-fit min-h-[438px] w-full flex-col scroll-auto rounded-xl bg-white p-3 md2:h-[466px] md2:min-h-[466px] md2:max-w-[412px] md2:p-4">
        <View className="relative aspect-square h-auto max-h-52 min-h-52 w-full overflow-clip rounded-lg md2:h-60 md2:max-h-60">
          {profileImage?.url != null ? (
            <Image
              src={profileImage.url}
              alt={name}
              className="z-below aspect-square w-full object-cover object-left-top"
            />
          ) : (
            <UserPlaceholderImage id={id} className="z-below aspect-square w-full" />
          )}
          <View className="absolute top-0 z-base aspect-square w-full bg-gradient-to-b from-black to-transparent to-40%" />
          <View className="absolute top-10 z-base aspect-square w-full bg-gradient-to-b from-transparent to-black to-60%" />

          <View className="absolute left-0 right-0 top-4 box-border flex h-auto w-full items-start justify-between px-4">
            <Text className="font-title text-[16px]/[21px] text-white md2:text-[24px]/[25px]">
              {name}
            </Text>

            <View className="flex items-center gap-2">
              <Text className="font-title text-[9px] text-white md2:text-[13px]/[20px]">
                {randomMemberId}
              </Text>

              <Image
                width={24}
                height={28}
                src={VAULT_LOGO_WHITE_PNG}
                className="mb-[2px] rotate-90 object-contain"
                alt="Logo"
              />
            </View>
          </View>

          <View className="absolute bottom-4 left-0 right-0 box-border flex h-auto w-full flex-col items-start gap-[2px] px-4 font-title text-[9px] md2:text-[13px]">
            <Text className="text-white">@yourname</Text>
            <Text className="text-white/50">Joined {joinedAt}</Text>
          </View>
        </View>

        <Text className="mt-4 text-center font-title text-[30px]/[40px] text-black">
          Join the inner circle
        </Text>

        <Text className="mb-6 mt-2 text-center font-base text-[14px]/[20px] text-black">
          Get notified about first access to music,
          <br />
          merch, tickets + all things {name}.
        </Text>

        <SignInForm dominantColor={dominantColor} setView={setView} />

        <Text className="mt-6 w-full text-center font-base text-[12px]/[14px] font-normal text-black/70">
          By signing up, you agree to the{' '}
          <a
            href={TOS_URL}
            target="_blank"
            className="text-black/70 no-underline hover:cursor-pointer"
          >
            Terms
          </a>{' '}
          &{' '}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            className="text-black/70 no-underline hover:cursor-pointer"
          >
            Privacy Policy
          </a>
          .
        </Text>

        <button
          className="absolute -bottom-12 left-0 right-0 flex cursor-pointer appearance-none items-center justify-center gap-2 border-none bg-transparent p-2"
          onClick={() => (lurkState.isLurking = true)}
        >
          <Text className="font-base text-[14px]/[18px] text-white/70">Take a look around...</Text>

          <FontAwesomeIcon icon={faArrowRight} className="text-white/70" />
        </button>
      </View>
    </View>
  );
}

export function VaultLandingError({ refetch }: { refetch: () => void }) {
  return (
    <View className="flex h-full w-full items-center justify-center">
      <ErrorView onRetryClick={refetch} loggingType="vault_landing_page" withVaultTheme={false} />
    </View>
  );
}

export function VaultLandingLoading() {
  return (
    <View className="box-border flex h-full w-full items-center justify-center px-6">
      <View className="h-auto max-h-[500px] w-full flex-col rounded-xl bg-white p-3 md2:max-w-[412px] md2:p-4">
        <LoadingSkeleton className="h-52 w-full rounded-lg bg-black/10 md2:h-64" />

        <LoadingSkeleton className="mt-6 h-[30px] bg-black/10" />
        <LoadingSkeleton className="mt-3 h-[14px] bg-black/10" />

        <LoadingSkeleton className="mt-5 h-[52px] bg-black/10" />
      </View>
    </View>
  );
}

function SignInForm({
  dominantColor,
  setView,
}: {
  dominantColor: string | null | undefined;
  setView: (view: 'join' | 'verify') => void;
}) {
  const buttonDominantColor = !!dominantColor
    ? isGrayScale(dominantColor)
      ? COLOR.black
      : dominantColor
    : undefined;

  const { pathname } = useLocation();

  const {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
  } = useSignIn();

  const hasError = errorText != null;

  const {
    sendCode,
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const isSubmitLoading = status === 'sending-code' || status === 'submitting-code';
  const isSubmitDisabled =
    isSubmitLoading ||
    (codeSentDisabledSecondsRemaining !== 0 && !!validPhoneNumber && codeRenabled !== 1);

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;

    trackEvent({ type: EVENTS.NEXT, properties: { type: 'Sign In' }, pathname });

    if (!validPhoneNumber) {
      setErrorText('This phone number cannot be used for verification');
      return;
    }

    try {
      setErrorText(null);
      await wrapSendCode(() => sendCode({ phoneNumber: validPhoneNumber }));

      const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;

      SignInStore.produceExistingState(
        draft => {
          draft.lastActivePhoneNumber = validPhoneNumber;
          draft.codeRenabled[validPhoneNumber] = codeRenabled;
        },
        {
          codeRenabled: {
            [validPhoneNumber]: codeRenabled,
          },
          lastActivePhoneNumber: validPhoneNumber,
        },
      );

      setView('verify');
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          errorText: 'We encountered an error sending your verification code',
          phoneNumber: validPhoneNumber,
        },
      });
      setErrorText('We encountered an error sending your verification code');
      return;
    }
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  return (
    <View className="box-border w-full">
      <form onSubmit={onNextClick} className="box-border flex w-full items-center">
        <View className="box-content flex w-full flex-col rounded-l-full border border-solid border-black/5 py-4">
          <View className="relative box-border flex items-center gap-7 overflow-hidden">
            <Popover.Root open={hasError}>
              <Popover.Anchor asChild>
                <Select
                  value={COUNTRY_CODES.find(({ code }) => code === countryCode)?.code ?? ''}
                  onValueChange={setCountryCode}
                  itemProps={{
                    className:
                      'bg-transparent text-black hover:bg-transparent focus:bg-transparent',
                  }}
                  className="w-[5em] bg-transparent p-0 font-base !text-base-l font-normal text-black hover:bg-transparent"
                  contentClassName="w-[5em] border border-solid border-black/5 bg-white/90 backdrop-blur-2xl"
                  onOpenChange={open => {
                    setIsOpen(open);
                    setErrorText(null);
                  }}
                  disabled={status === 'sending-code'}
                >
                  {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                    <Item
                      key={code}
                      value={code}
                      className="box-border flex w-[5em] flex-row justify-center bg-transparent py-0 pl-0 font-base !text-base-l font-normal text-black hover:bg-transparent focus:bg-transparent"
                      dropDownClassName="box-border justify-center overflow-x-clip rounded-sm bg-transparent pr-1 font-base !text-base-l font-normal hover:bg-transparent focus:bg-transparent"
                    >
                      <Text className="mx-2 text-[24px]">{flag}</Text>
                      <Text className="pr-2 text-[12px] md2:pr-0">{dial_code}</Text>
                    </Item>
                  ))}
                </Select>
              </Popover.Anchor>
              <Popover.Portal>
                <Popover.Content
                  side="top"
                  sideOffset={26}
                  align="start"
                  className="z-bottomsheet box-border flex max-w-[320px] flex-col self-center rounded-lg bg-destructive300 p-1 px-2 outline-none will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade md2:w-full md2:max-w-none"
                >
                  <Text className="p-3 text-left font-base !text-base-m font-normal text-white">
                    {errorText}
                  </Text>

                  <Popover.Arrow className="fill-destructive300" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>

            <input
              type="tel"
              value={phone}
              placeholder="Phone number"
              onChange={e => {
                onChange(e);
                setErrorText(null);
              }}
              disabled={status === 'sending-code'}
              autoFocus
              className={twMerge(
                'w-full border-none bg-transparent font-base !text-base-l font-normal placeholder:text-black/50 focus:border-none focus:outline-none',
                hasError ? 'text-destructive300' : 'text-black',
              )}
            />
          </View>
        </View>

        <Button
          label="Join"
          type="primary"
          buttonType="submit"
          loading={isSubmitLoading}
          disabled={isSubmitDisabled}
          disabledClassName="opacity-30"
          className="w-full max-w-[80px] !rounded-l-none border border-solid border-black/5 md2:w-[112px] md2:max-w-[112px]"
          style={
            buttonDominantColor
              ? { backgroundColor: buttonDominantColor, color: getTextColor(buttonDominantColor) }
              : undefined
          }
          event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
        />
      </form>
    </View>
  );
}

function VerifyForm({ dominantColor }: { dominantColor: string | null | undefined }) {
  const { loginStatus, authError } = useAuthContext();
  const [errorText, setErrorText] = useState<string | null>(null);

  const [_, setSearchParams] = useSearchParams();

  const {
    sendCode,
    loginWithCode,
    state: { status },
  } = useLoginWithSms();

  useEffect(() => {
    if (authError != null) {
      setErrorText('We encountered an error creating your profile');
    }
  }, [authError]);

  const buttonDominantColor = !!dominantColor
    ? isGrayScale(dominantColor)
      ? COLOR.black
      : dominantColor
    : undefined;

  const signInState = SignInStore.useStore().value;

  const phoneNumber = signInState?.lastActivePhoneNumber;

  const hasError = errorText != null;

  const codeRenabled = signInState?.codeRenabled[phoneNumber ?? '_'] ?? 1;

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const onResendCodeClick = async () => {
    if (!phoneNumber || codeSentDisabledSecondsRemaining > 0) return;

    try {
      await wrapSendCode(() => sendCode({ phoneNumber: `+${phoneNumber}` }));

      const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;
      SignInStore.produceExistingState(
        draft => {
          draft.codeRenabled[phoneNumber] = codeRenabled;
          draft.lastActivePhoneNumber = phoneNumber;
        },
        {
          codeRenabled: { [phoneNumber]: codeRenabled },
          lastActivePhoneNumber: phoneNumber,
        },
      );
    } catch (e) {
      setErrorText('We encountered an error sending your verification code');

      return;
    }
  };

  const tryLogin = async (code: string): Promise<void> => {
    try {
      await loginWithCode({ code });
      // Still loading, need to wait for AuthProvider loginStatus to update
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          phoneNumber,
        },
      });
      setErrorText('We encountered an error verifying your code');

      return;
    }
  };

  const onChange = (value: string) => {
    const newVal = value.replace(/\D/g, '').replace(/\s/g, ''); // Remove non-digit characters

    if (newVal.length === 6) {
      void tryLogin(newVal);
    }

    setErrorText(null);
  };

  useEffect(() => {
    const queryParams = constructQueryParams({
      openBottomSheet: 'freeTierModal',
    });

    setSearchParams(queryParams);
  }, [setSearchParams]);

  return (
    <View className="box-border flex h-full flex-col items-center justify-center pb-10">
      <Text className="text-center font-title text-[24px]/[30px] text-black md2:text-[30px]/[40px]">
        Enter the code we sent
      </Text>

      <Text className="mb-6 mt-2 text-center font-base text-[14px]/[20px] text-black/80">
        Sent SMS verification {!phoneNumber ? '' : `to ${phoneNumber}`}
      </Text>

      <View className="box-border flex w-full flex-col items-center justify-center">
        <CodeInput
          autoFocus
          length={6}
          placeholder="_"
          onChange={onChange}
          allowedCharacters="numeric"
          hasError={hasError}
          variant="default"
        />

        <Button
          label="Resend"
          type="primary"
          onClick={onResendCodeClick}
          disabled={
            codeSentDisabledSecondsRemaining > 0 ||
            status === 'sending-code' ||
            status === 'submitting-code' ||
            isInCooldown ||
            loginStatus === LoginStatus.LOADING
          }
          disabledClassName="opacity-30"
          loading={
            status === 'sending-code' ||
            status === 'submitting-code' ||
            loginStatus === LoginStatus.LOADING
          }
          event={{ type: EVENTS.RESEND_CODE, properties: null }}
          className="mt-5 w-full max-w-[180px] border border-solid border-black/5"
          style={
            buttonDominantColor
              ? { backgroundColor: buttonDominantColor, color: getTextColor(buttonDominantColor) }
              : undefined
          }
        />

        <Text
          className={twMerge(
            'mt-6 text-center font-base text-[14px]/[20px]',
            hasError ? 'text-destructive300' : 'text-black/80',
          )}
        >
          {hasError
            ? errorText
            : codeSentDisabledSecondsRemaining > 0
              ? `Didn't receive the code? Resend in ${codeSentDisabledSecondsRemaining}s`
              : "Didn't receive the code? Resend"}
        </Text>
      </View>
    </View>
  );
}
