import { useEffect } from 'react';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import { fetchGQL, useMutation } from '../../../graphql/client';
import { SetUserLastViewedTimeOnVaultDocument } from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';

gql(/* GraphQL */ `
  mutation SetUserLastViewedTimeOnVault($vaultId: UUID!) {
    setUserLastViewedTimeOnVault(vaultId: $vaultId) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

export function useVaultEngagement({
  vaultId,
  triggerQuery,
}: {
  vaultId: string | undefined;
  triggerQuery: boolean;
}) {
  const { loggedInUser } = useAuthContext();

  const { mutateAsync, isLoading } = useMutation(SetUserLastViewedTimeOnVaultDocument, {
    retry: 3,
  });

  const setUserEngagement = useStableCallback(async () => {
    if (!vaultId || !loggedInUser) return;

    try {
      await mutateAsync({ vaultId });
    } catch (error) {
      // do nothing
    }
  });

  /*
   * We need to call this mutation twice, once when the user enters the channel page,
   * and another when the user leaves the channel page
   */
  useEffect(() => {
    if (!loggedInUser || !vaultId || !triggerQuery) return;

    const engagement = setTimeout(() => {
      setUserEngagement();
    }, 1000);

    return () => {
      clearTimeout(engagement);
      setUserEngagement();
    };
  }, [loggedInUser, vaultId, setUserEngagement, triggerQuery]);

  useEffect(() => {
    if (!loggedInUser || !vaultId || !triggerQuery) return;

    const handleOnBeforeUnload = async () => {
      await fetchGQL(SetUserLastViewedTimeOnVaultDocument, {
        variables: { vaultId },
        keepalive: true,
      });
      return;
    };

    window.addEventListener('beforeunload', handleOnBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload);
    };
  }, [loggedInUser, vaultId, setUserEngagement, triggerQuery]);

  return { setUserEngagement, isLoading };
}
