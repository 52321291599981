import type { FC } from 'react';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toPng } from 'html-to-image';
import { faArrowDownToBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useToast } from '../../contexts/ToastContext';
import type { ReceiptBottomSheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { ReceiptUI } from '../membership/ReceiptUI';

const ReceiptBottomSheet: FC<ReceiptBottomSheetProps> = props => {
  const receiptRef = useRef(null);
  const { openToast } = useToast();

  const htmlToImageConvert = () => {
    if (!receiptRef.current) return;
    toPng(receiptRef.current, { cacheBust: false })
      .then(dataUrl => {
        const link = document.createElement('a');
        link.download = `${props.title}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(() => {
        openToast({
          text: 'There was an error downloading the receipt. Please try again.',
          variant: 'error',
        });
      });
  };
  return (
    <View className="mb-4 mt-5 flex h-[70vh] w-full flex-col items-center gap-10 overflow-hidden rounded-xl lg:h-full">
      <div className="flex h-[408px] w-[300px]">
        <div ref={receiptRef} className="scale-receipt-xl  w-[300px]">
          <ReceiptUI {...props} />
        </div>
      </div>

      <div className="mt-auto flex">
        <button
          aria-label="Download receipt"
          className="flex aspect-square h-11 w-11 cursor-pointer items-center justify-center rounded-lg border-none bg-base600 outline-none"
          onClick={htmlToImageConvert}
        >
          <FontAwesomeIcon icon={faArrowDownToBracket} className="text-[20px] text-vault_text" />
        </button>
      </div>
    </View>
  );
};

export { ReceiptBottomSheet };
