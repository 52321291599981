import { useEffect } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { VAULT_FM_APP_STORE_URL } from '../../constants/urlConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import type { ShareSnippetInterstitialBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function ShareSnippetInterstitialBottomsheet({
  snippetVideo,
  trackId,
  onContinue,
}: ShareSnippetInterstitialBottomsheetProps) {
  const { pathname } = useLocation();
  const { closeBottomsheet, isOpen } = useBottomsheetContainer();
  const { updateUpsellInterstitials, setShowedInSession } = useUpsellInterstitials();

  const onGetAppClick = () => {
    trackEvent({
      type: EVENTS.GET_APP,
      properties: {
        trackId,
        interstitial: 'first_snippet_share',
        vaultId: null,
      },
      pathname,
    });

    updateUpsellInterstitials({
      input: {
        firstSnippetShare: true,
      },
    });

    closeBottomsheet();
  };

  const onContinueInBrowserClick = () => {
    trackEvent({
      type: EVENTS.CONTINUE_ON_BROWSER,
      properties: {
        interstitial: 'first_snippet_share',
        vaultId: null,
      },
      pathname,
    });

    updateUpsellInterstitials({
      input: {
        firstSnippetShare: true,
      },
    });

    onContinue?.();
  };

  // Trigger the mutation on close
  useEffect(() => {
    if (!isOpen) {
      updateUpsellInterstitials({
        input: {
          firstSnippetShare: true,
        },
      });

      onContinue?.();
      // Avoid showing more than one interstitial in the same session
      setShowedInSession(true);
    }
  }, [isOpen, onContinue, setShowedInSession, updateUpsellInterstitials]);

  return (
    <View className="w-full px-4 md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View
        className={twMerge(
          'flex w-full flex-col items-center justify-center gap-6',
          isMobileSafari ? 'mb-[20px]' : 'my-[20px]',
        )}
      >
        <video
          className="aspect-[79/140] rounded-lg border-1.5 border-solid border-white"
          height="auto"
          width="168px"
          controls={false}
          preload="auto"
          src={snippetVideo}
          playsInline
          autoPlay
          muted
          loop
        />

        <View className="flex flex-col items-center justify-center gap-3 px-6">
          <Text className="text-center font-title text-title-l font-medium text-white">
            Access exclusive sharing features on the app
          </Text>
          <Text className="text-center font-base text-base-m text-base400">
            The Vault app lets you create and share
            <br />
            videos to instagram stories.
          </Text>
        </View>

        <View className="flex w-4/5 flex-col items-center justify-center gap-6 px-6">
          <Button
            label="Get app"
            isExternal
            href={VAULT_FM_APP_STORE_URL}
            type="primary"
            className="w-full"
            linkClassName="w-full"
            onClick={onGetAppClick}
          />

          <Button
            label="Continue in browser"
            onClick={onContinueInBrowserClick}
            type="default"
            className="w-full !font-title !text-title-s !font-medium text-white"
          />
        </View>
      </View>
    </View>
  );
}
