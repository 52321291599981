import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parsePhoneNumberFromString from 'libphonenumber-js/min';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCopy } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { TEXT_MESSAGE_LIMIT } from '../../constants/phoneConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import {
  ArtistProfileSettingsDocument,
  ArtistSettingsViewFragmentDoc,
  getFragment,
  SetVaultWelcomeMessageDocument,
} from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';

gql(/* GraphQL */ `
  mutation SetVaultWelcomeMessage($input: MutationSetVaultWelcomeMessageInput!) {
    setVaultWelcomeMessage(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationSetVaultWelcomeMessageSuccess {
        data {
          id
          welcomeMessage
        }
      }
    }
  }
`);

export function SmsSettingsPage() {
  const { loginStatus, loggedInUser } = useAuthContext();

  const { data, isLoading, error, refetch } = useQuery(ArtistProfileSettingsDocument, {
    staleTime: 0,
    enabled: loginStatus === LoginStatus.LOGGED_IN && !!loggedInUser?.artist?.id,
    variables: !!loggedInUser?.artist?.id && { artistId: loggedInUser.artist.id },
  });

  if (loginStatus === LoginStatus.LOADING || isLoading) return <LoadingSkeleton />;
  if (error) return <ErrorView withVaultTheme={false} onRetryClick={refetch} />;

  const artistFragment = getFragment(ArtistSettingsViewFragmentDoc, data?.data.artistById);

  const artistPhoneNumber = artistFragment?.phoneNumbers[0];
  const vaultId = artistFragment?.mainVault?.id;
  const artistName = artistFragment?.name ?? loggedInUser?.artist?.mainLinkValue;
  const vaultWelcomeMessage =
    artistFragment?.mainVault?.welcomeMessage ||
    `Hey, it's ${artistName}. Thanks so much for joining my inner circle. I'll text you about new music, shows near you, and other exclusive updates.`;

  if (
    loginStatus === LoginStatus.LOGGED_OUT ||
    !loggedInUser?.artist?.mainLinkValue ||
    !vaultId ||
    !artistName
  )
    return <Navigate to={ROUTES.NOT_FOUND} />;

  const formattedPhoneNumber = artistPhoneNumber
    ? parsePhoneNumberFromString(
        artistPhoneNumber.phoneNumber,
        artistPhoneNumber.phoneCountryCode,
      )?.formatInternational() ?? artistPhoneNumber.phoneNumber
    : null;

  return (
    <SmsSettings
      formattedPhoneNumber={formattedPhoneNumber}
      vaultWelcomeMessage={vaultWelcomeMessage}
      vaultId={vaultId}
      artistHandle={loggedInUser?.artist?.mainLinkValue}
      refetch={refetch}
    />
  );
}

// TODO: confirmation modal on back button (should probably add this as an option on SettingsLayout as it's needed for user settings page too)
function SmsSettings({
  formattedPhoneNumber,
  vaultWelcomeMessage,
  vaultId,
  artistHandle,
  refetch,
}: {
  formattedPhoneNumber: string | null | undefined;
  vaultWelcomeMessage: string | null | undefined;
  vaultId: string;
  artistHandle: string;
  refetch: () => void;
}) {
  const [welcomeMessage, setWelcomeMessage] = useState(vaultWelcomeMessage || '');
  const { openToast } = useToast();
  const {
    register,
    setValue,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      welcomeMessage,
    },
  });
  const [charCounter, setCharCounter] = useState(welcomeMessage.length);
  const { pathname } = useLocation();

  const { mutateAsync: setVaultWelcomeMessage } = useMutation(SetVaultWelcomeMessageDocument, {
    onError: e => {
      openToast({
        text: e.message,
        variant: 'error',
      });
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await setVaultWelcomeMessage({
        input: {
          vaultId,
          welcomeMessage,
        },
      });

      if (
        result.data?.setVaultWelcomeMessage.__typename === 'MutationSetVaultWelcomeMessageSuccess'
      ) {
        openToast({
          text: 'Welcome message updated successfully',
          variant: 'success',
        });

        // Reset the form state after successful submission
        reset({ welcomeMessage });
        refetch();

        trackEvent({
          type: EVENTS.SET_VAULT_WELCOME_MESSAGE,
          properties: { vaultId },
          pathname,
        });
      } else {
        throw new Error(result.data?.setVaultWelcomeMessage.message || 'An error occurred');
      }
    } catch (error) {
      openToast({
        text: 'Failed to update welcome message. Please try again.',
        variant: 'error',
      });
    }
  };

  const mailtoLink = `mailto:help@vault.fm?subject=Add%20a%20Vault%20Number&body=Hello,%20I%20don't%20currently%20have%20a%20vault%20number%20for%20my%20account%20and%20would%20like%20to%20set%20one%20up.%20My%20artist%20handle%20is:%20${encodeURIComponent(artistHandle)}.`;

  return (
    <SettingsLayout
      title="SMS settings"
      right={
        <Button
          type="default"
          onClick={handleSubmit}
          disabled={isSubmitting || !isDirty}
          disabledClassName="opacity-50"
          label="Save"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
        />
      }
    >
      <View className="flex w-full flex-col gap-6 pb-6">
        <View className="flex w-full flex-col gap-1">
          <Text className="font-title text-title-s text-white">Vault number</Text>
          {formattedPhoneNumber ? (
            <View
              className="mt-3 inline-flex cursor-pointer items-center justify-between rounded-[16px] bg-base800 px-6 py-5"
              onClick={() => {
                navigator.clipboard.writeText(formattedPhoneNumber);
                openToast({ text: 'Copied to clipboard', variant: 'success' });
              }}
            >
              <View className="inline-flex items-center">
                <FontAwesomeIcon icon={faMobile} className="mr-3 px-[4px] py-[2px] text-white" />
                <Text className="pr-3">{formattedPhoneNumber}</Text>
              </View>
              <View>
                <FontAwesomeIcon
                  icon={faCopy}
                  className="inline-block font-medium text-white"
                  fontSize={16}
                />
              </View>
            </View>
          ) : (
            // Prod artists **should** have a vault number, but if they don't we should handle it gracefully,
            // and most dev artist accounts don't have a vault number so we should handle that.
            <View className="flex w-full flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
              <Text className="text-body-m text-base400">
                Get a dedicated phone number to reach your audience
              </Text>
              <Button
                type="secondary"
                label="Contact us"
                className="w-[150px] min-w-[150px] justify-center rounded-full py-3"
                onClick={() => (window.location.href = mailtoLink)}
              />
            </View>
          )}
        </View>

        <form onSubmit={handleSubmit} className="flex w-full flex-col gap-4">
          <View className="flex flex-col gap-1">
            <Text className="font-title text-title-s text-white">Welcome text</Text>
            <Text className="text-body-m text-white/50">
              Fans receive this text when first joining your vault
            </Text>
          </View>
          <textarea
            value={welcomeMessage}
            aria-multiline="true"
            placeholder=""
            className={twMerge(
              'no-scrollbar min-h-[150px] resize-none rounded-md border border-solid border-white/20 bg-transparent p-3 font-base !text-base-l text-white outline-none placeholder:text-white/50',
              errors.welcomeMessage ? 'border-destructive300' : 'focus:border-white/50',
            )}
            maxLength={TEXT_MESSAGE_LIMIT}
            {...register('welcomeMessage')}
            onChange={e => {
              setWelcomeMessage(e.target.value);
              setValue('welcomeMessage', e.target.value, {
                shouldDirty: true,
              });
              setCharCounter(e.target.value.length);
            }}
          />
          <Text
            className={twMerge(
              'h-6 text-right font-base !text-base-s tabular-nums',
              !!errors.welcomeMessage ? 'text-destructive300' : 'text-white/50',
            )}
          >
            {charCounter}/{TEXT_MESSAGE_LIMIT}
          </Text>
        </form>
      </View>
    </SettingsLayout>
  );
}
