import { memo, useEffect } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { FullPageLoading } from '../components/views/FullPageLoading';
import { useAuthContext } from '../contexts/AuthContext';
import { fetchQuery, useMutation } from '../graphql/client';
import { GetSpotifyAuthStateRedirectDocument, SpotifyConnectDocument } from '../graphql/generated';
import {
  newSpotifyConnectState,
  SpotifyConnectState,
  useLinkSpotifyAccount,
} from '../hooks/spotify/useSpotifyAuth';
import { LoginStatus } from '../types/authTypes';
import { passiveExhaustiveGuard } from '../utils/guards';

function closeConnectFlow({ navigate }: { navigate: ReturnType<typeof useNavigate> }) {
  try {
    window.close();
  } catch (error) {
    captureException(error, {
      level: 'warning',
    });
  } finally {
    setTimeout(() => {
      navigate('/');
    }, 2000);
  }
}

export const SpotifyConnectPage = memo(function SpotifyConnectPage() {
  const navigate = useNavigate();

  const { mutateAsync: connectSpotify } = useMutation(SpotifyConnectDocument, {
    retry: 5,
  });

  const [_searchParams, setSearchParams] = useSearchParams();

  const { loginStatus } = useAuthContext();

  const linkSpotify = useLinkSpotifyAccount();

  useEffect(() => {
    if (loginStatus === LoginStatus.LOADING) return;

    SpotifyConnectState.initialValue.then(async initialValue => {
      const url = new URL(window.location.href);

      const state = url.searchParams.get('state');
      const code = url.searchParams.get('code');
      const error = url.searchParams.get('error');

      if (!state || !code) {
        return closeConnectFlow({ navigate });
      }

      const authStateRedirectCheck = await fetchQuery(GetSpotifyAuthStateRedirectDocument, {
        variables: {
          input: {
            state,
          },
        },
        retry: 5,
      })
        .then(({ data }) => {
          if (
            data.spotifyAuthStateRedirectUrl.__typename ===
            'QuerySpotifyAuthStateRedirectUrlSuccess'
          ) {
            const queryRedirectUrl = new URL(data.spotifyAuthStateRedirectUrl.data);
            const redirectUrl = new URL(window.location.href);

            if (redirectUrl.hostname !== queryRedirectUrl.hostname) {
              redirectUrl.hostname = queryRedirectUrl.hostname;

              window.location.href = redirectUrl.href;

              return 'redirecting' as const;
            } else {
              return 'connect-from-redirection' as const;
            }
          } else {
            return 'connect' as const;
          }
        })
        .catch(error => {
          captureException(error, {
            level: 'warning',
          });

          return 'connect-fallback' as const;
        });

      switch (authStateRedirectCheck) {
        case 'redirecting': {
          break;
        }
        case 'connect-fallback':
        case 'connect':
        case 'connect-from-redirection': {
          const storeState = initialValue?.state;

          /**
           * Protect against cross-site request forgery attacks
           */
          if (!storeState || state !== storeState) {
            return closeConnectFlow({ navigate });
          }

          function finalErrorState(error: unknown) {
            captureMessage('Spotify connect error', {
              level: 'warning',
              extra: {
                error,
                authStateRedirectCheck,
              },
            });

            setSearchParams(() => {
              return {};
            });

            newSpotifyConnectState({
              code: null,
            })
              .catch(error => {
                captureException(error, {
                  extra: {
                    authStateRedirectCheck,
                  },
                });
              })
              .finally(() => {
                // TODO: Spotify connection error modal
              });
          }

          if (error) {
            return finalErrorState(error);
          }

          const redirectUri = new URL('/spotify-connect', window.location.origin);

          redirectUri.hostname = import.meta.env.VITE_SPOTIFY_CONNECT_HOSTNAME;

          connectSpotify({
            input: {
              redirectUri: redirectUri.href,
              code,
            },
          })
            .then(connection => {
              if (connection.data.connectSpotify.__typename !== 'MutationConnectSpotifySuccess') {
                return finalErrorState(connection.data.connectSpotify);
              }

              return linkSpotify({
                authCode: code,
              })
                .then(() => {
                  return newSpotifyConnectState({
                    code,
                  });
                })
                .then(() => {
                  setTimeout(() => {
                    closeConnectFlow({ navigate });
                  }, 1500);
                })
                .catch(e => finalErrorState(e));
            })
            .catch(e => finalErrorState(e));
          break;
        }

        default: {
          passiveExhaustiveGuard(authStateRedirectCheck);
        }
      }
    });
  }, [navigate, connectSpotify, linkSpotify, loginStatus, setSearchParams]);

  return <FullPageLoading withVaultTheme={false} />;
});
