import { proxy } from 'valtio';
import { baseHeaders } from '../constants/env';

export type Headers = {
  'auth-token'?: string;
  'CF-Access-Client-Id'?: string;
  'CF-Access-Client-Secret'?: string;
  'x-vault-request-source': string;
};

export const graphqlClientHeaders: Headers = proxy({ ...baseHeaders });
