import { useEffect, useRef } from 'react';
import { MediaType } from '../../graphql/generated';
import { useWindow } from '../../hooks/useWindow';
import { Image } from '../common/Image';
import { View } from '../common/View';

type Media = {
  id: string;
  url: string;
  type: MediaType;
  childrenMedia: { id: string; url: string; mediaType: MediaType }[];
};

export const MediaStack = ({
  isAuthor,
  medias,
  onView,
}: {
  isAuthor: boolean | undefined;
  medias: Media[];
  onView: (startAt?: number) => void;
}) => {
  const { isDesktop } = useWindow();
  if (medias.length === 0) return null;
  const focusedMedia = medias[0];
  if (!focusedMedia) return null;

  const marginRight = () => {
    const photoCount = medias.length;
    if (photoCount === 2) {
      return 36;
    } else if (photoCount > 2) {
      return 36 + (photoCount - 2) * 18;
    }
    return 0;
  };

  const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({}).current;

  useEffect(() => {
    Object.values(videoRefs).forEach(video => {
      if (video) {
        video.load();
      }
    });
  }, [videoRefs]);

  const renderMedia = (media: Media) => {
    const commonClasses = 'h-[200px] w-[150px] rounded-lg object-cover shadow-lg';

    const mediaContent =
      media.type === MediaType.Video ? (
        <>
          <video
            preload="metadata"
            key={media.id}
            src={media.url}
            className={commonClasses + 'block cursor-pointer'}
            onClick={e => {
              // on mobile web, the video automatically goes full screen
              if (isDesktop) {
                e.preventDefault();
                onView();
              }
            }}
            controls
            controlsList="nodownload"
            ref={el => (videoRefs[media.id] = el)}
          />
        </>
      ) : (
        <Image
          key={media.id}
          src={media.childrenMedia?.[0]?.url || media.url}
          alt="Primary Media"
          className={commonClasses}
          onClick={() => onView()}
        />
      );

    return mediaContent;
  };

  return (
    <div
      className="relative mb-0.5 flex"
      style={{ marginRight: isAuthor ? `${marginRight()}px` : 'auto' }}
    >
      <View className="relative z-above1">{renderMedia(focusedMedia)}</View>

      {medias.slice(1).map((media, index) => {
        const scale = 0.8 - index * 0.2;
        const tiltAngle = 10 + index * 10;
        const overlayOpacity = 0.4 + index * 0.2;
        const rightOffset = 24 + index * 20;

        return (
          <div
            key={media.id}
            className="transition-transform absolute right-0 transform duration-200 ease-out"
            style={{
              zIndex: -index,
              transform: `translateX(${rightOffset}%) rotate(${tiltAngle}deg) scale(${scale})`,
            }}
            onClick={() => {
              onView(index + 1);
            }}
          >
            <div
              className="absolute inset-0 rounded-lg bg-black"
              style={{ opacity: overlayOpacity }}
            />
            {renderMedia(media)}
          </div>
        );
      })}
    </div>
  );
};
