import { RsvpFieldSchema } from './schema';
import { initialRsvpState, rsvpState } from './store';

export const setField = <FieldKey extends keyof typeof rsvpState.fields>(
  name: FieldKey,
  value: (typeof rsvpState.fields)[FieldKey],
) => {
  rsvpState.fields[name] = value;
};

export const setError = <FieldKey extends keyof typeof rsvpState.errors>(
  name: FieldKey,
  value: (typeof rsvpState.errors)[FieldKey],
) => {
  rsvpState.errors[name] = value;
};

export const populateFields = (newFields: Partial<typeof rsvpState.fields>) => {
  for (const [key, value] of Object.entries(newFields)) {
    if (key in rsvpState.fields && value !== undefined) {
      (rsvpState.fields as Record<string, unknown>)[key] = value;
    }
  }
};

export const clearErrors = () => {
  rsvpState.errors = {
    title: null,
    message: null,
  };
};

export const clearFields = () => {
  rsvpState.fields = initialRsvpState().fields;
};

export const validateField = async <FieldKey extends keyof typeof rsvpState.errors>(
  name: FieldKey,
) => {
  rsvpState.errors[name] = null;

  if (name === 'message' && !rsvpState.fields.shouldSendSms) {
    return;
  }

  const result = await RsvpFieldSchema.shape[name].safeParseAsync(rsvpState.fields[name]);

  if (!result.success) {
    const errorMessage = result.error.errors[0]?.message || `Invalid ${String(name)}`;
    rsvpState.errors[name] = errorMessage as (typeof rsvpState.errors)[FieldKey];
  }
};

export function setFieldsToUndefined(obj: object) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (typeof value === 'string' && value === '') {
      //@ts-expect-error  - overwrite with undefined
      result[key] = undefined;
    } else {
      //@ts-expect-error - this is fine
      result[key] = value;
    }
    return result;
  }, {});
}
