import { twMerge } from 'tailwind-merge';
import { Image, type ImageProps } from '../../../common/Image';

export function ArtistLogo({ className, ...rest }: { className?: string } & ImageProps) {
  return (
    <Image
      className={twMerge('h-auto max-h-[70px] w-full max-w-[300px] object-contain', className)}
      {...rest}
    />
  );
}
