import { proxy } from 'valtio';
import { RsvpEventReleaseAnnouncementTarget } from '../../graphql/generated';
import type { RsvpState } from './schema';

export const generateThankYouMessage = (title: string, artistName?: string | null) => {
  const displayTitle = title ? ` to "${title}"` : '';
  const artistSuffix = artistName && title ? ` by ${artistName}` : '';
  return `You are now RSVP'd${displayTitle}${artistSuffix}. We'll text you as soon as it's live.`;
};

export const initialRsvpState = () =>
  ({
    fields: {
      mediaId: null,
      image: '',
      title: '',
      description: '',
      eventDate: null,
      message: '',
      sendToGroup: RsvpEventReleaseAnnouncementTarget.RsvpdUsers,
      shouldSendSms: true,
      canEditSms: true,
      shouldSendThankyouMessage: true,
      thankyouMessage: '',
      isThankYouMessageCustomized: false,
      isEditMode: false,
    },
    errors: {
      title: null,
      message: null,
    },
  }) satisfies RsvpState;

export const rsvpState: RsvpState = proxy(initialRsvpState());

export type RsvpStateKeys = keyof RsvpState['fields'];
