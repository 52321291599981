import { type UIEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useWindow } from '../../hooks/useWindow';
import { Text } from '../common/Text';
import { View } from '../common/View';

interface HowItWorksProps {
  carouselData: Array<{
    title: string;
    description: string;
    component: string;
  }>;
  renderCarouselContent: (component: string | undefined) => React.ReactNode;
}

export const HowItWorks: React.FC<HowItWorksProps> = ({ carouselData, renderCarouselContent }) => {
  const { isDesktop } = useWindow();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [offset, setOffset] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % carouselData.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + carouselData.length) % carouselData.length);
  };

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          const totalWidth = childWidth * carouselData.length;

          let newIndex = Math.round(scrollLeft / childWidth) % carouselData.length;
          const newOffset = Math.floor(scrollLeft / totalWidth);

          if (newIndex < 0) {
            newIndex += carouselData.length;
          }

          if (newIndex !== currentSlide || newOffset !== offset) {
            setCurrentSlide(newIndex);
            setOffset(newOffset);
          }

          // Implement infinite scroll
          if (scrollLeft < childWidth / 2) {
            container.scrollLeft += totalWidth;
            setOffset(prev => prev + 1);
          } else if (scrollLeft > totalWidth * 1.5 - childWidth / 2) {
            container.scrollLeft -= totalWidth;
            setOffset(prev => prev - 1);
          }
        },
        100,
        { trailing: true },
      ),
    [currentSlide, offset, carouselData.length],
  );

  useEffect(() => {
    const scrollToSlide = (index: number) => {
      const container = containerRef.current;
      if (container) {
        const child = container.firstChild as HTMLElement | null;
        const childWidth = child?.getBoundingClientRect().width || 0;
        container.scrollLeft = childWidth * (index + offset * carouselData.length);
      }
    };

    scrollToSlide(currentSlide);
  }, [currentSlide, offset, carouselData.length]);

  return (
    <View className="flex w-full flex-1 flex-col items-center justify-center">
      <View className="mb-20 flex w-full flex-col items-center justify-center">
        {isDesktop ? (
          <View className="flex flex-row items-center gap-8">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="aspect-square cursor-pointer rounded-full bg-vault_text/10 p-2 text-[16px] text-vault_text"
              onClick={prevSlide}
            />
            {renderCarouselContent(carouselData[currentSlide]?.component)}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="aspect-square cursor-pointer rounded-full bg-vault_text/10 p-2 text-[16px] text-vault_text"
              onClick={nextSlide}
            />
          </View>
        ) : (
          <View
            containerRef={containerRef}
            className="no-scrollbar flex min-h-[322px] w-full flex-shrink-[1] snap-x snap-mandatory flex-row items-center overflow-x-auto"
            onScroll={onScroll}
          >
            {[...carouselData, ...carouselData, ...carouselData].map((item, index) => (
              <motion.div key={index} className="h-full w-full flex-none snap-center">
                <View className="z-above4 flex h-full flex-col items-center justify-center">
                  {renderCarouselContent(item.component)}
                </View>
              </motion.div>
            ))}
          </View>
        )}

        {/* Dots */}
        <View className="z-above4 mt-7 flex justify-center">
          {carouselData.map((_, index) => (
            <View
              key={index}
              className={`mx-1 h-2 w-2 rounded-full ${
                index === currentSlide ? 'bg-vault_accent' : 'bg-base500'
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </View>

        <View className="mt-5 flex flex-[1.4] flex-shrink-[10] flex-col items-center justify-start">
          {/* Text content */}
          <View className="mt-2 box-border w-full">
            <View className="relative">
              <View className="box-border flex flex-col items-center justify-center overflow-hidden rounded-lg text-center">
                {carouselData[currentSlide]?.title && (
                  <Text className="mb-2 text-[min(28px,4vh)]/[min(34px,5vh)] font-semibold text-vault_text">
                    {carouselData[currentSlide]?.title}
                  </Text>
                )}

                {carouselData[currentSlide]?.description && (
                  <Text className="box-border h-28 w-full px-4 text-[min(16px,2.5vh)]/[min(20px,3.125vh)] text-vault_text/50 md2:w-5/6">
                    {carouselData[currentSlide]?.description}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export function PhoneImageSlide({
  imageUri,
  loading,
}: {
  imageUri: string;
  loading: 'eager' | 'lazy';
}) {
  return (
    <View className="relative aspect-[280/378] h-full max-h-[400px] min-h-[55vh] w-full max-w-[280px] select-none overflow-hidden md2:aspect-[300/425] md2:max-h-[425px] md2:max-w-[320px]">
      <img
        src={imageUri}
        alt="iphone"
        className="z-above2 h-full w-full object-contain"
        fetchPriority="high"
        loading={loading}
      />
    </View>
  );
}
