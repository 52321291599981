import { useCallback } from 'react';
import { useLatestRef } from './useLatestRef';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useStableCallback<Cb extends (...args: any[]) => unknown>(cb: Cb) {
  const latestCb = useLatestRef(cb);

  return useCallback<Cb>(
    // @ts-expect-error - we don't have strong types for useCallback
    (...args) => {
      return latestCb.current(...args);
    },
    [latestCb],
  );
}
