import { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, uniqBy } from 'lodash-es';
import { useInView } from 'react-intersection-observer';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { SkeletonUserRow } from '../../components/user/UserRow';
import { VaultRow } from '../../components/vault/VaultRow';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  ArtistVaultRowFragmentDoc,
  GetSubscriptionsForArtistNotificationsDocument,
  makeFragmentData,
  VaultSubscriptionStatus,
} from '../../graphql/generated';

gql(/* GraphQL */ `
  query GetSubscriptionsForArtistNotifications(
    $after: String
    $first: Int!
    $status: VaultSubscriptionStatus
  ) {
    allVaultSubscriptionsByPriority(after: $after, first: $first, status: $status) {
      edges {
        cursor
        node {
          id
          vault {
            id
            artist: artistProfile {
              id
              name
              ...artistVaultRow
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);
const LIMIT = 20;

export function ArtistNotificationSettingsPage() {
  const { loggedInUser } = useAuthContext();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const {
    isInitialLoading,
    orderedList,
    fetchNextPage,
    isError,
    refetch,
    isLoadingNewPage,
    hasNextPage,
  } = useInfiniteQuery(GetSubscriptionsForArtistNotificationsDocument, {
    enabled: loggedInUser != null,
    staleTime: 0,
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    getNextPageParam: ({ data }) => {
      return (
        data.allVaultSubscriptionsByPriority.pageInfo.hasNextPage && {
          after: data.allVaultSubscriptionsByPriority.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
        status: VaultSubscriptionStatus.Active,
      };
    },
    list: ({ allVaultSubscriptionsByPriority }) => {
      return compact(
        allVaultSubscriptionsByPriority.edges.map(({ node }) => {
          const artist = node.vault.artist;
          if (artist == null) {
            return null;
          }
          return { vaultId: node.vault.id, ...artist };
        }),
      );
    },
    uniq: ({ id }) => id,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom]);

  const rows = useMemo(() => {
    if (loggedInUser?.artist != null) {
      return uniqBy(
        [
          {
            ...makeFragmentData(loggedInUser.artist, ArtistVaultRowFragmentDoc),
            ...loggedInUser.artist,
            vaultId: loggedInUser.artist.mainVaultId,
          },
          ...orderedList,
        ],
        ({ id }) => id,
      );
    }
    return orderedList;
  }, [loggedInUser?.artist, orderedList]);

  if (isError) {
    return (
      <SettingsLayout title="Artist notifications">
        <ErrorView onRetryClick={refetch} withVaultTheme={false} />
      </SettingsLayout>
    );
  }

  if (isInitialLoading) {
    return (
      <SettingsLayout title="Artist notifications">
        <SkeletonUserRow
          className="mb-0"
          profileImageClassName="h-7 w-7 rounded-full"
          subText={false}
        />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout title="Artist notifications">
      {rows.map(item => {
        return (
          <VaultRow
            key={item.id}
            artist={item}
            vaultId={item.vaultId}
            showUnreadCount={false}
            profileImageClassName="h-7 w-7 rounded-full"
            className="mx-4 mb-3 box-border w-full items-center bg-base800 px-3 py-4"
            actionComponent={<FontAwesomeIcon className="text-white" icon={faChevronRight} />}
            href={item.id}
          />
        );
      })}
      {isLoadingNewPage && (
        <SkeletonUserRow className="mb-0" profileImageClassName="h-7 w-7 rounded-full" />
      )}
      <View containerRef={bottomRef} />
    </SettingsLayout>
  );
}
