import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faGift, faReceipt } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';

export const Item = ({
  header,
  headerLink,
  children,
  className,
}: {
  header: string;
  headerLink?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <View className={twMerge('flex flex-col gap-6', className)}>
      <View className="flex w-full items-center justify-between">
        <Text className="font-title text-[18px]/[20px] text-vault_text lg:text-[24px]/[26px]">
          {header}
        </Text>
        {headerLink}
      </View>

      {children}
    </View>
  );
};

export const EmptyReceipts = () => (
  <View className="box-border flex w-full flex-col items-center gap-6 rounded-xl border border-vault_text/7 bg-vault_text/10 p-10">
    <View className="flex flex-col gap-4">
      <FontAwesomeIcon
        icon={faReceipt}
        size="2xl"
        className="flex items-center text-vault_text/90"
      />
      <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
        Collect receipts by RSVPing to drops
      </Text>
    </View>

    <View className="h-[1px] w-full bg-vault_text/5" />

    <View className="flex flex-col gap-4">
      <FontAwesomeIcon icon={faGift} size="2xl" className="flex items-center text-vault_text/90" />
      <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
        The more receipts you collect, the more rewards & access you get
      </Text>
    </View>
  </View>
);
