import { Navigate, useParams } from 'react-router';
import { View } from '../../../components/common/View';
import { MassMessageInsightsView } from '../../../components/massMessage/MassMesageInsightsView';
import { ROUTES } from '../../../constants/routeConstants';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';

export function MassMessageInsightsPage() {
  const { messageId } = useParams();
  const { artistHandle } = useArtistHandle();

  useVaultTheme();
  useOwnerOnlyAccess();

  if (!messageId || !artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <View className="relative z-above1 box-border flex h-full w-full flex-col items-center justify-start bg-vault_background md2:ml-[272px] md2:w-[calc(100%-272px)] md2:px-2">
      <View className="box-border h-full w-full md2:mt-1 md2:rounded-t-[20px] md2:border md2:border-solid md2:border-vault_text/10 md2:bg-vault_text/3">
        <MassMessageInsightsView messageId={messageId} isOwnPage artistHandle={artistHandle} />
      </View>
    </View>
  );
}
