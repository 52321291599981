import React, { type ReactNode, useEffect, useMemo } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import ms from 'ms';
import { useLocation, useNavigate } from 'react-router';
import { proxy } from 'valtio';
import { gql } from '@soundxyz/gql-string';

import { unload } from '../audio/AudioEngineHTML5';
import { setActiveTrackId } from '../audio/AudioMeta';
import { mixpanelClient } from '../clients/mixpanelClient';
import { ROUTES } from '../constants/routeConstants';
import { AuthContext, type AuthContextType, GlobalAuthContext } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { fetchGQL, GraphQLReactQuery, useQuery } from '../graphql/client';
import type { SubscriptionTierLevel } from '../graphql/generated';
import {
  AuthUserDocument,
  AuthUserFragmentDoc,
  CheckInDocument,
  getFragment,
} from '../graphql/generated';
import { AppleMusicConnectState, MusicKitReady } from '../hooks/appleMusic/useAppleMusicAuth';
import { newSpotifyConnectState } from '../hooks/spotify/useSpotifyAuth';
import { clearStatsigUser, setStatsigUser } from '../hooks/statsigUser';
import { Sentry } from '../sentry';
import { LoginStatus } from '../types/authTypes';

gql(/* GraphQL */ `
  fragment authUser on PrivateUser {
    id
    phone
    email
    zipCode
    username
    isInternal
    isAdmin
    isTestPhoneUser
    isOfficialVaultUser
    highestSubscriptionLevel
    displayName
    createdAt
    inviteCode
    pushNotificationsConfigurable
    spotifyAuthConnection {
      spotifyUserId
      product
    }
    upsellInterstitials {
      firstChat
      firstSnippetShare
      firstTrack
    }
    adminArtists {
      artistId
      artistMainLinkValue
      artistLinks
      artistMainVaultId
      artistMainVaultType
      artistName
      role
      artistProfileImage {
        id
        url
        dominantColor
        smallImageMedia: childrenMedia(filter: SMALL_IMAGE, take: 1) {
          id
          mediaType
          url
        }
        mediumImageMedia: childrenMedia(filter: MEDIUM_IMAGE, take: 1) {
          id
          mediaType
          url
        }
      }
    }
    avatar {
      id
      url
      dominantColor
      mediaType
      smallImageMedia: childrenMedia(filter: SMALL_IMAGE, take: 1) {
        id
        mediaType
        url
      }
    }
    payee {
      id
      email
      phone
      hasCompletedOnboarding
    }
    appleMusicAuthConnections {
      userToken
    }
    ...userRow
  }

  query AuthUser {
    currentUser {
      __typename
      ... on QueryCurrentUserSuccess {
        data {
          ...authUser
          privyUserId
          id
          username
          email
          phone
          highestSubscriptionLevel
          isInternal
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation CheckIn {
    userCheckIn
  }
  mutation ResetTestPhoneUser {
    resetSelfTestUser
  }
`);

const defaultLoggedInUserEventProperties: {
  highestSubscriptionLevel: SubscriptionTierLevel | 'UNSUBSCRIBED';
} = {
  highestSubscriptionLevel: 'UNSUBSCRIBED',
};

export const LoggedInUserEventProperties = proxy(defaultLoggedInUserEventProperties);

export const OnLoginAction: {
  action: (() => void) | null;
} = {
  action: null,
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { ready, authenticated, getAccessToken, user: privyUser, logout } = usePrivy();

  const { openToast } = useToast();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const {
    data: authUserData,
    error,
    isInitialLoading,
    refetch: refetchAuthUser,
  } = useQuery(AuthUserDocument, {
    retry: 7,
    retryDelay: count => {
      return Math.min(Math.pow(2, count - 1) * 500, 16000);
    },
    enabled: ready && authenticated,
    onSuccess(data) {
      if (data.data.currentUser.__typename === 'AccountDeletedPending') {
        openToast({
          text: 'Your account has been deleted. Let us know at help@vault.fm if you believe this is an error.',
          variant: 'error',
          duration: ms('10 seconds'),
        });
        logout().catch(error => {
          Sentry.captureMessage('Error when logging out after account deletion', {
            extra: {
              error,
              privyUser,
            },
            level: 'warning',
          });
        });

        if (pathname === ROUTES.VERIFY) {
          navigate(ROUTES.SIGN_IN);
        }
      }
      if (data.data.currentUser.__typename === 'QueryCurrentUserSuccess') {
        const me = data.data.currentUser.data;
        Sentry.setUser({
          id: me.id,
          username: me.username || undefined,
        });
        setStatsigUser({
          userID: me.id,
          custom: {
            username: me.username || undefined,
            isInternal: me.isInternal || undefined,
          },
          privateAttributes: {
            email: me.email || undefined,
            phone: me.phone || undefined,
          },
        });
        LoggedInUserEventProperties.highestSubscriptionLevel =
          me.highestSubscriptionLevel ?? 'UNSUBSCRIBED';

        const user = getFragment(AuthUserFragmentDoc, me);

        mixpanelClient.identify(me.id);
        mixpanelClient.people.set({
          name: user.username,
          isInternal: user.isInternal,
          displayName: user.displayName,
          artistId: user.adminArtists?.[0]?.artistId,
          email: user.email,
          phone: user.phone,
          isAdmin: user.isAdmin,
          isActiveSubscriber: user.highestSubscriptionLevel === 'PAID',
          subscriptionStatus: user.highestSubscriptionLevel ?? 'UNSUBSCRIBED',
        });
      }
    },
    cacheTime: Infinity,
    staleTime: 0,
    filterQueryKey: {
      privyUserId: privyUser?.id,
    },
    onError(error) {
      getAccessToken()
        .then(accessToken => {
          Sentry.captureMessage('Error when calling AuthUserDocument, access token found', {
            extra: {
              error,
              accessToken,
              privyUser,
              authenticated,
              ready,
            },
            level: 'warning',
          });
        })
        .catch(error => {
          Sentry.captureMessage('Error when calling AuthUserDocument, access token NOT found', {
            extra: {
              error,
              privyUser,
              authenticated,
              ready,
            },
            level: 'warning',
          });
        });
    },
    onSettled(data) {
      if (data?.data.currentUser == null) {
        Sentry.captureMessage('No user data returned from AuthUser query', {
          extra: {
            data,
            privyUser,
          },
          level: 'warning',
        });
      }
    },
  });

  const wasAuthenticatedRef = React.useRef(false);

  useEffect(() => {
    if (!ready) return;

    if (authenticated) return;

    unload();
    setActiveTrackId(null);
  }, [ready, authenticated]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (authenticated) {
      if (!wasAuthenticatedRef.current) {
        OnLoginAction.action?.();
        OnLoginAction.action = null;
      }

      wasAuthenticatedRef.current = true;
      // Add login effects

      fetchGQL(CheckInDocument, {
        keepalive: true,
      });
    } else if (wasAuthenticatedRef.current) {
      // Add logout effects
      GraphQLReactQuery.clear();
      clearStatsigUser();

      LoggedInUserEventProperties.highestSubscriptionLevel = 'UNSUBSCRIBED';

      wasAuthenticatedRef.current = false;

      AppleMusicConnectState.set({
        userToken: null,
      });

      newSpotifyConnectState({
        code: null,
      });

      if (MusicKitReady.instanceLoaded) {
        const musicKit = MusicKit.getInstance();
        if (musicKit.isAuthorized) {
          musicKit.unauthorize();
        }
      }
    }
  }, [ready, authenticated, getAccessToken]);

  const loggedInUserValue =
    authenticated && ready
      ? getFragment(
          AuthUserFragmentDoc,
          authUserData?.data.currentUser.__typename === 'QueryCurrentUserSuccess'
            ? authUserData.data.currentUser.data
            : null,
        )
      : null;

  //TODO: Remove this when we FE to select between multiple artists
  const firstAdminArtist = loggedInUserValue?.adminArtists?.[0];

  const loggedInUser = useMemo(() => {
    if (!loggedInUserValue) return null;

    return {
      ...loggedInUserValue,
      artist: firstAdminArtist
        ? {
            id: firstAdminArtist.artistId,
            name: firstAdminArtist.artistName,
            mainLinkValue: firstAdminArtist.artistMainLinkValue,
            artistLinks: firstAdminArtist.artistLinks,
            profileImage: firstAdminArtist.artistProfileImage,
            mainVaultId: firstAdminArtist.artistMainVaultId,
            role: firstAdminArtist.role,
            mainVaultType: firstAdminArtist.artistMainVaultType,
          }
        : null,
    };
  }, [loggedInUserValue, firstAdminArtist]);

  const value = useMemo<AuthContextType>(() => {
    const isLoginLoading = isInitialLoading || !ready;
    return {
      loggedInUser,
      isArtist: firstAdminArtist != null,
      isTestPhoneUser: !!loggedInUserValue?.isTestPhoneUser,
      isOfficialVaultUser: !!loggedInUserValue?.isOfficialVaultUser,
      authError: error,
      loginStatus:
        isInitialLoading || !ready
          ? LoginStatus.LOADING
          : authenticated && authUserData?.data.currentUser.__typename === 'QueryCurrentUserSuccess'
            ? LoginStatus.LOGGED_IN
            : LoginStatus.LOGGED_OUT,
      refetchAuthUser,
      initialLoginCheckLoading: GlobalAuthContext.initialLoginCheckLoading && isLoginLoading,
    };
  }, [
    loggedInUser,
    firstAdminArtist,
    loggedInUserValue,
    isInitialLoading,
    ready,
    authenticated,
    authUserData,
    error,
    refetchAuthUser,
  ]);

  useEffect(() => {
    Object.assign(GlobalAuthContext, value);
  }, [value]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
