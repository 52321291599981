import { Navigate } from 'react-router-dom';
import { View } from '../../../components/common/View';
import { FilterMembersView } from '../../../components/massMessage/FilterMembersView';
import { ROUTES } from '../../../constants/routeConstants';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';

export function FilterMembersScreen() {
  const { artistHandle } = useArtistHandle();

  useOwnerOnlyAccess();

  if (!artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    <View className="flex h-full w-full flex-col">
      <FilterMembersView artistHandle={artistHandle} />
    </View>
  );
}
