import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { EventType } from '../../types/eventTypes';
import type { ButtonProps } from '../buttons/Button';
import { Button } from '../buttons/Button';

export const StandardModalButton = <Event extends EventType>(
  props: ButtonProps<Event> & { isNotModalSmall?: boolean },
) => {
  const { className, isNotModalSmall } = props;
  return (
    <Button
      {...props}
      className={twMerge(
        'h-[44px] w-full max-w-[unset] text-[14px]/[18px] text-white md:text-[16px]/[20px]',
        isNotModalSmall && 'w-[initial] max-w-[initial]',
        className,
      )}
      linkClassName={twMerge(
        'w-full max-w-[unset]',
        isNotModalSmall && 'w-[initial] max-w-[initial]',
      )}
    />
  );
};
