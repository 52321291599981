import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { HookAutoSave } from '../../components/forms/hook-form/Autosave';
import { HookToggle } from '../../components/forms/hook-form/Toggle';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistUserNotificationSettingsFragmentDoc,
  type FragmentType,
  GetArtistUserNotificationSettingsDocument,
  getFragment,
  UpdateArtistUserNotificationSettingsDocument,
} from '../../graphql/generated';

RefetchOnComplete({
  trigger: [UpdateArtistUserNotificationSettingsDocument],
  refetch: [GetArtistUserNotificationSettingsDocument],
});

gql(/* GraphQL */ `
  fragment ArtistUserNotificationSettings on ArtistUserNotificationSettings {
    isPushNotificationEnabled
    isSmsEnabled
  }

  query GetArtistUserNotificationSettings($artistId: UUID!) {
    artistUserSettings(input: { artistId: $artistId }) {
      artistId
      artist {
        id
        name
      }
      notificationSettings {
        ...ArtistUserNotificationSettings
      }
    }
  }

  mutation UpdateArtistUserNotificationSettings($input: MutationUpdateArtistUserSettingsInput!) {
    updateArtistUserSettings(input: $input) {
      artistId
      notificationSettings {
        isPushNotificationEnabled
        isSmsEnabled
      }
    }
  }
`);

export function ManageArtistNotificationScreen() {
  const { artistId } = useParams();
  const { data, isError, isInitialLoading, refetch } = useQuery(
    GetArtistUserNotificationSettingsDocument,
    {
      variables: !!artistId && { artistId },
      staleTime: 0,
      enabled: !!artistId,
    },
  );

  if (isError || artistId == null) {
    return (
      <SettingsLayout title={data?.data.artistUserSettings.artist.name ?? ''}>
        <ErrorView onRetryClick={refetch} withVaultTheme={false} />
      </SettingsLayout>
    );
  }

  if (isInitialLoading || data?.data.artistUserSettings == null) {
    return (
      <SettingsLayout title={data?.data.artistUserSettings.artist.name ?? ''}>
        <LoadingSkeleton className="h-10 w-full" />
      </SettingsLayout>
    );
  }
  const artistName = data.data.artistUserSettings.artist.name;

  return (
    <SettingsLayout title={artistName}>
      <ManageArtistNotificationView
        notificationSettings={data.data.artistUserSettings.notificationSettings}
        artistId={artistId}
      />
    </SettingsLayout>
  );
}

const validationSchema = z.object({
  isPushNotificationEnabled: z.boolean(),
  isSmsEnabled: z.boolean(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

function ManageArtistNotificationView({
  notificationSettings,
  artistId,
}: {
  notificationSettings: FragmentType<ArtistUserNotificationSettingsFragmentDoc>;
  artistId: string;
}) {
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();
  const { mutateAsync } = useMutation(UpdateArtistUserNotificationSettingsDocument, {
    onError: e => {
      captureException(e, {
        tags: { source: 'UpdateArtistUserNotificationSettings' },
        extra: { artistId },
      });
      openToast({
        text: e.message,
        variant: 'error',
      });
    },
  });

  const { isPushNotificationEnabled, isSmsEnabled } = getFragment(
    ArtistUserNotificationSettingsFragmentDoc,
    notificationSettings,
  );

  const form = useForm<ValidationSchema>({
    defaultValues: {
      isPushNotificationEnabled,
      isSmsEnabled,
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (data: ValidationSchema): Promise<ValidationSchema> => {
    const resp = await mutateAsync({ input: { artistId, ...data } });
    return resp.data.updateArtistUserSettings.notificationSettings;
  };

  return (
    <FormProvider {...form}>
      <HookAutoSave onSubmit={onSubmit} />
      <View className="mt-3 w-full gap-6">
        <HookToggle control={form.control} label="Enable SMS notifications" name="isSmsEnabled" />
      </View>
      {loggedInUser?.pushNotificationsConfigurable && (
        <View className="mt-3 w-full gap-6">
          <HookToggle
            control={form.control}
            label="Enable Push notifications"
            name="isPushNotificationEnabled"
          />
        </View>
      )}
    </FormProvider>
  );
}
