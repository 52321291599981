import { useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { derive, proxyMap } from 'valtio/utils';
import type { Maybe } from '@soundxyz/utils';
import type { NonEmptyArray } from '@soundxyz/utils/const';
import { useSafeLayoutEffect } from '../hooks/useSafeLayoutEffect';
import { nonEmptyLast } from './list';

export function createBatchingInputStore({ chunkLimit }: { chunkLimit: number }) {
  const batchedStore = proxy({
    batchedInput: proxyMap<string, number>(),
  });

  const batchedKeys = derive({
    keys(get) {
      const keysLists = new Map<string, string[]>();

      const chunkedKeys: NonEmptyArray<string[]> = [[]];

      get(batchedStore).batchedInput.forEach((subscribers, key) => {
        if (!subscribers) return;

        if (nonEmptyLast(chunkedKeys).length >= chunkLimit) chunkedKeys.push([]);

        const chunkKeysList = nonEmptyLast(chunkedKeys);

        chunkKeysList.push(key);
        keysLists.set(key, chunkKeysList);
      });

      return keysLists;
    },
  });

  function addBatchedKey(key: string) {
    const existingAdd = batchedStore.batchedInput.get(key) ?? 0;

    batchedStore.batchedInput.set(key, existingAdd + 1);

    return function cleanup() {
      const existingRemove = batchedStore.batchedInput.get(key);

      if (existingRemove != null) batchedStore.batchedInput.set(key, existingRemove - 1);
    };
  }

  function useBatchedKey({ key }: { key: Maybe<string> }) {
    useSafeLayoutEffect(() => {
      if (!key) return;

      return addBatchedKey(key);
    }, [key]);

    const snapshotKeys = useSnapshot(batchedKeys);

    return useMemo(() => {
      return key ? snapshotKeys.keys.get(key) : undefined;
    }, [snapshotKeys, key]);
  }

  return {
    useBatchedKey,
    batchedKeys,
  };
}
