import type React from 'react';
import { useRef } from 'react';
import { useSafeLayoutEffect } from './useSafeLayoutEffect';

export const useIsOverflow = (ref: React.RefObject<HTMLElement>) => {
  const isOverflowRef = useRef<boolean | undefined>(undefined);

  useSafeLayoutEffect(() => {
    const current = ref.current;

    if (!current) return;

    const trigger = () => {
      isOverflowRef.current = current.scrollHeight > current.clientHeight;
    };

    const resizeObserver = new ResizeObserver(trigger);
    resizeObserver.observe(current);

    trigger();

    return () => resizeObserver.disconnect();
  }, [ref]);

  return isOverflowRef.current;
};
