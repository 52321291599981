import type { FC } from 'react';
import React from 'react';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';

const ActionBottomsheet: FC<ActionBottomsheetProps> = ({ buttons, className, withVaultTheme }) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { pathname } = useLocation();

  return (
    <View
      className={twMerge(
        'mb-4 mt-5 flex w-full flex-col gap-y-[2px] overflow-hidden rounded-xl',
        className,
      )}
    >
      {buttons.map((button, index) => (
        <Button
          {...button}
          key={button.key ?? index}
          className={twMerge(
            'flex cursor-pointer items-center gap-1.5 self-stretch rounded-none border-none p-4 outline-none',
            withVaultTheme
              ? 'hover:bg-vault_text/ bg-vault_text/10 text-vault_text'
              : 'bg-base800 hover:bg-white/10',
            buttons.length === 1
              ? 'rounded-lg'
              : index === 0
                ? 'rounded-t-lg'
                : index === buttons.length - 1 && 'rounded-b-lg',
            button.className,
          )}
          loading={button.loading}
          disabled={button.loading}
          onClick={
            button.onClick != null && button.requireConfirmation
              ? () =>
                  openBottomsheet({
                    type: 'CONFIRMATION',
                    confirmationBottomsheetProps: {
                      onConfirm:
                        button.onClick != null
                          ? e => {
                              button.event != null &&
                                trackEvent({
                                  type: button.event.type,
                                  properties: button.event.properties,
                                  pathname,
                                });

                              button.onClick?.(e);
                            }
                          : () => null,
                      subText: button.confirmationSubText,
                      confirmType: button.confirmType,
                    },
                  })
              : button.onClick
          }
          event={
            button.onClick != null && button.requireConfirmation
              ? {
                  type: EVENTS.OPEN_BOTTOMSHEET,
                  properties: {
                    bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION,
                    event: button.event?.type,
                  },
                }
              : button.event
          }
        />
      ))}
    </View>
  );
};

export { ActionBottomsheet };
