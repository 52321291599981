import assert from 'assert';
import React, { type FC, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash-es';
import { useNavigate, useParams } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faPen } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { uuidv4 } from '@soundxyz/utils';
import { VaultType } from '../../graphql/generated';
import { setSplitData, SplitData, type SplitDetails } from '../../screens/vault/TrackSplitsPage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ActivateFreemiumPrompt } from '../vault/ActivateFreemiumPrompt';

export const TrackSplitsView: FC<{
  artistVaultType: VaultType | undefined;
  artistLinkValue: string;
}> = ({ artistVaultType, artistLinkValue }) => {
  const navigate = useNavigate();
  const { vaultContentId } = useParams();

  assert(vaultContentId, 'vaultContentId is required');

  const splitData = useSnapshot(SplitData)[vaultContentId];

  const orderedSplitData = useMemo(() => {
    return orderBy(
      Object.values(splitData || {}).filter(v => !v.isNew),
      [v => v.isOwnSplit, v => v.percentBps, v => v.role, v => v.name, v => v.id],
      ['desc', 'desc', 'asc', 'asc', 'asc'],
    );
  }, [splitData]);

  return (
    <View className="w-full">
      <View className="mx-4 my-2 flex flex-col">
        {orderedSplitData.map(splitDetails => {
          return <SplitRow key={splitDetails.id} splitDetails={splitDetails} />;
        })}

        <View className="mt-5 flex w-full flex-row items-center justify-center">
          {artistVaultType === VaultType.FreeOnly ? (
            <ActivateFreemiumPrompt
              type="splits"
              artistLinkValue={artistLinkValue}
              withVaultTheme
            />
          ) : (
            <Button
              label="Add collaborator"
              onClick={() => {
                const id = uuidv4();
                setSplitData({
                  id,
                  emailAddress: '',
                  isOwnSplit: false,
                  name: '',
                  percentBps: 0,
                  role: '',
                  vaultContentId,
                  isNew: true,
                });
                navigate(`add?id=${id}`);
              }}
              className={twMerge(
                'min-w-[50%] rounded-[100px] px-4 py-4 text-[16px]/[20px] font-normal',
                'bg-vault_accent text-vault_accent_text',
              )}
            />
          )}
        </View>
      </View>
    </View>
  );
};

const SplitRow = ({ splitDetails }: { splitDetails: SplitDetails }) => {
  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(`add?id=${splitDetails.id}`);
  };

  return (
    <View className="flex flex-col border-solid border-x-transparent border-b-vault_text/5 border-t-transparent py-3">
      <View className="flex w-[90%] flex-row">
        <Text className="text-vault_text">{splitDetails.name}</Text>
      </View>
      <View className="flex w-full flex-row py-[12px]">
        <View className="flex w-[90%] flex-row items-center justify-between">
          <Text className="text-vault_text/50">{splitDetails.role}</Text>
          <Text className="text-vault_text/50">{splitDetails.percentBps / 100} %</Text>
        </View>
        <View
          className="flex w-[10%] items-center justify-center hover:cursor-pointer"
          onClick={onEditClick}
        >
          <FontAwesomeIcon icon={faPen} size="sm" className="text-vault_text/50" />
        </View>
      </View>
    </View>
  );
};
