import React, { type FC, useCallback } from 'react';

import Cropper, { type Area } from 'react-easy-crop';

import { twMerge } from 'tailwind-merge';
import { ModalContext } from '../../contexts/ModalContext';
import { ToastContext } from '../../contexts/ToastContext';
import { getCroppedImage } from '../../utils/imageUtils';
import { Slider } from '../common/Slider';
import { Spinner } from '../common/Spinner';
import { View, type ViewProps } from '../common/View';
import { StandardModalButton } from './ModalButton';
import { StandardModal } from './StandardModal';

export const ImageCropModal = React.forwardRef<
  HTMLDivElement,
  {
    modalTitle?: string;
    forCover?: boolean;
    blobURL: string | null;
    setBlob: (b: Blob | null) => void;
    handleCloseAction?: () => void;
    handleCancelAction?: () => void;
    saveImageMutation?: (file: File) => Promise<void>;
    closeOnUpload?: boolean;
    handleReselectImage?: () => void;
    preventClose?: boolean;
    cropShape?: 'round' | 'rect';
    showGrid?: boolean;
  }
>(function ImageCropModal(
  {
    modalTitle,
    blobURL,
    forCover = false,
    setBlob,
    handleCloseAction,
    handleCancelAction,
    saveImageMutation,
    closeOnUpload = false,
    handleReselectImage,
    preventClose = false,
    cropShape = 'rect',
    showGrid = true,
  },
  ref,
) {
  const { openToast } = ToastContext.useContainer();
  const [loading, setLoading] = React.useState(false);
  const { closeCancelModal } = ModalContext.useContainer();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>();

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCancelCrop = useCallback(() => {
    setBlob(null);
    handleCancelAction?.();
    closeCancelModal();
  }, [closeCancelModal, handleCancelAction, setBlob]);

  const handleSave = useCallback(async () => {
    if (!croppedAreaPixels || !blobURL) {
      return;
    }

    const croppedImage = await getCroppedImage({ imageSrc: blobURL, crop: croppedAreaPixels });
    setBlob(croppedImage);

    if (saveImageMutation && croppedImage) {
      const newImage = new File([croppedImage], 'newImage.png', { type: 'image/png' });

      if (closeOnUpload) {
        saveImageMutation(newImage);
      } else {
        setLoading(true);
        await saveImageMutation(newImage).catch(_err => {
          // TODO: linear.app/sound/issue/ENG-9185/add-missing-observability-in-fe
          openToast({ text: 'Something went wrong', variant: 'error' });
        });
      }
    }

    closeCancelModal();
    handleCloseAction?.();
  }, [
    blobURL,
    closeCancelModal,
    closeOnUpload,
    croppedAreaPixels,
    handleCloseAction,
    openToast,
    saveImageMutation,
    setBlob,
  ]);

  const body = loading ? (
    <CropperContainer forCover={forCover} containerRef={ref}>
      <Spinner />
    </CropperContainer>
  ) : (
    <>
      <CropperContainer forCover={forCover} containerRef={ref}>
        <Cropper
          classes={{ containerClassName: 'bg-white' }}
          image={blobURL || ''}
          crop={crop}
          zoom={zoom}
          // smallest possible aspect ratio for cover
          aspect={forCover ? 2 / 1 : 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit={forCover ? 'horizontal-cover' : 'contain'}
          style={{ cropAreaStyle: { aspectRatio: '2/1' } }}
          cropShape={cropShape}
          showGrid={showGrid}
        />
      </CropperContainer>
      <View className="mt-[16px]">
        <Slider
          aria-label="slider-zoom-image"
          value={zoom}
          onChange={ev => setZoom(ev.target.valueAsNumber)}
        />
      </View>
    </>
  );

  const reselectImageButton = handleReselectImage && (
    <StandardModalButton
      label="Browse"
      onClick={handleReselectImage}
      className="text-base400"
      isNotModalSmall
    />
  );

  const saveButton = (
    <StandardModalButton
      label="Save"
      onClick={handleSave}
      loading={loading}
      className="text-yellow100"
      isNotModalSmall
    />
  );

  return (
    <StandardModal
      title={!!modalTitle ? modalTitle : 'Edit Image'}
      subHeader="Drag to resize the image."
      body={body}
      closeModal={!preventClose ? handleCancelCrop : undefined}
      size="large"
      backCallToAction={reselectImageButton ?? null}
      callToAction={saveButton}
      preventClose={preventClose}
    />
  );
});

const CropperContainer: FC<
  {
    forCover?: boolean;
  } & ViewProps
> = ({ children, forCover = false, containerRef }) => {
  const className = forCover ? 'mt-0 w-full h-[unset] aspect-[2/1] md:h-full' : '';
  return (
    <View
      className={twMerge(
        'relative mt-[16px] flex h-[308.31px] max-h-[458px] items-center justify-center md:mt-0',
        className,
      )}
      containerRef={containerRef}
    >
      {children}
    </View>
  );
};
