import { gql } from '@soundxyz/gql-string';
import type { OnData } from '@soundxyz/graphql-react-ws';
import type { Maybe } from '@soundxyz/utils';
import { useAuthContext } from '../contexts/AuthContext';
import { MessageChannelUpdatesDocument } from '../graphql/generated';
import { useSubscription } from '../graphql/wsClient';
import { LoginStatus } from '../types/authTypes';

gql(/* GraphQL */ `
  subscription MessageChannelUpdates($messageChannelId: UUID!) {
    messageChannelUpdates(messageChannelId: $messageChannelId) {
      __typename
      ... on SubscriptionMessageChannelUpdatesSuccess {
        data {
          __typename
          ... on CreateMessageSubscription {
            id
            source
            ...messageSubscriptionBubble
          }
          ... on DeleteMessageSubscription {
            id
          }
          ... on PinMessageSubscription {
            id
            pinnedPriority
          }
          ... on UnpinAllMessageSubscription {
            count
          }
          ... on ReactionMessageSubscription {
            id
            reactionType
            created
            userId
            reactionTotalCount
            isArtistReaction
            asArtistId
            emojiKeyword
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export const useMessageChannelUpdatesSubscription = ({
  messageChannelId,
  onSubscriptionData,
}: {
  messageChannelId: Maybe<string>;
  onSubscriptionData: OnData<typeof MessageChannelUpdatesDocument>;
}) => {
  const { loginStatus } = useAuthContext();
  useSubscription({
    query: MessageChannelUpdatesDocument,
    variables: !!messageChannelId && { messageChannelId },
    onData(data) {
      /**
       * We are currently hiding comments from chat
       */
      if (
        data.data.messageChannelUpdates.__typename === 'SubscriptionMessageChannelUpdatesSuccess' &&
        data.data.messageChannelUpdates.data.__typename === 'CreateMessageSubscription' &&
        data.data.messageChannelUpdates.data.source === 'CONTENT_SECTION'
      ) {
        return;
      }

      return onSubscriptionData(data);
    },
    enabled: loginStatus === LoginStatus.LOGGED_IN,
  });
};
