import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { getDurationAsTime } from '../../utils/dateUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const MockTrackFile = ({
  title,
  date,
  duration,
}: {
  title: string;
  date: string;
  duration: number;
}) => {
  return (
    <View className="flex select-none flex-col items-center">
      <View className="relative mb-[12px] flex h-[80px] w-[60px] flex-col items-center justify-center bg-file-background bg-cover pt-[4px]">
        <FontAwesomeIcon icon={faWaveformLines} className="mt-3 text-[24px] text-white" />
        <FontAwesomeIcon icon={faLock} className="mt-3 text-[11px] text-base400" />
      </View>
      <Text className="mb-[4px] line-clamp-1 text-center font-base text-base-m font-semibold text-white">
        {title}
      </Text>
      <Text className="mb-[4px] flex-1 font-base text-base-xs font-normal text-base500">
        {date}
      </Text>
      <Text className="mb-[4px] flex-1 font-base text-base-xs font-normal text-base500">
        {getDurationAsTime(duration)}
      </Text>
    </View>
  );
};
