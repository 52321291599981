import type { FC, ReactNode } from 'react';
import React from 'react';
import {
  BottomsheetContainer,
  BottomsheetContext,
  ExtraBottomsheetContext,
} from '../contexts/BottomsheetContext';

export const BottomsheetProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <BottomsheetContext.Provider>
      <ExtraBottomsheetContext.Provider>
        <BottomsheetContainer />
      </ExtraBottomsheetContext.Provider>
      {children}
    </BottomsheetContext.Provider>
  );
};

export const ExtraBottomsheetProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <ExtraBottomsheetContext.Provider>{children}</ExtraBottomsheetContext.Provider>;
};
