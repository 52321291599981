import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faXTwitter } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useCopy } from '../../hooks/useCopy';
import type { ShareBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

const ShareBottomsheet: FC<ShareBottomsheetProps> = ({
  link,
  entity = 'vault',
  artistName,
  withVaultTheme,
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { pathname } = useLocation();

  const { copy: onCopyLinkClick } = useCopy({
    text: link,
    successMessage: 'Shareable link copied to clipboard!',
  });

  const onExternalSiteClick = (sitename: 'Facebook' | 'Instagram' | 'X') => async () => {
    trackEvent({
      type: EVENTS.EXTERNAL_LINK,
      properties: { type: sitename.toLowerCase() as 'facebook' | 'x' | 'instagram' },
      pathname,
    });

    closeBottomsheet();
    onCopyLinkClick();
    window.open(getSharingUrlPathForSite({ url: link, site: sitename, entity, artistName }));
  };

  const buttons = [
    {
      name: 'X',
      onClick: onExternalSiteClick('X'),
      icon: faXTwitter,
      bgColor: withVaultTheme ? 'bg-vault_text' : 'bg-white',
      iconColor: withVaultTheme ? 'text-vault_text_opposite' : 'text-black',
    },
    {
      name: 'Copy link',
      onClick: () => {
        trackEvent({
          type: EVENTS.COPY,
          properties: { type: 'link' },
          pathname,
        });

        closeBottomsheet();
        onCopyLinkClick();
      },
      icon: faLink,
      bgColor: withVaultTheme ? 'bg-vault_text' : 'bg-yellow100',
      iconColor: withVaultTheme ? 'text-vault_text_opposite' : 'text-black',
    },
  ];

  return (
    <View className="w-full md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-row items-center justify-around">
        {buttons.map(({ onClick, bgColor, iconColor, name, icon }) => {
          return (
            <View className="flex flex-col items-center justify-center text-center" key={name}>
              <View
                onClick={onClick}
                className={twMerge(
                  'mx-4 mb-4 flex h-[60px] w-[60px] items-center justify-center rounded-full hover:cursor-pointer',
                  bgColor,
                )}
              >
                <FontAwesomeIcon icon={icon} className={twMerge('size-8', iconColor)} />
              </View>
              <Text
                className={twMerge(
                  'font-normal',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                {name}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export const getSharingUrlPathForSite = ({
  url,
  site,
  entity,
  artistName,
}: {
  url: string;
  site: 'X' | 'Instagram' | 'Facebook';
  entity: 'vault' | 'song';
  artistName: string;
}) => {
  const link = url || window.location.href;

  const message =
    entity === 'vault'
      ? `Check%20out%20this%20vault%20from%20${artistName.replaceAll(' ', '%20')}`
      : `Check%20out%20this%20unreleased%20track%20from%20${artistName.replaceAll(' ', '%20')}`;

  switch (site) {
    case 'Facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${link}`;
    case 'X':
      return `https://twitter.com/intent/tweet?text=${message}%0A%0A${link}`;
    case 'Instagram':
      return isMobile ? 'instagram-stories://share' : 'https://www.instagram.com/';
    default:
      return '';
  }
};

export { ShareBottomsheet };
