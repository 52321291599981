import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App.jsx';
import './sentry.ts';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

declare module 'valtio' {
  function useSnapshot<T extends object>(p: T, options?: { sync?: boolean }): T;
  function snapshot<T extends object>(p: T): T;
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
