const pluralizeText = ({ count, text }: { count: number; text: string }) => {
  return count != 1 ? `${text}s` : text;
};

function daysFromToday(targetDateStr: string): number {
  const targetDate = new Date(targetDateStr);
  const today = new Date();

  targetDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const timeDiff = targetDate.getTime() - today.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff < 0 ? 0 : daysDiff;
}

const formatDateString = ({
  date,
  format,
}: {
  date: string | number;
  format?:
    | 'month_day'
    | 'month_year'
    | 'month_day_year'
    | 'numerical_month_year'
    | 'numerical_month_day_year'
    | 'numerical_month_day';
}) => {
  const d = new Date(date);
  switch (format) {
    case 'month_day':
      return `${d.toDateString().split(' ')[1]} ${d.getDate()}`;
    case 'month_year':
      return `${d.toDateString().split(' ')[1]} ${d.getFullYear()}`;
    case 'month_day_year':
      return `${d.toDateString().split(' ')[1]} ${d.getDate()}, ${d.getFullYear()}`;
    case 'numerical_month_year':
      return `${d.getMonth() + 1}/${d.getFullYear() % 100}`;
    case 'numerical_month_day':
      return `${d.getMonth() + 1}/${d.getDate()}`;
    case 'numerical_month_day_year':
    default:
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear() % 100}`;
  }
};

const formatNumberText = (
  price: number,
  maxDecimals = 2,
  prefixSymbol = '',
  includePlus = false,
): string => {
  if (maxDecimals < 0 || maxDecimals > 3) {
    // Don't support less than 0 or greater than 3 decimals
    return price.toFixed(maxDecimals);
  }

  const sign = price < 0 ? '-' : includePlus ? '+' : '';
  price = Math.abs(price);

  // Greater than 1B
  if (price >= 1000000000) {
    let roundedStat = (price / 1000000000).toFixed(1);
    if (roundedStat.endsWith('0') || roundedStat.indexOf('.') === 3) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 2);
    }
    return `${sign}${prefixSymbol}${roundedStat}B`;
  }

  // Greater than 1M
  if (price >= 1000000) {
    let roundedStat = (price / 1000000).toFixed(1);
    if (roundedStat.endsWith('0') || roundedStat.indexOf('.') === 3) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 2);
    }
    return `${sign}${prefixSymbol}${roundedStat}M`;
  }

  // Greater than 1K
  if (price >= 1000) {
    let roundedStat = (price / 1000).toFixed(1);
    if (roundedStat.endsWith('0') || roundedStat.indexOf('.') === 3) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 2);
    }
    return `${sign}${prefixSymbol}${roundedStat}K`;
  }

  if (price === 0) {
    return '0';
  }

  // Small number
  if (price < 1) {
    let roundedStat = price.toFixed(maxDecimals);

    if (maxDecimals === 0) {
      return `${sign}${prefixSymbol}${Math.round(price).toString()}`;
    }

    const [zeroValue, lessThanValue] =
      maxDecimals === 1
        ? ['0.0', '0.1']
        : maxDecimals === 2
          ? ['0.00', '0.01']
          : ['0.000', '0.001'];

    if (roundedStat === zeroValue) {
      return `< ${sign}${prefixSymbol}${lessThanValue}`;
    }

    if (maxDecimals > 0 && roundedStat.endsWith('0')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    if (maxDecimals > 1 && roundedStat.endsWith('0')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    if (maxDecimals > 2 && roundedStat.endsWith('0')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    if (roundedStat.endsWith('.')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    return `${sign}${prefixSymbol}${roundedStat}`;
  }

  // 2 digit number
  if (price < 10) {
    let roundedStat = price.toFixed(Math.min(2, maxDecimals));
    if (maxDecimals > 0 && roundedStat.includes('.') && roundedStat.endsWith('0')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    if (maxDecimals > 1 && roundedStat.includes('.') && roundedStat.endsWith('0')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    if (roundedStat.endsWith('.')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }

    return `${sign}${prefixSymbol}${roundedStat}`;
  }

  // 3 digit number
  if (price < 100) {
    let roundedStat = price.toFixed(Math.min(2, maxDecimals));
    if (maxDecimals > 0 && roundedStat.includes('.') && roundedStat.endsWith('0')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    if (roundedStat.endsWith('.')) {
      roundedStat = roundedStat.slice(0, roundedStat.length - 1);
    }
    return `${sign}${prefixSymbol}${roundedStat}`;
  }

  // between 100-999
  return `${sign}${prefixSymbol}${price.toFixed(0)}`;
};

const formatTime = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60) || 0;
  const seconds = Math.floor(totalSeconds) - minutes * 60 || 0;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

function maskPhoneNumber(phone?: string | null): string | null {
  const pattern = /^(\+\d+)\s?([\d\s]+)$/;

  if (!phone || !pattern.test(phone)) {
    return null;
  }

  const match = phone.match(pattern);
  if (!match || !match[2]) {
    return null;
  }

  const countryCode = match[1];
  const remainingNumbers = match[2];

  const digitsOnly = remainingNumbers.replace(/\s/g, '');

  if (digitsOnly.length < 4) {
    return null;
  }
  let masked = '';
  let index = 0;
  for (const char of remainingNumbers) {
    if (char === ' ') {
      masked += char; // Preserve the space
    } else {
      // Replace digits with asterisks, except for the last four
      if (index < digitsOnly.length - 4) {
        masked += '*';
      } else {
        masked += digitsOnly[index];
      }
      index++;
    }
  }

  return `${countryCode} ${masked}`;
}

/**
 * Formats a number as a serial number with leading zeros
 */
function formatSerialNumber(num: number | null | undefined): string {
  if (!num) return '';
  const numStr = num.toString();

  if (numStr.length < 7) {
    return numStr.padStart(7, '0');
  }
  return numStr;
}

export {
  pluralizeText,
  daysFromToday,
  formatDateString,
  formatNumberText,
  formatTime,
  maskPhoneNumber,
  formatSerialNumber,
};
