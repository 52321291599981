import type { FC } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import type { BillingRowFragment } from '../../graphql/generated';
import { formatDateString } from '../../utils/textUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';

type Props = { billing: BillingRowFragment };

const BillingRow: FC<Props> = ({ billing }) => {
  let status: string;
  let statusClassName: string;

  switch (billing.status) {
    case 'succeeded': {
      status = 'Paid';
      statusClassName = 'bg-yellow100';
      break;
    }
    case 'failed': {
      status = 'Failed';
      statusClassName = 'bg-destructive300';
      break;
    }
    default: {
      status = 'Pending';
      statusClassName = 'bg-base400';
      break;
    }
  }

  const billingRecipient =
    billing.recipientName && billing.recipientName?.length > 0 ? billing.recipientName : 'Unknown';

  return (
    <View className="flex w-full flex-row items-center justify-between border-solid border-x-transparent border-b-transparent border-t-base800 py-[20px] ">
      <View className="flex flex-col pr-[8px]">
        <Text className="font-title text-title-s font-medium text-white">{billingRecipient}</Text>
        <Text className="mt-1 font-base text-[14px]/[18px] font-normal text-base500">
          {formatDateString({ date: billing.timestamp * 1000, format: 'month_day_year' })} • $
          {billing.amount}
        </Text>
      </View>
      <Text
        className={twMerge(
          'rounded-full px-[6px] py-[2px] font-base text-[14px]/[18px] font-semibold text-black',
          statusClassName,
        )}
      >
        {status}
      </Text>
    </View>
  );
};

const SkeletonBillingRow = () => {
  return (
    <View className="flex w-full flex-row items-center justify-between border-solid border-x-transparent border-b-transparent border-t-base800 py-[20px]">
      <View className="flex flex-col pr-[8px]">
        <LoadingSkeleton className="mb-2 h-5 w-[100px]" />
        <LoadingSkeleton className="h-5 w-[100px]" />
      </View>
      <View className="flex flex-row items-center">
        <LoadingSkeleton className="mr-2 h-5 w-[100px]" />
        <LoadingSkeleton className="h-5 w-[50px]" />
      </View>
    </View>
  );
};

export { BillingRow, SkeletonBillingRow };
