import { useNavigate, useSearchParams } from 'react-router-dom';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../../contexts/BottomsheetContext';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { constructQueryParams } from '../../../utils/stringUtils';

export const useJoinToGetStartedUpsell = ({
  vaultId,
  artistHandle,
  artistName,
  vaultArtistProfileImage,
  vaultArtistMembershipCardImage,
  hasActiveSubscription,
}: {
  vaultId: string;
  artistHandle: string;
  artistName: string | null;
  vaultArtistProfileImage: string | null;
  vaultArtistMembershipCardImage: string | null;
  hasActiveSubscription: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openExtraBottomsheet } = useExtraBottomsheetContainer();
  const inviteCode = searchParams.get('code');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const { loggedInUser, loginStatus } = useAuthContext();
  const navigate = useNavigate();

  const onIsLocked = () => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: 'JOIN_VAULT',
        joinType: 'free',
        artistHandle,
      },
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.JOIN_VAULT,
      shared: {
        withVaultTheme: true,
      },
      joinVaultBottomsheetProps: {
        artistHandle,
        vaultArtistProfileImage,
        joinType: 'free',
        buttonTitle: 'Join for free',
        title: 'Join this vault to get started',
        onConfirm() {
          if (hasActiveSubscription) {
            const queryParams = constructQueryParams({
              code: inviteCode,
              c: smsCampaignResponseShortcode,
            });
            navigate(artistNavigationPath(artistHandle, '/subscribe', queryParams));
            return;
          }

          // If logged in, show membership confirmation bottomsheet
          if (!!loggedInUser?.id) {
            openExtraBottomsheet({
              type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
              membershipConfirmationBottomsheetProps: {
                vaultId,
                isLoading: false,
                artistHandle,
                artistName: artistName,
                imageUrl: vaultArtistMembershipCardImage,
                loggedInUserUsername: loggedInUser?.username,
                loginStatus,
                inviteCode,
                smsCampaignResponseShortcode,
                sourceReleaseCampaignId: null,
              },
              shared: {
                hideCloseBottomsheetButton: false,
                preventSwipeToDismiss: false,
                preventOutsideAutoClose: true,
                hidePulleyBar: true,
                withVaultTheme: true,
              },
            });

            return;
          }

          // If not logged in, take to sign in page
          navigate(
            `${ROUTES.SIGN_IN}?${constructQueryParams({ artistHandle, inviteCode, c: smsCampaignResponseShortcode, openBottomSheet: 'freeTierModal' })}`,
          );
        },
      },
    });
  };

  return { onIsLocked };
};
