import { proxy } from 'valtio';
import { MediaType } from '../graphql/generated';
import { fileIdentifier } from '../utils/s3Utils';

export const VideoAttachment = proxy<{
  video: {
    identifier: string;
    progress: number;
    objectUrl: string;
    uploaded: boolean;
    mediaId: string | null;
    cdnUrl: string | null;
    type: MediaType;
  } | null;
  isUploading: boolean;
  setVideo: (file: File) => void;
  updateVideoWithUploadResult: (mediaId: string | null, cdnUrl: string | null) => void;
  updateProgress: (progress: number) => void;
  clearVideo: () => void;
}>({
  video: null,
  isUploading: false,
  setVideo(file: File) {
    const video = {
      identifier: fileIdentifier(file),
      progress: 0,
      objectUrl: URL.createObjectURL(file),
      uploaded: false,
      mediaId: null,
      cdnUrl: null,
      type: MediaType.Video,
    };
    VideoAttachment.video = video;
  },
  updateProgress(progress: number) {
    if (VideoAttachment.video) VideoAttachment.video = { ...VideoAttachment.video, progress };
  },
  updateVideoWithUploadResult(mediaId: string | null, cdnUrl: string | null) {
    if (VideoAttachment.video) {
      VideoAttachment.video.mediaId = mediaId;
      VideoAttachment.video.cdnUrl = cdnUrl;
      VideoAttachment.video.uploaded = true;
    }
  },
  clearVideo() {
    if (VideoAttachment.video) URL.revokeObjectURL(VideoAttachment.video.objectUrl);
    VideoAttachment.isUploading = false;
    VideoAttachment.video = null;
  },
});
