import type { FC, ReactNode } from 'react';
import React from 'react';
import { ToastContainer, ToastContext } from '../contexts/ToastContext';

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ToastContext.Provider>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};
