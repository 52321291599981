import { useCallback } from 'react';
import { captureException } from '@sentry/react';
import { proxy } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  CreateVaultFolderDocument,
  EditVaultFolderDocument,
  GetAllFoldersDocument,
  GetFolderInformationDocument,
  VaultContentAccessFeatureInput,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
} from '../../graphql/generated';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';

export const folderToEdit: { current: { id: string; title: string; isEditing: boolean } | null } =
  proxy({
    current: null,
  });

gql(/* GraphQL */ `
  mutation CreateVaultFolder($input: MutationCreateVaultFolderInput!) {
    createVaultFolder(input: $input) {
      __typename
      ... on MutationCreateVaultFolderSuccess {
        data {
          id
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation EditVaultFolder($input: MutationUpdateVaultFolderInput!) {
    updateVaultFolder(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [CreateVaultFolderDocument, EditVaultFolderDocument],
  refetch: [
    VaultContentByFolderDocument,
    GetFolderInformationDocument,
    GetAllFoldersDocument,
    VaultContentByFolderPositionDocument,
  ],
});

RefetchOnComplete({
  trigger: [VaultContentByFolderDocument, VaultContentByFolderPositionDocument],
  refetch() {
    if (folderToEdit.current != null && !folderToEdit.current.isEditing) {
      setTimeout(() => (folderToEdit.current = null), 100);
    }
  },
});

export const FOLDER_TITLE_MAX_LENGTH = 50;
export const FOLDER_TITLE_MIN_LENGTH = 3;

export function useVaultFolder({ vaultId }: { vaultId: false | string }) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { closeOverlay } = useOverlayContainer();
  const { openToast } = useToast();

  const { mutate: editVaultFolderMutate, isLoading: isEditingFolder } = useMutation(
    EditVaultFolderDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (data.data.updateVaultFolder.__typename !== 'MutationUpdateVaultFolderSuccess') {
          openToast({
            text: 'An error occurred while editing the folder. Please try again later.',
            variant: 'error',
          });
          closeBottomsheet();
          closeOverlay();
        }

        if (folderToEdit.current != null) {
          folderToEdit.current.isEditing = false;
        }
      },
    },
  );

  const editVaultFolder = useCallback(async () => {
    if (vaultId === false) {
      return;
    }

    if (folderToEdit.current != null) {
      trackEvent({
        type: EVENTS.EDIT_FOLDER,
        properties: {
          folderId: folderToEdit.current.id,
          vaultId,
        },
      });

      return editVaultFolderMutate({
        input: {
          folderId: folderToEdit.current.id,
          title: folderToEdit.current.title,
          vaultId,
        },
      });
    }
    return null;
  }, [editVaultFolderMutate, vaultId]);

  const { mutateAsync: createFolderMutation, isLoading: isCreatingFolder } = useMutation(
    CreateVaultFolderDocument,
    {
      retry: 3,
    },
  );

  const createFolder = useCallback(
    async ({
      title = 'New folder',
      folderId,
      onSuccess,
    }: {
      title?: string;
      folderId?: string | null;
      onSuccess?: () => void;
    }) => {
      if (vaultId === false) {
        return;
      }

      try {
        const {
          data: { createVaultFolder: createVaultFolderData },
        } = await createFolderMutation({
          input: {
            title,
            parentVaultContentId: folderId,
            vaultId,
            featureAccess: VaultContentAccessFeatureInput.FreeVaultContent,
          },
        });

        if (createVaultFolderData?.__typename !== 'MutationCreateVaultFolderSuccess') {
          openToast({
            text: 'An error occurred while creating the folder. Please try again later.',
            variant: 'error',
          });
          closeBottomsheet();
          return;
        }
        const { id } = createVaultFolderData.data;

        trackEvent({
          type: EVENTS.CREATE_FOLDER,
          properties: {
            newFolderId: id,
            vaultId,
          },
        });

        closeBottomsheet();
        onSuccess?.();
        return;
      } catch (error) {
        captureException(error, {
          extra: {
            isMovingFolder: true,
          },
        });
        openToast({
          text: 'An error occurred while creating the folder. Please try again later.',
          variant: 'error',
        });
      }
    },
    [closeBottomsheet, createFolderMutation, openToast, vaultId],
  );

  return {
    createFolder,
    isCreatingFolder,
    editVaultFolder,
    isEditingFolder,
  };
}
