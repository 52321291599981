import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ACCEPTED_IMAGE_TYPES } from '../../constants/fileConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useCampaignForm } from './useCampaignForm';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
const calculateCircumference = (radius: number) => 2 * Math.PI * radius;

export const CampaignImageUpload = ({ artistId }: { artistId: string | undefined | null }) => {
  const { openToast } = useToast();
  const { closeBottomsheet } = useBottomsheetContainer();
  const { fields, isUploading, progress, setField, uploadCampaignImage } = useCampaignForm();

  const radius = 12;
  const circumference = calculateCircumference(radius);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_IMAGE_TYPES,
    multiple: false,
    maxSize: MAX_FILE_SIZE,
    disabled: isUploading,
    onDrop: async (acceptedFiles, fileRejections) => {
      const limitExceeded = fileRejections.some(rejection =>
        rejection.errors.some(error => error.code === 'too-many-files'),
      );

      if (limitExceeded) {
        openToast({
          text: `You can only upload one image file at a time. Please select fewer files.`,
          variant: 'error',
        });
        return;
      }

      const sizeError = fileRejections.some(rejection =>
        rejection.errors.some(error => error.code === 'file-too-large'),
      );

      if (sizeError) {
        openToast({
          text: 'Please upload files smaller than 20MB.',
          variant: 'error',
        });
        return;
      }

      if (acceptedFiles.length === 1 && !!artistId) {
        await uploadCampaignImage(artistId, acceptedFiles[0] as File);
        closeBottomsheet();
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className="relative mx-auto flex aspect-square w-full max-w-[232px] cursor-pointer items-center justify-center rounded-lg bg-vault_text/5"
    >
      <input {...getInputProps()} />
      {isUploading ? (
        <>
          <View className="absolute inset-0 rounded-lg" />
          <svg
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <circle
              cx="20"
              cy="20"
              r={radius}
              fill="transparent"
              stroke="#737373"
              strokeWidth="4"
            />
            <circle
              cx="20"
              cy="20"
              r={radius}
              fill="transparent"
              stroke="#fff"
              strokeWidth="4"
              strokeDasharray={circumference}
              strokeDashoffset={circumference - (Math.min(90, progress) / 100) * circumference}
              strokeLinecap="round"
              transform="rotate(-90 20 20)"
            />
          </svg>
        </>
      ) : (fields.image && fields.mediaId) || fields.thirdPartyImageUrl ? (
        <div className="relative h-full w-full">
          <img
            src={fields.image || fields.thirdPartyImageUrl || ''}
            alt="Uploaded Image"
            className="h-full w-full rounded-lg object-cover"
          />
          <button
            className="absolute right-3 top-3 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border-none bg-black/80 outline-none"
            onClick={e => {
              e.stopPropagation();
              setField('image', '');
              setField('mediaId', null);
              setField('thirdPartyImageUrl', null);
            }}
          >
            <FontAwesomeIcon icon={faClose} color="white" />
          </button>
        </div>
      ) : (
        <View className="flex flex-col items-center justify-center gap-2">
          <View className="flex h-6 w-6 items-center justify-center rounded-full bg-vault_text/10 p-3">
            <FontAwesomeIcon icon={faPlus} fontSize="20" className="text-vault_text" />
          </View>
          <Text className="mx-8 text-center text-base-m text-vault_text/40">
            Add song artwork .jpg, .png up to 20mb
          </Text>
        </View>
      )}
    </div>
  );
};
