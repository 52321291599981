import ms from 'ms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useToast } from '../../../contexts/ToastContext';
import { invalidateOperations, useMutation } from '../../../graphql/client';
import { AttachPayeeDocument, AuthUserDocument } from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { Sentry } from '../../../sentry';

export function useAttachPayee() {
  const [searchParams] = useSearchParams();

  const { loggedInUser } = useAuthContext();

  const payeeSecretId = searchParams.get('psid');

  const { mutateAsync: attachPayee } = useMutation(AttachPayeeDocument, {
    retry: 3,
  });

  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  const attachPayeeIfPresent = useStableCallback(
    ({ onError, onWithoutPayee }: { onError?(): void; onWithoutPayee?(): void } = {}): boolean => {
      if (!payeeSecretId) {
        onWithoutPayee?.();
        return false;
      }

      openBottomsheet({
        type: BOTTOMSHEET_TYPES.ACCEPT_COLLABORATION,
        acceptCollaborationBottomsheetProps: {
          payeeSecretId,
          onConfirm({ attachArtistId, onAttachSuccess }) {
            return attachPayee({ input: { payeeSecretId, attachArtistId } })
              .then(({ data }) => {
                if (data.attachPayeeToUser.__typename !== 'MutationAttachPayeeToUserSuccess') {
                  openToast({
                    variant: 'error',
                    text: data.attachPayeeToUser.message,
                    duration: ms('10 seconds'),
                  });

                  onError?.();

                  return;
                }
                invalidateOperations({ operations: [AuthUserDocument] }).finally(() => {
                  navigate(
                    attachArtistId != null
                      ? ROUTES.SETTINGS_ARTIST_PAYOUTS
                      : ROUTES.SETTINGS_COLLABORATOR_PAYOUTS,
                  );
                });
                onAttachSuccess();
              })
              .catch(error => {
                Sentry.captureException(error, {
                  extra: {
                    payeeSecretId,
                    loggedInUser,
                  },
                  tags: {
                    type: 'attachPayeeMutation',
                  },
                });

                onError?.();
              });
          },
          onError() {
            onError?.();
          },
        },
        shared: {
          hideCloseBottomsheetButton: true,
          preventOutsideAutoClose: true,
        },
      });

      return true;
    },
  );

  return {
    attachPayeeIfPresent,
  };
}
