import React, { useRef } from 'react';

import { captureException } from '@sentry/react';
import { fileTypeFromBuffer } from 'file-type';
import { useDropzone } from 'react-dropzone';

import { useLocation } from 'react-router';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { ImageCropModal } from '../components/modals/ImageCropModal';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { ACCEPTED_IMAGE_TYPES } from '../constants/fileConstants';
import { ModalType } from '../constants/modalConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';
import { useToast } from '../contexts/ToastContext';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { humanFileSize } from '../utils/imageUtils';
import { uploadMultipartFile } from '../utils/s3Utils';

const MAX_AVATAR_IMAGE_SIZE = 30000000;

export function useUploadAvatar({
  onSuccess,
  onDone,
  onConfirm,
}: {
  onSuccess(args: { mediaId: string; cdnUrl: string }): void | Promise<void>;
  onDone?: () => void;
  onConfirm?: () => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [progressPercentage, setProgressPercentage] = React.useState(0);

  const { openToast } = useToast();

  const { openModal } = useModal();

  const { loggedInUser } = useAuthContext();

  const saveCroppedImage = useStableCallback(async (file: File) => {
    setProgressPercentage(0);

    onConfirm?.();

    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: 'IMAGE',
        setProgress(bytes) {
          setProgressPercentage(Math.floor((bytes / file.size) * 100));
        },
        artistId: loggedInUser?.artist?.id,
      });

      await onSuccess({ mediaId, cdnUrl });
      setProgressPercentage(100);
    } catch (error) {
      setProgressPercentage(0);
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'useUpdateAvatar',
        },
      });
    } finally {
      onDone?.();
    }
  });

  const showAvatarCropDialog = useStableCallback(async (selectedFile: File) => {
    try {
      const fileUrl = URL.createObjectURL(selectedFile);
      const buffer = await selectedFile.arrayBuffer();
      const uint8Array = new Uint8Array(buffer);
      const fileType = await fileTypeFromBuffer(uint8Array);

      if (fileType?.mime && !Object.keys(ACCEPTED_IMAGE_TYPES).includes(fileType.mime)) {
        openToast({
          text: 'Images can only be JPG, PNG or HEIF. Please try again.',
          variant: 'error',
        });
        return;
      }

      openModal(ModalType.IMAGE_CROP, {
        body: (
          <ImageCropModal
            modalTitle="Crop your profile image"
            blobURL={fileUrl}
            setBlob={(b: Blob | null) => {
              if (!b) return;
            }}
            saveImageMutation={saveCroppedImage}
            closeOnUpload
            handleReselectImage={() => {
              inputRef.current?.click();
            }}
            cropShape="round"
            showGrid={false}
          />
        ),
      });
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: selectedFile.name,
          selectedFileSize: selectedFile.size,
          selectedFileType: selectedFile.type,
          feature: 'useUpdateAvatar',
        },
      });
    }
  });

  const { pathname } = useLocation();

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noDragEventsBubbling: true,
    onDropAccepted([file]) {
      if (!file) {
        openToast({
          variant: 'error',
          text: `File could not be uploaded. Please make sure the browser supports file uploads.`,
        });
        return;
      }

      trackEvent({
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: { bottomsheetType: BOTTOMSHEET_TYPES.PROFILE_PICTURE },
        pathname,
      });

      showAvatarCropDialog(file);
    },
    onDropRejected() {
      openToast({
        text: `File could not be uploaded. Make sure it is a JPG, PNG or HEIF file and less than ${humanFileSize(MAX_AVATAR_IMAGE_SIZE, true)}.`,
        variant: 'error',
      });
    },
    accept: ACCEPTED_IMAGE_TYPES,
    maxFiles: 1,
    maxSize: MAX_AVATAR_IMAGE_SIZE,
    disabled: false,
  });

  return {
    getRootProps,
    getInputProps,
    inputRef,
    progressPercentage,
    open,
  };
}
