import { type FC, useEffect, useMemo, useState } from 'react';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  format,
} from 'date-fns';
import { useSnapshot } from 'valtio';
import { useLatestRef } from '../../hooks/useLatestRef';
import { globalTimerInterval } from '../../hooks/useTimer';
import { Text } from './Text';

type Props = {
  date: string;
  formatType: 'long' | 'short';
  className?: string;
  shouldIncludeAgo?: boolean;
  shouldIncludeYearsMonths?: boolean;
  endDate?: string;
};

export const TimestampText: FC<Props> = props => {
  const { date } = props;
  const [timestamp, setTimestamp] = useState<string | null>(() => getTimestampText(props));

  const { now } = useSnapshot(globalTimerInterval);

  const latestProps = useLatestRef(props);

  const nowMinutes = useMemo(() => {
    const date = new Date(now);

    return date.getMinutes();
  }, [now]);

  const every5Seconds = useMemo(() => {
    const date = new Date(now);

    return date.getSeconds() % 5 === 0;
  }, [now]);

  /**
   * Every time the minute changes or every 5 seconds, we update the timestamp
   */
  useEffect(() => {
    setTimestamp(getTimestampText(latestProps.current));
  }, [nowMinutes, every5Seconds, date, latestProps]);

  if (timestamp == null) {
    return null;
  }

  return <Text className={props.className}>{timestamp}</Text>;
};

export const getTimestampText = ({
  date,
  formatType,
  shouldIncludeAgo,
  shouldIncludeYearsMonths,
  endDate,
}: Props): string | null => {
  const start = new Date(date);

  const end = endDate != null ? new Date(endDate) : new Date();

  const years = differenceInYears(end, start);
  const months = differenceInMonths(end, start);
  const weeks = differenceInWeeks(end, start);
  const days = differenceInDays(end, start);
  const hours = differenceInHours(end, start);
  const minutes = differenceInMinutes(end, start);
  const seconds = differenceInSeconds(end, start);

  if (
    years == undefined ||
    months == undefined ||
    weeks == undefined ||
    days == undefined ||
    hours == undefined ||
    minutes == undefined ||
    seconds == undefined
  ) {
    return null;
  }

  if (years > 0) {
    if (shouldIncludeYearsMonths) {
      return (
        (formatType == 'long' ? `${years} year${years !== 1 ? 's' : ''}` : `${years}y`) +
        (shouldIncludeAgo ? ' ago' : '')
      );
    } else {
      return format(start, 'yy-MM-dd');
    }
  }

  if (months > 0) {
    if (shouldIncludeYearsMonths) {
      return (
        (formatType == 'long' ? `${months} month${months !== 1 ? 's' : ''}` : `${months}mo`) +
        (shouldIncludeAgo ? ' ago' : '')
      );
    } else {
      return format(start, 'yy-MM-dd');
    }
  }

  if (weeks > 0) {
    return (
      (formatType == 'long' ? `${weeks} week${weeks !== 1 ? 's' : ''}` : `${weeks}w`) +
      (shouldIncludeAgo ? ' ago' : '')
    );
  }

  if (days > 0) {
    return (
      (formatType == 'long' ? `${days} day${days !== 1 ? 's' : ''}` : `${days}d`) +
      (shouldIncludeAgo ? ' ago' : '')
    );
  }

  if (hours > 0) {
    return (
      (formatType === 'long' ? `${hours} hour${hours !== 1 ? 's' : ''}` : `${hours}h`) +
      (shouldIncludeAgo ? ' ago' : '')
    );
  }

  if (minutes > 0) {
    return (
      (formatType === 'long' ? `${minutes} minute${minutes !== 1 ? 's' : ''}` : `${minutes}m`) +
      (shouldIncludeAgo ? ' ago' : '')
    );
  }

  if (seconds > 15) {
    const value = Math.round(seconds);
    return (
      (formatType === 'long' ? `${value} second${value !== 1 ? 's' : ''}` : `${value}s`) +
      (shouldIncludeAgo ? ' ago' : '')
    );
  }

  return 'now';
};
