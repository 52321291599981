import { captureException } from '@sentry/react';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation } from '../../../graphql/client';
import { SignedTrackDownloadUrlByTrackIdDocument } from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useJoinToGetStartedUpsell } from './useJoinToGetStartedUpsell';

gql(/* GraphQL */ `
  query SignedTrackDownloadUrlByTrackId($trackContentId: UUID!) {
    getSignedTrackDownloadContent(trackContentId: $trackContentId) {
      __typename
      ... on Error {
        message
      }
      ... on QueryGetSignedTrackDownloadContentSuccess {
        data
      }
    }
  }
`);

export const useTrackDownloadPress = ({
  trackContentId,
  isLocked,
  vaultId,
  artistHandle,
  artistName,
  vaultArtistProfileImage,
  vaultArtistMembershipCardImage,
  hasActiveSubscription,
}: {
  trackContentId: string;
  isLocked: boolean;
  vaultId: string;
  artistHandle: string;
  artistName: string | null;
  vaultArtistProfileImage: string | null;
  vaultArtistMembershipCardImage: string | null;
  hasActiveSubscription: boolean;
}) => {
  const { mutateAsync: fetchDownloadUrl } = useMutation(SignedTrackDownloadUrlByTrackIdDocument, {
    retry: 3,
  });

  const { openToast } = useToast();
  const { onIsLocked } = useJoinToGetStartedUpsell({
    vaultId,
    artistHandle,
    artistName,
    vaultArtistProfileImage,
    vaultArtistMembershipCardImage,
    hasActiveSubscription,
  });

  const downloadTrack = useStableCallback(async () => {
    try {
      const { data } = await fetchDownloadUrl({
        trackContentId,
      });

      if (
        data.getSignedTrackDownloadContent.__typename ===
        'QueryGetSignedTrackDownloadContentSuccess'
      ) {
        window.open(data.getSignedTrackDownloadContent.data, '_blank', 'noopener,noreferrer');
      } else {
        openToast({
          text: data.getSignedTrackDownloadContent.message,
          variant: 'error',
        });
      }
    } catch (e) {
      captureException(e, {
        extra: {
          trackContentId,
        },
      });
      openToast({
        text: 'Error fetching download url. Please try again later.',
        variant: 'error',
      });
    }
  });

  const onDownloadClick = async () => {
    if (isLocked) {
      onIsLocked();
      return;
    }

    downloadTrack();
  };

  return { onDownloadClick };
};
