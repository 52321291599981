import { string } from 'zod';

export const usernameSchema = string()
  .min(3, { message: 'Username must be at least 3 characters' })
  .max(20, { message: 'Max length 20 characters' })
  .regex(/^[-_a-zA-Z0-9]+$/, {
    message: 'Only lowercase letters, numbers, hyphens, and underscores',
  });

export function removeInvalidUsernameChars(input: string): string {
  // Replace any character that is not a letter, digit, underscore, or hyphen
  return input.replace(/[^-_a-zA-Z0-9]/g, '');
}
