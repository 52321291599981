import * as Sentry from '@sentry/react';

import type { Breadcrumb } from '@sentry/react';
import type { BreadcrumbHint } from '@sentry/react';
import { ZodError } from 'zod';

const SEND_DEBUG_ERRORS = import.meta.env.VITE_SEND_DEBUG_ERRORS === 'true';

const BREADCRUMB_URL_SEGMENTS_TO_IGNORE = ['vault.fm', 'sound.xyz', 'walletconnect'];

export const beforeBreadcrumb = (breadcrumb: Breadcrumb, hint: BreadcrumbHint | undefined) => {
  if (['fetch', 'xhr'].includes(breadcrumb.category || '')) {
    const url = hint?.response?.url;
    for (const urlSegment of BREADCRUMB_URL_SEGMENTS_TO_IGNORE) {
      if (url?.includes(urlSegment)) {
        return null;
      }
    }
  }
  return breadcrumb;
};

/**
 * Filters known (ignorable) errors out before sending them to Sentry. Also, adds tags to the event.
 * Returning null filters the error from Sentry.
 */
const beforeSend: Parameters<typeof Sentry.init>[0]['beforeSend'] = (event, hint) => {
  if (event.level === 'debug' && !SEND_DEBUG_ERRORS) {
    return null;
  }

  if (shouldRejectError(hint.originalException)) {
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#filtering-error-events
    // null drops event
    return null;
  }

  return event;
};

// src: https://github.com/Uniswap/interface/blob/main/src/tracing/errors.ts
function shouldRejectError(error: unknown) {
  if (error instanceof Error) {
    // If the error is a network change, it should not be considered an exception.
    if (error.message.match(/underlying network changed/)) return true;

    // This is caused by HTML being returned for a chunk from Cloudflare.
    // Usually, it's the result of a 499 exception right before it, which should be handled.
    // Therefore, this can be ignored.
    if (error.message.match(/Unexpected token '<'/)) return true;

    // Errors coming from a browser extension can be ignored. These errors are usually caused by extensions injecting
    // scripts into the page, which we cannot control.
    if (error.stack?.match(/-extension:\/\//i)) return true;

    // Errors coming from OneKey (a desktop wallet) can be ignored for now.
    // These errors are either application-specific, or they will be thrown separately outside of OneKey.
    if (error.stack?.match(/OneKey/i)) return true;

    // Content security policy 'unsafe-eval' errors can be filtered out because there are expected failures.
    // For example, if a user runs an eval statement in console this error would still get thrown.
    if (error.message.match(/'unsafe-eval'.*content security policy/i)) {
      return true;
    }

    // WebAssembly compilation fails because we do not allow 'unsafe-eval' in our CSP.
    // Any thrown errors are due to 3P extensions/applications, so we do not need to handle them.
    if (
      error.message.match(
        /WebAssembly.instantiate\(\): Wasm code generation disallowed by embedder/,
      )
    ) {
      return true;
    }

    // Filters out errors caused by checking for meta tags that may not exist.
    if (
      error.message.match(
        /null is not an object \(evaluating 'document\.querySelector\('meta\[[^\]]+\]'\)\.content'\)/,
      )
    ) {
      return true;
    }

    // filter out weird errors thrown from a RN Webview
    if (error.message.match(/Can't find variable: bytecode/)) {
      return true;
    }

    // These are caused by user navigation away from the page before a request has finished.
    if (error instanceof DOMException && error.name === 'AbortError') return true;
    // Trying to manipulate the DOM during a state where it is not allowed, such as when the document is still being loaded.
    if (error instanceof DOMException && error.name === 'OperationError') return true;
    // These are custom errors thrown by our code

    if (error instanceof ZodError) return true;
  }

  return false;
}

const SentryOptions: Sentry.BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [
    // TODO: Enable browser tracing
    // new Sentry.BrowserTracing({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', import.meta.env.VITE_GRAPHQL_API_URL],
    // }),
    // TODO: Enable replay
    // new Replay({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  // // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled:
    import.meta.env.VITE_DEPLOY_ENVIRONMENT !== 'development' &&
    window.location.hostname !== 'localhost',
  environment: import.meta.env.VITE_DEPLOY_ENVIRONMENT,
  beforeBreadcrumb,
  beforeSend,

  tunnel: 'https://sleuth.vault.fm',

  ignoreErrors: [
    'Not authorized!',
    'Failed to fetch',
    'AbortError: The user aborted a request.',
    'UnknownError: Unable to open database file on disk',
    /^Invariant Violation:(.+)/,
    /.*walletconnect.*/i,
    /.*IDBDatabase.*/i,
    /.*websocket error 1006.*/,
    /.*Failed to fetch dynamically imported module.*/,
    'ResizeObserver loop completed with undelivered notifications.',
  ],
};

Sentry.init(SentryOptions);

Sentry.setTag('version', process.env.NEXT_PUBLIC_WEBAPP_VERSION);

export { Sentry };
