import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export type TextInputModalProps = {
  title: string;
  confirmText: string;
  placeholder: string;
  onConfirm: (text: string) => Promise<void>;
  maxLength?: number;
  minLength?: number;
  initialValue?: string;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose?: () => void;
};

export function TextInputModal({
  title,
  confirmText,
  onConfirm,
  placeholder,
  maxLength,
  minLength = 0,
  initialValue,
  onInputChange,
  onClose,
}: TextInputModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { closeBottomsheet } = useBottomsheetContainer();
  const { closeOverlay } = useOverlayContainer();

  const [text, setText] = useState<string>(initialValue ?? '');

  const hasMinLength = text != null && text.trim().length >= minLength;
  const canSubmitText = !!text
    ? !!maxLength
      ? hasMinLength && text.trim().length <= maxLength
      : hasMinLength
    : false;

  useEffect(() => {
    return () => {
      onClose?.();
    };
  }, [onClose]);
  return (
    <View
      className={twMerge(
        'flex h-36 w-80 flex-col justify-between rounded-lg border border-solid p-4 pb-2',
        'border-vault_text/5 bg-vault_background',
      )}
    >
      <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">{title}</Text>

      <View className="border-b-1 flex w-full flex-row items-center border-x-0 border-t-0 border-solid border-vault_text/5 py-3">
        <input
          autoFocus
          className="w-full border-0 bg-vault_background !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none"
          placeholder={placeholder}
          value={text}
          onChange={e => {
            setText(e.target.value);
            onInputChange?.(e);
          }}
          minLength={minLength}
          maxLength={maxLength}
          onKeyDown={async e => {
            if (!canSubmitText) return;

            if (e.key === 'Enter') {
              setIsLoading(true);
              await onConfirm(text.trim());
              setIsLoading(false);
              closeBottomsheet();
            }
          }}
        />
      </View>

      <View className="flex justify-end gap-2">
        <Button
          type="secondary"
          label="Cancel"
          className="h-fit w-fit rounded-full bg-vault_text/20 px-5 py-3 text-[14px]/[18px] text-vault_text disabled:opacity-50"
          onClick={() => {
            closeBottomsheet();
            closeOverlay();
            onClose?.();
          }}
          disabled={isLoading}
        />
        <Button
          type="primary"
          label={confirmText}
          className={twMerge(
            'h-fit w-fit rounded-full px-5 py-3 text-[14px]/[18px] disabled:opacity-50',
            'bg-vault_accent text-vault_accent_text',
          )}
          onClick={async () => {
            if (!canSubmitText) return;

            setIsLoading(true);
            await onConfirm(text.trim());
            setIsLoading(false);
            closeBottomsheet();
          }}
          disabled={isLoading || !canSubmitText}
        />
      </View>
    </View>
  );
}
