import { gql } from '@soundxyz/gql-string';
import type { Falsy } from '@soundxyz/utils';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import { GetPaginatedVaultAnnouncementsDocument } from '../../graphql/generated';
import { getManyFromList } from '../../utils/arrayUtils';

gql(/* GraphQL */ `
  query GetPaginatedVaultAnnouncements($artistHandle: String!, $after: String, $first: Int!) {
    announcementsForUserByArtistHandle(artistHandle: $artistHandle, after: $after, first: $first) {
      edges {
        node {
          id
          ... on Announcement {
            content
            ...AnnouncementRow
            ...MMInsights
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const LIMIT = 10;

export function usePaginatedVaultAnnouncements({
  enabled,
  artistHandle,
  limit = LIMIT,
}: {
  enabled: boolean;
  artistHandle: string | Falsy;
  limit?: number;
}) {
  const { loggedInUser } = useAuthContext();

  return useInfiniteQuery(GetPaginatedVaultAnnouncementsDocument, {
    filterQueryKey: { artistHandle },
    staleTime: 0,
    enabled: enabled && !!loggedInUser,
    getNextPageParam: ({ data }) => {
      return (
        data.announcementsForUserByArtistHandle.pageInfo.hasNextPage && {
          after: data.announcementsForUserByArtistHandle.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
        };
      }),
    list: ({ announcementsForUserByArtistHandle }) => {
      return getManyFromList(
        announcementsForUserByArtistHandle.edges,
        ({ node }) => node.content != null && node,
      );
    },
    uniq: ({ id }) => id,
  });
}
