import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routeConstants';
import { useSubscribeToVault } from '../../hooks/useSubscribeToVault';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  vaultId: string;
  clientSecret: string;
  artistHandle: string;
  subscriptionId: string;
};

const SubscribeSuccessView: FC<Props> = ({
  vaultId,
  clientSecret,
  artistHandle,
  subscriptionId,
}) => {
  const { subscribe } = useSubscribeToVault();
  const stripe = useStripe();
  const navigate = useNavigate();
  useEffect(() => {
    const performSuccessActions = async () => {
      if (stripe == null) {
        return;
      }
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      if (paymentIntent?.status === 'succeeded') {
        subscribe({ vaultId, linkValue: artistHandle, subscriptionId });
      } else {
        navigate(ROUTES.NOT_FOUND);
      }
    };
    performSuccessActions();
  }, [artistHandle, clientSecret, navigate, stripe, subscribe, subscriptionId, vaultId]);
  return (
    <View className="flex h-full w-full max-w-[350px] flex-1 flex-col items-center justify-center">
      <Text className="mb-5 w-full text-center font-title text-title-l font-medium text-white">
        Congratulations you have successfully subscribed
      </Text>
      <Text className="w-full text-center font-base text-base-l font-normal text-white opacity-50">
        Please wait to be redirected...
      </Text>
    </View>
  );
};

export { SubscribeSuccessView };
