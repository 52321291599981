import React, { type FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faThumbtack } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { type FragmentType, getFragment, PinnedMessageFragmentDoc } from '../../graphql/generated';
import { DetailsTabStore } from '../../screens/SeeDetailsMessageChannelPage';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { MessageAttachment } from './MessageAttachment';

gql(/* GraphQL */ `
  fragment pinnedMessage on Message {
    id
    content
    vaultContent {
      id
      ...vaultMessageAttachment
    }
  }
`);
type Props = {
  artistHandle: string;
  message: FragmentType<PinnedMessageFragmentDoc>;
  pinnedMessageCount: number;
};
const PinnedMessage: FC<Props> = ({ message, artistHandle }) => {
  const { content, vaultContent } = getFragment(PinnedMessageFragmentDoc, message);
  const messageContent = vaultContent?.[0];

  return (
    <Link
      to="details"
      className={twMerge(
        'sticky top-[82px] z-stickyHeader flex w-full select-none flex-col px-4 py-2 text-[unset] no-underline',
        'w-[calc(100%+20px)] border-0 border-b border-solid md2:top-[86px]',
        'top-[80px] border-0 border-b border-t border-vault_text/5 bg-vault_background md2:border-t-0 md2:bg-transparent',
      )}
      onClick={() => {
        DetailsTabStore.produceExistingState(
          draft => {
            draft[artistHandle] = 'pinned';
          },
          { [artistHandle]: 'pinned' },
        );
      }}
    >
      <View className="flex flex-row items-center gap-2 px-4">
        <FontAwesomeIcon icon={faThumbtack} fontSize="14" className="text-vault_text/50" />

        {messageContent != null ? (
          <MessageAttachment messageContent={messageContent} type="pinned" />
        ) : (
          <Text className="line-clamp-1 font-base text-[14px] font-medium text-vault_text/50">
            {content || 'Pinned an attachment'}
          </Text>
        )}
      </View>
    </Link>
  );
};

export { PinnedMessage };
