import React from 'react';
import type { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import type { ViewProps } from './View';
import { View } from './View';

export const Spinner: FC<ViewProps> = ({ children, className, containerRef }) => {
  return (
    <View
      className={twMerge(
        'flex animate-[spin_45s_linear_infinite] justify-center border-[2px] border-solid border-b-transparent border-l-transparent',
        className,
      )}
      containerRef={containerRef}
    >
      {children}
    </View>
  );
};
