import { type FC, type ReactNode, useEffect, useState } from 'react';
import * as emoji from 'node-emoji';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { FALLBACK_EMOJI } from '../../constants/emojis';
import { useInfiniteQuery } from '../../graphql/client';
import { MessageReactionType, MessageReactionTypeInput } from '../../graphql/generated';
import { ReactorsByMessageIdDocument } from '../../graphql/generated';
import type { MessageReactionBottomsheetProps } from '../../types/bottomsheetTypes';
import { type EventObject, EVENTS, type EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { notEmpty } from '../../utils/arrayUtils';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { UserProfileImage } from '../user/UserProfileImage';
import { SkeletonUserRow } from '../user/UserRow';
import { EmptyStateView } from '../views/EmptyStateView';

gql(/* GraphQL */ `
  query ReactorsByMessageId(
    $messageId: UUID!
    $after: String
    $first: Int
    $reactionType: MessageReactionType
    $emojiKeyword: String
  ) {
    reactorsByMessageId(
      messageId: $messageId
      after: $after
      first: $first
      reactionType: $reactionType
      emojiKeyword: $emojiKeyword
    ) {
      __typename
      ... on QueryReactorsByMessageIdSuccess {
        data {
          edges {
            cursor
            node {
              actor {
                __typename

                ... on MessageActorUser {
                  id
                  user {
                    id
                    displayName
                    username
                    avatar {
                      id
                      url
                      dominantColor
                    }
                  }
                }
                ... on MessageActorArtist {
                  id
                  artist {
                    id
                    name
                    linkValue
                    profileImage {
                      id
                      url
                      dominantColor
                    }
                  }
                }
              }
              reactions: reactionsInfo {
                id
                type
                emojiKeyword
                createdAt
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const Container: FC<{
  href?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  bottomRef?: (node?: Element | null | undefined) => void;
}> = ({ href, children, onClick, className, bottomRef }) => {
  if (href == null) {
    return (
      <View onClick={onClick} className={className} containerRef={bottomRef}>
        {children}
      </View>
    );
  }
  return (
    <Link to={href} onClick={onClick} className={className} ref={bottomRef}>
      {children}
    </Link>
  );
};

const MessageReactionUserRow = <Event extends EventType>({
  username,
  avatarUrl,
  onClick,
  subText,
  href,
  className,
  rightComponent,
  subTextComponent,
  profileImageClassName,
  fallbackColor,
  bottomRef,
  event,
}: {
  username: string | null;
  avatarUrl: string | null;
  onClick?: () => void;
  subText?: string;
  subTextComponent?: ReactNode;
  href?: string;
  className?: string;
  rightComponent?: ReactNode;
  profileImageClassName?: string;
  fallbackColor?: string | null;
  bottomRef?: (node?: Element | null | undefined) => void;
  event?: EventObject<Event>;
}) => {
  const { pathname } = useLocation();
  return (
    <Container
      className={twMerge(
        'mb-[12px] flex w-full flex-row justify-between rounded-xl py-[12px] pl-[8px] text-[unset] no-underline',
        onClick != null ? 'hover:cursor-pointer' : '',
        className,
      )}
      href={href}
      onClick={
        onClick != null
          ? () => {
              if (event != null) {
                trackEvent({ ...event, pathname });
              }

              onClick();
            }
          : undefined
      }
      bottomRef={bottomRef}
    >
      <View className={twMerge('flex flex-row', subText == null && 'items-center')}>
        <UserProfileImage
          profileImageUrl={avatarUrl}
          className={twMerge('mr-[16px] w-[48px]', profileImageClassName)}
          withVaultTheme={false}
          fallbackColor={fallbackColor}
        />
        <View className="flex flex-col justify-center">
          <Text className={twMerge('mb-[5px] text-title-s text-white', subText == null && 'mb-0')}>
            {username}
          </Text>
          {subTextComponent != null
            ? subTextComponent
            : subText != null && (
                <Text className="font-base text-base-m font-normal text-base500">{subText}</Text>
              )}
        </View>
      </View>
      {rightComponent != null && rightComponent}
    </Container>
  );
};

const MessageReactionBottomsheet: FC<
  MessageReactionBottomsheetProps & { setIsScrolling: (isScrolling: boolean) => void }
> = ({ messageId, initialEmojiKeyword, reactionsSummary, setIsScrolling }) => {
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });
  const handlers = useSwipeable({
    onSwipeStart: () => {
      setIsScrolling(true);
    },
    trackMouse: true,
  });

  const [selectedEmoji, setSelectedEmoji] = useState(initialEmojiKeyword ?? null);
  const LIMIT = 20;
  const {
    data,
    orderedList: reactors,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
  } = useInfiniteQuery(ReactorsByMessageIdDocument, {
    staleTime: 0,
    filterQueryKey: {
      messageId,
      selectedEmoji,
    },
    getNextPageParam: ({ data }) => {
      if (data.reactorsByMessageId.__typename !== 'QueryReactorsByMessageIdSuccess') {
        return null;
      }
      return (
        data.reactorsByMessageId.data.pageInfo.hasNextPage && {
          after: data.reactorsByMessageId.data.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        messageId,
        after: pageParam?.after ?? null,
        first: LIMIT,
        emojiKeyword: selectedEmoji,
        reactionType: MessageReactionTypeInput.Emoji,
      };
    },
    list: ({ reactorsByMessageId }) => {
      if (reactorsByMessageId.__typename !== 'QueryReactorsByMessageIdSuccess') {
        return [];
      }
      return reactorsByMessageId.data.edges
        .map(({ node: { actor, reactions } }) => {
          switch (actor.__typename) {
            case 'MessageActorArtist': {
              return {
                id: actor.id,
                reactions,
                artist: actor.artist,
              };
            }
            case 'MessageActorUser': {
              return {
                id: actor.id,
                reactions,
                user: actor.user,
              };
            }
            default: {
              passiveExhaustiveGuard(actor);
              return null;
            }
          }
        })
        .filter(notEmpty);
    },
    uniq: ({ id }) => id,
    order: [e => e.reactions[e.reactions.length - 1]?.createdAt, 'asc'],
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      loadMoreNextPage();
    }
  }, [hasNextPage, isAtBottom, loadMoreNextPage]);

  if (isError || (!isInitialLoading && data == null)) {
    return (
      <ErrorView
        onRetryClick={refetch}
        loggingType="message_reaction_next_page"
        withVaultTheme={false}
      />
    );
  }

  return (
    <View className="flex w-full select-none flex-col items-center justify-start">
      <View className="mt-1 flex max-w-full flex-row gap-[10px] overflow-scroll bg-black pb-4 pl-4 scrollbar-none">
        <Button
          label="All"
          className={twMerge(
            'rounded-full bg-base700 bg-opacity-30 px-3 py-1 font-base text-[16px] text-white',
            selectedEmoji == null && 'bg-white bg-opacity-100 text-base900',
          )}
          onClick={() => setSelectedEmoji(null)}
          event={{
            type: EVENTS.VIEW_REACTORS,
            properties: {
              messageId,
              reactionType: null,
              count: reactionsSummary.reduce((acc, v) => acc + v.count, 0),
            },
          }}
        />
        {reactionsSummary.map((reaction, index) => {
          if (reaction.type !== 'EMOJI') return null;

          if (reaction.count <= 0) return null;

          return (
            <Button
              key={index}
              label={null}
              labelComponent={
                <Text className="flex gap-2 font-base">
                  <span>{emoji.get(reaction.emojiKeyword)}</span> <span>{reaction.count}</span>
                </Text>
              }
              className={twMerge(
                'rounded-full bg-base700 bg-opacity-30 px-3 py-1 text-[16px] text-white',
                reaction.type === MessageReactionType.Emoji &&
                  reaction.emojiKeyword === selectedEmoji &&
                  'bg-white bg-opacity-100 text-base900',
              )}
              onClick={() => setSelectedEmoji(reaction.emojiKeyword)}
            />
          );
        })}
      </View>
      <View className="w-full py-2">
        <View className="flex h-[1px] w-full flex-row bg-base700" />
      </View>
      <View
        className="no-scrollbar box-border flex max-h-[75vh] w-full flex-col items-center justify-start overflow-y-scroll"
        swipeableHandlers={handlers}
      >
        {isInitialLoading ? (
          <>
            <SkeletonUserRow className="box-border" />
          </>
        ) : reactors.length > 0 ? (
          <>
            {reactors.map(({ artist, user, reactions, id }, i) => {
              const username = artist?.linkValue ?? user?.username;
              return (
                <MessageReactionUserRow
                  className={twMerge(
                    'm-0 box-border items-center rounded-none border-0 py-3',
                    i !== 0 && ' border-t-[1px] border-solid border-base800',
                  )}
                  fallbackColor={artist?.profileImage?.dominantColor ?? user?.avatar?.dominantColor}
                  subText={username ? `@${username}` : undefined}
                  avatarUrl={artist?.profileImage?.url ?? user?.avatar?.url ?? null}
                  username={artist?.linkValue ?? user?.username ?? null}
                  rightComponent={
                    <View className="flex flex-row gap-[10px] px-5">
                      {reactions.map(reaction => (
                        <Text className="text-base-xl">
                          {emoji.get(reaction.emojiKeyword) || FALLBACK_EMOJI}
                        </Text>
                      ))}
                    </View>
                  }
                  key={id}
                />
              );
            })}
            {isLoadingNewPage && (
              <>
                <SkeletonUserRow className="box-border" />
              </>
            )}
            <View containerRef={bottomRef} />
          </>
        ) : (
          <EmptyStateView title="No reactions" subtitle="Be the first to react to this message" />
        )}
      </View>
    </View>
  );
};

export { MessageReactionBottomsheet };
