import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faXTwitter } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useCopy } from '../../hooks/useCopy';
import type { ShareSnippetBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

const ShareSnippetBottomsheet: FC<ShareSnippetBottomsheetProps> = ({
  artistName,
  link,
  withVaultTheme,
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { pathname } = useLocation();

  const { copy: onCopyLinkClick } = useCopy({
    text: link,
    successMessage: 'Shareable snippet link copied to clipboard!',
  });

  const buttons = [
    {
      name: 'X',
      onClick: () => {
        trackEvent({
          type: EVENTS.EXTERNAL_LINK,
          properties: { type: 'x' },
          pathname,
        });

        closeBottomsheet();
        onCopyLinkClick();
        window.open(getSharingUrlPath({ url: link, artistName }));
      },
      bgColor: withVaultTheme ? 'bg-vault_text' : 'bg-white',
      iconColor: withVaultTheme ? 'text-vault_text_opposite' : 'text-black',
      icon: faXTwitter,
    },
    {
      name: 'Copy link',
      onClick: () => {
        trackEvent({
          type: EVENTS.COPY,
          properties: { type: 'link' },
          pathname,
        });

        closeBottomsheet();
        onCopyLinkClick();
      },
      bgColor: withVaultTheme ? 'bg-vault_text' : 'bg-yellow100',
      iconColor: withVaultTheme ? 'text-vault_text_opposite' : 'text-black',
      icon: faLink,
    },
  ];

  return (
    <View className="w-full md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-row items-center justify-around">
        {buttons.map(({ onClick, bgColor, iconColor, name, icon }) => {
          return (
            <View className="flex flex-col items-center justify-center text-center" key={name}>
              <View
                onClick={onClick}
                className={twMerge(
                  'mx-4 mb-4 flex h-[60px] w-[60px] items-center justify-center rounded-full hover:cursor-pointer',
                  bgColor,
                )}
              >
                <FontAwesomeIcon icon={icon} className={twMerge('size-8', iconColor)} />
              </View>
              <Text
                className={twMerge(
                  'font-normal',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                {name}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const getSharingUrlPath = ({ url, artistName }: { url: string; artistName: string }) => {
  const link = url || window.location.href;

  const message = `Check%20out%20this%20new%20unreleased%20song%20from%20${artistName.replaceAll(' ', '%20')}`;

  return `https://twitter.com/intent/tweet?text=${message}%0A%0A${link}`;
};

export { ShareSnippetBottomsheet };
