import * as emoji from 'node-emoji';

export const BASE_EMOJI_KEYWORDS = {
  HEART: 'heart',
  THUMBS_UP: '+1',
  FIRE: 'fire',

  UNKNOWN: 'white_medium_small_square',
} satisfies Record<string, string>;

export const convertEmojiToKeyword = (value: string) => {
  return emoji.which(value) || 'white_medium_small_square';
};

export function getReactionColor(emoji: string): string {
  switch (emoji) {
    case BASE_EMOJI_KEYWORDS.HEART:
      return '#FF4133';
    case BASE_EMOJI_KEYWORDS.THUMBS_UP:
      return '#FFD664';
    case 'UNKNOWN':
    default:
      return '#E3F41D';
  }
}

export const FALLBACK_EMOJI = '◽';
