import { gql } from '@soundxyz/gql-string';
import { useQuery } from '../../graphql/client';
import type { ArtistMembershipReceiptTypenames } from '../../graphql/generated';
import { LastMembershipReceiptDocument } from '../../graphql/generated';
import { AppleMusicConnectState } from '../appleMusic/useAppleMusicAuth';
import { SpotifyConnectState } from '../spotify/useSpotifyAuth';

gql(/* GraphQL */ `
  query LastMembershipReceipt(
    $artistHandle: String
    $receiptType: ArtistMembershipReceiptTypenames
    $releaseCampaignId: UUID
    $rsvpEventId: UUID
    $spotifyAuthCode: String
    $appleMusicUserToken: String
  ) {
    lastMembershipReceipt(
      artistHandle: $artistHandle
      receiptType: $receiptType
      releaseCampaignId: $releaseCampaignId
      rsvpEventId: $rsvpEventId
      spotifyAuthCode: $spotifyAuthCode
      appleMusicUserToken: $appleMusicUserToken
    ) {
      id
      ...MembershipReceipt
    }
  }
`);

export function useLastMembershipReceipt({
  artistHandle,
  receiptType,
  releaseCampaignId,
  rsvpEventId,
  source,
  enabled,
  staleTime = 0,
}: {
  artistHandle: string | undefined;
  receiptType: ArtistMembershipReceiptTypenames | undefined;
  releaseCampaignId: string | undefined;
  rsvpEventId: string | undefined;
  source: 'apple' | 'spotify' | null;
  enabled: boolean;
  staleTime?: number;
}) {
  const spotifyAuth = SpotifyConnectState.useStore();
  const appleMusicAuth = AppleMusicConnectState.useStore();

  const { data, isLoading } = useQuery(LastMembershipReceiptDocument, {
    variables: {
      artistHandle,
      releaseCampaignId,
      rsvpEventId,
      receiptType,
      ...(source === 'spotify' && { spotifyAuthCode: spotifyAuth.value?.code }),
      ...(source === 'apple' && { appleMusicUserToken: appleMusicAuth.value?.userToken }),
    },
    enabled: enabled && ((!!releaseCampaignId && !!source) || !!rsvpEventId),
    staleTime,
  });

  return {
    data,
    isLoading,
  };
}
