import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'date-fns';
import DatePicker from 'react-datepicker';

import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faChevronLeft, faClock, faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES, US_STATE_CODES } from '../../constants/bottomsheetConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { type AnnouncementLocationTargetInput, IsoCountry } from '../../graphql/generated';
import { MASS_MESSAGE_MAX_CHARS, useMassMessageForm } from '../../hooks/message/useMassMessageForm';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import { getManyFromList } from '../../utils/arrayUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { pluralizeText } from '../../utils/textUtils';
import { BackButton } from '../buttons/BackButton';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { FilterPill } from './FilterPill';
import { PreviewMassMessageView } from './PreviewMassMessageView';

export function EditMassMessageView({
  artistHandle,
  mainVaultId,
  artistId,
  artistProfileImageUrl,
  artistName,
  isEdit,
  announcementId,
}: {
  artistHandle: string;
  mainVaultId: string;
  artistId: string;
  artistProfileImageUrl: string | null;
  artistName: string;
} & ({ isEdit: false; announcementId?: undefined } | { isEdit: true; announcementId: string })) {
  const [isBackClicked, setIsBackClicked] = useState(false);
  const dateRef = useRef<DatePicker>(null);

  const { openToast } = useToast();

  const currentDate = useMemo(() => new Date(), []);

  const { openBottomsheet } = useBottomsheetContainer();

  const navigate = useNavigate();

  const {
    fields,
    errors,
    setField,
    clearErrors,
    clearFields,
    validateField,
    enableSubmit,
    createMassMessage,
    isLoadingCreateMassMessage,
    editMassMessage,
    isLoadingEditMassMessage,
    memberCount,
    isLoadingMemberCount,
  } = useMassMessageForm({ vaultId: mainVaultId });

  useEffect(() => {
    if (isEdit) {
      setField('hasAddedMembers', true);
    }
  }, [isEdit, setField]);

  const { isDesktop } = useWindow();

  const isForEveryone = useMemo(() => {
    return (
      fields.memberType.length === 2 && fields.locations.length === 0 && fields.drops.length == 0
    );
  }, [fields.drops.length, fields.locations.length, fields.memberType.length]);

  const onClickBack = useCallback(() => {
    if (!fields.message && !isEdit) {
      setIsBackClicked(true);
      if (window.history.state.idx != null && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate(artistHandle != null ? artistNavigationPath(artistHandle, '/dashboard') : '/');
      }
      return;
    }

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        title: 'Are you sure you want to leave?',
        subText: 'You have unsaved changes.',
        onConfirm: () => {
          setIsBackClicked(true);
          if (window.history.state.idx != null && window.history.state.idx > 0) {
            navigate(-1);
          } else {
            navigate(artistHandle != null ? artistNavigationPath(artistHandle, '/dashboard') : '/');
          }
        },
      },
    });
  }, [artistHandle, fields.message, isEdit, navigate, openBottomsheet]);

  //Handle clearing fields and errors when navigating back
  useEffect(() => {
    if (!isBackClicked) return;

    return () => {
      clearErrors();
      clearFields();
    };
  }, [clearErrors, clearFields, isBackClicked, onClickBack]);

  const countries = useMemo(() => {
    if (fields.locations.length === 0) return [];
    return getManyFromList(fields.locations, ({ code, regionCode }) => {
      const country = COUNTRY_CODES.find(country => country.code === code);
      if (country == null) return null;

      if (code === IsoCountry.Us && regionCode !== null) {
        const state = US_STATE_CODES.find(state => state.code === regionCode);

        if (state == null) {
          return country;
        }

        return { code, name: `${state.name}, ${country.name}`, flag: country.flag };
      }

      return country;
    });
  }, [fields.locations]);

  const onClickSend = useStableCallback(async () => {
    if (fields.message.trim().length === 0) return;

    const scheduledAt = (() => {
      if (fields.date == null) return null;
      if (fields.date < new Date()) return null;

      return fields.date.toISOString();
    })();

    let resultId: string | undefined;

    if (isEdit) {
      const result = await editMassMessage({
        input: {
          content: fields.message,
          scheduledAt: scheduledAt ?? new Date().toISOString(),
          scheduledEventId: announcementId,
          locationTarget:
            fields.locations.length > 0
              ? fields.locations.map(
                  location =>
                    ({
                      isoCountry: location.code,
                      isoUsStateCode: location.regionCode,
                    }) satisfies AnnouncementLocationTargetInput,
                )
              : null,
          artistEventTarget: fields.drops.length > 0 ? fields.drops.map(({ id }) => id) : null,
          featureAccessList: fields.memberType,
        },
      });

      if (result.data.updateAnnouncement.__typename !== 'MutationUpdateAnnouncementSuccess') {
        openToast({
          variant: 'error',
          text: 'Failed to send mass message. Please try again',
        });
        return;
      }

      resultId = result.data.updateAnnouncement.data.id;
    } else {
      const result = await createMassMessage({
        input: {
          artistId,
          content: fields.message,
          scheduledAt,
          locationTarget:
            fields.locations.length > 0
              ? fields.locations.map(
                  location =>
                    ({
                      isoCountry: location.code,
                      isoUsStateCode: location.regionCode,
                    }) satisfies AnnouncementLocationTargetInput,
                )
              : null,
          artistEventTarget: fields.drops.length > 0 ? fields.drops.map(({ id }) => id) : null,
          featureAccessList: fields.memberType,
        },
      });

      if (result.data.createAnnouncement.__typename !== 'MutationCreateAnnouncementSuccess') {
        openToast({
          variant: 'error',
          text: 'Failed to send mass message. Please try again',
        });
        return;
      }

      resultId = result.data.createAnnouncement.data.id;
    }

    openToast({
      variant: 'success',
      text: `Mass message ${scheduledAt != null ? 'scheduled' : 'sent'}`,
    });

    clearErrors();
    clearFields();

    navigate(artistNavigationPath(artistHandle, `/messages/insights?messageId=${resultId}`), {
      replace: true,
    });
  });

  const CalendarContainer = useCallback(
    ({ className, children }: { className?: string; children: React.ReactNode }) => (
      <View className={twMerge('bg-vault_background', className)}>
        <View className="flex flex-col items-center bg-vault_background">
          <Text className="pb-2 font-base text-[28px]/[34px] font-normal text-vault_text">
            Schedule message
          </Text>
          <Text className="pb-2 font-base text-[16px]/[20px] font-normal text-vault_text/50">
            EDT timezone
          </Text>
          <View className="relative flex flex-row bg-vault_background pt-4 md2:px-4">
            {children}
          </View>
        </View>
      </View>
    ),
    [],
  );

  return (
    <View className="box-border flex h-full flex-col overflow-x-hidden overscroll-none bg-vault_background md2:px-2">
      <View className="z-above1 mt-1 box-border flex w-full flex-1 flex-col items-center justify-start overflow-x-hidden overscroll-none rounded-t-[10px] bg-vault_text/3 md2:ml-[272px] md2:mt-2 md2:w-[calc(100%-272px)] md2:rounded-t-[20px] md2:border md2:border-solid md2:border-vault_text/10">
        <View className="box-border flex h-[64px] w-full flex-row items-center justify-between gap-0 border-0 border-b border-solid border-b-vault_text/10 px-4 py-3 md2:h-[unset] md2:gap-6 md2:px-6 md2:pb-6 md2:pt-8 lg:justify-start">
          <BackButton
            withVaultTheme
            icon={isDesktop ? faChevronLeft : faClose}
            className="flex w-20 flex-row justify-start px-0 text-[24px]/[24px] md2:w-[unset]"
            onClick={onClickBack}
          />
          <Text className="font-title text-title-m text-vault_text md2:text-title-l">
            {isDesktop ? (isEdit ? 'Edit mass message' : 'New mass message') : 'Mass message'}
          </Text>
          <Button
            label="Preview"
            className="w-20 justify-end font-base text-base-l text-vault_accent md2:w-[unset] lg:hidden"
            href={artistNavigationPath(
              artistHandle,
              isEdit ? '/messages/edit/preview' : '/messages/create/preview',
            )}
            disabled={isEdit ? isLoadingEditMassMessage : isLoadingCreateMassMessage}
          />
        </View>
        <View className="box-border flex w-full flex-1 flex-row">
          <View className="box-border flex w-full flex-col gap-2">
            <View
              className={twMerge(
                'box-border flex w-full flex-col gap-2 border-0 border-b border-solid border-b-vault_text/10 p-4 pb-2 md2:p-6',
                !fields.hasAddedMembers && 'pb-4',
              )}
            >
              <View className="box-border flex w-full flex-row items-center justify-between">
                {!isLoadingMemberCount ? (
                  <Text className="line-clamp-1 flex-1 flex-shrink font-title text-title-m text-vault_text md2:text-title-l">
                    To {memberCount} {pluralizeText({ count: memberCount, text: 'member' })}
                  </Text>
                ) : (
                  <LoadingSkeleton className="h-[22px] w-[150px] md2:h-[26px]" withVaultTheme />
                )}
                <Button
                  onClick={() => {
                    if (isDesktop) {
                      openBottomsheet({
                        type: BOTTOMSHEET_TYPES.FILTER_MEMBERS,
                        filterMembersBottomsheetProps: {
                          artistHandle,
                        },
                        shared: {
                          withVaultTheme: true,
                          hidePulleyBar: true,
                        },
                      });
                    }
                  }}
                  href={
                    !isDesktop
                      ? isEdit
                        ? artistNavigationPath(artistHandle, '/messages/edit/filter')
                        : 'filter'
                      : undefined
                  }
                  label={fields.hasAddedMembers ? 'Edit' : 'Add'}
                  disabled={isLoadingCreateMassMessage}
                  className="font-base text-[16px]/[20px] font-normal text-vault_accent"
                />
              </View>
              {fields.hasAddedMembers && (
                <View className="no-scrollbar flex flex-shrink flex-row gap-1 overflow-x-scroll md2:flex-wrap">
                  {isForEveryone ? (
                    <FilterPill type="EVERYONE" />
                  ) : (
                    <>
                      {fields.memberType.length < 2 &&
                        fields.memberType.map(type => (
                          <FilterPill
                            type="TIER"
                            tierName={type === 'FREE_EVENT' ? 'Free members' : 'Paid members'}
                            key={type}
                          />
                        ))}
                      {fields.drops.map(({ id, name }) => (
                        <FilterPill type="DROP" dropName={name} key={id} />
                      ))}
                      {countries.map(country => {
                        return (
                          <FilterPill
                            type="LOCATION"
                            locationName={country.name}
                            key={country.name}
                          />
                        );
                      })}
                    </>
                  )}
                </View>
              )}
            </View>
            <textarea
              disabled={isLoadingCreateMassMessage}
              className="box-border w-full flex-1 resize-none border-none bg-transparent p-4 text-[16px]/[20px] text-vault_text outline-none placeholder:text-vault_text/50 md2:p-6"
              placeholder="Add a message..."
              value={fields.message}
              maxLength={MASS_MESSAGE_MAX_CHARS}
              onChange={e => {
                setField('message', e.target.value);
                validateField('message');
              }}
              onBlur={() => {
                validateField('message');
              }}
            />
            <View className="box-border flex w-full flex-row items-center justify-between px-4 pb-4 md2:px-6 md2:pb-6">
              <Text
                className={twMerge(
                  'h-6 font-base !text-base-s tabular-nums',
                  !!errors.message ? 'text-destructive400' : 'text-vault_text/50',
                )}
              >
                {fields.message.length}/{MASS_MESSAGE_MAX_CHARS}
              </Text>
              <View className="flex flex-row items-center gap-4">
                <DatePicker
                  disabled={isLoadingCreateMassMessage}
                  ref={dateRef}
                  withPortal
                  selected={fields.date}
                  onChange={date => {
                    if (!isDesktop) {
                      dateRef.current?.cancelFocusInput();
                    }
                    if (!date || date < currentDate) {
                      openToast({
                        variant: 'error',
                        text: 'Must select a future date',
                      });

                      setField('date', null);
                      return;
                    }

                    setField('date', date);
                  }}
                  minDate={currentDate}
                  timeIntervals={1}
                  showTimeSelect
                  calendarContainer={CalendarContainer}
                  customInput={
                    <CustomInput
                      date={fields.date}
                      onClick={() => {
                        dateRef.current?.setFocus();
                      }}
                    />
                  }
                />
                <Button
                  label={fields.date == null ? 'Send now' : 'Save'}
                  className="rounded-full bg-vault_accent px-3 py-2 text-[14px]/[18px] text-vault_accent_text md2:px-4"
                  disabled={!enableSubmit}
                  disabledClassName="opacity-50"
                  onClick={onClickSend}
                  loading={isLoadingCreateMassMessage}
                />
              </View>
            </View>
          </View>
          <View className="box-border hidden h-full flex-col items-center justify-start bg-vault_text/10 p-6 lg:flex">
            <PreviewMassMessageView
              message={fields.message}
              artistName={artistName}
              artistProfileImageUrl={artistProfileImageUrl}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const CustomInput = forwardRef<
  HTMLButtonElement,
  { className?: string; onClick: () => void; date: Date | null }
>(({ className, onClick, date }, ref) => (
  <button
    ref={ref}
    className={twMerge(
      'flex cursor-pointer flex-row items-center gap-2 border-none bg-transparent font-base text-[14px]/[16px] outline-none md2:rounded-full md2:bg-vault_text/10 md2:px-4 md2:py-2',
      date != null ? 'text-vault_text' : 'text-vault_text/50 md2:text-vault_text',
      className,
    )}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faClock} className="text-[16px]/[16px]" />
    <Text className="font-base text-[14px]/[18px] font-normal text-vault_text">
      {date != null ? formatDate(date, 'M/d/yy, h:mm aa') : 'Schedule'}
    </Text>
  </button>
));
