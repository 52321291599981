import { useCallback, useState } from 'react';
import { setHideAudioPlayer } from '../audio/AudioMeta';
import { Text } from '../components/common/Text';
import { Menu } from '../components/menu/Menu';
import { CustomizeVaultMenu } from '../components/menu/customize-vault/CustomizeVaultMenu';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { resetVaultTheme } from '../hooks/useVaultTheme';
import { createContainer } from '../utils/unstated';
import { useBottomsheetContainer, useExtraBottomsheetContainer } from './BottomsheetContext';

const MenuContext = createContainer(function MenuContext() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosingMenu, setIsClosingMenu] = useState(false);
  const [isClosingCustomizeMenu, setIsClosingCustomizeMenu] = useState(false);
  const [selectedHandle, setSelectedHandle] = useState<string | null>();
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const [isVaultCustomizeOpen, setIsVaultCustomizeOpen] = useState(false);
  const [vaultId, setVaultId] = useState<string | null>(null);
  const [folderId, setFolderId] = useState<string | null>(null);
  const { closeBottomsheet } = useBottomsheetContainer();
  const { openExtraBottomsheet, closeExtraBottomsheet } = useExtraBottomsheetContainer();

  const openMenu = useCallback((selectedArtistHandle?: string) => {
    setIsOpen(true);
    setSelectedHandle(selectedArtistHandle);
  }, []);

  const closeMenu = useCallback(() => {
    isOpen && setIsClosingMenu(true);
    setTimeout(() => {
      setIsOpen(false);
      setSelectedHandle(null);
      setIsClosingMenu(false);
      setIsAccountOpen(false);
      setIsVaultCustomizeOpen(false);
    }, 100);
  }, [isOpen]);

  const openVaultCustomization = useCallback(({ vaultId }: { vaultId: string }) => {
    setVaultId(vaultId);
    setIsVaultCustomizeOpen(true);
    setHideAudioPlayer(true);
  }, []);

  const closeVaultCustomization = useCallback(
    ({ showConfirm }: { showConfirm: boolean }) => {
      isVaultCustomizeOpen && setIsClosingCustomizeMenu(true);

      if (showConfirm) {
        openExtraBottomsheet({
          type: BOTTOMSHEET_TYPES.CONFIRMATION,
          confirmationBottomsheetProps: {
            title: 'Leave without saving?',
            customContent: (
              <Text className="mb-[24px] text-center font-base text-[18px]/[24px] font-normal text-white md2:mb-[16px]">
                You have unsaved changes.
                <br />
                Keep editing or discard?
              </Text>
            ),
            cancelButtonText: 'Keep editing',
            confirmButtonText: 'Discard',
            buttonContainerClassName: 'w-full',
            confirmType: 'delete',
            isExtraBottomsheet: true,
            fullWidth: true,
            onConfirm: () => {
              // Reset vault theme to the original theme
              if (vaultId) {
                resetVaultTheme({ vaultId });
              }

              setVaultId(null);
              setIsVaultCustomizeOpen(false);

              closeExtraBottomsheet();
              closeBottomsheet();
              setIsClosingCustomizeMenu(false);
            },
          },
        });
      } else {
        setTimeout(() => {
          // Reset vault theme to the original theme
          if (vaultId) {
            resetVaultTheme({ vaultId });
          }

          setVaultId(null);
          setIsVaultCustomizeOpen(false);

          closeBottomsheet();
          setIsClosingCustomizeMenu(false);
        }, 100);
      }
    },
    [closeBottomsheet, closeExtraBottomsheet, isVaultCustomizeOpen, openExtraBottomsheet, vaultId],
  );

  return {
    isOpen: isOpen || isClosingMenu,
    openMenu,
    closeMenu,
    selectedHandle,
    isClosingMenu,
    isClosingCustomizeMenu,
    isAccountOpen,
    isVaultCustomizeOpen: isVaultCustomizeOpen || isClosingCustomizeMenu,
    vaultId,
    setIsAccountOpen,
    openVaultCustomization,
    closeVaultCustomization,
    showMenu,
    setShowMenu,
    folderId,
    setFolderId,
  };
});

const useMenuContainer = () => MenuContext.useContainer();

const MenuContainer = () => {
  const { isVaultCustomizeOpen, vaultId } = useMenuContainer();

  if (isVaultCustomizeOpen && vaultId) {
    return <CustomizeVaultMenu vaultId={vaultId} />;
  }

  return <Menu />;
};

export { useMenuContainer, MenuContainer, MenuContext };
