import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faClose } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useWindow } from '../../hooks/useWindow';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function PreviewToast() {
  const { isDesktop } = useWindow();

  const [open, setOpen] = useState(true);
  const { isVaultCustomizeOpen } = useMenuContainer();

  useEffect(() => {
    // Reset open state after closing the customize menu
    if (!isVaultCustomizeOpen && !open) {
      setOpen(true);
    }
  }, [isVaultCustomizeOpen, open]);

  if (!isVaultCustomizeOpen || !open || !isDesktop) return null;

  return (
    <View className="absolute bottom-[60px] z-uploadTooltip flex w-[calc(100%-398px)] flex-row justify-center">
      <View className="flex w-full max-w-[358px] flex-col gap-2 rounded-lg bg-vault_text px-4 py-3">
        <View className="flex flex-1 flex-row items-center justify-between">
          <View className="flex items-center gap-2 font-base">
            <FontAwesomeIcon icon={faEye} className="text-[16px] text-vault_text_opposite" />

            <Text className="font-base text-[14px]/[18px] font-normal text-vault_text_opposite">
              This is a preview.
            </Text>
          </View>

          <button
            className="appearance-none rounded-full border-none bg-transparent p-1 outline-none hover:cursor-pointer focus:outline-none"
            onClick={() => setOpen(false)}
          >
            <FontAwesomeIcon icon={faClose} className="text-[16px] text-vault_text_opposite" />
          </button>
        </View>
      </View>
    </View>
  );
}
