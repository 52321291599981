import type { StatsigUser } from 'statsig-react';
import { proxy, snapshot, useSnapshot } from 'valtio';

const anonymousUser: StatsigUser = {
  userID: 'anonymous',
  appVersion: import.meta.env.NEXT_PUBLIC_WEBAPP_VERSION,
};

const statsigUserState = proxy<{ user: StatsigUser }>({
  user: {
    ...anonymousUser,
  },
});

export function setStatsigUser(newUser: StatsigUser) {
  const currentUser = snapshot(statsigUserState);
  statsigUserState.user = {
    ...currentUser.user,
    ...newUser,
  };
}

export function clearStatsigUser() {
  statsigUserState.user = {
    ...anonymousUser,
  };
}

export function getStatsigUser() {
  return statsigUserState.user;
}

export function useStatsigUser() {
  return useSnapshot(statsigUserState);
}
