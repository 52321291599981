import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { EarningRow, SkeletonEarningRow } from '../../components/payment/EarningRow';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { GetMyEarningsBreakdownDocument, GetMyEarningsDocument } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  query GetMyEarningsBreakdown($artistId: UUID, $month: Int!, $year: Int!) {
    earningsBreakdown(artistId: $artistId, month: $month, year: $year) {
      payeeEarningAmount
      vaultArtistName
      vaultContentTitle
    }
  }
`);

export const EarningsBreakdownPage = ({ isArtistPage }: { isArtistPage: boolean }) => {
  const navigate = useNavigate();
  const { loginStatus, loggedInUser } = useAuthContext();
  const [searchParams] = useSearchParams();
  const cursor = searchParams.get('cursor');
  const month = searchParams.get('month');
  const year = searchParams.get('year');
  const artistId = isArtistPage ? loggedInUser?.artist?.id ?? null : null;

  const {
    data: earningData,
    isError: isTotalEarningError,
    isInitialLoading: isTotalEarningLoading,
    refetch: refetchTotalEarning,
  } = useQuery(GetMyEarningsDocument, {
    enabled: loginStatus === LoginStatus.LOGGED_IN && (isArtistPage ? artistId != null : true),
    staleTime: 0,
    filterQueryKey: {
      userId: loggedInUser?.id,
      artistId,
    },
    variables: {
      artistId,
      first: 1,
      after: cursor,
    },
  });

  const totalEarning =
    earningData?.data.myEarnings?.__typename === 'QueryMyEarningsSuccess'
      ? earningData?.data.myEarnings?.data.edges[0]?.node
      : null;

  useEffect(() => {
    /**
     * Redirect to the earnings page if the month / year isnt defined
     */
    if (month == null || year == null) {
      navigate(isArtistPage ? `${ROUTES.SETTINGS}/artist-earnings` : `${ROUTES.SETTINGS}/earnings`);
    }
  }, [isArtistPage, month, navigate, year]);

  const {
    data,
    isError: isEarningsBreakdownsError,
    refetch: refetchEarningsBreakdowns,
    isInitialLoading: isEarningsBreakdownsLoading,
  } = useQuery(GetMyEarningsBreakdownDocument, {
    enabled:
      loginStatus === LoginStatus.LOGGED_IN &&
      (isArtistPage ? artistId != null : true) &&
      earningData?.data.myEarnings?.__typename === 'QueryMyEarningsSuccess' &&
      month != null &&
      year != null,
    staleTime: 0,
    filterQueryKey: {
      userId: loggedInUser?.id,
      artistId,
    },
    variables: {
      artistId: isArtistPage ? artistId : undefined,
      month: month != null ? Number(month) + 1 : 1, // month is 0-indexed
      year: year != null ? Number(year) : 1,
    },
  });

  const earningBreakdowns = data?.data.earningsBreakdown;

  if (loginStatus === LoginStatus.LOADING || isEarningsBreakdownsLoading || isTotalEarningLoading) {
    return (
      <SettingsLayout title="Earnings">
        <SkeletonEarningRow />
        <SkeletonEarningRow />
        <SkeletonEarningRow />
      </SettingsLayout>
    );
  }

  if (
    totalEarning == null ||
    earningData?.data.myEarnings?.__typename === 'StripeApiError' ||
    (earningData == null && !isTotalEarningLoading) ||
    isTotalEarningError
  ) {
    return (
      <SettingsLayout title="Earnings">
        <ErrorView
          className="flex-grow"
          onRetryClick={refetchTotalEarning}
          loggingType="earnings_breakdown_page"
          withVaultTheme={false}
        />
      </SettingsLayout>
    );
  }

  if (
    earningBreakdowns == null ||
    (earningBreakdowns == null && !isEarningsBreakdownsLoading) ||
    (isEarningsBreakdownsError && earningBreakdowns?.length === 0)
  ) {
    return (
      <SettingsLayout title="Earnings">
        <ErrorView
          className="flex-grow"
          onRetryClick={refetchEarningsBreakdowns}
          loggingType="earnings_breakdown_page"
          withVaultTheme={false}
        />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      title="Earnings"
      nonScrollingChildren={
        earningBreakdowns?.length === 0 && (
          <EmptyStateView title="Breakdown not available" subtitle="" className="h-full" />
        )
      }
    >
      <EarningRow earning={totalEarning} />
      {earningBreakdowns.map((earningBreakdown, i) => (
        <EarningBreakdownRow earningBreakdownDetails={earningBreakdown} key={i} />
      ))}
    </SettingsLayout>
  );
};

export function EarningBreakdownRow({
  earningBreakdownDetails,
}: {
  earningBreakdownDetails: {
    payeeEarningAmount: number; // in cents
    vaultArtistName: string;
    vaultContentTitle: string;
  };
}) {
  const { payeeEarningAmount, vaultArtistName, vaultContentTitle } = earningBreakdownDetails;
  return (
    <View
      className="flex w-full flex-row items-center justify-between py-[20px] "
      // containerRef={bottomRef}
    >
      <View className="flex flex-col pr-[8px]">
        <Text className="font-title text-title-s font-medium text-white">{vaultContentTitle}</Text>
        <Text className="font-base text-base-m font-normal text-base500">{`Vault: ${vaultArtistName}`}</Text>
      </View>
      <View className="flex flex-row items-center">
        <Text className="mr-[8px] font-base text-base-l font-normal text-white">
          {`$${(payeeEarningAmount / 100).toFixed(2)}`}
        </Text>
      </View>
    </View>
  );
}
