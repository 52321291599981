import { gql } from '@soundxyz/gql-string';
import type { Maybe } from '@soundxyz/utils';
import { useAuthContext } from '../../contexts/AuthContext';
import { ScheduledEventUpdatesDocument } from '../../graphql/generated';
import { type OnData, useSubscription } from '../../graphql/wsClient';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  subscription ScheduledEventUpdates($vaultId: UUID!) {
    scheduledEventUpdates(vaultId: $vaultId) {
      __typename
      ... on SubscriptionScheduledEventUpdatesSuccess {
        data {
          __typename
          ... on AnnouncementUpdateSubscription {
            scheduledEventId
            scheduledAt
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export function useVaultScheduledEventUpdatesSubscription({
  vaultId,
  onSubscriptionData,
}: {
  vaultId: Maybe<string>;
  onSubscriptionData: OnData<typeof ScheduledEventUpdatesDocument>;
}) {
  const { loginStatus } = useAuthContext();

  useSubscription({
    query: ScheduledEventUpdatesDocument,
    variables: !!vaultId && { vaultId },
    onData: onSubscriptionData,
    enabled: !!vaultId && loginStatus === LoginStatus.LOGGED_IN,
  });
}
