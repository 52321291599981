import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import {
  faCalendar,
  faGlobe,
  faLocationDot,
  faUserGroup,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import { Text } from '../common/Text';
import { View } from '../common/View';

const FILTER_PILL_ICONS = {
  EVERYONE: faGlobe,
  TIER: faUserGroup,
  DROP: faCalendar,
  LOCATION: faLocationDot,
} as const;

export function FilterPill({
  locationName,
  dropName,
  tierName,
  type,
  className,
}: { className?: string } & (
  | {
      type: 'EVERYONE';
      tierName?: undefined;
      locationName?: undefined;
      dropName?: undefined;
    }
  | {
      type: 'TIER';
      tierName: string;
      locationName?: undefined;
      dropName?: undefined;
    }
  | {
      type: 'DROP';
      dropName: string;
      locationName?: undefined;
      tierName?: undefined;
    }
  | { type: 'LOCATION'; dropName?: undefined; tierName?: undefined; locationName: string }
)) {
  const icon = FILTER_PILL_ICONS[type];

  const text = useMemo(() => {
    if (type === 'EVERYONE') {
      return 'Everyone';
    }

    if (type === 'TIER') {
      return tierName;
    }

    if (type === 'DROP') {
      return dropName;
    }

    return locationName;
  }, [dropName, locationName, tierName, type]);

  return (
    <View
      className={twMerge(
        'flex flex-shrink-0 flex-row items-center gap-2 rounded-full bg-vault_text/10 py-2 pl-3 pr-4 md2:py-3 md2:pl-4',
        className,
      )}
    >
      <FontAwesomeIcon
        icon={icon}
        className="text-[14px]/[14px] text-vault_text md2:text-[16px]/[16px]"
      />
      <Text className="flex-shrink-0 flex-grow font-base text-[14px]/[18px] font-medium text-vault_text md2:text-[16px]/[20px]">
        {text}
      </Text>
    </View>
  );
}
