import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useUserArtistReceipts } from '../../hooks/membership/useUserArtistReceipts';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { ReceiptItem, ReceiptItemSkeleton } from './ReceiptItem';
import { EmptyReceipts, Item } from './shared';

export const ReceiptsSection = () => {
  const { loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { userArtistReceipts: receipts = [], isInitialLoading } = useUserArtistReceipts({
    artistHandle,
    limit: 4,
    source: 'membership_page',
  });

  const isUnauthenticated = loginStatus === LoginStatus.LOGGED_OUT;

  const hasReceipts = receipts.length > 0;
  const hasMoreReceipts = receipts.length > 3;

  if (isInitialLoading) {
    return (
      <Item header="Receipts">
        <div className="flex flex-col">
          {Array.from({ length: 3 }).map((_, index) => (
            <ReceiptItemSkeleton key={index} />
          ))}
        </div>
      </Item>
    );
  }

  if (!hasReceipts || isUnauthenticated) {
    return (
      <Item header="Receipts">
        <EmptyReceipts />
      </Item>
    );
  }

  return (
    <Item
      header="Receipts"
      headerLink={
        hasMoreReceipts ? (
          <Link
            to={artistNavigationPath(artistHandle, '/membership/receipts')}
            className="text-base text-[14px]/[16px] text-vault_text/50 no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
          >
            See all
          </Link>
        ) : null
      }
    >
      {receipts.slice(0, 4).map(item => (
        <ReceiptItem key={item.id} receiptData={item} />
      ))}
    </Item>
  );
};
