import { useEffect, useMemo } from 'react';
import throttle from 'lodash-es/throttle';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import type { ExecutionResultWithData } from '@soundxyz/graphql-react-query';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import type { Maybe } from '@soundxyz/utils';
import { invalidateOperations, useQuery } from '../../../graphql/client';
import {
  VaultMessageUnreadCountsDocument,
  type VaultMessageUnreadCountsQuery,
  type VaultMessageUnreadCountsQueryVariables,
} from '../../../graphql/generated';
import { useMessageChannelUpdatesSubscription } from '../../../hooks/useMessageChannelUpdatesSubscription';
import { createBatchingInputStore } from '../../../utils/batchInput';
import { UpdatingVaultMessageEngagement } from './useVaultMessageCountEngagement';

gql(/* GraphQL */ `
  query VaultMessageUnreadCounts($vaultIds: [UUID!]!) {
    unreadMessageCounts(vaultIds: $vaultIds) {
      __typename
      ... on QueryUnreadMessageCountsSuccess {
        data {
          vaultId
          unreadCount
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const { useBatchedKey } = createBatchingInputStore({
  chunkLimit: 25,
});

const throttledRefetchSubscriptionCount = throttle(
  () => {
    invalidateOperations({
      operations: [VaultMessageUnreadCountsDocument],
    });
  },
  5000,
  {
    leading: true,
    trailing: true,
  },
);

export function useBatchedVaultMessageUnreadCount({
  enabled,
  messageChannelId,
  vaultId,
}: {
  enabled: boolean;
  vaultId: Maybe<string>;
  messageChannelId: Maybe<string>;
}) {
  const { inProgress } = useSnapshot(UpdatingVaultMessageEngagement);

  const ids = useBatchedKey({
    key: vaultId,
  });

  const variables = useMemo<VaultMessageUnreadCountsQueryVariables | false>(() => {
    if (!ids) return false;

    return {
      vaultIds: ids,
    };
  }, [ids]);

  const isQueryEnabled = enabled && !!variables;

  const { data: unreadCount, isLoading } = useQuery(VaultMessageUnreadCountsDocument, {
    enabled: isQueryEnabled,
    variables,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
    select: useStableCallback((data: ExecutionResultWithData<VaultMessageUnreadCountsQuery>) => {
      return data.data.unreadMessageCounts.__typename === 'QueryUnreadMessageCountsSuccess'
        ? data.data.unreadMessageCounts.data.find(value => value.vaultId === vaultId)
            ?.unreadCount ?? null
        : null;
    }),
  });

  const shouldRefetch = inProgress === 0 && isQueryEnabled;

  useEffect(() => {
    if (shouldRefetch) {
      invalidateOperations({
        operations: [VaultMessageUnreadCountsDocument],
      });
    }
  }, [shouldRefetch]);

  useMessageChannelUpdatesSubscription({
    messageChannelId,
    onSubscriptionData({ data: { messageChannelUpdates } }) {
      if (messageChannelUpdates.__typename !== 'SubscriptionMessageChannelUpdatesSuccess') return;

      switch (messageChannelUpdates.data.__typename) {
        case 'DeleteMessageSubscription':
        case 'CreateMessageSubscription': {
          throttledRefetchSubscriptionCount();
        }
      }
    },
  });

  return {
    unreadCount,
    isLoading,
  };
}
