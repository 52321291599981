import type { FC, ReactNode } from 'react';
import React from 'react';
import { GlobalModal } from '../components/modals/GlobalModal';
import { ModalContext } from '../contexts/ModalContext';

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ModalContext.Provider>
      <GlobalModal />
      {children}
    </ModalContext.Provider>
  );
};
