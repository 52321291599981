import { getSubdomain, isValidSubdomain } from './subdomainUtils';

export const artistNavigationPath = (
  artistHandle: string | undefined | null,
  path: string,
  queryParams: string | undefined = undefined,
) => {
  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const isSubdomain = !!subdomainArtistHandle;

  const basePath = isSubdomain ? `${path}` : `/${artistHandle}${path}`;
  const fullPath = queryParams ? `${basePath}?${queryParams}` : basePath;

  return fullPath;
};

export const normalizePath = (path: string, queryParams?: string) => {
  const isSubdomainValid = isValidSubdomain();
  const fullPathWithParams = queryParams ? `${path}?${queryParams}` : path;

  if (isSubdomainValid) {
    return `${window.location.protocol}//${window.location.host.replace(`${getSubdomain()}.`, '')}${fullPathWithParams}`;
  } else {
    return fullPathWithParams;
  }
};
