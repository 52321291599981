import React, { useEffect } from 'react';
import { proxy, subscribe } from 'valtio';
import { useLatestRef } from './useLatestRef';
import { useStableCallback } from './useStableCallback';

const DEFAULT_DELAY = 1000;

function millisecondsToSeconds(milliseconds: number) {
  return Math.floor(milliseconds / 1000);
}

export function getSecondsFromExpiry(expiry: Date | number, shouldRound?: boolean) {
  const milliSecondsDistance =
    (typeof expiry === 'number' ? expiry : expiry.getTime()) - new Date().getTime();
  if (milliSecondsDistance > 0) {
    const val = millisecondsToSeconds(milliSecondsDistance);
    return shouldRound ? Math.round(val) : val;
  }
  return 0;
}

export function getTimeFromSeconds(secs: number) {
  const totalSeconds = Math.ceil(secs);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    seconds,
    minutes,
    hours,
    days,
  };
}

export const globalTimerInterval = proxy({
  now: Date.now(),
});

if (typeof window !== 'undefined') {
  setInterval(() => {
    globalTimerInterval.now = Date.now();
  }, DEFAULT_DELAY);
}

// based on https://github.com/amrlabib/react-timer-hook/blob/master/src/useTimer.js
export function useTimer({
  expiryTimestamp: expiryTs,
  onExpire,
  autoStart = true,
}: {
  expiryTimestamp: Date | number;
  onExpire?: () => void;
  autoStart?: boolean;
}) {
  const expiryTimestamp = typeof expiryTs === 'number' ? expiryTs : expiryTs.getTime();

  const [seconds, setSeconds] = React.useState(() => getSecondsFromExpiry(expiryTimestamp));
  const [isRunning, setIsRunning] = React.useState(() => autoStart && Date.now() < expiryTimestamp);

  const isRunningCurrent = useLatestRef(isRunning);

  // resets the timer on expiry ts change
  React.useEffect(() => {
    setIsRunning(!!expiryTimestamp && Date.now() < expiryTimestamp);
  }, [expiryTimestamp]);

  const handleExpire = useStableCallback(() => {
    onExpire?.();
    setIsRunning(false);
  });

  useEffect(() => {
    function cb() {
      const secondsValue = getSecondsFromExpiry(expiryTimestamp);
      setSeconds(secondsValue);
      if (secondsValue <= 0) {
        if (isRunningCurrent.current) {
          handleExpire();
        }
      } else if (!isRunningCurrent.current) {
        setIsRunning(true);
      }
    }

    cb();

    return subscribe(globalTimerInterval, cb);
  }, [expiryTimestamp, handleExpire, isRunningCurrent]);

  return {
    ...getTimeFromSeconds(seconds),
    isRunning,
  };
}
