import { type FC } from 'react';
import Picker, { type EmojiClickData, Theme } from 'emoji-picker-react';
import { motion } from 'framer-motion';
import { keyBy } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faCopy, faReply, faTrashCan } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { convertEmojiToKeyword } from '../../constants/emojis';
import { THUMBTACK } from '../../constants/imageConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { MessageReactionTypeInput } from '../../graphql/generated';
import {
  CreateMessageReactionDocument,
  DeleteMessageReactionDocument,
  makeFragmentData,
  ReplyToMessageFragmentDoc,
} from '../../graphql/generated';
import {
  DeleteMessageDocument,
  type FragmentType,
  getFragment,
  MessageBubbleFragmentDoc,
  PinMessageDocument,
  RemovePinnedMessageDocument,
} from '../../graphql/generated';
import { useCopy } from '../../hooks/useCopy';
import { useStableCallback } from '../../hooks/useStableCallback';
import { setReplyToMessage, useVaultMessageChannel } from '../../hooks/useVaultMessageChannel';
import { Sentry } from '../../sentry';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { MessageBubbleInner } from './MessageBubble';

gql(/* GraphQL */ `
  mutation DeleteMessage($input: MutationDeleteMessageInput!) {
    deleteMessage(input: $input) {
      __typename
      ... on MutationDeleteMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation PinMessage($input: MutationUpdatePinnedMessageInput!) {
    updatePinnedMessage(input: $input) {
      __typename
      ... on MutationUpdatePinnedMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation RemovePinnedMessage($input: MutationRemovePinnedMessageInput!) {
    removePinnedMessage(input: $input) {
      __typename
      ... on MutationRemovePinnedMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation CreateMessageReaction($input: MutationCreateMessageReactionInput!) {
    createMessageReaction(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  mutation DeleteMessageReaction($input: MutationDeleteMessageReactionInput!) {
    deleteMessageReaction(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

type ElevatedMessageBubbleProps = {
  messageFrag: FragmentType<MessageBubbleFragmentDoc> & { id: string };
  areSubscriptionTierBadgesVisible: boolean;
  isOwner: boolean;
  isAuthor: boolean;
  artistProfileImageUrl: string | undefined;
  parentType: 'default' | 'see_details';
  isVaultArtist: boolean;
};

export const ElevatedMessageBubble: FC<ElevatedMessageBubbleProps> = ({
  messageFrag,
  areSubscriptionTierBadgesVisible,
  isOwner,
  isAuthor,
  artistProfileImageUrl,
  parentType,
  isVaultArtist,
}) => {
  const { pathname } = useLocation();

  const { closeOverlay, isAnimationComplete } = useOverlayContainer();
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync: deleteMessage, isLoading: isDeleting } = useMutation(DeleteMessageDocument, {
    retry: 3,
    onSuccess: data => {
      if (data.data.deleteMessage.__typename === 'MutationDeleteMessageSuccess') {
        closeOverlay();
      }
    },
    onError: () => {
      openToast({
        text: 'This message could not be deleted at this time. Try again.',
        variant: 'error',
      });
    },
  });

  const { mutateAsync: pinMessage, isLoading: isPinning } = useMutation(PinMessageDocument, {
    retry: 3,
    onSuccess: data => {
      if (data.data.updatePinnedMessage.__typename === 'MutationUpdatePinnedMessageSuccess') {
        closeOverlay();
      } else if (data.data.updatePinnedMessage.__typename === 'MaximumPinnedReachedError') {
        openToast({
          text: 'Too many messages are pinned. Unpin a message and try again',
          variant: 'error',
        });
      } else {
        openToast({
          text: 'This message could not be pinned at this time. Try again.',
          variant: 'error',
        });
      }
    },
    onError: () => {
      openToast({
        text: 'This message could not be pinned at this time. Try again.',
        variant: 'error',
      });
    },
  });

  const { mutateAsync: removePinnedMessage, isLoading: isRemovingPin } = useMutation(
    RemovePinnedMessageDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (data.data.removePinnedMessage.__typename === 'MutationRemovePinnedMessageSuccess') {
          closeOverlay();
        } else {
          openToast({
            text: 'This message could not be unpinned at this time. Try again.',
            variant: 'error',
          });
        }
      },
      onError: () => {
        openToast({
          text: 'This message could not be unpinned at this time. Try again.',
          variant: 'error',
        });
      },
    },
  );

  const createMessageReactionMutation = useMutation(CreateMessageReactionDocument, {
    retry: 3,
    onSuccess: data => {
      if (data.data.createMessageReaction.__typename === 'MutationCreateMessageReactionSuccess') {
        closeOverlay();
      } else {
        openToast({
          text: 'This message could not be reacted to at this time. Try again.',
          variant: 'error',
        });
      }
    },
    onError: () => {
      openToast({
        text: 'This message could not be reacted to at this time. Try again.',
        variant: 'error',
      });
    },
  });

  const removeMessageReactionMutation = useMutation(DeleteMessageReactionDocument, {
    retry: 3,
    onSuccess: data => {
      if (data.data.deleteMessageReaction.__typename === 'MutationDeleteMessageReactionSuccess') {
        closeOverlay();
      } else {
        openToast({
          text: 'This message reaction could not be removed at this time. Try again.',
          variant: 'error',
        });
      }
    },
    onError: () => {
      openToast({
        text: 'This message reaction could not be removed at this time. Try again.',
        variant: 'error',
      });
    },
  });

  const message = getFragment(MessageBubbleFragmentDoc, messageFrag);
  const { id, pinnedPriority, myReactions, createdAt, content } = message;

  const { reactionUpdate, removeMessage } = useVaultMessageChannel();

  const { copy } = useCopy({
    text: content,
    successMessage: 'Message copied!',
  });

  const onMessageCopy = useStableCallback(async () => {
    await copy();
    closeOverlay();
  });

  const onDeleteClick = useStableCallback(async () => {
    async function execute() {
      const { revert } = removeMessage({ messageId: id });

      const result = await deleteMessage({ input: { messageId: id } }).catch(error => {
        Sentry.captureException(error, {
          extra: {
            messageFrag,
          },
          tags: {
            type: 'deleteMessage',
          },
        });

        return {
          data: {
            deleteMessage: {
              __typename: 'Error',
              message: 'An error occurred',
            },
          },
        };
      });

      if (result.data.deleteMessage.__typename !== 'MutationDeleteMessageSuccess') {
        revert();
        openToast({
          text: (
            <p>
              This message could not be deleted at this time.{' '}
              <span
                className="cursor-pointer !text-base-m font-semibold underline"
                onClick={() => {
                  trackEvent({
                    type: EVENTS.RETRY,
                    properties: { type: 'delete_message', component: 'toast' },
                    pathname,
                  });

                  execute();
                }}
              >
                Try again.
              </span>
            </p>
          ),
          variant: 'error',
        });
      }
    }

    execute();
    closeOverlay();
  });

  const onPinClick = useStableCallback(() => {
    if (pinnedPriority == null) {
      pinMessage({ input: { messageId: id } });
    } else {
      removePinnedMessage({ input: { messageId: id } });
    }
  });

  const myReactionsObj = keyBy(myReactions, v => v.type + v.emojiKeyword);

  const onMessageReactionClick = useStableCallback(async (reaction: EmojiClickData) => {
    const emojiKeyword = convertEmojiToKeyword(reaction.emoji);
    const userId = loggedInUser?.id;
    if (!userId) return;

    const execute = async () => {
      if (myReactionsObj[MessageReactionTypeInput.Emoji + emojiKeyword] == null) {
        const { revert } = reactionUpdate({
          created: true,
          id,
          reactionType: MessageReactionTypeInput.Emoji,
          userId,
          reactionTotalCount: null,
          isArtistReaction: isOwner,
          asArtistId: loggedInUser.artist?.id || null,
          emojiKeyword,
        });

        const result = await createMessageReactionMutation
          .mutateAsync({
            input: {
              messageId: message.id,
              reactionType: MessageReactionTypeInput.Emoji,
              asArtistId: loggedInUser.artist?.id,
              emojiKeyword,
            },
          })
          .catch(error => {
            Sentry.captureException(error, {
              extra: {
                messageFrag,
                type: MessageReactionTypeInput.Emoji,
                emojiKeyword,
              },
              tags: {
                type: 'createMessageReaction',
              },
            });

            return {
              data: {
                createMessageReaction: {
                  __typename: 'Error',
                  message: 'An error occurred',
                },
              },
            };
          });

        if (
          result.data.createMessageReaction.__typename !== 'MutationCreateMessageReactionSuccess'
        ) {
          revert();
          openToast({
            text: (
              <p>
                This message could not be reacted to at this time.{' '}
                <span
                  className="cursor-pointer !text-base-m font-semibold underline"
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.RETRY,
                      properties: { type: 'create_reaction', component: 'toast' },
                      pathname,
                    });

                    execute();
                  }}
                >
                  Try again.
                </span>
              </p>
            ),
            variant: 'error',
          });
        }
      } else {
        const { revert } = reactionUpdate({
          created: false,
          id,
          reactionType: 'EMOJI',
          userId,
          reactionTotalCount: null,
          isArtistReaction: isOwner,
          asArtistId: loggedInUser.artist?.id || null,
          emojiKeyword,
        });
        const result = await removeMessageReactionMutation
          .mutateAsync({
            input: {
              messageId: message.id,
              reactionType: MessageReactionTypeInput.Emoji,
              asArtistId: loggedInUser.artist?.id,
              emojiKeyword,
            },
          })
          .catch(error => {
            Sentry.captureException(error, {
              extra: {
                messageFrag,
                type: MessageReactionTypeInput.Emoji,
                emojiKeyword,
              },
              tags: {
                type: 'removeMessageReaction',
              },
            });

            return {
              data: {
                deleteMessageReaction: {
                  __typename: 'Error',
                  message: 'An error occurred',
                },
              },
            };
          });
        if (
          result.data.deleteMessageReaction.__typename !== 'MutationDeleteMessageReactionSuccess'
        ) {
          revert();
          openToast({
            text: (
              <p>
                This message reaction could not be removed at this time.{' '}
                <span
                  className="cursor-pointer !text-base-m font-semibold underline"
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.RETRY,
                      properties: { type: 'delete_reaction', component: 'toast' },
                      pathname,
                    });

                    execute();
                  }}
                >
                  Try again.
                </span>
              </p>
            ),
            variant: 'error',
          });
        }
      }
    };

    execute();
  });

  const items = [
    {
      label: 'Reply',
      icon: faReply,
      className: 'text-white',
      action: () => {
        setReplyToMessage(
          makeFragmentData(
            {
              id: message.id,
              user: message.user,
              content: message.content,
              createdAt: message.createdAt,
              vaultContent: message.vaultContent,
              asArtist: message.asArtist,
              messageAttachments: message.messageAttachments,
              source: message.source,
              activeSubscriptionTier: message.activeSubscriptionTier,
            },
            ReplyToMessageFragmentDoc,
          ),
        );
        closeOverlay();
        if (parentType === 'see_details') {
          navigate(-1);
        }
      },
      event: {
        type: EVENTS.SET_REPLY_TO_MESSAGE,
        properties: {
          messageId: id,
          type: 'elevated',
        },
      },
    },
    message.content && {
      label: 'Copy',
      icon: faCopy,
      className: 'text-white',
      action: () => onMessageCopy(),
      event: {
        type: EVENTS.COPY_MESSAGE,
        properties: { messageId: id },
      },
    },
    isOwner && {
      label: pinnedPriority == null ? 'Pin' : 'Unpin',
      image: THUMBTACK,
      className: 'text-white',
      isLoading: isPinning || isRemovingPin,
      action: onPinClick,
      event: {
        type: pinnedPriority == null ? EVENTS.PIN_MESSAGE : EVENTS.UNPIN_MESSAGE,
        properties: { messageId: id },
      },
    },
    (isAuthor || isOwner || loggedInUser?.isAdmin) && {
      label: 'Delete',
      icon: faTrashCan,
      className: 'text-delete',
      isLoading: isDeleting,
      action: onDeleteClick,
      event: {
        type: EVENTS.DELETE_MESSAGE,
        properties: {
          messageId: id,
          timeActive: Math.abs(Date.now() - new Date(createdAt).getTime()),
        },
      },
    },
  ].filter(Boolean);
  const translateY = 48 * items.length + 4;

  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <>
      {isAnimationComplete && (
        <motion.div
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.2 }}
          className={twMerge(
            isAuthor ? 'right-0 mx-4' : 'left-0',
            'absolute z-above2 flex -translate-y-14',
          )}
        >
          <Picker
            theme={Theme.DARK}
            style={{
              border: 'none',
            }}
            skinTonesDisabled
            reactionsDefaultOpen
            onReactionClick={onMessageReactionClick}
            onEmojiClick={onMessageReactionClick}
            emojiVersion="11.0"
            reactions={['2764-fe0f', '1f44d', '1f525', '1f923']} // Emoji universal code for heart, thumbs up, fire, and laughing emoji
            width={300}
            autoFocusSearch={false}
          />
        </motion.div>
      )}

      <View className="flex max-w-[67vw] select-none flex-col items-end md:max-w-[420px]">
        <MessageBubbleInner
          message={messageFrag}
          isAuthor={isAuthor}
          type="elevated"
          artistProfileImageUrl={artistProfileImageUrl}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          isVaultArtist={isVaultArtist}
        />
      </View>
      {isAnimationComplete && (
        <motion.div
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.2 }}
          style={{ transform: `translateY(${translateY}px)` }}
          className={twMerge(
            isAuthor ? 'right-0' : 'left-0',
            'z-10 absolute bottom-0 flex flex-col rounded-lg bg-base700',
          )}
        >
          {items.map((item, index) => (
            <Button
              key={item.label}
              label={item.label}
              trailingImage={item.image}
              imageSize={16}
              trailingIcon={item.icon}
              onClick={item.action}
              className={twMerge(
                'h-[45px] w-[200px] select-none justify-between px-4 font-base text-[16px]/[20px] font-normal text-white',
                index !== items.length - 1 &&
                  'border-0 border-b-[0.5px] border-solid border-[rgba(255,255,255,0.2)]',
                item.className,
              )}
              hideIconWhenLoading
              loading={item.isLoading}
              disabled={item.isLoading}
              event={item.event}
            />
          ))}
        </motion.div>
      )}
    </>
  );
};
