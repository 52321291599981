import { useState } from 'react';
import { isEqual } from 'lodash-es';
import { useNavigate } from 'react-router';
import { subscribe, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';

import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { CreateAnnouncementDocument } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { PersistenceStorage } from '../../utils/storeUtils';
import {
  clearFields,
  formatSelectedDate,
  setError,
  setField,
  setFieldsToUndefined,
  validateField,
} from './helpers';
import { AnnouncementFieldSchema } from './schema';
import { announcementState, initialAnnouncementState } from './store';

gql(/* GraphQL */ `
  mutation CreateAnnouncement($input: MutationCreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      __typename
      ... on MutationCreateAnnouncementSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }
`);

// RefetchOnComplete({
//   trigger: [CreateAnnouncementDocument],
//   refetch: [GetPaginatedVaultAnnouncementsDocument],
// });

const version = '0.1';

const storageKey = `@vault/announcement${ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT}-${version}`;

const AnnouncementPersistence = PersistenceStorage({
  schema: AnnouncementFieldSchema.partial(),
  key: storageKey,
  eager: true,
});

if (typeof window !== 'undefined') {
  AnnouncementPersistence.initialValue
    .then(value => {
      if (!value) return;
      Object.assign(announcementState.fields, value);
    })
    .catch(
      // eslint-disable-next-line no-console
      console.error,
    )
    .finally(() => {
      subscribe(
        announcementState.fields,
        () => {
          if (isEqual(announcementState.fields, initialAnnouncementState().fields)) return;
          // Debounce storage updates to minimize CPU Usage on frequent state updates
          const pendingStorageSet = setTimeout(() => {
            const data = setFieldsToUndefined(announcementState.fields);
            const parseToStore = AnnouncementFieldSchema.partial().safeParse(data);
            // This way we ensure that we are inserting valid data into the storage

            if (parseToStore.success) {
              AnnouncementPersistence.set(parseToStore.data);
            }
            clearTimeout(pendingStorageSet);
          }, 0);
        },
        true,
      );
    });
}

export const useAnnouncementForm = () => {
  const { fields, errors } = useSnapshot(announcementState, {
    sync: true,
  });
  const { openToast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync, isLoading: announcmentCreationLoading } = useMutation(
    CreateAnnouncementDocument,
    {},
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);

  const hasError = Object.values(errors).some(error => error !== null);

  const enableSubmit =
    !hasError &&
    fields.announcement?.length > 0 &&
    !isSubmitting &&
    !hasSubmitError &&
    !announcmentCreationLoading;

  const clearAllFields = useStableCallback(() => {
    AnnouncementPersistence.clear();
    clearFields();
  });

  const onSubmit = useStableCallback(
    async ({ artistId, artistHandle }: { artistId: string; artistHandle: string }) => {
      if (!enableSubmit) return;

      setIsSubmitting(true);
      const input = {
        artistId,
        content: fields.announcement,
        featureAccess: fields.accessType,
        scheduledAt: fields.date?.toISOString() ?? null,
      };

      try {
        const { data } = await mutateAsync({
          input,
        });

        if (data.createAnnouncement.__typename === 'ValidationError') {
          setHasSubmitError(true);
          openToast({
            text: 'There was a validation error on the announcement',
            variant: 'error',
          });
          // logError({
          //   type: 'CUSTOM',
          //   action: 'CREATE_ANNOUNCEMENT',
          //   error: data.createAnnouncement.message,
          //   level: 'warning',
          //   message: 'Validation error creating announcement',
          //   indexedTags: {
          //     artistId,
          //   },
          // });
        }

        if (data.createAnnouncement.__typename === 'NotAuthorizedError') {
          setHasSubmitError(true);
          openToast({
            text: 'You are not authorized to create an announcement',
            variant: 'error',
          });
        }

        if (data.createAnnouncement.__typename === 'MutationCreateAnnouncementSuccess') {
          const formatDate = formatSelectedDate({ date: fields.date });
          const isNow = formatDate === 'Now';
          const toastContent = isNow
            ? 'Your announcement has been sent'
            : `Announcement scheduled for ${formatDate}`;
          setHasSubmitError(false);

          // logInfo({
          //   type: 'CUSTOM',
          //   action: 'ANNOUNCEMENT_CREATED',
          //   message: 'Announcement created',
          //   data: {
          //     artistHandle,
          //   },
          // });
          trackEvent({
            type: EVENTS.CREATE_ANNOUNCEMENT,
            properties: {
              artistId,
              announcementId: data.createAnnouncement.data.id,
            },
          });

          navigate(artistNavigationPath(artistHandle, '/announcements'));
          openToast({
            text: toastContent,
            variant: 'success',
          });
          setTimeout(() => {
            clearAllFields();
          }, 5000);
        }
      } catch (error) {
        // logError({
        //   type: 'CUSTOM',
        //   action: 'CREATE_ANNOUNCEMENT',
        //   error,
        //   level: 'warning',
        //   message: 'Error creating announcement',
        //   indexedTags: {
        //     artistId,
        //   },
        // });
        openToast({
          text: 'There was an error creating the announcement',
          variant: 'error',
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  );

  return {
    fields,
    errors,
    enableSubmit,
    onSubmit,
    clearFields: clearAllFields,
    isSubmitting,
    setField,
    validateField,
    setError,
  };
};
