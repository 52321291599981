import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import {
  CreateConnectedAccountDocument,
  GetExternalAccountDocument,
} from '../../graphql/generated';

const PayoutsRefreshPage: FC = () => {
  const { loggedInUser } = useAuthContext();

  const artistId = loggedInUser?.artist?.id;

  const {
    data: externalAccountData,
    isError: isErrorExternalAccount,
    isLoading: isLoadingExternalAccount,
    refetch: refetchExternalAccount,
  } = useQuery(GetExternalAccountDocument, {
    staleTime: 0,
    enabled: loggedInUser?.artist?.id != null,
    variables: !!artistId && { artistId },
  });

  const {
    data: connectedAccountData,
    isError: isErrorConnectedAccount,
    refetch: refetchConnectedAccount,
  } = useQuery(CreateConnectedAccountDocument, {
    staleTime: Infinity,
    enabled: !isLoadingExternalAccount && loggedInUser?.artist?.id != null,
    variables: !!artistId && { artistId },
  });

  const redirectAccountLink = useMemo(() => {
    if (
      connectedAccountData?.data.createConnectedAccount.__typename ==
      'MutationCreateConnectedAccountSuccess'
    ) {
      return connectedAccountData.data.createConnectedAccount.data.accountLinkUrl;
    }
    return undefined;
  }, [connectedAccountData]);

  useEffect(() => {
    if (redirectAccountLink != null) {
      window.location.href = redirectAccountLink;
    }
  }, [redirectAccountLink]);

  if (
    isErrorExternalAccount ||
    isErrorConnectedAccount ||
    externalAccountData?.data.externalAccount?.__typename === 'StripeApiError' ||
    connectedAccountData?.data.createConnectedAccount.__typename === 'StripeApiError'
  ) {
    return (
      <SettingsLayout
        title="Payouts"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={() => {
              refetchExternalAccount();
              refetchConnectedAccount();
            }}
            loggingType="payouts_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  return <SettingsLayout title="Payouts Refresh" />;
};

export { PayoutsRefreshPage };
