import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { LoginStatus } from '../types/authTypes';
import { constructQueryParams } from '../utils/stringUtils';
import { useSearchParams } from './params';

const ALLOWED_TO_REDIRECT_ROUTES = [ROUTES.EXPLORE, ROUTES.VAULTS];

export function useRedirectUserOnboarding() {
  const navigate = useNavigate();
  const { psid, openBottomSheet, c, invite } = useSearchParams();
  const { loggedInUser, loginStatus } = useAuthContext();
  const location = useLocation();
  const currPath = location.pathname;

  const hasPsid = psid != null;

  const isAllowedToRedirectRoute = useMemo(() => {
    return ALLOWED_TO_REDIRECT_ROUTES.some(path => currPath === path);
  }, [currPath]);

  useEffect(() => {
    if (
      loginStatus !== LoginStatus.LOGGED_IN ||
      !loggedInUser ||
      hasPsid ||
      !isAllowedToRedirectRoute
    )
      return;

    const queryParams = constructQueryParams({
      openBottomSheet,
      c,
      invite,
    });

    if (loggedInUser?.username == null) {
      setTimeout(
        () => navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`),
        500,
      );
    }
  }, [
    loggedInUser,
    loginStatus,
    navigate,
    hasPsid,
    isAllowedToRedirectRoute,
    openBottomSheet,
    psid,
    c,
    invite,
  ]);
}
