import { type RefObject, useEffect, useRef, useState } from 'react';
import { useStableCallback } from './useStableCallback';

export function useDrag(
  ref: RefObject<HTMLElement>,
  {
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onDragLeft,
    onDragRight,

    directionThreshold = 5,
  }: {
    onPointerDown?: (e: PointerEvent) => void;
    onPointerUp?: () => void;
    onPointerMove?: (e: PointerEvent) => void;
    onDragRight?: (data: { amount: number }) => void;
    onDragLeft?: (data: { amount: number }) => void;

    directionThreshold?: number;
  },
) {
  const [isDragging, setIsDragging] = useState(false);

  const fromGrabPosition = useRef({ x: 0, y: 0 });

  const resetDragging = useStableCallback(() => {
    setIsDragging(false);
  });

  const handlePointerDown = useStableCallback((e: PointerEvent) => {
    setIsDragging(true);

    fromGrabPosition.current = { x: e.clientX, y: e.clientY };

    onPointerDown?.(e);
  });

  const handlePointerUp = useStableCallback(() => {
    resetDragging();

    onPointerUp?.();
  });

  const handleDrag = useStableCallback(({ clientX }: { clientX: number }) => {
    if (isDragging && fromGrabPosition.current.x - clientX > directionThreshold) {
      onDragLeft?.({ amount: fromGrabPosition.current.x - clientX });
    } else if (isDragging && fromGrabPosition.current.x - clientX < -directionThreshold) {
      onDragRight?.({ amount: Math.abs(fromGrabPosition.current.x - clientX) });
    }
  });

  const handlePointerMove = useStableCallback((e: PointerEvent) => {
    onPointerMove?.(e);

    handleDrag({ clientX: e.clientX });
  });

  const handleTouchMove = useStableCallback((e: TouchEvent) => {
    const clientX = e.touches[0]?.clientX;

    if (clientX == null) return;

    handleDrag({ clientX });
  });

  useEffect(() => {
    const element = ref?.current;

    if (!element) return;

    element.addEventListener('pointerdown', handlePointerDown);
    element.addEventListener('pointerup', handlePointerUp);
    element.addEventListener('pointermove', handlePointerMove);
    element.addEventListener('touchmove', handleTouchMove);
    element.addEventListener('touchend', handlePointerUp);

    return () => {
      element.removeEventListener('pointerdown', handlePointerDown);
      element.removeEventListener('pointerup', handlePointerUp);
      element.removeEventListener('pointermove', handlePointerMove);
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handlePointerUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return { isDragging, resetDragging };
}
