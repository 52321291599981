import shuffle from 'lodash-es/shuffle';
import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';
import { loadTrack } from './AudioController';
import { AudioMeta, RepeatMode, setRepeatMode } from './AudioMeta';

export const AudioQueueSchema = z.object({
  /** List of tracks in original order to allow for unshuffling (e.g. the fetched playlist) */
  vaultTrackIds: z.array(z.string()),
  /** List of tracks in current order to allow for shuffling */
  playbackTrackIds: z.array(z.string()),
  /** Whether or not shuffle is turned on */
  shuffleEnabled: z.boolean(),
  /** Length of the queue */
  queueLength: z.number(),
});

type AudioQueueType = (typeof AudioQueueSchema)['_output'];

const initialAudioQueue: AudioQueueType = {
  vaultTrackIds: [],
  playbackTrackIds: [],
  shuffleEnabled: false,
  queueLength: 0,
};

export const AudioQueue = proxy(initialAudioQueue);

export function setVaultTrackIds({ vaultTrackIds }: { vaultTrackIds: string[] }) {
  AudioQueue.vaultTrackIds = vaultTrackIds || [];
  AudioQueue.playbackTrackIds = vaultTrackIds || [];

  shuffleTracks();
}

export const shuffleTracks = () => {
  if (!AudioQueue.shuffleEnabled) return;

  const activeTrackId = AudioMeta.activeTrackId;
  const trackIdsWithoutActiveTrack = [...AudioQueue.vaultTrackIds].filter(
    id => id !== activeTrackId,
  );

  // If there is an active track, put it at the front of the shuffled list of tracks
  AudioQueue.playbackTrackIds = activeTrackId
    ? [activeTrackId, ...shuffle(trackIdsWithoutActiveTrack)]
    : shuffle(trackIdsWithoutActiveTrack);
};

const unshuffleTracks = () => {
  if (AudioQueue.shuffleEnabled) return;

  AudioQueue.playbackTrackIds = [...AudioQueue.vaultTrackIds];
};

export const setShuffleEnabled = (shuffleEnabled: boolean) => {
  AudioQueue.shuffleEnabled = shuffleEnabled;
  if (shuffleEnabled) {
    shuffleTracks();
  } else {
    unshuffleTracks();
  }
};

export const toggleShuffleEnabled = () => {
  setShuffleEnabled(!AudioQueue.shuffleEnabled);
};

export const clearPlaybackSequence = () => {
  AudioQueue.queueLength = 0;
  AudioQueue.playbackTrackIds = [];
  AudioQueue.vaultTrackIds = [];
};

export const useAudioQueue = () => useSnapshot(AudioQueue);

export function goToPrevTrack() {
  const activeTrackId = AudioMeta.activeTrackId;

  if (!activeTrackId) return;

  const activeTrackIndex = AudioQueue.playbackTrackIds.indexOf(activeTrackId);

  if (activeTrackIndex === -1) return;

  const prevTrackIndex = activeTrackIndex - 1;

  const prevTrackId = AudioQueue.playbackTrackIds[prevTrackIndex];

  if (!prevTrackId) {
    AudioMeta.activeTrackId = null;
    return;
  }

  loadTrack({
    trackId: prevTrackId,
    vaultId: null,
    folderId: null,
  });
}

export function goToNextTrack() {
  const activeTrackId = AudioMeta.activeTrackId;

  if (!activeTrackId) return;

  const activeTrackIndex = AudioQueue.playbackTrackIds.indexOf(activeTrackId);

  if (activeTrackIndex === -1) return;

  const nextTrackIndex = activeTrackIndex + 1;

  const nextTrackId = AudioQueue.playbackTrackIds[nextTrackIndex];

  if (!nextTrackId) {
    AudioMeta.activeTrackId = null;
    return;
  }

  if (AudioMeta.repeatMode === RepeatMode.REPEAT_ONE) {
    setRepeatMode(RepeatMode.REPEAT_ALL);
  }

  loadTrack({
    trackId: nextTrackId,
    vaultId: null,
    folderId: null,
  });
}
