import { captureException } from '@sentry/react';
import { useSnapshot } from 'valtio';
import { useToast } from '../contexts/ToastContext';
import { VideoAttachment } from '../contexts/VideoMessageAttachmentContext';
import { uploadMultipartFile } from '../utils/s3Utils';

export const useVideoAttachments = () => {
  const { setVideo, updateProgress, updateVideoWithUploadResult, clearVideo } =
    useSnapshot(VideoAttachment);
  const { openToast } = useToast();

  const saveVideoAttachment = async (artistId: string, file: File) => {
    clearVideo();
    setVideo(file);

    VideoAttachment.isUploading = true;
    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: 'VIDEO',
        setProgress: bytes => {
          const progress = Math.floor((bytes / file.size) * 100);
          updateProgress(progress);
        },
        artistId,
      });
      updateVideoWithUploadResult(mediaId, cdnUrl);
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'useVideoMessageAttachments',
        },
      });
      openToast({
        text: `There was an error uploading your video. ${error}`,
        variant: 'error',
      });
      clearVideo();
    } finally {
      VideoAttachment.isUploading = false;
    }
  };

  return { saveVideoAttachment };
};
