import { type MutableRefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faBadgeCheck, faCommentMusic } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import {
  type FragmentType,
  getFragment,
  MessageBubbleFragmentDoc,
  VaultMessageAttachmentFragmentDoc,
} from '../../graphql/generated';
import { useUserDisplayName } from '../../hooks/useUserDisplayName';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { UserProfileImage } from '../user/UserProfileImage';

export function MessageCommentBubbleInner({
  message,
  type,
  isAuthor,
  isVaultArtist,
  onSingleClick,
}: {
  message: FragmentType<MessageBubbleFragmentDoc>;
  isAuthor: boolean;
  onSingleClick: () => void;
  type: 'default' | 'elevated';
  isVaultArtist: boolean;
  layoutRef: MutableRefObject<HTMLDivElement | null>;
}) {
  const { user, content, vaultContent, asArtist } = getFragment(MessageBubbleFragmentDoc, message);

  const userDisplayName = useUserDisplayName({
    artistName: asArtist?.name,
    userDisplayName: user.displayName,
    userId: user.id,
    userUsername: user.username,
  });

  return (
    <View
      className={twMerge(
        'flex max-w-full select-none flex-row items-center',
        type === 'default' && 'w-[calc(100vw-20px)]',
        isAuthor ? 'justify-end' : 'justify-start',
      )}
    >
      {(content.length !== 0 || vaultContent.length !== 0) && (
        <View
          className={twMerge(
            'rounded-[20px] border-2 border-solid p-3',
            isAuthor ? 'border-[#0085ff]' : '!border-base700',
          )}
          onClick={onSingleClick}
        >
          {vaultContent.map(messageContent => {
            const { vaultContent } = getFragment(VaultMessageAttachmentFragmentDoc, messageContent);

            return (
              <View key={messageContent.id} className="mb-1 flex w-full flex-row items-center">
                <FontAwesomeIcon icon={faCommentMusic} size="sm" className="mr-1 text-base400" />
                <Text className="line-clamp-1 max-w-[300px] font-base text-base-s text-base400">
                  <span className="font-semibold">Track comment: </span>
                  <span>{vaultContent?.title || '<untitled>'}</span>
                </Text>
              </View>
            );
          })}
          <View className={twMerge('flex flex-row items-center gap-2', isAuthor && 'justify-end')}>
            {!isAuthor && (
              <UserProfileImage
                profileImageUrl={asArtist?.profileImage?.url || user.avatar?.url}
                className="h-5 w-5 self-start"
                withVaultTheme
                fallbackColor={asArtist?.profileImage?.dominantColor ?? user.avatar?.dominantColor}
              />
            )}
            <Text className="m-0 line-clamp-2 max-w-[300px] select-none text-base-m text-white">
              {!isAuthor && (
                <span className="mr-0 pb-1 text-base-m text-white">
                  <span className={twMerge('font-semibold', isVaultArtist && 'pr-1')}>
                    {userDisplayName}
                  </span>
                  {isVaultArtist && (
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      size="sm"
                      className="select-none pr-1 text-yellow100"
                    />
                  )}
                  {': '}
                </span>
              )}

              {content.length !== 0 && <span>{content}</span>}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
}
