import { type MutableRefObject, useEffect, useState } from 'react';

const useIsVisible = ({
  scrollRef,
  visibleRef,
}: {
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
  visibleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const scrollDiv = scrollRef?.current ?? window;
    const onScroll = () => {
      if (visibleRef.current == null) {
        setIsVisible(false);
        return;
      }
      const top = visibleRef.current.getBoundingClientRect().top;
      setIsVisible(top >= 0 && top <= window.innerHeight);
    };
    scrollDiv?.addEventListener('scroll', onScroll);
    return () => scrollDiv?.removeEventListener('scroll', onScroll);
  }, [scrollRef, visibleRef]);

  return isVisible;
};

const useOnScroll = ({
  scrollRef,
  onScroll,
}: {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  onScroll: EventListener;
}) => {
  useEffect(() => {
    const scrollDiv = scrollRef.current;
    scrollDiv?.addEventListener('scroll', onScroll);
    return () => scrollDiv?.removeEventListener('scroll', onScroll);
  }, [scrollRef, onScroll]);
};

export { useIsVisible, useOnScroll };
