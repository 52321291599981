import {
  type SyntheticEvent,
  type UIEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faChevronLeft, faChevronRight } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { MediaType } from '../../graphql/generated';
import { useWindow } from '../../hooks/useWindow';
import { pluralizeText } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const MediaViewer = ({
  title,
  medias,
  startAt = 0,
  onClose,
  onEndReached,
  isLoadingNextPage,
  titles,
  subtitles,
}: {
  title: string | undefined;
  medias: { id: string; url: string; type: MediaType }[];
  startAt?: number;
  onClose: () => void;
  onEndReached?: () => Promise<number>;
  isLoadingNextPage?: boolean;
  titles?: string[];
  subtitles?: string[];
}) => {
  const { isDesktop } = useWindow();
  const [currentIndex, setCurrentIndex] = useState<number>(startAt);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentVideoRef = useRef<HTMLVideoElement | null>(null);

  const goToPrevious = () => {
    if (medias[currentIndex]?.type === MediaType.Video) {
      currentVideoRef.current?.pause();
    }
    setCurrentIndex(prevIndex => {
      const newIndex = (prevIndex - 1 + medias.length) % medias.length;
      scrollToImage(newIndex);
      return newIndex;
    });
  };

  const goToNext = async () => {
    if (medias[currentIndex]?.type === MediaType.Video) {
      currentVideoRef.current?.pause();
    }
    if (currentIndex === medias.length - 1 && onEndReached != null) {
      const newIndex = await onEndReached();

      setCurrentIndex(() => {
        scrollToImage(newIndex);
        return newIndex;
      });
    } else {
      setCurrentIndex(prevIndex => {
        const newIndex = (prevIndex + 1) % medias.length;
        scrollToImage(newIndex);
        return newIndex;
      });
    }
  };

  const scrollToImage = (index: number) => {
    const container = containerRef.current;
    if (container) {
      const child = container.firstChild as HTMLElement | null;
      const childWidth = child?.getBoundingClientRect().width || 0;
      container.scrollLeft = childWidth * index;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        onClose(); // Call the onClose function if the click is outside the container and not on a button
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    scrollToImage(currentIndex);
  }, [medias, currentIndex]);

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        async e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          let newIndex = Math.floor(scrollLeft / childWidth);

          if (newIndex !== currentIndex) {
            if (newIndex < 0) {
              newIndex += medias.length;
            }

            setCurrentIndex(newIndex % medias.length);
          }
        },
        100,
        { trailing: true },
      ),
    [currentIndex, medias.length],
  );

  const renderMedia = (media: { id: string; url: string; type: MediaType }, index: number) => {
    const maxWidth = isDesktop ? 810 : 440;
    const maxHeight = isDesktop ? 540 : window.innerHeight * 0.8;
    const [dimensions, setDimensions] = useState<{ height: number; width: number } | null>(null);
    const onLoad = (event: SyntheticEvent<HTMLImageElement | HTMLVideoElement, Event>) => {
      const width = event.currentTarget.width;
      const height = event.currentTarget.height;
      const aspectRatio = width / height;

      let actualWidth = Math.min(width, maxWidth);
      let actualHeight = actualWidth / aspectRatio;

      if (actualHeight > maxHeight) {
        actualHeight = maxHeight;
        actualWidth = actualHeight * aspectRatio;
      }

      setDimensions({ width: actualWidth, height: actualHeight });
    };

    return media.type === MediaType.Video ? (
      <motion.div
        key={media.id}
        className="relative flex w-full flex-none snap-center items-center justify-center rounded-lg"
      >
        <video
          ref={index === currentIndex ? currentVideoRef : null}
          src={media.url}
          preload="auto"
          className="h-auto max-h-[450px] w-full rounded-lg object-cover shadow-lg md2:h-[540px] md2:max-h-[540px]"
          style={{ objectFit: 'contain' }}
          controls
          controlsList="nodownload"
          onLoad={onLoad}
          height={dimensions?.height}
          width={dimensions?.width}
        />
      </motion.div>
    ) : (
      <motion.div
        key={media.id}
        className="flex w-full flex-none snap-center items-center justify-center md2:h-[540px]"
      >
        <Image
          src={media.url}
          alt={`Photo ${index + 1}`}
          className="pointer-events-none w-full rounded-lg object-contain shadow-lg"
          onLoad={onLoad}
          height={isDesktop ? maxHeight : dimensions?.height}
          width={isDesktop ? maxWidth : dimensions?.width}
        />
      </motion.div>
    );
  };

  return (
    <View className="flex h-[85vh] w-screen max-w-[600px] flex-col justify-between md2:max-w-[910px] md2:justify-center">
      <Title
        title={titles?.[currentIndex] || title || 'Medias'}
        onClose={onClose}
        subtitle={
          subtitles?.[currentIndex] ??
          `${medias.length} ${pluralizeText({ text: 'asset', count: medias.length })}`
        }
      />

      <View containerRef={contentRef} className="mx-4 flex flex-row items-center gap-4">
        {medias.length > 1 ? (
          <Button
            leadingIcon={faChevronLeft}
            iconOnly
            iconSize="xl"
            onClick={goToPrevious}
            label="Previous"
            className={twMerge(
              'rounded-full bg-transparent bg-opacity-0 md2:h-12 md2:min-w-12 md2:bg-opacity-70',
              'text-vault_text md2:bg-vault_text/10 md2:backdrop-blur-2xl',
            )}
          />
        ) : (
          // This is a placeholder to keep the layout consistent
          <View className="md2:h-12 md2:w-14" />
        )}
        <View
          containerRef={containerRef}
          className={twMerge(
            'no-scrollbar flex w-full snap-x snap-mandatory justify-center overflow-x-auto md2:rounded-b-xl',
            'text-vault_text md2:bg-vault_text/10 md2:backdrop-blur-2xl',
          )}
          onScroll={onScroll}
        >
          <View className="flex max-h-[80vh] gap-4 rounded-lg">
            {medias.map((media, index) => renderMedia(media, index))}
          </View>
        </View>

        {medias.length > 1 ? (
          <Button
            leadingIcon={faChevronRight}
            iconOnly
            iconSize="xl"
            onClick={goToNext}
            label="Next"
            className={twMerge(
              'rounded-full bg-transparent bg-opacity-0 text-white md2:h-12 md2:min-w-12 md2:bg-opacity-70',
              'text-vault_text md2:bg-vault_text/10 md2:backdrop-blur-2xl',
            )}
            loading={isLoadingNextPage}
            disabled={isLoadingNextPage}
          />
        ) : (
          // This is a placeholder to keep the layout consistent
          <View className="md2:h-12 md2:w-14" />
        )}
      </View>

      <View />
    </View>
  );
};

function Title({
  title,
  subtitle,
  onClose,
}: {
  title: string;
  subtitle: string;
  onClose: () => void;
}) {
  const { isDesktop } = useWindow();

  return (
    <View
      className={twMerge(
        'mx-4 flex flex-row justify-between md2:mx-20 md2:rounded-t-xl md2:border-0 md2:border-b md2:border-solid md2:p-4',
        'md2:border-vault_text/5 md2:bg-vault_text/10 md2:backdrop-blur-2xl',
      )}
    >
      {isDesktop ? (
        <View className="h-4 w-4" />
      ) : (
        <Button
          leadingIcon={faXmark}
          iconOnly
          onClick={onClose}
          label="Close"
          className="text-[24px] text-vault_text"
        />
      )}

      <View className="flex flex-col gap-1 text-center">
        <Text className="font-title !text-title-s font-medium text-vault_text md2:!text-title-m">
          {title}
        </Text>
        <Text className="!text-base-s text-vault_text/50 md2:!text-base-m">{subtitle}</Text>
      </View>
      {isDesktop ? (
        <Button
          leadingIcon={faXmark}
          iconOnly
          onClick={onClose}
          label="Close"
          className="text-[24px] text-vault_text"
        />
      ) : (
        <View className="h-4 w-4" />
      )}
    </View>
  );
}
