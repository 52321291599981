import { proxy, useSnapshot } from 'valtio';

export const WindowState = proxy<{
  isDesktop: boolean;
  isLg: boolean;
  height: number | null;
  width: number | null;
}>({
  isDesktop: false,
  isLg: false,
  height: null,
  width: null,
});

const desktopMinWidth = 869;
const lgMinWidth = 1023;

const isWindowWiderThan = (width: number) => window.innerWidth > width;

const handleResize = () => {
  WindowState.isDesktop = isWindowWiderThan(desktopMinWidth);
  WindowState.isLg = isWindowWiderThan(lgMinWidth);
  WindowState.height = window.innerHeight;
  WindowState.width = window.innerWidth;
};

window.addEventListener('resize', handleResize);

// Initial setup
handleResize();

export function useWindow() {
  const { isDesktop, isLg, height, width } = useSnapshot(WindowState);

  return {
    // Use this flag for conditional rendering of components, not for responsive css
    isDesktop,
    isLg,
    height,
    width,
  };
}
