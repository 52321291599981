export const FEATURE_GATES = {
  KILLSWITCH_CHAT: 'killswitch_vault_chat',
  KILLSWITCH_SUBSCRIPTION: 'killswitch_vault_subscription',
  MEMBERSHIP_V2: 'vault_membership_v2',
  SPOTIFY_CONNECT: 'vault_spotify_connect',
  SPOTIFY_REVIEW_PRESAVE: 'vault_spotify_review_presave',
  VAULT_APPLE_MUSIC_CONNECT: 'vault_apple_music_connect',
  PRESAVES: 'vault_presaves',
  STREAMS: 'vault_streams',
  TRACK_DOWNLOADS: 'vault_track_downloads',
  MASS_MESSAGING: 'vault_mass_messaging_web_v1',
} as const satisfies Record<string, string>;

export const FEATURE_GATE_OVERRIDES: Record<keyof typeof FEATURE_GATES, boolean | undefined> = {
  KILLSWITCH_CHAT: undefined,
  KILLSWITCH_SUBSCRIPTION: undefined,
  MEMBERSHIP_V2: undefined,
  SPOTIFY_CONNECT: undefined,
  SPOTIFY_REVIEW_PRESAVE: undefined,
  VAULT_APPLE_MUSIC_CONNECT: undefined,
  PRESAVES: undefined,
  STREAMS: undefined,
  TRACK_DOWNLOADS: undefined,
  MASS_MESSAGING: undefined,
};
