import { proxy } from 'valtio';

import { ScheduledEventAccessFeatureInput } from '../../graphql/generated';
import { type AnnouncementState } from './schema';

export const initialAnnouncementState = () =>
  ({
    fields: {
      announcement: '',
      accessType: ScheduledEventAccessFeatureInput.FreeEvent,
      date: null,
    },
    errors: {
      announcement: null,
      accessType: null,
      date: null,
    },
  }) satisfies AnnouncementState;

export const announcementState: AnnouncementState = proxy(initialAnnouncementState());

export type AnnouncementStateKeys = keyof AnnouncementState['fields'];
