import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { EditMassMessageView } from '../../../components/massMessage/EditMassMessageView';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMassMessageForm } from '../../../hooks/message/useMassMessageForm';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';

export function CreateMassMessagePage() {
  const { artistHandle } = useArtistHandle();
  const { loggedInUser, loginStatus } = useAuthContext();

  const { isLg } = useWindow();

  const artist = loggedInUser?.adminArtists?.find(({ artistLinks }) =>
    artistLinks.includes(artistHandle ?? ''),
  );

  const { clearErrors, clearFields } = useMassMessageForm({ vaultId: artist?.artistMainVaultId });

  useOwnerOnlyAccess();

  useVaultTheme();

  useEffect(() => {
    return () => {
      if (isLg) {
        clearErrors();
        clearFields();
      }
    };
  }, [clearErrors, clearFields, isLg]);

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme />;
  }

  if (!artist || !artistHandle || loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    <EditMassMessageView
      artistHandle={artistHandle}
      mainVaultId={artist.artistMainVaultId}
      artistId={artist.artistId}
      artistProfileImageUrl={
        artist.artistProfileImage?.mediumImageMedia[0]?.url ??
        artist.artistProfileImage?.url ??
        null
      }
      artistName={artist.artistName}
      isEdit={false}
    />
  );
}
