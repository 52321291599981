import { useEffect } from 'react';
import { proxy } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import { fetchGQL, useMutation } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  UpdateUserLastViewedMessageTimeOnVaultDocument,
  VaultMessageUnreadCountsDocument,
  VaultUpdateCountsDocument,
} from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { Sentry } from '../../../sentry';

gql(/* GraphQL */ `
  mutation UpdateUserLastViewedMessageTimeOnVault($vaultId: UUID!) {
    updateUserLastViewedMessageTimeOnVault(vaultId: $vaultId) {
      __typename
      ... on MutationUpdateUserLastViewedMessageTimeOnVaultSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserLastViewedMessageTimeOnVaultDocument],
  refetch: [VaultMessageUnreadCountsDocument, VaultUpdateCountsDocument],
  throttled: '5 seconds',
});

export const UpdatingVaultMessageEngagement = proxy({
  inProgress: 0,
});

export function useVaultMessageCountEngagement({
  vaultId,
  triggerQuery,
}: {
  vaultId: string | undefined;
  triggerQuery: boolean;
}) {
  const { loggedInUser } = useAuthContext();

  const { mutateAsync, isLoading } = useMutation(UpdateUserLastViewedMessageTimeOnVaultDocument, {
    retry: 3,
  });

  const setUserEngagement = useStableCallback(() => {
    if (!vaultId || !loggedInUser) return;

    ++UpdatingVaultMessageEngagement.inProgress;

    mutateAsync({ vaultId })
      .catch(error => {
        Sentry.captureException(error, {
          extra: {
            vaultId,
            loggedInUser,
          },
          tags: {
            type: 'updateMessageReadCountMutation',
          },
        });
      })
      .finally(() => {
        --UpdatingVaultMessageEngagement.inProgress;
      });
  });

  /*
   * We need to call this mutation twice, once when the user enters the channel page,
   * and another when the user leaves the channel page
   */
  useEffect(() => {
    if (!loggedInUser || !vaultId || !triggerQuery) return;

    const engagement = setTimeout(() => {
      setUserEngagement();
    }, 1000);

    return () => {
      setUserEngagement();

      clearTimeout(engagement);
    };
  }, [loggedInUser, vaultId, setUserEngagement, triggerQuery]);

  useEffect(() => {
    if (!loggedInUser || !vaultId || !triggerQuery) return;

    const updateMessageReadCount = () => {
      ++UpdatingVaultMessageEngagement.inProgress;
      fetchGQL(UpdateUserLastViewedMessageTimeOnVaultDocument, {
        variables: { vaultId },
        keepalive: true,
      })
        .catch(error => {
          Sentry.captureException(error, {
            extra: {
              vaultId,
              loggedInUser,
            },
            tags: {
              type: 'updateMessageReadCountMutation',
            },
          });
        })
        .finally(() => {
          --UpdatingVaultMessageEngagement.inProgress;
        });
    };

    window.addEventListener('beforeunload', updateMessageReadCount);
    window.addEventListener('focus', updateMessageReadCount);

    return () => {
      window.removeEventListener('beforeunload', updateMessageReadCount);
      window.removeEventListener('focus', updateMessageReadCount);
    };
  }, [loggedInUser, vaultId, setUserEngagement, triggerQuery]);

  return { setUserEngagement, isLoading };
}
