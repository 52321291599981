import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import millify from 'millify';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import {
  faComment,
  faCommentSlash,
  faEnvelope,
  faFileImport,
  faGlobe,
  faMobile,
  faMusic,
  faReceipt,
  faSave,
  faUser,
  faWaveform,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faTicket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faMapPin } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  BanSubscriberDocument,
  GetExposedUserInfoDocument,
  getFragment,
  GetSubscriberBanStatusDocument,
  GetUserInformationDocument,
  ReferralCodeInfoFragmentDoc,
  TierTypename,
  UnbanSubscriberDocument,
  VaultSubscriptionSourceType,
} from '../../graphql/generated';

import { useCopy } from '../../hooks/useCopy';
import type {
  ActionBottomsheetProps,
  UserProfileBottomsheetProps,
} from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { getMonthAndYear } from '../../utils/dateUtils';
import { getLocationText } from '../../utils/location';
import { Button } from '../buttons/Button';
import { ResponsiveDropdown } from '../common/Dropdown';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserProfileImage } from '../user/UserProfileImage';

gql(/* GraphQL */ `
  query GetSubscriberBanStatus($userId: UUID!, $artistId: UUID!) {
    getArtistSubscriberBanStatus(input: { userId: $userId, artistId: $artistId })
  }

  mutation BanSubscriber($input: MutationBanSubscriberFromVaultInput!) {
    banSubscriberFromVault(input: $input)
  }

  mutation UnbanSubscriber($input: MutationUnbanSubscriberFromVaultInput!) {
    unbanSubscriberFromVault(input: $input)
  }

  query GetUserInformation($input: QueryVaultSubscriberByUserIdInput!) {
    vaultSubscriberByUserId(input: $input) {
      __typename
      ... on QueryVaultSubscriberByUserIdSuccess {
        data {
          id
          phone
          email
          userLocation {
            country
            city
            region
          }
          vaultSubscriptionSourceText
          vaultSubscriptionSourceType
          artistMembership {
            receipts
          }
          createdAt
        }
      }
      ... on Error {
        message
      }
    }
  }

  query GetExposedUserInfo($input: QueryVaultSubscriberByUserIdForPublicInput!) {
    vaultSubscriberByUserIdForPublic(input: $input) {
      __typename
      ... on QueryVaultSubscriberByUserIdForPublicSuccess {
        data {
          createdAt
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [BanSubscriberDocument, UnbanSubscriberDocument],
  refetch: [GetSubscriberBanStatusDocument],
});

function AdminOptions({
  isBannedFromChat,
  userId,
  banUser,
  unbanUser,
}: {
  isBannedFromChat: boolean;
  userId: string;
  banUser: () => Promise<void>;
  unbanUser: () => Promise<void>;
}) {
  const { closeOverlay } = useOverlayContainer();

  return (
    <View className="flex flex-col items-end">
      <View className="flex flex-col rounded-lg bg-base700">
        <Button
          label={isBannedFromChat ? 'Unban from chat' : 'Ban from chat'}
          className="h-[45px] w-[200px] justify-between border-0 border-solid border-[rgba(255,255,255,0.2)] px-4 font-base text-[16px]/[20px] font-normal text-white"
          trailingIcon={isBannedFromChat ? faComment : faCommentSlash}
          imageSize={16}
          onClick={async () => {
            if (isBannedFromChat) {
              await unbanUser();
            } else {
              await banUser();
            }
            closeOverlay();
          }}
          event={{
            type: isBannedFromChat ? EVENTS.UNBAN_USER : EVENTS.BAN_USER,
            properties: { userId },
          }}
        />
        {/* todo add report button */}
      </View>
    </View>
  );
}

export const UserProfileBottomsheet: FC<UserProfileBottomsheetProps> = ({
  userId,
  avatarUrl,
  username,
  displayName,
  phone: passedPhone,
  email: passedEmail,
  receipts: passedReceipts,
  vaultSubscriptionSourceText: passedVaultSubscriptionSourceText,
  vaultSubscriptionSourceType: passedVaultSubscriptionSourceType,
  showAdminOptions,
  vaultArtistId,
  isVaultArtist,
  activeSubscriptionTier,
  referralCodeInfo,
  userLocation: passedGeoLocation,
  vaultId,
  withVaultTheme,
  joinDate: passedJoinDate,
}) => {
  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);
  const { openOverlay } = useOverlayContainer();
  const { openToast } = useToast();
  const { mutateAsync: banSubCall } = useMutation(BanSubscriberDocument, {});
  const { mutateAsync: unbanSubCall } = useMutation(UnbanSubscriberDocument, {});

  const {
    data,
    isLoading: isLoadingBanStatus,
    isError: isErrorBanStatus,
    refetch,
  } = useQuery(GetSubscriberBanStatusDocument, {
    enabled: showAdminOptions,
    variables: !!vaultArtistId && { userId, artistId: vaultArtistId },
    staleTime: 0,
  });

  const {
    data: userInfoData,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    refetch: refetchUserInfo,
  } = useQuery(GetUserInformationDocument, {
    enabled:
      showAdminOptions &&
      (passedPhone == null || passedJoinDate == null || passedGeoLocation == null),
    variables: !!vaultId && { input: { userId, vaultId } },
    staleTime: 0,
    select: data =>
      data.data.vaultSubscriberByUserId?.__typename === 'QueryVaultSubscriberByUserIdSuccess'
        ? data.data.vaultSubscriberByUserId.data
        : null,
  });

  const {
    data: exposedUserInfoData,
    isLoading: isLoadingExposedUserInfo,
    isError: isErrorExposedUserInfo,
  } = useQuery(GetExposedUserInfoDocument, {
    enabled: !showAdminOptions && passedJoinDate == null,
    variables: !!vaultId && { input: { userId, vaultId } },
    staleTime: 0,
    select: data =>
      data.data.vaultSubscriberByUserIdForPublic?.__typename ===
      'QueryVaultSubscriberByUserIdForPublicSuccess'
        ? data.data.vaultSubscriberByUserIdForPublic.data
        : null,
  });

  const isLoading = isLoadingBanStatus || isLoadingUserInfo || isLoadingExposedUserInfo;
  const isError = isErrorBanStatus || isErrorUserInfo || isErrorExposedUserInfo;

  const phone = passedPhone ?? userInfoData?.phone;
  const email = passedEmail ?? userInfoData?.email;
  const geoLocation = passedGeoLocation ?? userInfoData?.userLocation;
  const receipts = passedReceipts ?? userInfoData?.artistMembership?.receipts;
  const vaultSubscriptionSourceText =
    passedVaultSubscriptionSourceText ?? userInfoData?.vaultSubscriptionSourceText;
  const vaultSubscriptionSourceType =
    passedVaultSubscriptionSourceType ?? userInfoData?.vaultSubscriptionSourceType;
  const joinDate = passedJoinDate ?? exposedUserInfoData?.createdAt ?? userInfoData?.createdAt;

  const {
    code,
    artist: { linkValue },
  } = referralCodeInfo
    ? getFragment(ReferralCodeInfoFragmentDoc, referralCodeInfo)
    : { code: undefined, artist: { linkValue: undefined } };

  const banUser = async () => {
    if (!vaultArtistId) return;
    try {
      await banSubCall({
        input: {
          artistId: vaultArtistId,
          userId,
        },
      });
      openToast({
        text: 'You have banned this person from participating in the community chat.',
      });
    } catch (error) {
      openToast({
        text: 'There was an error banning this user. Please try again.',
        variant: 'error',
      });
    }
  };

  const unbanUser = async () => {
    if (!vaultArtistId) return;

    try {
      await unbanSubCall({
        input: {
          artistId: vaultArtistId,
          userId,
        },
      });
      openToast({
        text: 'You have unbanned this person from participating in the community chat.',
      });
    } catch (error) {
      openToast({
        text: 'There was an error unbanning this user. Please try again.',
        variant: 'error',
      });
    }
  };

  const emailCopyClick = useCopy({
    text: email,
    successMessage: 'Email copied to clipboard!',
  });
  const phoneCopyClick = useCopy({
    text: phone,
    successMessage: 'Phone number copied to clipboard!',
  });

  if (isLoading) {
    return (
      <LoadingSkeleton
        className={twMerge(
          'mb-[12px] h-[100px] w-[100px] rounded-full',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    );
  } else if (showAdminOptions && (isError || !data)) {
    return (
      <View className="flex h-full flex-col items-center">
        <ErrorView
          className="flex-grow"
          onRetryClick={() => {
            isErrorBanStatus && refetch();
            isErrorUserInfo && refetchUserInfo();
          }}
          loggingType="profile_bottomsheet"
          withVaultTheme={withVaultTheme}
        />
      </View>
    );
  }

  const isBannedFromChat = !!data?.data?.getArtistSubscriberBanStatus;
  const buttons: ActionBottomsheetProps['buttons'] = [];
  if (isBannedFromChat) {
    buttons.push({
      leadingIcon: faComment,
      label: 'Unban from chat',
      type: 'secondary',
      onClick: unbanUser,
      event: { type: EVENTS.UNBAN_USER, properties: { userId } },
    });
  } else if (isBannedFromChat === false) {
    buttons.push({
      leadingIcon: faCommentSlash,
      label: 'Ban from chat',
      type: 'secondary',
      onClick: banUser,
      event: { type: EVENTS.BAN_USER, properties: { userId } },
    });
  }

  const shouldShowAdminOptions = showAdminOptions && !isLoading;

  const showLocation =
    showAdminOptions && (!!geoLocation?.city || !!geoLocation?.country || !!geoLocation?.region);

  return (
    <View className="box-border flex w-full flex-col px-4">
      <View className="box-border flex w-full flex-col items-center px-4">
        <UserProfileImage
          className="mb-[16px] mt-[20px] h-[100px] w-[100px] rounded-full"
          profileImageUrl={avatarUrl}
          withVaultTheme
        />
        <View className="mb-4 flex flex-col items-center justify-center gap-4">
          {!!displayName && displayName.length > 0 && (
            <Text
              className={clsx(
                'flex items-center font-base !text-base-xl font-semibold',
                withVaultTheme
                  ? isVaultArtist
                    ? 'text-vault_accent'
                    : 'text-vault_text'
                  : isVaultArtist
                    ? 'text-yellow100'
                    : 'text-white',
              )}
            >
              {displayName}
              {(isVaultArtist || activeSubscriptionTier === TierTypename.PaidTier) && (
                <span>
                  <FontAwesomeIcon
                    icon={isVaultArtist ? faBadgeCheck : faTicket}
                    className={twMerge(
                      'ml-2 select-none text-[20px]',
                      withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
                    )}
                  />
                </span>
              )}
            </Text>
          )}

          <View className="flex flex-col justify-center gap-1 text-center">
            {!isVaultArtist && activeSubscriptionTier === TierTypename.PaidTier && (
              <Text
                className={twMerge(
                  '!text-base-m',
                  withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
                )}
              >
                All access pass
              </Text>
            )}
            <View className="flex flex-row justify-center gap-x-2">
              {!!username && username.length > 0 && (
                <View
                  className={twMerge(
                    '!text-base-m',
                    withVaultTheme ? 'text-vault_text/50' : 'text-base500',
                  )}
                >
                  @{username}
                </View>
              )}
              {isBannedFromChat && (
                <View
                  className={twMerge(
                    'rounded-full px-2 text-[11px] font-semibold leading-[20px]',
                    withVaultTheme
                      ? 'bg-vault_text/10 text-vault_text/50'
                      : 'bg-base800 text-destructive300',
                  )}
                >
                  Banned
                </View>
              )}
            </View>
          </View>
          <View className="flex flex-col items-center gap-2 text-center">
            {!!joinDate && (
              <View
                className={twMerge(
                  'text-center font-base !text-base-m',
                  withVaultTheme ? 'text-vault_text/60' : 'text-white',
                )}
              >
                Member since {getMonthAndYear(new Date(joinDate))}
              </View>
            )}

            {!!vaultSubscriptionSourceText && !!vaultSubscriptionSourceType && (
              <View
                className={twMerge(
                  'flex items-center gap-2 text-center font-base !text-base-m',
                  withVaultTheme ? 'text-vault_text/60' : 'text-white',
                )}
              >
                <FontAwesomeIcon
                  icon={
                    vaultSubscriptionSourceType ===
                    VaultSubscriptionSourceType.StreamingCampaignPage
                      ? faWaveform
                      : vaultSubscriptionSourceType ===
                          VaultSubscriptionSourceType.PresaveCampaignPage
                        ? faSave
                        : vaultSubscriptionSourceType ===
                            VaultSubscriptionSourceType.VaultContentPage
                          ? faMusic
                          : vaultSubscriptionSourceType === VaultSubscriptionSourceType.Import
                            ? faFileImport
                            : faGlobe
                  }
                />
                <Text>{vaultSubscriptionSourceText}</Text>
              </View>
            )}

            {membershipV2Enabled && receipts != null && (
              <View
                className={twMerge(
                  'flex items-center gap-2 text-center font-base !text-base-m',
                  withVaultTheme ? 'text-vault_text/60' : 'text-white',
                )}
              >
                <FontAwesomeIcon icon={faReceipt} />
                <Text>{millify(receipts)} receipts collected</Text>
              </View>
            )}

            {showLocation && (
              <View
                className={twMerge(
                  'flex items-center gap-2 text-center font-base !text-base-m',
                  withVaultTheme ? 'text-vault_text/60' : 'text-white',
                )}
              >
                <FontAwesomeIcon icon={faMapPin} />
                <Text>{getLocationText(geoLocation)}</Text>
              </View>
            )}

            {code && linkValue && (
              <View
                className={twMerge(
                  'text-center',
                  withVaultTheme ? 'text-vault_text/50' : 'text-base500',
                )}
              >
                {`Joined from `}
                <span className="underline">{`vault.fm/${linkValue}?code=${code}`}</span>
              </View>
            )}
          </View>
        </View>
      </View>

      {shouldShowAdminOptions && phone && (
        <Button
          leadingIcon={faMobile}
          type="secondary"
          position={email || shouldShowAdminOptions ? 'top' : 'individual'}
          label={phone}
          className={twMerge(
            'mb-[2px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10 text-vault_text' : 'text-white',
          )}
          onClick={phoneCopyClick.copy}
          event={{ type: EVENTS.COPY, properties: { type: 'phone' } }}
        />
      )}
      {shouldShowAdminOptions && email && (
        <Button
          leadingIcon={faEnvelope}
          type="secondary"
          position={shouldShowAdminOptions ? (phone ? 'middle' : 'top') : 'bottom'}
          label={email}
          className={twMerge(
            'mb-[2px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10 text-vault_text' : 'text-white',
          )}
          onClick={emailCopyClick.copy}
          event={{ type: EVENTS.COPY, properties: { type: 'email' } }}
          labelClassName="line-clamp-1 text-ellipsis"
        />
      )}
      {shouldShowAdminOptions && (
        <ResponsiveDropdown
          leadingIcon={faUser}
          buttons={buttons}
          buttonPosition={phone || email ? 'bottom' : 'individual'}
          dropdownType="Manage User"
          label="Manage User"
          desktopButtonType="secondary"
          mobileButtonType="secondary"
          desktopClassName={twMerge(
            'mb-[20px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10 text-vault_text' : 'text-white',
          )}
          mobileClassName={withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined}
          onClick={() => {
            openOverlay(
              <AdminOptions
                isBannedFromChat={isBannedFromChat}
                userId={userId}
                banUser={banUser}
                unbanUser={unbanUser}
              />,
            );
          }}
        />
      )}
    </View>
  );
};
