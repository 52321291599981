import React, { type FC, useRef } from 'react';
import { Content, type DialogOverlayProps, Overlay } from '@radix-ui/react-dialog';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons/index.mjs';

import type { ModalType } from '../../constants/modalConstants';
import { useIsOverflow } from '../../hooks/useIsOverflow';
import { useVisualViewport } from '../../hooks/useVisualViewport';
import { mergeRefs } from '../../utils/merge-refs';
//import { StyledScroll } from '../containers/RadixScroll';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import type { ViewProps } from '../common/View';
import { View } from '../common/View';

export interface ModalProps {
  backCallToAction?: React.ReactNode;
  body: React.ReactNode;
  closeModal?: () => void;
  callToAction?: React.ReactNode;
  isCentered?: boolean;
  isFullScreen?: boolean;
  size?: 'small' | 'medium' | 'large';
  subHeader?: React.ReactNode;
  title?: string;
  hideHeader?: boolean;
  showHeaderShadow?: boolean;
  showFooterShadow?: boolean;
  bodyWithoutPadding?: boolean;
  bodyWithoutPaddingBottom?: boolean;
  bodyWithoutTextStyle?: boolean;
  overlayClassName?: string;
  containerClassName?: string;
  bodyClassName?: string;
  preventClose?: boolean;
  mobileFullScreen?: boolean;
  titleClassName?: string;
  modalType?: (typeof ModalType)[keyof typeof ModalType];
  withVaultTheme?: boolean;
}

export const StandardModal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      backCallToAction,
      body,
      closeModal,
      callToAction,
      isCentered = false,
      isFullScreen = false,
      hideHeader = false,
      showHeaderShadow = false,
      showFooterShadow = false,
      size = 'small',
      subHeader,
      title,
      bodyWithoutPadding = false,
      bodyWithoutPaddingBottom = false,
      bodyWithoutTextStyle = false,
      overlayClassName,
      containerClassName,
      bodyClassName,
      preventClose,
      mobileFullScreen,
      modalType,
      withVaultTheme = false,
    },
    ref,
  ) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const hasFooter = !!callToAction || !!backCallToAction;
    const isBodyOverflow = useIsOverflow(bodyRef);
    const { height: visualViewportHeight } = useVisualViewport();

    const renderTitleContainer = (
      <View className="flex flex-col justify-center">
        {!!title && (
          <View
            className={twMerge(
              'font-title text-title-s font-medium md:text-title-l',
              !!subHeader && 'mb-2',
              isCentered && 'text-center',
              mobileFullScreen && 'text-title-m md:text-title-l',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            {title}
          </View>
        )}
      </View>
    );

    const content = (
      <ModalOverlay
        className={overlayClassName}
        type={mobileFullScreen ? 'isMobileFullScreen' : isFullScreen ? 'isFullScreen' : 'default'}
        key={modalType}
        visualViewportHeight={visualViewportHeight}
      >
        <Container
          className={containerClassName}
          onOpenAutoFocus={(event: Event) => event.preventDefault()}
          size={size}
          type={mobileFullScreen ? 'isMobileFullScreen' : isFullScreen ? 'isFullScreen' : 'default'}
          key={modalType}
          onPointerDownOutside={e => {
            if (preventClose) e.preventDefault();
          }}
          withVaultTheme={withVaultTheme}
        >
          {!hideHeader && (
            <View
              className={twMerge(
                'z-above1',
                withVaultTheme ? 'bg-vault_background' : 'bg-[#1f1f1f]',
                showHeaderShadow && '-mt-4 p-4 pb-2.5 shadow-tertiary md:-mt-6 md:p-6 md:pb-3',
              )}
            >
              <View className={twMerge('flex justify-between', isCentered && 'justify-end')}>
                {!isCentered && renderTitleContainer}
                {!!closeModal && (
                  <Button
                    leadingIcon={faXmark}
                    iconOnly
                    iconSize="lg"
                    onClick={closeModal}
                    label="Close"
                    className={twMerge(withVaultTheme ? 'text-vault_text' : 'text-white')}
                  />
                )}
              </View>
              <Text className={twMerge(withVaultTheme ? 'text-vault_text' : 'text-white')}>
                {subHeader}
              </Text>
            </View>
          )}

          <Body
            containerRef={mergeRefs([bodyRef, ref])}
            withSubtext={!!subHeader}
            isCentered={isCentered}
            withoutPadding={!subHeader && !title}
            withoutPaddingBottom={bodyWithoutPaddingBottom}
            withoutTextStyle={bodyWithoutTextStyle}
            className={twMerge(bodyClassName, isBodyOverflow && bodyWithoutPaddingBottom && 'pb-4')}
          >
            {body}
          </Body>
          {isCentered && renderTitleContainer}
          {hasFooter && (
            <Footer
              showShadow={showFooterShadow || isBodyOverflow}
              withoutPadding={bodyWithoutPadding}
            >
              {!!backCallToAction && backCallToAction}
              {!!callToAction && callToAction}
            </Footer>
          )}
        </Container>
      </ModalOverlay>
    );

    return <>{content}</>;
  },
);

const Container: FC<
  ViewProps & {
    onOpenAutoFocus: (e: Event) => void;
    size?: 'small' | 'medium' | 'large';
    type: 'isFullScreen' | 'isMobileFullScreen' | 'default';
    onPointerDownOutside: (e: Event) => void;
    withVaultTheme?: boolean;
  }
> = ({
  children,
  className,
  onOpenAutoFocus,
  size = 'small',
  type,
  onPointerDownOutside,
  withVaultTheme = false,
}) => {
  let fullScreenClassName;
  switch (type) {
    case 'isFullScreen':
      fullScreenClassName = 'w-[100vw] h-[100vh] rounded-none';
      break;
    case 'isMobileFullScreen':
      fullScreenClassName = 'w-[100vw] h-[100vh] rounded-none md:h-[unset]';
      break;
    case 'default':
    default:
      fullScreenClassName = '';
  }
  let sizeClassName;
  switch (size) {
    case 'medium':
      sizeClassName = twMerge('md:w-[600px]', type !== 'default' && 'rounded-[16px]');
      break;
    case 'large':
      sizeClassName = twMerge('md:w-[830px]', type !== 'default' && 'rounded-[16px]');
      break;
    case 'small':
    default:
      sizeClassName = twMerge('md:w-[420px]', type !== 'default' && 'rounded-[16px]');
      break;
  }
  return (
    <Content
      onOpenAutoFocus={onOpenAutoFocus}
      className={twMerge(
        'md:max-h-[100vh - 200px] relative z-modal box-border flex w-full flex-col overflow-hidden rounded-[16px] px-[16px] py-[16px] shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_-8px_28px_rgba(0,0,0,0.28)] md:w-[unset] md:px-[24px] md:py-[24px]',
        withVaultTheme ? 'bg-vault_background' : 'bg-[#1f1f1f]',
        fullScreenClassName,
        sizeClassName,
        className,
      )}
      onPointerDownOutside={onPointerDownOutside}
    >
      {children}
    </Content>
  );
};

const ModalOverlay: FC<
  DialogOverlayProps &
    React.RefAttributes<HTMLDivElement> & {
      type: 'isFullScreen' | 'isMobileFullScreen' | 'default';
      visualViewportHeight: number;
    }
> = ({ children, type = 'default', className, visualViewportHeight }) => {
  let fullScreenClassName;
  switch (type) {
    case 'isFullScreen':
      fullScreenClassName = 'p-0';
      break;
    case 'isMobileFullScreen':
      fullScreenClassName = 'p-0 md:p-[16px]';
      break;
    case 'default':
    default:
      fullScreenClassName = '';
      break;
  }

  return (
    <Overlay
      className={twMerge(
        `fixed inset-0 z-overlay grid h-full max-h-[${
          visualViewportHeight || '100vh'
        }] place-items-center bg-[rgba(0,0,0,0.6)] p-[16px]`,
        fullScreenClassName,
        className,
      )}
    >
      {children}
    </Overlay>
  );
};

const Body: FC<
  ViewProps & {
    withoutPadding?: boolean;
    withSubtext?: boolean;
    isCentered?: boolean;
    withoutPaddingBottom?: boolean;
    withoutTextStyle?: boolean;
    withVaultTheme?: boolean;
  }
> = ({
  children,
  withSubtext = false,
  withoutPadding = false,
  withoutPaddingBottom = false,
  isCentered = false,
  withoutTextStyle = false,
  className,
  withVaultTheme = false,
}) => {
  let variantClassName = '';
  if (withoutPadding) {
    variantClassName = 'p-0';
  } else if (withSubtext) {
    variantClassName = 'py-[16px]';
  } else if (isCentered) {
    variantClassName = 'pt-0 pb-[24px]';
  } else if (withoutPaddingBottom) {
    variantClassName = 'pt-[24px] pb-0';
  }

  return (
    <div
      className={twMerge(
        'h-full overflow-y-auto py-[24px]',
        withoutTextStyle ? '' : 'font-base text-base-m md:text-base-l',
        withVaultTheme ? 'text-vault_text/70' : 'text-neutral700',
        variantClassName,
        className,
      )}
    >
      {children}
    </div>
  );
};

const Footer: FC<
  ViewProps & {
    withoutPadding?: boolean;
    isCentered?: boolean;
    showShadow?: boolean;
  }
> = ({ children, showShadow = false, withoutPadding = false, isCentered = false, className }) => {
  let variantClassName = '';
  if (isCentered) {
    variantClassName = 'mt-[32px]';
  } else if (showShadow) {
    variantClassName =
      'm-[0px_0px_-16px_0px] p-[10px_16px_16px_16px] md:m-[0px_0px_-24px_0px] md:p-[12px_24px_24px_24px] w-auto shadow-high';
  } else if (withoutPadding) {
    variantClassName = 'p-0';
  }

  return (
    <div
      className={twMerge(
        'mt-[16px] flex w-full justify-end gap-[12px] md:mt-[24px]',
        variantClassName,
        className,
      )}
    >
      {children}
    </div>
  );
};
